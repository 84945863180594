import { OperationVariables } from '@apollo/client';
import {
	CreateCaseAgreementsFromQuestionnaireVariables,
	CreateMobileV2CaseDraftItemUsageVariables,
	CreateFalckDehumidifierReportVariables,
	CreateIndoorClimateReportVariables,
	CreateMobileV2InspectionReportVariables,
	CreateMobileV2MoistureReportVariables,
	CreateMobileV2MoistureTrygReportVariables,
	ModifyDrivingSlipFileMetadataVariables,
	SetDrivingSlipDrivingStartedVariables,
	SetDrivingSlipFacadePictureTakenVariables,
	SetDrivingSlipNoWasteUsedVariables,
	SetDrivingSlipQuestionnaireCompletionStatusVariables,
	SetMobileV2DrivingSlipQuestionnaireVariables,
	SetDrivingSlipRiskEvaluationStatusVariables,
	SetMobileV2DrivingSlipStatusVariables,
	UpdateCaseRiskEvaluationAnswersVariables,
	UpdateCaseVariables,
	UploadDrivingSlipFilesVariables,
} from 'GQL';
import { IQueue } from 'Helpers';

export interface ApiMutationQueueProcessorProps {
	mutationsQueue: IQueue<ApiMutation>;
	failedMutationsQueue: IQueue<FailedApiMutation>;
	events: {
		onStart: (event: QueuedMutationEvent, totalCount: number, currentCount: number) => void;
		onSuccess: (event: QueuedMutationEvent) => void;
		onError: (event: QueuedMutationEvent) => void;
		onCompleteAll: () => void;
		queueUpdated: (count: number) => void;
	};
}

export interface QueuedMutationEvent<TKey = ApiMutationKey, TVariables = OperationVariables> {
	id?: number;
	position: number;
	date: Date;
	error?: any;
	data: ApiMutationWrapper<TKey, TVariables>;
	metadata?: any;
}

export interface QueuedMutationEvents {
	onStart(listener: (event: QueuedMutationEvent) => void): void;
	onSuccess(listener: (event: QueuedMutationEvent) => void): void;
	onError(listener: (event: QueuedMutationEvent) => void): void;
	onCompleteAll(listener: () => void): void;
}

export enum ApiMutationKey {
	Case_CreateCaseAgreements = 'case_CreateCaseAgreements',
	Case_UpdateCase = 'case_UpdateCase',
	Case_UpdateRiskEvaluationAnswers = 'case_UpdateRiskEvaluationAnswers',
	Case_CreateCaseDraftItemUsage = 'case_CreateCaseDraftItemUsage',
	DrivingSlip_SetDrivingStarted = 'drivingSlip_SetDrivingStarted',
	DrivingSlip_SetFacadePictureTaken = 'drivingSlip_SetFacadePictureTaken',
	DrivingSlip_SetNoWasteUsed = 'drivingSlip_SetNoWasteUsed',
	DrivingSlip_SetQuestionnaire = 'drivingSlip_SetQuestionnaire',
	DrivingSlip_SetQuestionnaireStatus = 'drivingSlip_SetQuestionnaireStatus',
	DrivingSlip_SetRiskEvaluationStatus = 'drivingSlip_SetRiskEvaluationStatus',
	DrivingSlip_SetStatus = 'drivingSlip_SetStatus',
	DrivingSlip_UploadFile = 'drivingSlip_UploadFile',
	DrivingSlip_ModifyDrivingSlipFileMetadata = 'drivingSlip_ModifyDrivingSlipFileMetadata',
	//Reports
	DrivingSlip_CreateInspectionReport = 'drivingSlip_CreateInspectionReport',
	DrivingSlip_CreateInspectionMovablesReport = 'drivingSlip_CreateInspectionMovablesReport',
	DrivingSlip_CreateMoistureReport = 'drivingSlip_CreateMoistureReport',
	DrivingSlip_CreateMoistureTrygReport = 'drivingSlip_CreateMoistureTrygReport',
	DrivingSlip_CreateIndoorClimateReport = 'drivingSlip_CreateIndoorClimateReport',
	DrivingSlip_CreateFalckDehumidifierReport = 'drivingSlip_CreateFalckDehumidifierReport',
}

export type ApiMutationWrapper<TKey = ApiMutationKey, TVariables = OperationVariables> = {
	key: TKey;
	variables: TVariables | undefined;
};

export type ApiMutation =
	| ApiMutationWrapper<ApiMutationKey.Case_CreateCaseAgreements, CreateCaseAgreementsFromQuestionnaireVariables>
	| ApiMutationWrapper<ApiMutationKey.Case_UpdateCase, UpdateCaseVariables>
	| ApiMutationWrapper<ApiMutationKey.Case_UpdateRiskEvaluationAnswers, UpdateCaseRiskEvaluationAnswersVariables>
	| ApiMutationWrapper<ApiMutationKey.Case_CreateCaseDraftItemUsage, CreateMobileV2CaseDraftItemUsageVariables>
	| ApiMutationWrapper<ApiMutationKey.DrivingSlip_SetDrivingStarted, SetDrivingSlipDrivingStartedVariables>
	| ApiMutationWrapper<ApiMutationKey.DrivingSlip_SetFacadePictureTaken, SetDrivingSlipFacadePictureTakenVariables>
	| ApiMutationWrapper<ApiMutationKey.DrivingSlip_SetNoWasteUsed, SetDrivingSlipNoWasteUsedVariables>
	| ApiMutationWrapper<ApiMutationKey.DrivingSlip_SetQuestionnaire, SetMobileV2DrivingSlipQuestionnaireVariables>
	| ApiMutationWrapper<ApiMutationKey.DrivingSlip_SetQuestionnaireStatus, SetDrivingSlipQuestionnaireCompletionStatusVariables>
	| ApiMutationWrapper<ApiMutationKey.DrivingSlip_SetRiskEvaluationStatus, SetDrivingSlipRiskEvaluationStatusVariables>
	| ApiMutationWrapper<ApiMutationKey.DrivingSlip_SetStatus, SetMobileV2DrivingSlipStatusVariables>
	| ApiMutationWrapper<ApiMutationKey.DrivingSlip_UploadFile, UploadDrivingSlipFilesVariables>
	| ApiMutationWrapper<ApiMutationKey.DrivingSlip_CreateInspectionReport, CreateMobileV2InspectionReportVariables>
	| ApiMutationWrapper<ApiMutationKey.DrivingSlip_CreateMoistureReport, CreateMobileV2MoistureReportVariables>
	| ApiMutationWrapper<ApiMutationKey.DrivingSlip_CreateMoistureTrygReport, CreateMobileV2MoistureTrygReportVariables>
	| ApiMutationWrapper<ApiMutationKey.DrivingSlip_CreateIndoorClimateReport, CreateIndoorClimateReportVariables>
	| ApiMutationWrapper<ApiMutationKey.DrivingSlip_CreateFalckDehumidifierReport, CreateFalckDehumidifierReportVariables>
	| ApiMutationWrapper<ApiMutationKey.DrivingSlip_ModifyDrivingSlipFileMetadata, ModifyDrivingSlipFileMetadataVariables>;

export type FailedApiMutation = ApiMutation & { error: any };
