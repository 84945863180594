import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { DeepMap, ErrorOption, FieldError } from 'react-hook-form';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { ITimeRegistrationNew } from './schemas/ITimeRegistration';
import { GetPaymentSupplementsQuery, SupplementType, TimeTrackingSupplement, TimeTrackingSupplementUpdateInputType } from '../GraphQL/indexV2';
import Dropdown from '@ssg/common/Components/Dropdown';
import Popover from '@ssg/common/Components/Popover';
import TextButton from '@ssg/common/Components/TextButton';
import Input from '@ssg/common/Components/Input';

interface TimeRegGuide {
	workTypeKey: string;
	desc: string;
}

const fakeTimeRegGuides: TimeRegGuide[] = [
	{ workTypeKey: '510', desc: 'Vagtleder tillæg anvendes når...' },
	{ workTypeKey: '600', desc: 'Vagtmand tillæg anvendes når...' },
	{ workTypeKey: '8004', desc: 'Smuds tillæg anvendes når...' },
];

interface SupLine {
	lineIndex: number;
	fieldRegister: React.Ref<HTMLInputElement>;
	fieldRegisterNumber: any;
	supplementEntry?: TimeTrackingSupplementUpdateInputType;
	paymentSupplements: GetPaymentSupplementsQuery['paymentSupplements'];
	paymentSupplementOptions: SelectOption[];
	timeRegistration: boolean;
	errors: DeepMap<ITimeRegistrationNew, FieldError>;
	setValue: (
		name: string,
		value: unknown,
		config?:
			| Partial<{
				shouldValidate: boolean;
				shouldDirty: boolean;
			}>
			| undefined,
	) => void;
	getValues: any;
	setError: (name: string, error: ErrorOption) => void
	clearErrors: (name: string | string[] | undefined) => void
	removeLine: (index?: number | number[] | undefined) => void;
}

const SupplementLineNew: React.FC<SupLine> = ({ lineIndex, fieldRegister, fieldRegisterNumber, supplementEntry, paymentSupplements, paymentSupplementOptions, timeRegistration, errors, getValues, setValue, setError, clearErrors, removeLine }) => {
	const { t } = useTranslation();

	const [activeSupplementCode, setActiveSupplementCode] = React.useState(supplementEntry?.paymentSupplementCode);
	
	const convertTypeToEnum = (type: string): SupplementType | undefined => {
		switch (type) {
			case 'Stand-Alone':
				return SupplementType.StandAlone;
			case 'Surcharge':
				return SupplementType.Surcharge;
			case 'Piecework':
				return SupplementType.Piecework;
			default:
				return undefined;
		}
	};
	
	// Used for setting supplement values when selecting a supplement
	React.useEffect(() => {
		if (typeof activeSupplementCode === 'undefined' || activeSupplementCode === null) {
			return;
		}
	
		const supplement: GetPaymentSupplementsQuery['paymentSupplements'][number] | undefined = paymentSupplements?.find(s => s?.code === activeSupplementCode);
	
		if (typeof supplement === 'undefined' || supplement === null) {
			return;
		}
	
		setValue(`addonLines[${lineIndex}].paymentSupplementCode`, supplement?.code ?? '');
		setValue(`addonLines[${lineIndex}].paymentSupplementName`, supplement?.description ?? '');
		setValue(`addonLines[${lineIndex}].supplementUom`, supplement?.uOM ?? '');
		setValue(`addonLines[${lineIndex}].supplementType`, convertTypeToEnum(supplement?.type ?? ''));
		setValue(`addonLines[${lineIndex}].workTypeCode`, supplement?.workTypeCode ?? '');
	}, [activeSupplementCode, lineIndex, paymentSupplements, setValue]);

	React.useEffect(() => {
		if (lineIndex === 0) {
			clearErrors(`addonLines[${lineIndex}].paymentSupplementCode`);
		}
	}, [clearErrors, lineIndex]);

	return (
		<>
			<div className="flex flex-row">
				<div className="mr-3 w-full">
					<Dropdown
						name={`addonLines[${lineIndex}].paymentSupplementCode`}
						className="mt-2"
						data={[{ label: '', value: '' }, ...paymentSupplementOptions]}
						onChange={e => setActiveSupplementCode(e.currentTarget.value)}
						defaultValue={supplementEntry?.paymentSupplementCode ?? ''}
						disabled={!timeRegistration}
						onBlur={(e) => {
							const supplementId = e.currentTarget.value;
							const paymentSupplement = paymentSupplements.find(s => s?.code === supplementId);
							if (paymentSupplement) {
								if ((
									paymentSupplement.type === 'Stand-Alone' && lineIndex > 0) ||
									(paymentSupplement.code === '8010' && lineIndex > 0) ||
									(paymentSupplement.code === '8011' && lineIndex > 0)) {
									setError(`addonLines[${lineIndex}].paymentSupplementCode`, {
										type: 'custom',
										message: t('timeRegistration.standAloneSupplementError'),
									});
								} else {
									clearErrors(`addonLines[${lineIndex}].paymentSupplementCode`);
								}
							}
						}
						}
						errorMessage={errors.addonLines ? errors.addonLines[lineIndex]?.paymentSupplementCode?.message : ''}
					/>
				</div>

				<div className="mr-3 w-24">
					<Input
						name={`addonLines[${lineIndex}].hours`}
						type="number"
						step={0.25}
						lang="da"
						inputMode="decimal"
						className="mt-2"
						min={(paymentSupplements.find(s => s?.code === activeSupplementCode)?.uOM ?? '') === 'STK' ? 1 : 0.25}
						max={(paymentSupplements.find(s => s?.code === activeSupplementCode)?.uOM ?? '') === 'STK' ? 1 : undefined}
						unit={paymentSupplements.find(s => s?.code === activeSupplementCode)?.uOM ?? ''}
						innerRef={fieldRegisterNumber}
						onWheel={e => e.currentTarget.blur()} // Used for disabling scroll to change number value
						defaultValue={supplementEntry?.hours}
						// onChange={(e) => setValue(`addonLines[${lineIndex}].hours`, e.target.value, { shouldValidate: true })}
						onBlur={() => {
							if (paymentSupplements.find(s => s?.code === activeSupplementCode)?.type !== 'Surcharge') {
								return;
							}

							const addonLineEntries = getValues('addonLines') as TimeTrackingSupplement[];

							const addonLinesTotal = addonLineEntries?.filter(s => s.supplementType === SupplementType.Surcharge).reduce((acc, curr) => acc + Number(curr.hours), 0);

							const hours = getValues('hours');

							const lineTotal = addonLinesTotal;

							const isStandAloneStkSupplement = (paymentSupplements.find(s => s?.code === activeSupplementCode)?.uOM ?? '') === 'STK';
							if(isStandAloneStkSupplement) return;

							const isDrivingSupplement = (paymentSupplements.find(s => s?.code === activeSupplementCode)?.uOM ?? '') === 'KM';
							if(isDrivingSupplement) return;

							if (lineTotal > Number(hours)) {
								setError(`addonLines[${lineIndex}].hours`, {
									type: 'custom',
									message: t('timeRegistration.supplementHoursHigher'),
								});
							} else {
								clearErrors(`addonLines[${lineIndex}].hours`);
							}

							// 8010 = kørselstid standard, 8011 = kørselstid internal
							if(activeSupplementCode === '8010' || activeSupplementCode === '8011') {

								const inputDrivingHours = getValues(`addonLines[${lineIndex}].hours`);

								if (inputDrivingHours !== hours) {
									setError(`addonLines[${lineIndex}].hours`, {
										type: 'custom',
										message: t('timeRegistration.drivingSuppAndHoursDontMatch'),
									});
								} else {
									clearErrors(`addonLines[${lineIndex}].hours`);
								}
							}
						}}
						errorMessage={errors.addonLines ? errors.addonLines[lineIndex]?.hours?.message : ''}
					/>
				</div>

				{typeof paymentSupplementOptions.find(s => s.value === activeSupplementCode)?.label !== 'undefined' && (
					<div className="ml-2 mt-4 self-start">
						<Popover
							placement="top"
							content={
								<div className="rounded-b-default border-1 shadow-default border-black bg-white p-2 text-xs">
									<p className="font-bold">{paymentSupplementOptions.find(s => s.value === activeSupplementCode)?.label}</p>
									{fakeTimeRegGuides.find(g => g.workTypeKey === activeSupplementCode)?.desc}
								</div>
							}
						>
							<div>
								<FontAwesomeIcon icon={faInfoCircle} />
							</div>
						</Popover>
					</div>
				)}

				<div className="ml-2 mt-5 self-start">
					<TextButton
						icon={faTimes}
						onClick={() => removeLine()}
						iconClassName="text-red"
						disabled={typeof removeLine === 'undefined'}
					/>
				</div>
			</div>
			<div className="flex flex-row">
				<Input
					name={`addonLines[${lineIndex}].paymentSupplementName`}
					defaultValue={supplementEntry?.paymentSupplementName ?? ''}
					className="text-sm hidden"
					readOnly
					innerRef={fieldRegister}
				/>

				<Input name={`addonLines[${lineIndex}].supplementUom`} defaultValue={supplementEntry?.supplementUom ?? ''} className="text-sm hidden" readOnly innerRef={fieldRegister} />

				<Input name={`addonLines[${lineIndex}].supplementType`} defaultValue={supplementEntry?.supplementType ?? ''} className="text-sm hidden" readOnly innerRef={fieldRegister} />

				<Input name={`addonLines[${lineIndex}].workTypeCode`} defaultValue={supplementEntry?.workTypeCode ?? ''} className="text-sm hidden" readOnly innerRef={fieldRegister} />
			</div>
		</>
	);
};

export default SupplementLineNew;