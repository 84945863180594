import React, { useEffect, useState } from 'react';
import { ApolloClient, ApolloProvider, ApolloLink, InMemoryCache, NormalizedCacheObject, FieldMergeFunction, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { ConnectionStatus } from '@capacitor/network';
import QueueLink from 'apollo-link-queue';
import { createErrorLink } from '@ssg/common/Helpers/Helpers';
import { useMicrosoftAuth } from '@ssg/common/Components/MicrosoftAuth';
import Loading from '@ssg/common/Components/Loading';
import { useErrorIonicToaster } from '../Hooks/useErrorIonicToaster';
import { useAppSelector } from 'Store';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';

if (process.env.NODE_ENV === 'development') {
	// Adds Apollo error messages only in a dev environment
	loadDevMessages();
	loadErrorMessages();
}

const queueLink = new QueueLink();

const updateQueueLinkBasedOnConnectionStatus = (connected: boolean) => {
	if (connected) {
		queueLink.open();
	} else {
		queueLink.close();
	}
};

const merge: FieldMergeFunction<Array<{ __ref: string }>> = (existing = [], incoming) => {
	const incomingIds = incoming.map(x => x.__ref);
	return [...existing.filter(x => !incomingIds.includes(x.__ref)), ...incoming];
};

const ApolloAuthenticationWrapper: React.FC<{
	graphUrl: string;
	onError: (errorMessage: string) => void;
}> = ({ graphUrl, onError, children }) => {
	const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
	const { getTokens } = useMicrosoftAuth();
	const errorIonicToaster = useErrorIonicToaster();

	const defaultHttpOptions = {
		uri: graphUrl,
		credentials: 'same-origin' as RequestCredentials,
	};

	const connectionStatus = useAppSelector(state => state.localData.networkConnectionStatus);

	useEffect(() => {
		// Update queue link based on network status changes
		updateQueueLinkBasedOnConnectionStatus(connectionStatus);
	}, [connectionStatus]);

	useEffect(() => {
		const initClient = async () => {
			const errorLink = createErrorLink(graphError => {
				onError(graphError.message);
				//errorIonicToaster(graphError);
			}, true);

			const authLink = setContext(async (_, { headers }) => {
				const { appToken, bcToken } = await getTokens();

				// return the headers to the context so httpLink can read them
				return {
					headers: {
						...headers,
						authorization: appToken ? `Bearer ${appToken}` : '',
						'bc-token': bcToken ?? '',
					},
				};
			});

			const httpLink = new HttpLink(defaultHttpOptions);
			const batchHttpLink = new BatchHttpLink(defaultHttpOptions);

			const link = ApolloLink.from([errorLink, authLink]).split(operation => operation.getContext().debatch === true, authLink.concat(httpLink), authLink.concat(batchHttpLink));

			const cache = new InMemoryCache({
				typePolicies: {
					Screening: {
						keyFields: false, // Don't write this data to cache since we can't make a proper id
					},
					ScreeningLine: {
						keyFields: false, // Don't write this data to cache since we can't make a proper id
					},
					Debitor: {
						keyFields: false, // Don't write this data to cache since the police number can be different on debitors with the same id
					},
					CaseDraftUsageLine: {
						keyFields: ['erpId'],
						merge(existing = [], incoming) {
							console.log('hello');
							return [...existing, incoming];
						},
					},
					ESDHFile: {
						keyFields: ['name'],
					},
					Vehicle: {
						keyFields: ['vehicleNumber'],
					},
					Query: {
						fields: {
							drivingSlips: {
								keyArgs: [
									'personalOnly',
									'dateRange',
									'status',
									'urgent',
									'exceeded',
									'noDriver',
									'departments',
									'locations',
									'damageCauses',
									'damageCategories',
									'drivers',
									'minStartDate',
									'maxStartDate',
									'minEndDate',
									'maxEndDate',
									'case',
									'caseERPReferenceNo',
									'postalCode',
									'debitors',
									'searchString',
									'seriesId',
								],
								merge,
							},
							requisitions: {
								keyArgs: ['myRequisitionsOnly', 'caseId', 'departments', 'vendor', 'status', 'type', 'fromDate', 'toDate', 'damageCategories', 'damageCauses', 'searchString'],
								merge,
							},
							drivingSlipFiles: {
								keyArgs: ['caseNo', 'drivingSlipId'],
								merge,
							},
							caseDraftItemsUsage: {
								keyArgs: ['erpId'],
								merge(existing = [], incoming) {
									return [incoming];
								},
							},
						},
					},
				},
			});

			const apolloClient = new ApolloClient({
				link,
				cache,
				connectToDevTools: true,
			});

			setClient(apolloClient);
		};

		initClient();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!client) {
		return <Loading />;
	}

	return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloAuthenticationWrapper;

