import React from 'react';
import { useTranslation } from 'react-i18next';
import { Permissions } from '@ssg/common/GraphQL';
import BasePage from 'Components/Layout/BasePage';
import TimeRegistrationOverviewNew from '@ssg/common/TimeRegistration/TimeRegistrationOverviewNew';
import UserContext from 'UserContext';
import RejectedTimeRegistrationWarningModalNew from './RejctedTimeRegistrationWarningModalNew';

const TimeRegistration: React.FC = (): React.ReactElement => {
	const { t } = useTranslation();

	const userContext = React.useContext(UserContext);

	const timeregPermissions = {
		approver: userContext.user?.permissions.includes(Permissions.TIME_TRACKING_APPROVER),
		responsible: userContext.user?.permissions.includes(Permissions.TIME_TRACKING_RESPONSIBLE),
		datePicker: userContext.user?.permissions.includes(Permissions.TIME_TRACKING_DATE_PICKER),
	};
	
	return (
		<BasePage title={t('timeRegistration.overviewTitle')} shouldOfflineWarn>
			<TimeRegistrationOverviewNew
				userContext={{
					id: userContext.user?.id ?? '',
					permissions: timeregPermissions,
					salaryType: userContext.user?.employeeSalaryType ?? null,
					hireDate: userContext.user?.employeeHireStartDate ?? null,
				}}
				compactMode={true}
				rejectModal={<RejectedTimeRegistrationWarningModalNew userId={userContext.user?.id} />}
			/>
		</BasePage>
	);
};

export default TimeRegistration;
