import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IonAccordion, IonAccordionGroup, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonItem, IonLabel, IonModal, IonRow, IonTitle, IonToolbar, useIonModal, useIonToast } from '@ionic/react';
import {
	faQuestionCircle,
	faEye,
	faExclamationCircle,
	faMapMarker,
	faPhone,
	faBoxes,
	faFile,
	faCamera,
	faDoorOpen,
	faPlay,
	faStop,
	faPlayCircle,
	faCheckCircle,
	faHourglassStart,
	faCheck,
	faRecycle,
	faTrailer,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DrivingSlipStatus } from '@ssg/common/GraphQL';
import { CollectionItemType, GetMobileV2DrivingSlip_drivingSlip, UpdateCaseVariables, RiskEvaluationStatus, QuestionnaireCompletionStatus, FileMetadataField } from 'GQL';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { Interval } from 'luxon';
import { IVisitation } from 'Schemas/IVisitation';
import { visitationSchema } from 'Schemas/VisitationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { stringToByteArray } from '@ssg/common/Helpers/inputFileHelper';
import { UserPhoto, useSinglePhotoCapture } from 'Hooks/usePhotoCapture';
import { formatTimestamp } from '@ssg/common/Helpers/Helpers';
import { getDriverWhoCompleted } from '../DrivingSlipsOverview';
import { useHistory } from 'react-router-dom';
import BasePage from 'Components/Layout/BasePage';
import Routes from 'Routes';
import DrivingSlipRiskEvaluationModal, { Props as DrivingSlipRiskEvaluationModalProps } from './RiskEvaluation/DrivingSlipRiskEvaluationModal';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import classNames from 'classnames';
import Button from 'Components/Button';
import Textarea from '@ssg/common/Components/Textarea';
import Checkbox from 'Components/Checkbox';
import FinishDrivingSlipModal, { Props as FinishDrivingSlipModalProps } from './StatusModals/FinishDrivingSlipModal';
import ItemUsageModal, { Props as ItemUsageModalProps } from './ItemUsageModal';
import WasteModal, { Props as WasteModalProps } from './WasteModal';
import GuidesModal from './GuidesModal';
import DrivingStartedDrivingSlipModal, { Props as DrivingStartedDrivingSlipModalProps } from './StatusModals/DrivingStartedDrivingSlipModal';
import OnHoldDrivingSlipModal, { Props as OnHoldDrivingSlipModalProps } from './StatusModals/OnHoldDrivingSlipModal';
import ResumeDrivingSlipModal, { Props as ResumeDrivingSlipModalProps } from './StatusModals/ResumeDrivingSlipModal';
import StartDrivingSlipModal, { Props as StartDrivingSlipModalProps } from './StatusModals/StartDrivingSlipModal';
import { CameraSource } from '@capacitor/camera';
import { QuestionnaireSyncContext } from './Questionnaire/QuestionnaireSyncContext';
import EnvironmentVariableContext from '@ssg/common/EnvironmentVariableContext';
import { useFlag } from '@unleash/proxy-client-react';
import { ApiMutationKey, useAppSelector, useSetDrivingSlipFacadePictureTaken, useUpdateCase, useUploadDrivingSlipFile } from 'Store';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';

interface Props {
	drivingSlip: GetMobileV2DrivingSlip_drivingSlip;
}

const DrivingSlipOverview: React.FC<Props> = ({ drivingSlip }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const { siteUrlWeb } = useContext(EnvironmentVariableContext);

	const { isSyncedFacadePictureUploaded } = useContext(QuestionnaireSyncContext);
	const vehiclesMobileAppFlag = useFlag(FeatureFlagEnums.VEHICLES_MOBILE_APP);
	const [isOpen, setIsOpen] = React.useState(false);

	const [presentItemUsageModal, dismissItemUsageModal] = useIonModal(ItemUsageModal, {
		caseId: drivingSlip.case.id,
		caseNo: drivingSlip.case.erpNo,
		drivingSlipCategory: drivingSlip.category,
		onDismiss: () => dismissItemUsageModal(),
		itemUsage: drivingSlip.case.caseDraftItemsUsage,
	} as ItemUsageModalProps);

	const [presentWasteModal, dismissWasteModal] = useIonModal(WasteModal, {
		caseId: drivingSlip.case.id,
		caseNo: drivingSlip.case.erpNo,
		drivingSlip: drivingSlip,
		drivingSlipCategory: drivingSlip.category,
		defaultNoWasteUsed: drivingSlip.noWasteUsed,
		onDismiss: () => dismissWasteModal(),
		caseDraftUsageLines: drivingSlip.case.caseDraftItemsUsage,
	} as WasteModalProps);

	const [presentRiskEvaluationModal, dismissRiskEvaluationModal] = useIonModal(DrivingSlipRiskEvaluationModal, {
		drivingSlip: drivingSlip,
		riskEvaluationAnswers: drivingSlip.case.riskEvaluationAnswers,
		riskEvaluationStatus: drivingSlip.riskEvaluationStatus,
		riskEvaluationComment: drivingSlip.case.riskEvaluationComment,
		onDismiss: () => dismissRiskEvaluationModal(),
	} as DrivingSlipRiskEvaluationModalProps);

	// const [presentCaseChangelogModal, dismissCaseChangelogModal] = useIonModal(CaseChangelogModal, {
	// 	version: drivingSlip.case.version,
	// 	changes: drivingSlip.case.changes,
	// 	onDismiss: () => dismissCaseChangelogModal(),
	// } as CaseChangelogModalProps);

	const [presentFinishDrivingSlipModal, dismissFinishDrivingSlipModal] = useIonModal(FinishDrivingSlipModal, {
		drivingSlip,
		history: history,
		onDismiss: () => dismissFinishDrivingSlipModal(),
	} as FinishDrivingSlipModalProps);

	const [presentArrivedStartDrivingSlipModal, dismissArrivedStartDrivingSlipModal] = useIonModal(StartDrivingSlipModal, {
		drivingSlip,
		onDismiss: () => dismissArrivedStartDrivingSlipModal(),
	} as StartDrivingSlipModalProps);

	const [presentOnHoldDrivingSlipModal, dismissOnHoldDrivingSlipModal] = useIonModal(OnHoldDrivingSlipModal, {
		drivingSlip,
		onDismiss: () => dismissOnHoldDrivingSlipModal(),
	} as OnHoldDrivingSlipModalProps);

	const [presentResumeDrivingSlipModal, dismissResumeDrivingSlipModal] = useIonModal(ResumeDrivingSlipModal, {
		drivingSlip,
		onDismiss: () => dismissResumeDrivingSlipModal(),
	} as ResumeDrivingSlipModalProps);

	const [presentDrivingStartedDrivingSlipModal, dismissDrivingStartedDrivingSlipModal] = useIonModal(DrivingStartedDrivingSlipModal, {
		drivingSlip,
		onDismiss: () => dismissDrivingStartedDrivingSlipModal(),
	} as DrivingStartedDrivingSlipModalProps);

	const { takePhoto } = useSinglePhotoCapture();

	const [updateDrivingSlipFacadePicTaken] = useSetDrivingSlipFacadePictureTaken();

	const [uploadDrivingSlipFile] = useUploadDrivingSlipFile();

	const onFileUpload = async (filename: string, fileData: number[], comments?: string) => {
		const fileMetadata = [{ key: FileMetadataField.IsFacadePicture, value: 'true' }];

		if (comments) {
			fileMetadata.push({
				key: FileMetadataField.Comments,
				value: comments,
			});
		}

		const variables = {
			caseNo: drivingSlip.case.erpNo,
			drivingSlipId: drivingSlip.series,
			files: [{ filename, fileData, fileMetadata: fileMetadata }],
		};

		await updateDrivingSlipFacadePicTaken({
			variables: { id: drivingSlip.id },
			optimisticResponse: {
				setDrivingSlipFacadePictureTaken: {
					...drivingSlip,
					facadePictureTaken: true,
				},
			},
			queueOfflineMutationOptions: {
				key: ApiMutationKey.DrivingSlip_SetFacadePictureTaken,
				removePreviousEntry: true,
				findPreviousEntryPredicate: variables => variables.id === drivingSlip.id,
			},
		});

		await uploadDrivingSlipFile({
			variables: variables,
			queueOfflineMutationOptions: {
				key: ApiMutationKey.DrivingSlip_UploadFile,
				removePreviousEntry: false,
			},
			forceQueue: true,
		});
	};

	const [present, dismiss] = useIonToast();

	const uploadPhoto = async (photo: UserPhoto, comments?: string) => {
		const fileAsBytes = stringToByteArray(photo.base64EncodedData);
		const fileExtension = photo.format.startsWith('.') ? photo.format : `.${photo.format}`;
		const filename = photo.filepath + fileExtension;

		await onFileUpload(filename, fileAsBytes, comments);

		present({
			buttons: [{ text: t('common.hide'), handler: () => dismiss() }],
			message: t('drivingSlips.facadePictureUploaded'),
			duration: 3000,
			color: 'success',
			position: 'bottom',
		});
	};

	const [presentGuidesModal, dismissGuidesModal] = useIonModal(GuidesModal, {
		onDismiss: () => dismissGuidesModal(),
	});

	const {
		collectionItems: { data: visitationData },
		drivingSlip: { refetch: drivingSlipRefetch },
	} = useAppSelector(state => state.apiData);

	const visitationCallBackTexts = React.useMemo(() => visitationData.filter(f => f.type === CollectionItemType.VISITATION_DESCRIPTION_CALL_BACK).map(item => item.name) ?? [], [visitationData]);

	const [updateCase] = useUpdateCase();

	const { handleSubmit, register, setValue, getValues, errors } = useForm<IVisitation>({
		resolver: yupResolver(visitationSchema),
		mode: 'all',
		reValidateMode: 'onChange',
	});

	const onSubmit = async (data: IVisitation) => {
		await postCaseUpdate({
			id: drivingSlip.case.id,
			visitation: {
				urgent: drivingSlip.case.visitation.urgent,
				priorities: drivingSlip.case.visitation.priorities,
				calledBack: {
					value: data.calledBack,
					comment: data.calledBack ? data.calledBackDescription : null,
				},
				awaiting: {
					value: drivingSlip.case.visitation.awaiting.value,
					comment: drivingSlip.case.visitation.awaiting.comment,
				},
			},
		});

		setIsOpen(false);
	};

	const postCaseUpdate = async ({ id, visitation }: UpdateCaseVariables): Promise<void> => {
		await updateCase({
			variables: {
				id: id,
				visitation: visitation,
			},
			queueOfflineMutationOptions: {
				key: ApiMutationKey.Case_UpdateCase,
				removePreviousEntry: false,
			},
		});
	};

	// Used for building Google Maps URL
	const { road, houseNumber, postalCode, city } = drivingSlip.case.damage.contact.address;

	return (
		<BasePage title={t('drivingSlips.drivingSlip')} backButtonDefaultHref={Routes.DrivingSlipsOverview} refreshFunc={drivingSlipRefetch}>
			<>
				<IonAccordionGroup multiple={true} value="drivingslip" className="mb-4 border-none p-0">
					<IonAccordion value="case">
						<IonItem slot="header" color="light">
							<IonLabel>
								<div className="flex justify-between">
									<h2 className="text-blue font-semibold">{t('common.caseInfo')}</h2>
									{(drivingSlip.urgent || drivingSlip.case.skafor) && (
										<div className="flex">
											{drivingSlip.urgent && (
												<div className="text-orange mb-1 mr-3 flex flex-row items-center font-bold">
													<FontAwesomeIcon icon={faExclamationCircle} size="lg" />
													<p className="ml-1">{t('common.urgent')}</p>
												</div>
											)}

											{drivingSlip.case.skafor && (
												<div className="text-purple mb-1 mr-3 flex flex-row items-center font-bold">
													<FontAwesomeIcon icon={faExclamationCircle} size="lg" />
													<p className="ml-1">{t('case.SKAFOR')}</p>
												</div>
											)}
										</div>
									)}
								</div>
							</IonLabel>
						</IonItem>
						<div slot="content">
							<IonGrid className="text-sm">
								<IonRow>
									<IonCol size="6">
										<p className="text-blue text-base font-semibold">{t('common.debitor')}</p>
										<p>{drivingSlip.case.debitor.company}</p>
										<p>{drivingSlip.case.debitor.address?.addressLine}</p>
										<p>{`${drivingSlip.case.debitor.address?.postalCode} ${drivingSlip.case.debitor.address?.city}`}</p>
										<p>
											<a
												href={`tel:${drivingSlip.case.policyHolder.phone}`}
												className={classNames('hover:underline', {
													hidden: !drivingSlip.case.policyHolder.phone,
												})}
											>
												{t('common.tel')} {formatPhoneNumberIntl(drivingSlip.case.policyHolder.phone)}
											</a>
										</p>
										<p>
											<a
												href={`mailto:${drivingSlip.case.policyHolder.email}`}
												className={classNames('hover:underline', {
													hidden: !drivingSlip.case.policyHolder.email,
												})}
											>
												{t('common.mail')} {drivingSlip.case.policyHolder.email}
											</a>
										</p>
									</IonCol>

									<IonCol size="6">
										<p className="text-blue text-base font-semibold">{t('case.injuredParty')}:</p>
										<p>{drivingSlip.case.damage.contact.name}</p>
										<p>
											{drivingSlip.case.damage.contact.address.road} {drivingSlip.case.damage.contact.address.houseNumber} {drivingSlip.case.damage.contact.address.floor ?? ''}
										</p>
										<p>{`${drivingSlip.case.damage.contact.address.postalCode} ${drivingSlip.case.damage.contact.address.city}`}</p>
										<p>
											<a
												href={`tel:${drivingSlip.case.damage.contact.phone}`}
												className={classNames('hover:underline', {
													hidden: !drivingSlip.case.damage.contact.phone,
												})}
											>
												{t('common.tel')} {formatPhoneNumberIntl(drivingSlip.case.damage.contact.phone)}
											</a>
										</p>
										<p>
											<a
												href={`mailto:${drivingSlip.case.damage.contact.email}`}
												className={classNames('hover:underline', {
													hidden: !drivingSlip.case.damage.contact.email,
												})}
											>
												{t('common.mail')} {drivingSlip.case.damage.contact.email}
											</a>
										</p>
									</IonCol>

									<IonCol size="6">
										<p className="text-blue text-base font-semibold">{t('planner.projectNo')}.:</p>
										<p>{drivingSlip.case.erpNo}</p>
										<p className="text-blue mt-2 text-base font-semibold">{t('case.takscode')}:</p>
										<p>{drivingSlip.case.damage.category.name}</p>
										<p className="text-blue mt-2 text-base font-semibold">{t('planner.damageType')}:</p>
										<p>{drivingSlip.case.damage.cause.name}</p>

										{/* <RelatedCatalogs address={drivingSlip.case.damage.contact.address} debitorId={drivingSlip.case.debitor.debitorId} /> */}
									</IonCol>

									<IonCol size="6">
										<p className="text-blue text-base font-semibold">{t('case.ssgProjectManager')}:</p>
										<p>{drivingSlip.case.projectManager?.name}</p>
										<p>
											<a
												href={`tel:${drivingSlip.case.damage.contact.phone}`}
												className={classNames('hover:underline', {
													hidden: !drivingSlip.case.projectManager?.phone,
												})}
											>
												{t('common.tel')} {formatPhoneNumberIntl(drivingSlip.case.projectManager?.phone ?? '')}
											</a>
										</p>
										<p>
											{t('case.ssgCaseManager')}: {drivingSlip.case.caseManager?.name}
											<br />
											<a
												href={`tel:${drivingSlip.case.caseManager?.phone}`}
												className={classNames('hover:underline', {
													hidden: !drivingSlip.case.caseManager?.phone,
												})}
											>
												{t('common.tel')} {formatPhoneNumberIntl(drivingSlip.case.caseManager?.phone ?? '')}
											</a>
										</p>
										<IonCol size="6">
											<p className="text-blue text-base font-semibold">{t('case.calledBack')}:</p>
											{drivingSlip.case.visitation.calledBack.value ? (
												<div>
													<p>
														<span className="font-bold">{t('common.yes')}</span> - {drivingSlip.case.visitation.calledBack.comment}
													</p>
													<p>{drivingSlip.case.visitation.calledBack.timestamp !== null ? dateToDateTimeString(drivingSlip.case.visitation.calledBack.timestamp) : ''}</p>
												</div>
											) : (
												<p onClick={() => setIsOpen(true)} className="text-blue mt-2 text-base font-semibold">
													<FontAwesomeIcon icon={faPhone} className="mr-2" /> {t('case.markCallback')}
												</p>
											)}
										</IonCol>
										{/* <p onClick={() => presentCaseChangelogModal()} className="text-blue mt-2 text-base font-semibold">
											<FontAwesomeIcon icon={faHistory} className="mr-2" /> {t('case.viewChanges')}
										</p> */}
										<p className="text-blue mt-2 text-base font-semibold">
											<a href={`${siteUrlWeb}case/${drivingSlip.case.id}`}>
												<FontAwesomeIcon icon={faDoorOpen} className="mr-2" /> {t('case.case5C')}
											</a>
										</p>
									</IonCol>
								</IonRow>
							</IonGrid>
						</div>
					</IonAccordion>

					<IonAccordion value="drivingslip">
						<IonItem slot="header" color="light">
							<IonLabel>
								<h2 className="text-blue my-2 font-semibold">{t('drivingSlips.drivingslipInfo')}</h2>
							</IonLabel>
						</IonItem>
						<div slot="content">
							<IonGrid className="mb-4 text-sm">
								<IonRow>
									<IonCol size="6">
										<p className="text-blue text-base font-semibold">{t('common.status')}:</p>
										{t(`drivingSlips.status.${drivingSlip.status}`)}
										{drivingSlip.status === DrivingSlipStatus.STARTED && <span className="ml-1">({formatTimestamp(new Date(drivingSlip.start))})</span>}
										<p>{getDriverWhoCompleted(drivingSlip.changes)}</p>
									</IonCol>
									<IonCol size="6">
										<p className="text-blue text-base font-semibold">{t('drivingSlips.estimatedTime')}</p>
										{drivingSlip.estimatedHours !== null ? `${drivingSlip.estimatedHours} ${t('common.hours').toLocaleLowerCase()}` : '-'}
										{drivingSlip.status === DrivingSlipStatus.COMPLETED && (
											<span className="ml-2">
												(
												{Interval.fromISO(`${drivingSlip.start}/${drivingSlip.end}`).length('hours').toLocaleString(undefined, {
													maximumFractionDigits: 2,
												})}
												&nbsp;
												{t('common.hours').toLocaleLowerCase()}
												&nbsp;
												{t('common.used').toLocaleLowerCase()})
											</span>
										)}
									</IonCol>
									<IonCol size="6">
										<p className="text-blue text-base font-semibold">{t('common.location')}:</p>
										{drivingSlip.location.name}
									</IonCol>
									<IonCol size="6">{/* <p className="text-base font-semibold text-blue">Planlagt af:</p> */}</IonCol>
									<IonCol size="6">
										<p className="text-blue text-base font-semibold">{t('common.department')}:</p>
										{`${drivingSlip.department.name} (${drivingSlip.department.departmentNumber})`}
									</IonCol>
									<IonCol size="6">
										<p className="text-blue text-base font-semibold">{t('common.deadline')}:</p>
										{drivingSlip.deadline ? dateToDateTimeString(drivingSlip.deadline) : t('common.noneSpecified')}
									</IonCol>
									<IonCol size="6">
										<p className="text-blue text-base font-semibold">{t('drivingSlips.drivingStarted')}:</p>
										{drivingSlip.drivingStarted ? dateToDateTimeString(drivingSlip.drivingStarted) : t('common.no')}
									</IonCol>
									<IonCol size="6">
										<p className="text-blue text-base font-semibold">{t('case.visitation')}:</p>
										{drivingSlip.case.visitation.priorities.length > 0 && (
											<>
												<p className="text-blue text-xs font-semibold">{t('case.priority')}</p>
												<div className="text-xs">
													{drivingSlip.case.visitation.priorities.map(p => (
														<div key={p}>{t(`case.${p}`)}</div>
													))}
												</div>
											</>
										)}
										<p className="text-blue text-xs font-semibold">{t('case.visitationRegress')}</p>
										<p className="text-xs">{t(drivingSlip.case.visitation.urgent ? 'common.yes' : 'common.no')}</p>
										{drivingSlip.case.visitation.answers?.map(a => {
											if (a.answer.length === 0 || a.title.length === 0) return <React.Fragment key={a.question} />;
											return (
												<React.Fragment key={a.question}>
													<p className="text-blue text-xs font-semibold">{a.title}</p>
													<p className="text-xs">{a.answer}</p>
												</React.Fragment>
											);
										})}
									</IonCol>
									{vehiclesMobileAppFlag &&
										<>
											<IonCol size="6">
												<p className="text-blue text-base font-semibold">{t('drivingSlips.car')}:</p>
												{drivingSlip.car ? `${drivingSlip.car.brand} ${drivingSlip.car.registrationNumber} (${drivingSlip.car.vehicleNumber})` : t('common.none')}
											</IonCol>
											<IonCol size="6">
												<p className="text-blue text-base font-semibold">{t('drivingSlips.materials')}:</p>
												{drivingSlip.materials.length > 0 ? drivingSlip.materials.map(m => <p key={m.vehicleNumber}>{`${m.brand} ${m.registrationNumber} (${m.vehicleNumber})`}</p>) : t('common.none')}
											</IonCol>
										</>
									}
									<IonCol size="12">
										<p className="text-blue whitespace-pre-wrap break-words text-base font-semibold">{t('common.comment')}:</p>
										<p className="whitespace-pre-wrap break-words">{drivingSlip.comment}</p>
									</IonCol>
								</IonRow>
							</IonGrid>
						</div>
					</IonAccordion>
				</IonAccordionGroup>
				{(drivingSlip.status === DrivingSlipStatus.PLANNED || drivingSlip.status === DrivingSlipStatus.UNPLANNED) && drivingSlip.drivingStarted === null && (
					<Button onClick={presentDrivingStartedDrivingSlipModal} expand="block">
						<FontAwesomeIcon icon={faPlay} className="mr-2" />
						{t('drivingSlips.startDrivingStarted')}
					</Button>
				)}

				{(drivingSlip.status === DrivingSlipStatus.PLANNED || drivingSlip.status === DrivingSlipStatus.UNPLANNED) && (
					<Button onClick={presentArrivedStartDrivingSlipModal} expand="block" disabled={typeof drivingSlip.drivingStarted === 'undefined' || drivingSlip.drivingStarted === null}>
						<FontAwesomeIcon icon={faHourglassStart} className="mr-2" />
						{t('drivingSlips.arrivedAndStart')}
					</Button>
				)}

				{drivingSlip.status === DrivingSlipStatus.STARTED && (
					<Button onClick={presentOnHoldDrivingSlipModal} expand="block">
						<FontAwesomeIcon icon={faStop} className="mr-2" />
						{t('drivingSlips.onHoldDrivingSlip')}
					</Button>
				)}

				{drivingSlip.status === DrivingSlipStatus.ON_HOLD && (
					<Button onClick={presentResumeDrivingSlipModal} expand="block">
						<FontAwesomeIcon icon={faPlayCircle} className="mr-2" />
						{t('drivingSlips.resumeDrivingSlip')}
					</Button>
				)}

				{drivingSlip.status === DrivingSlipStatus.STARTED && (
					<Button
						onClick={async () => {
							// Go straight to camera, no prompt.
							const result = await takePhoto({
								photoSource: CameraSource.Camera,
							});
							if (result.success && result.photo) {
								// Upload photo directly. Bypass the edit screen.
								await uploadPhoto(result.photo);
							}
						}}
						expand="block"
					>
						<>
							<FontAwesomeIcon icon={faCamera} className="mr-2" />
							{t(drivingSlip.facadePictureTaken || isSyncedFacadePictureUploaded ? 'drivingSlips.takeFacadePictureAgain' : 'drivingSlips.takeFacadePicture')}
							{(drivingSlip.facadePictureTaken || isSyncedFacadePictureUploaded) && <FontAwesomeIcon icon={faCheck} className="text-green-calm ml-2" />}
						</>
					</Button>
				)}

				{drivingSlip.status === DrivingSlipStatus.STARTED && (
					<Button onClick={() => presentRiskEvaluationModal()} expand="block" disabled={!drivingSlip.facadePictureTaken && !isSyncedFacadePictureUploaded}>
						<FontAwesomeIcon icon={faEye} className="mr-2" />
						{t('case.riskEvaluation')}
						{drivingSlip.riskEvaluationStatus === RiskEvaluationStatus.ACCEPTED && <FontAwesomeIcon icon={faCheck} className="text-green-calm ml-2" />}
					</Button>
				)}

				{drivingSlip.status === DrivingSlipStatus.STARTED && (
					<Button
						expand="block"
						routerDirection="forward"
						routerLink={`/drivingslips/${drivingSlip.id}/questionnaire`}
						disabled={(!drivingSlip.facadePictureTaken && !isSyncedFacadePictureUploaded) || drivingSlip.riskEvaluationStatus !== RiskEvaluationStatus.ACCEPTED}
					>
						<FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
						{drivingSlip.questionnaireCompletionStatus === QuestionnaireCompletionStatus.COMPLETED ? t('questionnaire.viewQuestionnaire') : t('questionnaire.startQuestionnaire')}
						{drivingSlip.questionnaireCompletionStatus === QuestionnaireCompletionStatus.COMPLETED && <FontAwesomeIcon icon={faCheck} className="text-green-calm ml-2" />}
					</Button>
				)}

				{(drivingSlip.status === DrivingSlipStatus.STARTED || drivingSlip.status === DrivingSlipStatus.ON_HOLD) && (
					<Button
						onClick={presentFinishDrivingSlipModal}
						expand="block"
						disabled={
							drivingSlip.riskEvaluationStatus === RiskEvaluationStatus.NOT_ACCEPTED ||
							(!drivingSlip.facadePictureTaken && !isSyncedFacadePictureUploaded) ||
							drivingSlip.questionnaireCompletionStatus !== QuestionnaireCompletionStatus.COMPLETED ||
							(!drivingSlip.noWasteUsed && drivingSlip.case.caseDraftItemsUsage.length === 0)
						}
					>
						<FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
						{t('common.complete')}
					</Button>
				)}

				<hr className="my-5 opacity-25" />

				<Button onClick={() => presentItemUsageModal()} expand="block">
					<FontAwesomeIcon icon={faBoxes} className="mr-2" />
					{t('case.itemUsage')}
				</Button>

				<Button onClick={() => presentWasteModal()} expand="block">
					<FontAwesomeIcon icon={faRecycle} className="mr-2" />
					{t('case.waste')} ({drivingSlip.noWasteUsed ? t('case.noWasteUsed') : drivingSlip.case.caseDraftItemsUsage.filter(x => x.description.toLowerCase().includes('Affaldsafgift'.toLowerCase())).length})
				</Button>

				<Button expand="block" href={`https://www.google.com/maps/dir/?api=1&origin&destination=${road}+${houseNumber}+${postalCode}+${city}&travelmode=driving`} hrefTarget="_blank">
					<FontAwesomeIcon icon={faMapMarker} className="mr-2" />
					{t('drivingSlips.direction')}
				</Button>

				<Button onClick={() => presentGuidesModal()} expand="block">
					<FontAwesomeIcon icon={faFile} className="mr-2" />
					{t('common.guides')}
				</Button>
			</>

			<IonModal isOpen={isOpen}>
				<IonHeader>
					<IonToolbar>
						<IonTitle>{t('case.markCallback')}</IonTitle>
						<IonButtons slot="end">
							<Button onClick={() => setIsOpen(false)}>{t('common.close')}</Button>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent className="ion-padding">
					<form onSubmit={handleSubmit(onSubmit)} className="text-sm">
						{drivingSlip.case.visitation.calledBack.timestamp !== null && drivingSlip.case.visitation.calledBack.value && (
							<p className="text-blue mb-3 text-sm">
								<span className="font-medium">{t('common.lastUpdated')}: </span>
								{dateToDateTimeString(drivingSlip.case.visitation.calledBack.timestamp)}
							</p>
						)}

						<Checkbox
							name="calledBack"
							title="case.calledBack"
							innerRef={register}
							defaultChecked={drivingSlip.case.visitation.calledBack.value}
							errorMessage={errors.calledBack?.message}
						/>

						<p className="mt-3 font-semibold">{t('drivingSlips.descriptionTemplates')}</p>
						{visitationCallBackTexts.map(temp => (
							<p
								key={temp}
								className="w-full cursor-pointer"
								onClick={() => {
									const singleValue = getValues('calledBackDescription');
									setValue('calledBackDescription', singleValue === '' ? temp : `${singleValue}, ${temp}`);
								}}
							>
								"{temp}"
							</p>
						))}

						<Textarea
							title=""
							name="calledBackDescription"
							rows={3}
							innerRef={register}
							defaultValue={drivingSlip.case.visitation.calledBack.comment ?? ''}
							className="mt-2"
							errorMessage={errors.calledBackDescription?.message}
						/>

						<div className="flex justify-between">
							<Button color="success" submit>
								{t('common.save')}
							</Button>
							<Button color="danger" onClick={() => setIsOpen(false)}>
								{t('common.cancel')}
							</Button>
						</div>
					</form>
				</IonContent>
			</IonModal>
		</BasePage>
	);
};

export default DrivingSlipOverview;
