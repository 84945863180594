import { useIonToast } from '@ionic/react';
import { GraphQLErrorPartial, tryGetMessageFromGraphQLError } from '@ssg/common/Helpers/Helpers';
import { useTranslation } from 'react-i18next';
import { warning } from 'ionicons/icons';

const toastDurationInMilliseconds = 10_000;

export function useErrorIonicToaster(): (error: GraphQLErrorPartial) => void {
	const { t } = useTranslation();
	const [present, dismiss] = useIonToast();

	return (error: GraphQLErrorPartial) => {
		const errorMessage = tryGetMessageFromGraphQLError(error) ?? t('common.genericErrorMessage');

		present({
			buttons: [{ text: t('common.hide'), handler: () => dismiss() }],
			message: errorMessage,
			duration: toastDurationInMilliseconds,
			color: 'danger',
			position: 'bottom',
			icon: warning,
		});
	};
}
