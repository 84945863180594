import { useState } from 'react';
import { Camera } from '@capacitor/camera';
import { savePicture, UserPhoto } from './usePhotoCapture';
import { useIonViewWillLeave } from '@ionic/react';
import { current } from '@reduxjs/toolkit';

export function usePhotoChooser(): {
	photos: UserPhoto[];
	choosePhotos: (appendExtension?: boolean) => Promise<{ success: boolean; photos?: UserPhoto[] }>;
	resetPhotos: () => void;
} {
	const [photos, setPhotos] = useState<UserPhoto[]>([]);
	useIonViewWillLeave(() => {
		setPhotos([]);
	});
	return {
		photos,
		choosePhotos: () =>
			choosePhotos().then(photos => {
				if (photos) {
					setPhotos(photos);
					return { success: true, photos: photos };
				}
				return { success: false };
			}),
		resetPhotos: () => setPhotos([]),
	};
}

async function choosePhotos(appendExtension?: boolean): Promise<UserPhoto[] | undefined> {
	const result = await Camera.pickImages({ correctOrientation: true });
	if (result.photos && result.photos.length > 0) {
		const userPhotos: UserPhoto[] = [];
		const baseFileName = new Date().toISOString().replaceAll('T', '_').replaceAll(':', '-').split('.')[0];

		for (let i = 0; i < result.photos.length; i++) {
			const photo = result.photos[i];
			const fileName = `${baseFileName}_${i}`;
			const savedPhoto = await savePicture(photo, fileName);
			if (appendExtension) {
				savedPhoto.filepath += `.${savedPhoto.format}`;
			}
			userPhotos.push(savedPhoto);
		}

		return userPhotos;
	}

	return undefined;
}

export const getFileName = (photo: UserPhoto) => {
	if (photo.metadata?.fileName) {
		return photo.metadata.fileName;
	}

	const fileExtension = photo.format.startsWith('.') ? photo.format : `.${photo.format}`;
	return photo.filepath + fileExtension;
};

