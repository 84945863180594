import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ApiMutationQueueProcessor } from 'Store';
import { IQueue } from 'Helpers';
import { DB_NAME, DB_VERSION, INDEXED_PROP, MAX_NUMBER_OF_RETRIES, OBJECT_STORE_NAME } from './constants';
import { ApiMutation, FailedApiMutation, QueuedMutationEvent } from './types';
import { QueueOfflineMutationFunc } from '../types';
import EventEmitter from 'events';
import { isUndefined } from 'lodash';
import { Queue } from 'Helpers/Queue/Queue';

interface ApiMutationContextProps {
	offlineMutationQueueEvents: {
		onStart(listener: (event: QueuedMutationEvent, totalCount: number, currentCount: number) => void): void;
		onSuccess(listener: (event: QueuedMutationEvent) => void): void;
		onError(listener: (event: QueuedMutationEvent) => void): void;
		onCompleteAll(listener: () => void): void;
		queueUpdated(listener: (count: number) => void): void;
	};
	queueOfflineMutation: QueueOfflineMutationFunc;
	mutationCounter: number;
}

export const ApiMutationContext = React.createContext<ApiMutationContextProps>({
	offlineMutationQueueEvents: {
		onStart: _ => {
			// Noop
		},
		onSuccess: _ => {
			// Noop
		},
		onError: _ => {
			// Noop
		},
		onCompleteAll: () => {
			// Noop
		},
		queueUpdated: () => {
			// Noop
		},
	},
	queueOfflineMutation: async () => {
		// Noop
	},
	mutationCounter: 0,
});

// This allows consumers to hook into the queue replay events listed below
const eventEmitter = new EventEmitter();
const eventNames = {
	OnStart: 'onStart',
	OnSuccess: 'onSuccess',
	OnError: 'onError',
	OnCompleteAll: 'onCompleteAll',
	QueueUpdated: 'queueUpdated',
};

export const ApiMutationProvider: React.FC = ({ children }) => {
	const [mutationsQueue, setMutationsQueue] = useState<IQueue<ApiMutation>>();
	const [failedMutationsQueue, setFailedMutationsQueue] = useState<IQueue<FailedApiMutation>>();
	const [mutationCounter, setMutationCounter] = React.useState(0);

	useEffect(() => {
		async function initQueue<T extends Record<string, any>>(queueName: string, set: React.Dispatch<React.SetStateAction<IQueue<T> | undefined>>) {
			const queue: IQueue<T> = new Queue(DB_NAME, DB_VERSION, OBJECT_STORE_NAME, INDEXED_PROP, queueName);
			await queue.initialize();
			set(queue);
		}

		const init = async () => {
			await initQueue<ApiMutation>('mutations', setMutationsQueue);
			await initQueue<FailedApiMutation>('failed-mutations', setFailedMutationsQueue);
		};

		init();
	}, []);

	const queueOfflineMutation: QueueOfflineMutationFunc = useCallback(
		async (mutation, options) => {
			if (!mutationsQueue) return;

			const { removePreviousEntry, findPreviousEntryPredicate } = {
				...options,
			};

			if (removePreviousEntry && findPreviousEntryPredicate) {
				const entries = await mutationsQueue.getAll();
				const entryId = entries.find(entry => entry.data.key === mutation.key && findPreviousEntryPredicate(entry.data.variables as any))?.id;
				if (!isUndefined(entryId)) {
					await mutationsQueue.deleteEntry(entryId);
				}
			}

			await mutationsQueue.pushEntry({
				data: mutation,
				metadata: {
					numberOfRetries: 1,
					maxNumberOfRetries: MAX_NUMBER_OF_RETRIES,
				},
			});

			const count = await mutationsQueue.getCount();
			eventEmitter.emit(eventNames.QueueUpdated, count);
		},
		[mutationsQueue],
	);

	const getCount = async () => {
		if (mutationsQueue) {
			const count = await mutationsQueue.getCount();
			setMutationCounter(count);
		}
	};

	React.useEffect(() => {
		getCount();
	}, [mutationsQueue]);

	const context: ApiMutationContextProps = useMemo(
		() => ({
			offlineMutationQueueEvents: {
				// These provide the ability for consumers to add event listeners on our queued events
				onStart: listener => eventEmitter.on(eventNames.OnStart, listener),
				onSuccess: listener => eventEmitter.on(eventNames.OnSuccess, listener),
				onError: listener => eventEmitter.on(eventNames.OnError, listener),
				onCompleteAll: listener => eventEmitter.on(eventNames.OnCompleteAll, listener),
				queueUpdated: listener => eventEmitter.on(eventNames.QueueUpdated, listener),
			},
			queueOfflineMutation,
			mutationCounter,
		}),
		[mutationCounter, queueOfflineMutation],
	);

	if (!mutationsQueue || !failedMutationsQueue) {
		return <></>;
	}

	return (
		<ApiMutationContext.Provider value={context}>
			<ApiMutationQueueProcessor
				mutationsQueue={mutationsQueue}
				failedMutationsQueue={failedMutationsQueue}
				events={{
					onStart: (event, totalCount, currentCount) => eventEmitter.emit(eventNames.OnStart, event, totalCount, currentCount),
					onSuccess: event => eventEmitter.emit(eventNames.OnError, event),
					onError: event => eventEmitter.emit(eventNames.OnError, event),
					onCompleteAll: () => eventEmitter.emit(eventNames.OnCompleteAll),
					queueUpdated: count => eventEmitter.emit(eventNames.QueueUpdated, count),
				}}
			/>
			{children}
		</ApiMutationContext.Provider>
	);
};
