import { formatDateForInputEU } from '@ssg/common/Helpers/dateToDateOnlyString';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import { loader } from 'graphql.macro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseMachineFragment, GetMachineGuides, GetMachineGuidesVariables, GetMobileV2MachineLocations, MachineConsumptionType } from 'GQL';
import { useOfflineHandlingQuery } from 'Hooks';

interface Props {
	machine: BaseMachineFragment;
}

const GET_MACHINE_GUIDES = loader('src/GQL/Machines/GetMachineGuides.gql');
const GET_MACHINE_LOCATIONS = loader('src/GQL/Machines/GetMobileV2MachineLocations.gql');

const SelectedMachineInfo: React.FC<Props> = ({ machine }) => {
	const { t } = useTranslation();

	const machineLocationsQuery = useOfflineHandlingQuery<GetMobileV2MachineLocations>(GET_MACHINE_LOCATIONS);
	const guidesWithoutContentQuery = useOfflineHandlingQuery<GetMachineGuides, GetMachineGuidesVariables>(GET_MACHINE_GUIDES, {
		variables: {
			filter: {
				filterName: 'Maskintype',
				filterQuery: machine.name,
			},
		},
	});

	// const [selectedFile, setSelectedFile] = React.useState<ESDHFileFragment>();

	// const [presentDocumentViewer, dismissDocumentViewer] = useIonModal(DocumentViewerModal, {
	//     file: selectedFile
	//         ? {
	//             name: selectedFile.name,
	//             extension: selectedFile.extension,
	//             data: selectedFile.,
	//             base64Data: selectedFile.drivingSlipFile.fileDataBase64,
	//         } : undefined,
	//     onDismiss: () => dismissDocumentViewer(),
	// });

	// React.useEffect(() => {
	//     if (selectedFile != null) presentDocumentViewer();
	// }, [selectedFile])

	return (
		<div>
			<p className="text-lg font-semibold">
				{t('machines.no')} {machine.erpReferenceNo}
			</p>
			{/* 
			{machine.serviceDate != null && new Date(machine.serviceDate) < new Date() && (
				<div className="text-red flex py-1">
					<FontAwesomeIcon icon={faExclamationCircle} className="mr-1" size="lg" />
					<p className="font-medium">{t('machines.serviceOverdue')}</p>
				</div>
			)} */}

			<p>
				{t('common.type') + ': '}
				<span className="font-semibold">{machine.name}</span>
			</p>

			<p>
				{t('machines.moved') + ': '}
				<span className="font-semibold">{machine.dateStart && dateToDateTimeString(machine.dateStart + ' ' + machine.startTime)}</span>
			</p>

			<p>
				{t('machines.consumption') + ': '}
				<span className="font-semibold">
					{machine.consumption + ' ' + (machine.consumptionType === MachineConsumptionType.NONE ? '(' + t('common.missingUnit') + ')' : machine.consumptionType)}
				</span>
			</p>

			<p>
				{t('machines.currentCase') + ': '}
				<span>{machine.eRPCaseReference ? machine.eRPCaseReference : t('common.none')}</span>
			</p>

			{machine.placementDescription && (
				<p>
					{t('common.placement') + ': '}
					<span className="font-semibold">{machine.placementDescription}</span>
				</p>
			)}

			{machine.reservationStart && machine.reservationEnd && (
				<p>
					{t('machines.machineIsReserved') + ': '}
					<span className="font-semibold">
						{machine.reservationStart === '0001-01-01T00:00:00'
							? t('machines.noReservation')
							: formatDateForInputEU(new Date(machine.reservationStart)) + ' - ' + formatDateForInputEU(new Date(machine.reservationEnd))}
					</span>
				</p>
			)}

			{machine.reservationByRessource && (
				<p>
					{t('machines.machineReservedBy') + ': '}
					<span className="font-semibold">{machine.reservationByRessource}</span>
				</p>
			)}

			{machine.eRPCaseReference && (
				<>
					<p>
						{t('machines.hibernation') + ': '}
						{machine.hibernate ? <span className="font-semibold">{t('common.yes')}</span> : <span className="font-semibold">{t('common.no')}</span>}
					</p>
					{machine.hibernate && (
						<p>
							{t('machines.hibernationReason') + ': '}
							<span className="font-semibold">{machine.hibernationReason}</span>
						</p>
					)}
				</>
			)}

			<p>
				{t('common.location') + ': '}
				{machine.eRPLocationReference == null ? (
					<span className="font-semibold">{t('common.none')}</span>
				) : (
					<span className="font-semibold">
						{machineLocationsQuery.loading && <p>loading...</p>}
						{machineLocationsQuery.error && <p>error</p>}
						{machineLocationsQuery.data && machineLocationsQuery.data.machineLocations.find(l => l.locationCode === machine.eRPLocationReference)?.name}
					</span>
				)}
			</p>
			{machine.eRPLocationReference === 'INDISP' && machine.unavailableReason.length > 0 && <p>{t('machines.unavailableReason')}: <span className="font-semibold">{machine.unavailableReason}</span></p>}

			{/* {machine.serviceDate && (
				<p>
					{t('machines.nextService') + ': '}
					<span className="font-semibold">{machine.serviceDate === '0001-01-01T00:00:00' ? t('common.notSpecified') : formatDateForInputEU(new Date(machine.serviceDate))}</span>
				</p>
			)} */}

			<div>
				{t('common.guides')}:{guidesWithoutContentQuery.loading && <p>loading...</p>}
				{guidesWithoutContentQuery.error && <p>error</p>}
				{guidesWithoutContentQuery.data && (
					<ul className="list-disc">
						{guidesWithoutContentQuery.data.machineGuideFiles.map(file => {
							return (
								<li key={file.name} onClick={() => console.log(`Should view ${file.name}`)} className="text-hover ml-5">
									<p>{file.name}</p>
								</li>
							);
						})}
					</ul>
				)}
			</div>
		</div>
	);
};

export default SelectedMachineInfo;
