import { ESDHFileFragment } from '../GraphQL';
import { EsdhFileFragmentFragment, ReportFileInput } from '../GraphQL/indexV2';

const imageFileExtensions = ['.png', '.jpg', '.jpeg', '.gif'];
const documentFileExtensions = ['.pdf', '.doc'];
const videoFileExtensions = ['.m4v', '.mp4', '.mov', '.mkv', '.3gp'];

// Use bit shift operators to support enum "flags"
// This allows us to represent Image & Video (or any permutation) in one variable.
export enum FileType {
	None = 0,
	Image = 1 << 0,
	Video = 1 << 1,
	Document = 1 << 2,
	All = ~(~0 << 3),
}

export const getFileTypeFromUrl = (url: string): FileType => {
	const predicate = (fileExtension: string): boolean => url.toLowerCase().endsWith(fileExtension);
	if (imageFileExtensions.some(predicate)) return FileType.Image;
	if (videoFileExtensions.some(predicate)) return FileType.Video;
	// Return Document by default if extension is unknown
	return FileType.Document;
};

export function fileToInput(file: EsdhFileFragmentFragment | ESDHFileFragment): ReportFileInput {
	return {
		fileName: file.name,
		description: file.comments ?? '',
	};
}

export function filesToInput(files: (EsdhFileFragmentFragment | ESDHFileFragment)[]): { fileName: string; description: string | null }[] {
	const inputFiles: { fileName: string; description: string | null }[] = [];
	files.map((file, idx) => {
		const fileObj = { fileName: file.name, description: file.comments ?? '' };

		inputFiles.push(fileObj);
	});

	return inputFiles;
}

