/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetInputFields
// ====================================================

export interface GetInputFields_inputFields {
  /**
   * Key of input field
   */
  key: string;
  /**
   * Input field permissions
   */
  permissions: Permissions[];
}

export interface GetInputFields {
  inputFields: GetInputFields_inputFields[];
}

export interface GetInputFieldsVariables {
  keys: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateIndoorClimateReport
// ====================================================

export interface CreateIndoorClimateReport_createIndoorClimateReport {
  /**
   * File name
   */
  name: string;
}

export interface CreateIndoorClimateReport {
  /**
   * Create indoor climate report
   */
  createIndoorClimateReport: CreateIndoorClimateReport_createIndoorClimateReport | null;
}

export interface CreateIndoorClimateReportVariables {
  caseId: string;
  formData: IndoorClimateReportFormDataInput;
  reportFormat: ReportFormatType;
  onlySave: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCommonCurrentUser
// ====================================================

export interface GetCommonCurrentUser_currentUser_gdpr_userId {
  /**
   * Unique id of user
   */
  id: string;
}

export interface GetCommonCurrentUser_currentUser_gdpr {
  /**
   * Unique id of gdpr accept
   */
  id: string;
  /**
   * Timestamp of acceptance
   */
  timestamp: GQL_DateTime;
  /**
   * User who accepted GDPR
   */
  userId: GetCommonCurrentUser_currentUser_gdpr_userId;
}

export interface GetCommonCurrentUser_currentUser_caseFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * Filter view type
   */
  view: UserCaseFilterViewType;
  /**
   * Case awaiting status toggle
   */
  awaiting: boolean | null;
  /**
   * Case damage service completed status toggle
   */
  damageServiceCompleted: boolean | null;
  /**
   * Case called back status toggle
   */
  calledBack: boolean | null;
  /**
   * Case machines on case status toggle
   */
  machinesOnCase: boolean | null;
  /**
   * Toggle to show economy fields
   */
  showEconomyField: boolean;
  /**
   * Toggle to show business area fields
   */
  showBusinessAreaField: boolean;
  /**
   * Toggle to show applied closed cases
   */
  showAppliedClosedCases: boolean;
  /**
   * Toggle to show closed cases
   */
  showClosedCases: boolean;
  /**
   * Debitors to filter cases by
   */
  debitors: string[];
  /**
   * Postal code input text
   */
  postalCodeText: string;
  /**
   * Postal codes to filter cases by
   */
  postalCodes: string[];
  /**
   * Track to filter cases by
   */
  track: number[];
  /**
   * Departments to filter cases by
   */
  departments: string[];
  /**
   * Locations to filter cases by
   */
  locations: string[];
  /**
   * Damage categories to filter cases by
   */
  damageCategories: string[];
  /**
   * Damage causes to filter cases by
   */
  damageCauses: string[];
  /**
   * Priority to filter cases by
   */
  priority: CasePriority[];
  /**
   * Case managers to filter cases by
   */
  caseManagers: string[];
  /**
   * Project managers to filter cases by
   */
  projectManagers: string[];
}

export interface GetCommonCurrentUser_currentUser_drivingSlipFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * Debitors to filter driving slips by
   */
  debitors: string[];
  /**
   * Postal code input text
   */
  postalCodeText: string;
  /**
   * Postal codes to filter driving slips by
   */
  postalCodes: string[];
  /**
   * Departments to filter driving slips by
   */
  departments: string[];
  /**
   * Locations to filter driving slips by
   */
  locations: string[];
  /**
   * Damage categories to filter driving slips by
   */
  damageCategories: string[];
  /**
   * Damage causes to filter driving slips by
   */
  damageCauses: string[];
  /**
   * ERP reference nos to filter driving slips by
   */
  erpNos: string[];
  /**
   * Project managers to filter driving slips by
   */
  projectManagers: string[];
}

export interface GetCommonCurrentUser_currentUser_drivingSlipOverviewFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * Status to filter by
   */
  status: DrivingSlipStatus | null;
  /**
   * Urgent filter toggle
   */
  urgent: boolean | null;
  /**
   * Exceeded filter toggle
   */
  exceeded: boolean | null;
  /**
   * Departments to filter by
   */
  departments: string[];
  /**
   * Locations to filter by
   */
  locations: string[];
  /**
   * Damage causes to filter by
   */
  damageCauses: string[];
  /**
   * Damage categories to filter by
   */
  damageCategories: string[];
  /**
   * Drivers to filter by
   */
  drivers: string[];
  /**
   * Filter for no drivers
   */
  noDriver: boolean | null;
}

export interface GetCommonCurrentUser_currentUser_sanitizerFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * Departments to filter sanitizers by
   */
  departments: string[];
  /**
   * Locations to filter sanitizers by
   */
  locations: string[];
  /**
   * Job functions to filter sanitizers by
   */
  jobFunctions: string[];
  /**
   * Pre selected sanitizers on filter
   */
  sanitizers: string[];
}

export interface GetCommonCurrentUser_currentUser_carFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * Locations to filter cars by
   */
  locations: string[];
  /**
   * Pre selected cars on filter
   */
  cars: string[];
}

export interface GetCommonCurrentUser_currentUser_movableFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * ERP reference no to filter movables by
   */
  erpNo: string;
  /**
   * Location to filter movables by
   */
  location: string;
  /**
   * Status to filter movables by
   */
  status: string;
  /**
   * Toggle to show completed movables
   */
  includeCompleted: boolean;
  /**
   * Debitors to filter movables by
   */
  debitors: string[] | null;
}

export interface GetCommonCurrentUser_currentUser_jobFilters {
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Name of filter
   */
  name: string;
  /**
   * Location to filter jobs by
   */
  location: string;
  /**
   * Toggle to show only personal jobs
   */
  personalOnly: boolean;
}

export interface GetCommonCurrentUser_currentUser_machineFilters {
  /**
   * Name of filter
   */
  name: string;
  /**
   * Unique filter key
   */
  key: string;
  /**
   * Status to filter by
   */
  status: MachineStatus | null;
  /**
   * Cases to filter by
   */
  cases: string[] | null;
  /**
   * Locations to filter by
   */
  locations: string[] | null;
  /**
   * Types to filter by
   */
  types: string[] | null;
  /**
   * Include unavailable machines
   */
  unavailable: boolean | null;
  /**
   * Include hibernating machines
   */
  hibernation: boolean | null;
}

export interface GetCommonCurrentUser_currentUser {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
  /**
   * Users GDPR
   */
  gdpr: GetCommonCurrentUser_currentUser_gdpr | null;
  /**
   * permissions of user
   */
  permissions: Permissions[];
  /**
   * External user
   */
  external: boolean;
  /**
   * User saved case filters
   */
  caseFilters: GetCommonCurrentUser_currentUser_caseFilters[];
  /**
   * User saved driving slip filters
   */
  drivingSlipFilters: GetCommonCurrentUser_currentUser_drivingSlipFilters[];
  /**
   * User saved driving slip overview filters
   */
  drivingSlipOverviewFilters: GetCommonCurrentUser_currentUser_drivingSlipOverviewFilters[];
  /**
   * User saved sanitizer filters
   */
  sanitizerFilters: GetCommonCurrentUser_currentUser_sanitizerFilters[];
  /**
   * User saved car filters
   */
  carFilters: GetCommonCurrentUser_currentUser_carFilters[];
  /**
   * User saved movable filters
   */
  movableFilters: GetCommonCurrentUser_currentUser_movableFilters[];
  /**
   * User saved job filters
   */
  jobFilters: GetCommonCurrentUser_currentUser_jobFilters[];
  /**
   * User saved machine filters
   */
  machineFilters: GetCommonCurrentUser_currentUser_machineFilters[];
}

export interface GetCommonCurrentUser {
  currentUser: GetCommonCurrentUser_currentUser | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUser
// ====================================================

export interface GetUser_currentUser {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
  /**
   * permissions of user
   */
  permissions: Permissions[];
}

export interface GetUser {
  currentUser: GetUser_currentUser | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetVehicles
// ====================================================

export interface GetVehicles_vehicles_bookedDays_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetVehicles_vehicles_bookedDays {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetVehicles_vehicles_bookedDays_driver | null;
}

export interface GetVehicles_vehicles {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
  /**
   * Days where the vehicle is booked
   */
  bookedDays: GetVehicles_vehicles_bookedDays[];
}

export interface GetVehicles {
  vehicles: GetVehicles_vehicles[];
}

export interface GetVehiclesVariables {
  isCar: boolean;
  dateRange?: DateRangeInput | null;
  locations?: string[] | null;
  departments?: string[] | null;
  type?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCaseAgreementsFromQuestionnaire
// ====================================================

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_debitor_shippingAddress_address;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_damage_cause_departments_location;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_damage_contact_address;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_damage_businessArea;
  /**
   * Damage category
   */
  category: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_damage_category;
  /**
   * Cause behind damage
   */
  cause: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_damage_contact;
  /**
   * contacts for damage
   */
  contacts: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_policyHolder_address;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
  /**
   * The question
   */
  title: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_visitation_answers[] | null;
  /**
   * If case needs a moisture report
   */
  isMoistureReportRequired: boolean | null;
  /**
   * If case needs an SP phase one report
   */
  isSpPhaseOneReportCreated: boolean | null;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitioner {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Requisitioner relation
   */
  relation: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_adviser {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_referrer {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_ssgDepartment_location_address;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_ssgDepartment_location;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_caseAgreements_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_caseAgreements {
  /**
   * Agreement content
   */
  content: string;
  /**
   * Agreement category
   */
  category: CaseAgreementCategory;
  /**
   * User who created agreement
   */
  user: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_caseAgreements_user;
  /**
   * Timestamp of when agreement was created
   */
  timestamp: GQL_DateTime;
  /**
   * Whether case agreement should be sent to ScalePoint
   */
  shouldSaveInScalePoint: boolean;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_caseEmails {
  /**
   * Email content
   */
  content: string;
  /**
   * List of email attachments
   */
  attachments: string[];
  /**
   * List of email receivers
   */
  receivers: string[];
  /**
   * Timestamp of when email was created
   */
  timestamp: GQL_DateTime;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_userFavorite {
  /**
   * Unique user id
   */
  user: string;
  /**
   * Color code set by user
   */
  color: string | null;
  /**
   * Favorite active status
   */
  active: boolean;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_jobs_assignedTo {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_jobs_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_jobs_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_jobs_movable_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_jobs_movable {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_jobs_movable_placement;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_jobs {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Type of case task
   */
  type: JobType;
  /**
   * Description of the case task
   */
  description: string;
  /**
   * Deadline of the case task
   */
  deadline: GQL_DateTime;
  /**
   * The user responsible for the task
   */
  assignedTo: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_jobs_assignedTo | null;
  /**
   * The location responsible to the task
   */
  location: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_jobs_location | null;
  /**
   * The case the task belongs to
   */
  case: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_jobs_case | null;
  /**
   * The movable responsible to the task
   */
  movable: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_jobs_movable | null;
  /**
   * Whether the task is completed
   */
  completed: boolean;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_changes_user;
  /**
   * Value before
   */
  before: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_changes_before;
  /**
   * Value after
   */
  after: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_changes_after;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_changes[];
  /**
   * Location of driving slip
   */
  location: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_location;
  /**
   * Department of driving slip
   */
  department: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries {
  /**
   * Unique id of driving slip series
   */
  id: string;
  /**
   * Driving slip series completed status
   */
  completed: boolean;
  /**
   * Driving slips on series
   */
  drivingSlips: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries_drivingSlips[];
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_alternativeContact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Comment on person
   */
  comment: string | null;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
  /**
   * Id of damage category
   */
  id: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of damage cause
   */
  id: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_case_damage_contact {
  /**
   * Contact address information
   */
  address: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_case_damage_contact_address;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_case_damage {
  /**
   * Damage category
   */
  category: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_case_damage_contact;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_case_debitor;
  /**
   * Case damage details
   */
  damage: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_case_damage;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_drivingSlip_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_drivingSlip_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_drivingSlip_case_damage {
  /**
   * Damage category
   */
  category: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_drivingSlip_case_damage_cause;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_drivingSlip_case_damage;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Case which driving slip is related to
   */
  case: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_drivingSlip_case;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_vendor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_vendor {
  /**
   * ERP reference no for vendor
   */
  erpReferenceNo: string;
  /**
   * The name of the vendor
   */
  company: string | null;
  /**
   * The address of the vendor
   */
  address: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_vendor_address | null;
  /**
   * The email of the vendor
   */
  email: string | null;
  /**
   * The phone number of the vendor
   */
  phone: string | null;
  /**
   * The posting group of the vendor
   */
  vendorPostingGroup: string;
  /**
   * Attached SSG department of the vendor
   */
  ssgDepartment: string;
  /**
   * Attached SSG location of the vendor
   */
  ssgLocation: string;
  /**
   * Vendor type
   */
  type: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions {
  /**
   * id of the requisition
   */
  id: string;
  /**
   * case owning the requisition
   */
  case: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_case | null;
  /**
   * location owning the requisition
   */
  department: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_department | null;
  /**
   * driving slip owning the requisition
   */
  drivingSlip: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_drivingSlip | null;
  /**
   * description of the requisition
   */
  description: string;
  /**
   * orderNumber of the requisition
   */
  orderNumber: string;
  /**
   * the type of the requisition
   */
  type: RequisitionType;
  /**
   * Requisition status
   */
  status: boolean;
  /**
   * the vendor of the requisition
   */
  vendor: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_vendor | null;
  /**
   * Timestamp of when the requisition was created
   */
  createdAt: GQL_DateTime;
  /**
   * User who created the requisition
   */
  createdBy: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions_createdBy;
  /**
   * email of the vendor
   */
  vendorEmail: string | null;
  /**
   * phone number of the vendor
   */
  vendorPhoneNumber: string;
  /**
   * name of the vendor
   */
  vendorName: string;
}

export interface CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * ReportSent
   */
  reportSent: boolean;
  /**
   * Distance from ssg location to damage address
   */
  distance: string | null;
  /**
   * Whether notifications regarding machines on the case should be sent
   */
  machineNotification: boolean | null;
  /**
   * Whether time registration is allowed on the case
   */
  timeRegistration: boolean | null;
  /**
   * Case debitor details
   */
  debitor: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_debitor;
  /**
   * Case damage details
   */
  damage: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_damage;
  /**
   * Case policy holder details
   */
  policyHolder: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_policyHolder;
  /**
   * Case visitation details
   */
  visitation: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_visitation;
  /**
   * Case requisitioner details
   */
  requisitioner: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitioner;
  /**
   * Case advisor details
   */
  adviser: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_adviser | null;
  /**
   * Case referer details
   */
  referrer: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_referrer | null;
  /**
   * Project manager attached to case
   */
  projectManager: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_ssgLocation;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_ssgDepartment;
  /**
   * Case ready for billing
   */
  billingReady: boolean;
  /**
   * Case work complete
   */
  workComplete: boolean;
  /**
   * List of case agreements
   */
  caseAgreements: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_caseAgreements[];
  /**
   * List of case emails
   */
  caseEmails: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_caseEmails[];
  /**
   * List of user favorite entries
   */
  userFavorite: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_userFavorite | null;
  /**
   * User who created the case
   */
  createdBy: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_createdBy | null;
  /**
   * Timestamp of when the case was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the case was created
   */
  createdAt: GQL_DateTime;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * The tasks associated with the task
   */
  jobs: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_jobs[] | null;
  /**
   * The driving slips series associated with the case
   */
  drivingSlipSeries: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_drivingSlipSeries[];
  /**
   * Important info on the case
   */
  importantInfo: string | null;
  /**
   * Alternative contact
   */
  alternativeContact: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_alternativeContact | null;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_riskEvaluationAnswers[] | null;
  /**
   * Optional comment for risk evaluation
   */
  riskEvaluationComment: string | null;
  /**
   * Requisition of the case
   */
  requisitions: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire_requisitions[];
  scalePointStatus: ScalePointStatus;
  scalePointErrorMessage: string | null;
  arrived: boolean;
  arrivedDate: GQL_DateTime | null;
}

export interface CreateCaseAgreementsFromQuestionnaire {
  createCaseAgreementsFromQuestionnaire: CreateCaseAgreementsFromQuestionnaire_createCaseAgreementsFromQuestionnaire;
}

export interface CreateCaseAgreementsFromQuestionnaireVariables {
  id: string;
  newCaseAgreements: CaseAgreementInput[];
  trackTwoOptionSelected: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateMobileV2CaseDraftItemUsage
// ====================================================

export interface CreateMobileV2CaseDraftItemUsage_createCaseDraftItemUsage {
  /**
   * ERP system reference id
   */
  erpId: string;
  /**
   * ERP system reference key
   */
  erpKey: string;
  /**
   * Usage line title
   */
  title: string;
  /**
   * Usage line quantity
   */
  quantity: GQL_Decimal;
  /**
   * Date of posting
   */
  date: GQL_Date;
  /**
   * Posted By Username
   */
  postedBy: string;
  /**
   * Description
   */
  description: string;
  __typename: "CaseDraftUsageLine";
}

export interface CreateMobileV2CaseDraftItemUsage {
  createCaseDraftItemUsage: CreateMobileV2CaseDraftItemUsage_createCaseDraftItemUsage[] | null;
}

export interface CreateMobileV2CaseDraftItemUsageVariables {
  caseERPReferenceNo: string;
  itemERPReferenceNo: string;
  itemDescription: string;
  jobTaskERPReferenceNo: string;
  quantity: GQL_Decimal;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteMobileV2ItemUsage
// ====================================================

export interface DeleteMobileV2ItemUsage_deleteItemUsage {
  /**
   * ERP system reference id
   */
  erpId: string;
  /**
   * ERP system reference key
   */
  erpKey: string;
  /**
   * Usage line title
   */
  title: string;
  /**
   * Usage line quantity
   */
  quantity: GQL_Decimal;
  /**
   * Date of posting
   */
  date: GQL_Date;
  /**
   * Posted By Username
   */
  postedBy: string;
  /**
   * Description
   */
  description: string;
}

export interface DeleteMobileV2ItemUsage {
  deleteItemUsage: DeleteMobileV2ItemUsage_deleteItemUsage[] | null;
}

export interface DeleteMobileV2ItemUsageVariables {
  caseERPReferenceNo: string;
  itemERPReferenceNo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCaseDamage
// ====================================================

export interface GetCaseDamage_case_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetCaseDamage_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetCaseDamage_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetCaseDamage_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetCaseDamage_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetCaseDamage_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetCaseDamage_case_damage_cause_departments_location;
}

export interface GetCaseDamage_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: GetCaseDamage_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: GetCaseDamage_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (GetCaseDamage_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface GetCaseDamage_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetCaseDamage_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetCaseDamage_case_damage_contact_address;
}

export interface GetCaseDamage_case_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface GetCaseDamage_case_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: GetCaseDamage_case_damage_businessArea;
  /**
   * Damage category
   */
  category: GetCaseDamage_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetCaseDamage_case_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: GetCaseDamage_case_damage_contact;
  /**
   * contacts for damage
   */
  contacts: GetCaseDamage_case_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface GetCaseDamage_case {
  /**
   * Case damage details
   */
  damage: GetCaseDamage_case_damage;
}

export interface GetCaseDamage {
  case: GetCaseDamage_case;
}

export interface GetCaseDamageVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCaseDraftUsageLines
// ====================================================

export interface GetCaseDraftUsageLines_case_caseDraftItemsUsage {
  /**
   * ERP system reference id
   */
  erpId: string;
  /**
   * ERP system reference key
   */
  erpKey: string;
  /**
   * Usage line title
   */
  title: string;
  /**
   * Usage line quantity
   */
  quantity: GQL_Decimal;
  /**
   * Date of posting
   */
  date: GQL_Date;
  /**
   * Posted By Username
   */
  postedBy: string;
}

export interface GetCaseDraftUsageLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * List of draft case items usage
   */
  caseDraftItemsUsage: GetCaseDraftUsageLines_case_caseDraftItemsUsage[];
}

export interface GetCaseDraftUsageLines {
  case: GetCaseDraftUsageLines_case;
}

export interface GetCaseDraftUsageLinesVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCaseIds
// ====================================================

export interface GetCaseIds_cases {
  /**
   * ERP reference no
   */
  erpNo: string;
}

export interface GetCaseIds {
  cases: GetCaseIds_cases[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCaseRequisitions
// ====================================================

export interface GetCaseRequisitions_case_requisitions_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
}

export interface GetCaseRequisitions_case_requisitions_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
  /**
   * Id of damage category
   */
  id: string;
}

export interface GetCaseRequisitions_case_requisitions_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of damage cause
   */
  id: string;
}

export interface GetCaseRequisitions_case_requisitions_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetCaseRequisitions_case_requisitions_case_damage_contact {
  /**
   * Contact address information
   */
  address: GetCaseRequisitions_case_requisitions_case_damage_contact_address;
}

export interface GetCaseRequisitions_case_requisitions_case_damage {
  /**
   * Damage category
   */
  category: GetCaseRequisitions_case_requisitions_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetCaseRequisitions_case_requisitions_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: GetCaseRequisitions_case_requisitions_case_damage_contact;
}

export interface GetCaseRequisitions_case_requisitions_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetCaseRequisitions_case_requisitions_case_debitor;
  /**
   * Case damage details
   */
  damage: GetCaseRequisitions_case_requisitions_case_damage;
}

export interface GetCaseRequisitions_case_requisitions_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
}

export interface GetCaseRequisitions_case_requisitions_drivingSlip_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetCaseRequisitions_case_requisitions_drivingSlip_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
}

export interface GetCaseRequisitions_case_requisitions_drivingSlip_case_damage {
  /**
   * Damage category
   */
  category: GetCaseRequisitions_case_requisitions_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetCaseRequisitions_case_requisitions_drivingSlip_case_damage_cause;
}

export interface GetCaseRequisitions_case_requisitions_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: GetCaseRequisitions_case_requisitions_drivingSlip_case_damage;
}

export interface GetCaseRequisitions_case_requisitions_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Case which driving slip is related to
   */
  case: GetCaseRequisitions_case_requisitions_drivingSlip_case;
}

export interface GetCaseRequisitions_case_requisitions_vendor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetCaseRequisitions_case_requisitions_vendor {
  /**
   * ERP reference no for vendor
   */
  erpReferenceNo: string;
  /**
   * The name of the vendor
   */
  company: string | null;
  /**
   * The address of the vendor
   */
  address: GetCaseRequisitions_case_requisitions_vendor_address | null;
  /**
   * The email of the vendor
   */
  email: string | null;
  /**
   * The phone number of the vendor
   */
  phone: string | null;
  /**
   * The posting group of the vendor
   */
  vendorPostingGroup: string;
  /**
   * Attached SSG department of the vendor
   */
  ssgDepartment: string;
  /**
   * Attached SSG location of the vendor
   */
  ssgLocation: string;
  /**
   * Vendor type
   */
  type: string;
}

export interface GetCaseRequisitions_case_requisitions_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface GetCaseRequisitions_case_requisitions {
  /**
   * id of the requisition
   */
  id: string;
  /**
   * case owning the requisition
   */
  case: GetCaseRequisitions_case_requisitions_case | null;
  /**
   * location owning the requisition
   */
  department: GetCaseRequisitions_case_requisitions_department | null;
  /**
   * driving slip owning the requisition
   */
  drivingSlip: GetCaseRequisitions_case_requisitions_drivingSlip | null;
  /**
   * description of the requisition
   */
  description: string;
  /**
   * orderNumber of the requisition
   */
  orderNumber: string;
  /**
   * the type of the requisition
   */
  type: RequisitionType;
  /**
   * Requisition status
   */
  status: boolean;
  /**
   * the vendor of the requisition
   */
  vendor: GetCaseRequisitions_case_requisitions_vendor | null;
  /**
   * Timestamp of when the requisition was created
   */
  createdAt: GQL_DateTime;
  /**
   * User who created the requisition
   */
  createdBy: GetCaseRequisitions_case_requisitions_createdBy;
  /**
   * email of the vendor
   */
  vendorEmail: string | null;
  /**
   * phone number of the vendor
   */
  vendorPhoneNumber: string;
  /**
   * name of the vendor
   */
  vendorName: string;
}

export interface GetCaseRequisitions_case_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetCaseRequisitions_case_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: GetCaseRequisitions_case_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface GetCaseRequisitions_case_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetCaseRequisitions_case_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: GetCaseRequisitions_case_ssgDepartment_location_address;
}

export interface GetCaseRequisitions_case_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: GetCaseRequisitions_case_ssgDepartment_location;
}

export interface GetCaseRequisitions_case_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetCaseRequisitions_case_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetCaseRequisitions_case_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: GetCaseRequisitions_case_debitor_shippingAddress_address;
}

export interface GetCaseRequisitions_case_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetCaseRequisitions_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: GetCaseRequisitions_case_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: GetCaseRequisitions_case_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: GetCaseRequisitions_case_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface GetCaseRequisitions_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetCaseRequisitions_case_damage_contact {
  /**
   * Contact address information
   */
  address: GetCaseRequisitions_case_damage_contact_address;
}

export interface GetCaseRequisitions_case_damage {
  /**
   * Damage location contact information
   */
  contact: GetCaseRequisitions_case_damage_contact;
}

export interface GetCaseRequisitions_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Requisition of the case
   */
  requisitions: GetCaseRequisitions_case_requisitions[];
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: GetCaseRequisitions_case_ssgLocation;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: GetCaseRequisitions_case_ssgDepartment;
  /**
   * Case debitor details
   */
  debitor: GetCaseRequisitions_case_debitor;
  /**
   * Case damage details
   */
  damage: GetCaseRequisitions_case_damage;
}

export interface GetCaseRequisitions {
  case: GetCaseRequisitions_case;
}

export interface GetCaseRequisitionsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMobileV2Case
// ====================================================

export interface GetMobileV2Case_case_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2Case_case_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2Case_case_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: GetMobileV2Case_case_debitor_shippingAddress_address;
}

export interface GetMobileV2Case_case_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2Case_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: GetMobileV2Case_case_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: GetMobileV2Case_case_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: GetMobileV2Case_case_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
  /**
   * Indicates if debitor has automatic invoicing enabled for track one cases (null if unknown)
   */
  automaticTrackOneInvoicing: boolean | null;
}

export interface GetMobileV2Case_case_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetMobileV2Case_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetMobileV2Case_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetMobileV2Case_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetMobileV2Case_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetMobileV2Case_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetMobileV2Case_case_damage_cause_departments_location;
}

export interface GetMobileV2Case_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: GetMobileV2Case_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: GetMobileV2Case_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (GetMobileV2Case_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface GetMobileV2Case_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2Case_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetMobileV2Case_case_damage_contact_address;
}

export interface GetMobileV2Case_case_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface GetMobileV2Case_case_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: GetMobileV2Case_case_damage_businessArea;
  /**
   * Damage category
   */
  category: GetMobileV2Case_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetMobileV2Case_case_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: GetMobileV2Case_case_damage_contact;
  /**
   * contacts for damage
   */
  contacts: GetMobileV2Case_case_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface GetMobileV2Case_case_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2Case_case_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetMobileV2Case_case_policyHolder_address;
}

export interface GetMobileV2Case_case_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetMobileV2Case_case_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetMobileV2Case_case_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
  /**
   * The question
   */
  title: string;
}

export interface GetMobileV2Case_case_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: GetMobileV2Case_case_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: GetMobileV2Case_case_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: GetMobileV2Case_case_visitation_answers[] | null;
  /**
   * If case needs a moisture report
   */
  isMoistureReportRequired: boolean | null;
  /**
   * If case needs an SP phase one report
   */
  isSpPhaseOneReportCreated: boolean | null;
}

export interface GetMobileV2Case_case_requisitioner {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Requisitioner relation
   */
  relation: string;
}

export interface GetMobileV2Case_case_adviser {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface GetMobileV2Case_case_referrer {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface GetMobileV2Case_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetMobileV2Case_case_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetMobileV2Case_case_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2Case_case_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: GetMobileV2Case_case_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface GetMobileV2Case_case_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2Case_case_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: GetMobileV2Case_case_ssgDepartment_location_address;
}

export interface GetMobileV2Case_case_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: GetMobileV2Case_case_ssgDepartment_location;
}

export interface GetMobileV2Case_case_caseAgreements_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetMobileV2Case_case_caseAgreements {
  /**
   * Agreement content
   */
  content: string;
  /**
   * Agreement category
   */
  category: CaseAgreementCategory;
  /**
   * User who created agreement
   */
  user: GetMobileV2Case_case_caseAgreements_user;
  /**
   * Timestamp of when agreement was created
   */
  timestamp: GQL_DateTime;
  /**
   * Whether case agreement should be sent to ScalePoint
   */
  shouldSaveInScalePoint: boolean;
}

export interface GetMobileV2Case_case_caseEmails {
  /**
   * Email content
   */
  content: string;
  /**
   * List of email attachments
   */
  attachments: string[];
  /**
   * List of email receivers
   */
  receivers: string[];
  /**
   * Timestamp of when email was created
   */
  timestamp: GQL_DateTime;
}

export interface GetMobileV2Case_case_userFavorite {
  /**
   * Unique user id
   */
  user: string;
  /**
   * Color code set by user
   */
  color: string | null;
  /**
   * Favorite active status
   */
  active: boolean;
}

export interface GetMobileV2Case_case_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetMobileV2Case_case_jobs_assignedTo {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface GetMobileV2Case_case_jobs_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetMobileV2Case_case_jobs_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
}

export interface GetMobileV2Case_case_jobs_movable_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface GetMobileV2Case_case_jobs_movable {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: GetMobileV2Case_case_jobs_movable_placement;
}

export interface GetMobileV2Case_case_jobs {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Type of case task
   */
  type: JobType;
  /**
   * Description of the case task
   */
  description: string;
  /**
   * Deadline of the case task
   */
  deadline: GQL_DateTime;
  /**
   * The user responsible for the task
   */
  assignedTo: GetMobileV2Case_case_jobs_assignedTo | null;
  /**
   * The location responsible to the task
   */
  location: GetMobileV2Case_case_jobs_location | null;
  /**
   * The case the task belongs to
   */
  case: GetMobileV2Case_case_jobs_case | null;
  /**
   * The movable responsible to the task
   */
  movable: GetMobileV2Case_case_jobs_movable | null;
  /**
   * Whether the task is completed
   */
  completed: boolean;
}

export interface GetMobileV2Case_case_drivingSlipSeries_drivingSlips_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetMobileV2Case_case_drivingSlipSeries_drivingSlips_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetMobileV2Case_case_drivingSlipSeries_drivingSlips_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetMobileV2Case_case_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface GetMobileV2Case_case_drivingSlipSeries_drivingSlips_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: GetMobileV2Case_case_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface GetMobileV2Case_case_drivingSlipSeries_drivingSlips_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: GetMobileV2Case_case_drivingSlipSeries_drivingSlips_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface GetMobileV2Case_case_drivingSlipSeries_drivingSlips_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: GetMobileV2Case_case_drivingSlipSeries_drivingSlips_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface GetMobileV2Case_case_drivingSlipSeries_drivingSlips_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetMobileV2Case_case_drivingSlipSeries_drivingSlips_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetMobileV2Case_case_drivingSlipSeries_drivingSlips_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetMobileV2Case_case_drivingSlipSeries_drivingSlips_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetMobileV2Case_case_drivingSlipSeries_drivingSlips_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetMobileV2Case_case_drivingSlipSeries_drivingSlips_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetMobileV2Case_case_drivingSlipSeries_drivingSlips_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetMobileV2Case_case_drivingSlipSeries_drivingSlips_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetMobileV2Case_case_drivingSlipSeries_drivingSlips_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetMobileV2Case_case_drivingSlipSeries_drivingSlips_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetMobileV2Case_case_drivingSlipSeries_drivingSlips_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetMobileV2Case_case_drivingSlipSeries_drivingSlips_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetMobileV2Case_case_drivingSlipSeries_drivingSlips_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetMobileV2Case_case_drivingSlipSeries_drivingSlips_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetMobileV2Case_case_drivingSlipSeries_drivingSlips_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetMobileV2Case_case_drivingSlipSeries_drivingSlips_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: GetMobileV2Case_case_drivingSlipSeries_drivingSlips_changes_user;
  /**
   * Value before
   */
  before: GetMobileV2Case_case_drivingSlipSeries_drivingSlips_changes_before;
  /**
   * Value after
   */
  after: GetMobileV2Case_case_drivingSlipSeries_drivingSlips_changes_after;
}

export interface GetMobileV2Case_case_drivingSlipSeries_drivingSlips_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetMobileV2Case_case_drivingSlipSeries_drivingSlips_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface GetMobileV2Case_case_drivingSlipSeries_drivingSlips {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: GetMobileV2Case_case_drivingSlipSeries_drivingSlips_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: GetMobileV2Case_case_drivingSlipSeries_drivingSlips_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetMobileV2Case_case_drivingSlipSeries_drivingSlips_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: GetMobileV2Case_case_drivingSlipSeries_drivingSlips_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: GetMobileV2Case_case_drivingSlipSeries_drivingSlips_changes[];
  /**
   * Location of driving slip
   */
  location: GetMobileV2Case_case_drivingSlipSeries_drivingSlips_location;
  /**
   * Department of driving slip
   */
  department: GetMobileV2Case_case_drivingSlipSeries_drivingSlips_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
}

export interface GetMobileV2Case_case_drivingSlipSeries {
  /**
   * Unique id of driving slip series
   */
  id: string;
  /**
   * Driving slip series completed status
   */
  completed: boolean;
  /**
   * Driving slips on series
   */
  drivingSlips: GetMobileV2Case_case_drivingSlipSeries_drivingSlips[];
}

export interface GetMobileV2Case_case_alternativeContact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Comment on person
   */
  comment: string | null;
}

export interface GetMobileV2Case_case_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface GetMobileV2Case_case_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: GetMobileV2Case_case_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface GetMobileV2Case_case_requisitions_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
}

export interface GetMobileV2Case_case_requisitions_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
  /**
   * Id of damage category
   */
  id: string;
}

export interface GetMobileV2Case_case_requisitions_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of damage cause
   */
  id: string;
}

export interface GetMobileV2Case_case_requisitions_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2Case_case_requisitions_case_damage_contact {
  /**
   * Contact address information
   */
  address: GetMobileV2Case_case_requisitions_case_damage_contact_address;
}

export interface GetMobileV2Case_case_requisitions_case_damage {
  /**
   * Damage category
   */
  category: GetMobileV2Case_case_requisitions_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetMobileV2Case_case_requisitions_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: GetMobileV2Case_case_requisitions_case_damage_contact;
}

export interface GetMobileV2Case_case_requisitions_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetMobileV2Case_case_requisitions_case_debitor;
  /**
   * Case damage details
   */
  damage: GetMobileV2Case_case_requisitions_case_damage;
}

export interface GetMobileV2Case_case_requisitions_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
}

export interface GetMobileV2Case_case_requisitions_drivingSlip_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetMobileV2Case_case_requisitions_drivingSlip_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
}

export interface GetMobileV2Case_case_requisitions_drivingSlip_case_damage {
  /**
   * Damage category
   */
  category: GetMobileV2Case_case_requisitions_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetMobileV2Case_case_requisitions_drivingSlip_case_damage_cause;
}

export interface GetMobileV2Case_case_requisitions_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: GetMobileV2Case_case_requisitions_drivingSlip_case_damage;
}

export interface GetMobileV2Case_case_requisitions_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Case which driving slip is related to
   */
  case: GetMobileV2Case_case_requisitions_drivingSlip_case;
}

export interface GetMobileV2Case_case_requisitions_vendor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2Case_case_requisitions_vendor {
  /**
   * ERP reference no for vendor
   */
  erpReferenceNo: string;
  /**
   * The name of the vendor
   */
  company: string | null;
  /**
   * The address of the vendor
   */
  address: GetMobileV2Case_case_requisitions_vendor_address | null;
  /**
   * The email of the vendor
   */
  email: string | null;
  /**
   * The phone number of the vendor
   */
  phone: string | null;
  /**
   * The posting group of the vendor
   */
  vendorPostingGroup: string;
  /**
   * Attached SSG department of the vendor
   */
  ssgDepartment: string;
  /**
   * Attached SSG location of the vendor
   */
  ssgLocation: string;
  /**
   * Vendor type
   */
  type: string;
}

export interface GetMobileV2Case_case_requisitions_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface GetMobileV2Case_case_requisitions {
  /**
   * id of the requisition
   */
  id: string;
  /**
   * case owning the requisition
   */
  case: GetMobileV2Case_case_requisitions_case | null;
  /**
   * location owning the requisition
   */
  department: GetMobileV2Case_case_requisitions_department | null;
  /**
   * driving slip owning the requisition
   */
  drivingSlip: GetMobileV2Case_case_requisitions_drivingSlip | null;
  /**
   * description of the requisition
   */
  description: string;
  /**
   * orderNumber of the requisition
   */
  orderNumber: string;
  /**
   * the type of the requisition
   */
  type: RequisitionType;
  /**
   * Requisition status
   */
  status: boolean;
  /**
   * the vendor of the requisition
   */
  vendor: GetMobileV2Case_case_requisitions_vendor | null;
  /**
   * Timestamp of when the requisition was created
   */
  createdAt: GQL_DateTime;
  /**
   * User who created the requisition
   */
  createdBy: GetMobileV2Case_case_requisitions_createdBy;
  /**
   * email of the vendor
   */
  vendorEmail: string | null;
  /**
   * phone number of the vendor
   */
  vendorPhoneNumber: string;
  /**
   * name of the vendor
   */
  vendorName: string;
}

export interface GetMobileV2Case_case_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetMobileV2Case_case_changes_before_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2Case_case_changes_before_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor billing address
   */
  billingAddress: GetMobileV2Case_case_changes_before_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
}

export interface GetMobileV2Case_case_changes_before_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetMobileV2Case_case_changes_before_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetMobileV2Case_case_changes_before_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetMobileV2Case_case_changes_before_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetMobileV2Case_case_changes_before_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetMobileV2Case_case_changes_before_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetMobileV2Case_case_changes_before_damage_cause_departments_location;
}

export interface GetMobileV2Case_case_changes_before_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: GetMobileV2Case_case_changes_before_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: GetMobileV2Case_case_changes_before_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (GetMobileV2Case_case_changes_before_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface GetMobileV2Case_case_changes_before_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2Case_case_changes_before_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetMobileV2Case_case_changes_before_damage_contact_address;
}

export interface GetMobileV2Case_case_changes_before_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface GetMobileV2Case_case_changes_before_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: GetMobileV2Case_case_changes_before_damage_businessArea;
  /**
   * Damage category
   */
  category: GetMobileV2Case_case_changes_before_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetMobileV2Case_case_changes_before_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: GetMobileV2Case_case_changes_before_damage_contact;
  /**
   * contacts for damage
   */
  contacts: GetMobileV2Case_case_changes_before_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface GetMobileV2Case_case_changes_before_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2Case_case_changes_before_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetMobileV2Case_case_changes_before_policyHolder_address;
}

export interface GetMobileV2Case_case_changes_before_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetMobileV2Case_case_changes_before_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetMobileV2Case_case_changes_before_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
}

export interface GetMobileV2Case_case_changes_before_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: GetMobileV2Case_case_changes_before_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: GetMobileV2Case_case_changes_before_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: GetMobileV2Case_case_changes_before_visitation_answers[] | null;
}

export interface GetMobileV2Case_case_changes_before_requisitioner {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Requisitioner relation
   */
  relation: string;
}

export interface GetMobileV2Case_case_changes_before_drivingSlips_driver {
  /**
   * Name of user
   */
  name: string;
}

export interface GetMobileV2Case_case_changes_before_drivingSlips_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
}

export interface GetMobileV2Case_case_changes_before_drivingSlips {
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetMobileV2Case_case_changes_before_drivingSlips_driver | null;
  /**
   * Car added to the drivingslip
   */
  car: GetMobileV2Case_case_changes_before_drivingSlips_car | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
}

export interface GetMobileV2Case_case_changes_before_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetMobileV2Case_case_changes_before_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetMobileV2Case_case_changes_before_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2Case_case_changes_before_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: GetMobileV2Case_case_changes_before_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface GetMobileV2Case_case_changes_before_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2Case_case_changes_before_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: GetMobileV2Case_case_changes_before_ssgDepartment_location_address;
}

export interface GetMobileV2Case_case_changes_before_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: GetMobileV2Case_case_changes_before_ssgDepartment_location;
}

export interface GetMobileV2Case_case_changes_before_caseEmails {
  /**
   * Email content
   */
  content: string;
  /**
   * List of email attachments
   */
  attachments: string[];
  /**
   * List of email receivers
   */
  receivers: string[];
  /**
   * Timestamp of when email was created
   */
  timestamp: GQL_DateTime;
}

export interface GetMobileV2Case_case_changes_before_caseMachineUsages {
  /**
   * Machine identifier
   */
  machine: string;
  /**
   * Start time
   */
  startTime: GQL_DateTime;
  /**
   * End time
   */
  endTime: GQL_DateTime | null;
  /**
   * Meter reading at start time
   */
  startMeterReading: GQL_Decimal;
  /**
   * Meter reading at end time
   */
  endMeterReading: GQL_Decimal | null;
}

export interface GetMobileV2Case_case_changes_before_alternativeContact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Comment on person
   */
  comment: string | null;
}

export interface GetMobileV2Case_case_changes_before_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface GetMobileV2Case_case_changes_before_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: GetMobileV2Case_case_changes_before_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface GetMobileV2Case_case_changes_before {
  /**
   * Case status
   */
  status: CaseStatus | null;
  /**
   * Case track
   */
  track: number | null;
  /**
   * Case debitor details
   */
  debitor: GetMobileV2Case_case_changes_before_debitor | null;
  /**
   * Case damage details
   */
  damage: GetMobileV2Case_case_changes_before_damage | null;
  /**
   * Case policy holder details
   */
  policyHolder: GetMobileV2Case_case_changes_before_policyHolder | null;
  /**
   * Case visitation details
   */
  visitation: GetMobileV2Case_case_changes_before_visitation | null;
  /**
   * Case requisitioner details
   */
  requisitioner: GetMobileV2Case_case_changes_before_requisitioner | null;
  /**
   * The driving slip changed on case
   */
  drivingSlips: GetMobileV2Case_case_changes_before_drivingSlips | null;
  /**
   * Project manager attached to case
   */
  projectManager: GetMobileV2Case_case_changes_before_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: GetMobileV2Case_case_changes_before_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: GetMobileV2Case_case_changes_before_ssgLocation | null;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: GetMobileV2Case_case_changes_before_ssgDepartment | null;
  /**
   * List of case emails
   */
  caseEmails: GetMobileV2Case_case_changes_before_caseEmails[] | null;
  /**
   * List of case machine usages
   */
  caseMachineUsages: GetMobileV2Case_case_changes_before_caseMachineUsages[] | null;
  /**
   * Important info on case
   */
  importantInfo: string | null;
  /**
   * Requisition from fire department
   */
  skafor: boolean | null;
  /**
   * Alternative contact person
   */
  alternativeContact: GetMobileV2Case_case_changes_before_alternativeContact | null;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: GetMobileV2Case_case_changes_before_riskEvaluationAnswers[] | null;
  /**
   * Name of files
   */
  fileNames: string | null;
}

export interface GetMobileV2Case_case_changes_after_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2Case_case_changes_after_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor billing address
   */
  billingAddress: GetMobileV2Case_case_changes_after_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
}

export interface GetMobileV2Case_case_changes_after_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetMobileV2Case_case_changes_after_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetMobileV2Case_case_changes_after_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetMobileV2Case_case_changes_after_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetMobileV2Case_case_changes_after_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetMobileV2Case_case_changes_after_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetMobileV2Case_case_changes_after_damage_cause_departments_location;
}

export interface GetMobileV2Case_case_changes_after_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: GetMobileV2Case_case_changes_after_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: GetMobileV2Case_case_changes_after_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (GetMobileV2Case_case_changes_after_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface GetMobileV2Case_case_changes_after_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2Case_case_changes_after_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetMobileV2Case_case_changes_after_damage_contact_address;
}

export interface GetMobileV2Case_case_changes_after_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface GetMobileV2Case_case_changes_after_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: GetMobileV2Case_case_changes_after_damage_businessArea;
  /**
   * Damage category
   */
  category: GetMobileV2Case_case_changes_after_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetMobileV2Case_case_changes_after_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: GetMobileV2Case_case_changes_after_damage_contact;
  /**
   * contacts for damage
   */
  contacts: GetMobileV2Case_case_changes_after_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface GetMobileV2Case_case_changes_after_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2Case_case_changes_after_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetMobileV2Case_case_changes_after_policyHolder_address;
}

export interface GetMobileV2Case_case_changes_after_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetMobileV2Case_case_changes_after_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetMobileV2Case_case_changes_after_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
}

export interface GetMobileV2Case_case_changes_after_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: GetMobileV2Case_case_changes_after_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: GetMobileV2Case_case_changes_after_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: GetMobileV2Case_case_changes_after_visitation_answers[] | null;
}

export interface GetMobileV2Case_case_changes_after_requisitioner {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Requisitioner relation
   */
  relation: string;
}

export interface GetMobileV2Case_case_changes_after_drivingSlips_driver {
  /**
   * Name of user
   */
  name: string;
}

export interface GetMobileV2Case_case_changes_after_drivingSlips_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
}

export interface GetMobileV2Case_case_changes_after_drivingSlips {
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetMobileV2Case_case_changes_after_drivingSlips_driver | null;
  /**
   * Car added to the drivingslip
   */
  car: GetMobileV2Case_case_changes_after_drivingSlips_car | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
}

export interface GetMobileV2Case_case_changes_after_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetMobileV2Case_case_changes_after_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetMobileV2Case_case_changes_after_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2Case_case_changes_after_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: GetMobileV2Case_case_changes_after_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface GetMobileV2Case_case_changes_after_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2Case_case_changes_after_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: GetMobileV2Case_case_changes_after_ssgDepartment_location_address;
}

export interface GetMobileV2Case_case_changes_after_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: GetMobileV2Case_case_changes_after_ssgDepartment_location;
}

export interface GetMobileV2Case_case_changes_after_caseEmails {
  /**
   * Email content
   */
  content: string;
  /**
   * List of email attachments
   */
  attachments: string[];
  /**
   * List of email receivers
   */
  receivers: string[];
  /**
   * Timestamp of when email was created
   */
  timestamp: GQL_DateTime;
}

export interface GetMobileV2Case_case_changes_after_caseMachineUsages {
  /**
   * Machine identifier
   */
  machine: string;
  /**
   * Start time
   */
  startTime: GQL_DateTime;
  /**
   * End time
   */
  endTime: GQL_DateTime | null;
  /**
   * Meter reading at start time
   */
  startMeterReading: GQL_Decimal;
  /**
   * Meter reading at end time
   */
  endMeterReading: GQL_Decimal | null;
}

export interface GetMobileV2Case_case_changes_after_alternativeContact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Comment on person
   */
  comment: string | null;
}

export interface GetMobileV2Case_case_changes_after_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface GetMobileV2Case_case_changes_after_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: GetMobileV2Case_case_changes_after_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface GetMobileV2Case_case_changes_after {
  /**
   * Case status
   */
  status: CaseStatus | null;
  /**
   * Case track
   */
  track: number | null;
  /**
   * Case debitor details
   */
  debitor: GetMobileV2Case_case_changes_after_debitor | null;
  /**
   * Case damage details
   */
  damage: GetMobileV2Case_case_changes_after_damage | null;
  /**
   * Case policy holder details
   */
  policyHolder: GetMobileV2Case_case_changes_after_policyHolder | null;
  /**
   * Case visitation details
   */
  visitation: GetMobileV2Case_case_changes_after_visitation | null;
  /**
   * Case requisitioner details
   */
  requisitioner: GetMobileV2Case_case_changes_after_requisitioner | null;
  /**
   * The driving slip changed on case
   */
  drivingSlips: GetMobileV2Case_case_changes_after_drivingSlips | null;
  /**
   * Project manager attached to case
   */
  projectManager: GetMobileV2Case_case_changes_after_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: GetMobileV2Case_case_changes_after_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: GetMobileV2Case_case_changes_after_ssgLocation | null;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: GetMobileV2Case_case_changes_after_ssgDepartment | null;
  /**
   * List of case emails
   */
  caseEmails: GetMobileV2Case_case_changes_after_caseEmails[] | null;
  /**
   * List of case machine usages
   */
  caseMachineUsages: GetMobileV2Case_case_changes_after_caseMachineUsages[] | null;
  /**
   * Important info on case
   */
  importantInfo: string | null;
  /**
   * Requisition from fire department
   */
  skafor: boolean | null;
  /**
   * Alternative contact person
   */
  alternativeContact: GetMobileV2Case_case_changes_after_alternativeContact | null;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: GetMobileV2Case_case_changes_after_riskEvaluationAnswers[] | null;
  /**
   * Name of files
   */
  fileNames: string | null;
}

export interface GetMobileV2Case_case_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: GetMobileV2Case_case_changes_user | null;
  /**
   * Value before
   */
  before: GetMobileV2Case_case_changes_before;
  /**
   * Value after
   */
  after: GetMobileV2Case_case_changes_after;
}

export interface GetMobileV2Case_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * ReportSent
   */
  reportSent: boolean;
  /**
   * Distance from ssg location to damage address
   */
  distance: string | null;
  /**
   * Whether notifications regarding machines on the case should be sent
   */
  machineNotification: boolean | null;
  /**
   * Whether time registration is allowed on the case
   */
  timeRegistration: boolean | null;
  /**
   * Case debitor details
   */
  debitor: GetMobileV2Case_case_debitor;
  /**
   * Case damage details
   */
  damage: GetMobileV2Case_case_damage;
  /**
   * Case policy holder details
   */
  policyHolder: GetMobileV2Case_case_policyHolder;
  /**
   * Case visitation details
   */
  visitation: GetMobileV2Case_case_visitation;
  /**
   * Case requisitioner details
   */
  requisitioner: GetMobileV2Case_case_requisitioner;
  /**
   * Case advisor details
   */
  adviser: GetMobileV2Case_case_adviser | null;
  /**
   * Case referer details
   */
  referrer: GetMobileV2Case_case_referrer | null;
  /**
   * Project manager attached to case
   */
  projectManager: GetMobileV2Case_case_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: GetMobileV2Case_case_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: GetMobileV2Case_case_ssgLocation;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: GetMobileV2Case_case_ssgDepartment;
  /**
   * Case ready for billing
   */
  billingReady: boolean;
  /**
   * Case work complete
   */
  workComplete: boolean;
  /**
   * List of case agreements
   */
  caseAgreements: GetMobileV2Case_case_caseAgreements[];
  /**
   * List of case emails
   */
  caseEmails: GetMobileV2Case_case_caseEmails[];
  /**
   * List of user favorite entries
   */
  userFavorite: GetMobileV2Case_case_userFavorite | null;
  /**
   * User who created the case
   */
  createdBy: GetMobileV2Case_case_createdBy | null;
  /**
   * Timestamp of when the case was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the case was created
   */
  createdAt: GQL_DateTime;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * The tasks associated with the task
   */
  jobs: GetMobileV2Case_case_jobs[] | null;
  /**
   * The driving slips series associated with the case
   */
  drivingSlipSeries: GetMobileV2Case_case_drivingSlipSeries[];
  /**
   * Important info on the case
   */
  importantInfo: string | null;
  /**
   * Alternative contact
   */
  alternativeContact: GetMobileV2Case_case_alternativeContact | null;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: GetMobileV2Case_case_riskEvaluationAnswers[] | null;
  /**
   * Optional comment for risk evaluation
   */
  riskEvaluationComment: string | null;
  /**
   * Requisition of the case
   */
  requisitions: GetMobileV2Case_case_requisitions[];
  scalePointStatus: ScalePointStatus;
  scalePointErrorMessage: string | null;
  arrived: boolean;
  arrivedDate: GQL_DateTime | null;
  /**
   * Case change version number
   */
  version: number;
  /**
   * Case change log
   */
  changes: GetMobileV2Case_case_changes[];
}

export interface GetMobileV2Case {
  case: GetMobileV2Case_case;
}

export interface GetMobileV2CaseVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMobileV2CaseAdminData
// ====================================================

export interface GetMobileV2CaseAdminData_damageCategories {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetMobileV2CaseAdminData_damageCauses_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetMobileV2CaseAdminData_damageCauses_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetMobileV2CaseAdminData_damageCauses_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetMobileV2CaseAdminData_damageCauses_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetMobileV2CaseAdminData_damageCauses_departments_location;
}

export interface GetMobileV2CaseAdminData_damageCauses {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: GetMobileV2CaseAdminData_damageCauses_businessArea;
  /**
   * Id of associated category
   */
  category: GetMobileV2CaseAdminData_damageCauses_category;
  /**
   * Departments associated to damage cause
   */
  departments: (GetMobileV2CaseAdminData_damageCauses_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface GetMobileV2CaseAdminData_departments_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2CaseAdminData_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: GetMobileV2CaseAdminData_departments_location_address;
}

export interface GetMobileV2CaseAdminData_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: GetMobileV2CaseAdminData_departments_location;
}

export interface GetMobileV2CaseAdminData_locations_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2CaseAdminData_locations {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: GetMobileV2CaseAdminData_locations_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface GetMobileV2CaseAdminData_drivingSlipCategories {
  /**
   * Category code
   */
  code: string;
  /**
   * Category name
   */
  name: string;
}

export interface GetMobileV2CaseAdminData_drivingSlipItems {
  /**
   * item no
   */
  no: string;
  /**
   * description
   */
  description: string;
  /**
   * Base unit of messure
   */
  baseUOM: string;
  /**
   * ERP job task number
   */
  jobTask: string;
  /**
   * ERP job filter code
   */
  filterCode: string;
}

export interface GetMobileV2CaseAdminData {
  damageCategories: GetMobileV2CaseAdminData_damageCategories[];
  damageCauses: GetMobileV2CaseAdminData_damageCauses[];
  departments: GetMobileV2CaseAdminData_departments[];
  locations: GetMobileV2CaseAdminData_locations[];
  drivingSlipCategories: GetMobileV2CaseAdminData_drivingSlipCategories[];
  drivingSlipItems: GetMobileV2CaseAdminData_drivingSlipItems[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMobileV2Cases
// ====================================================

export interface GetMobileV2Cases_cases_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2Cases_cases_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2Cases_cases_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: GetMobileV2Cases_cases_debitor_shippingAddress_address;
}

export interface GetMobileV2Cases_cases_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2Cases_cases_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: GetMobileV2Cases_cases_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: GetMobileV2Cases_cases_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: GetMobileV2Cases_cases_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface GetMobileV2Cases_cases_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetMobileV2Cases_cases_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetMobileV2Cases_cases_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetMobileV2Cases_cases_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetMobileV2Cases_cases_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetMobileV2Cases_cases_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetMobileV2Cases_cases_damage_cause_departments_location;
}

export interface GetMobileV2Cases_cases_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: GetMobileV2Cases_cases_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: GetMobileV2Cases_cases_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (GetMobileV2Cases_cases_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface GetMobileV2Cases_cases_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2Cases_cases_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetMobileV2Cases_cases_damage_contact_address;
}

export interface GetMobileV2Cases_cases_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface GetMobileV2Cases_cases_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: GetMobileV2Cases_cases_damage_businessArea;
  /**
   * Damage category
   */
  category: GetMobileV2Cases_cases_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetMobileV2Cases_cases_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: GetMobileV2Cases_cases_damage_contact;
  /**
   * contacts for damage
   */
  contacts: GetMobileV2Cases_cases_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface GetMobileV2Cases_cases_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2Cases_cases_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetMobileV2Cases_cases_policyHolder_address;
}

export interface GetMobileV2Cases_cases_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetMobileV2Cases_cases_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetMobileV2Cases_cases_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
  /**
   * The question
   */
  title: string;
}

export interface GetMobileV2Cases_cases_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: GetMobileV2Cases_cases_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: GetMobileV2Cases_cases_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: GetMobileV2Cases_cases_visitation_answers[] | null;
  /**
   * If case needs a moisture report
   */
  isMoistureReportRequired: boolean | null;
  /**
   * If case needs an SP phase one report
   */
  isSpPhaseOneReportCreated: boolean | null;
}

export interface GetMobileV2Cases_cases_requisitioner {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Requisitioner relation
   */
  relation: string;
}

export interface GetMobileV2Cases_cases_adviser {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface GetMobileV2Cases_cases_referrer {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface GetMobileV2Cases_cases_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetMobileV2Cases_cases_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetMobileV2Cases_cases_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2Cases_cases_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: GetMobileV2Cases_cases_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface GetMobileV2Cases_cases_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2Cases_cases_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: GetMobileV2Cases_cases_ssgDepartment_location_address;
}

export interface GetMobileV2Cases_cases_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: GetMobileV2Cases_cases_ssgDepartment_location;
}

export interface GetMobileV2Cases_cases_caseAgreements_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetMobileV2Cases_cases_caseAgreements {
  /**
   * Agreement content
   */
  content: string;
  /**
   * Agreement category
   */
  category: CaseAgreementCategory;
  /**
   * User who created agreement
   */
  user: GetMobileV2Cases_cases_caseAgreements_user;
  /**
   * Timestamp of when agreement was created
   */
  timestamp: GQL_DateTime;
  /**
   * Whether case agreement should be sent to ScalePoint
   */
  shouldSaveInScalePoint: boolean;
}

export interface GetMobileV2Cases_cases_caseEmails {
  /**
   * Email content
   */
  content: string;
  /**
   * List of email attachments
   */
  attachments: string[];
  /**
   * List of email receivers
   */
  receivers: string[];
  /**
   * Timestamp of when email was created
   */
  timestamp: GQL_DateTime;
}

export interface GetMobileV2Cases_cases_userFavorite {
  /**
   * Unique user id
   */
  user: string;
  /**
   * Color code set by user
   */
  color: string | null;
  /**
   * Favorite active status
   */
  active: boolean;
}

export interface GetMobileV2Cases_cases_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetMobileV2Cases_cases_jobs_assignedTo {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface GetMobileV2Cases_cases_jobs_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetMobileV2Cases_cases_jobs_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
}

export interface GetMobileV2Cases_cases_jobs_movable_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface GetMobileV2Cases_cases_jobs_movable {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: GetMobileV2Cases_cases_jobs_movable_placement;
}

export interface GetMobileV2Cases_cases_jobs {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Type of case task
   */
  type: JobType;
  /**
   * Description of the case task
   */
  description: string;
  /**
   * Deadline of the case task
   */
  deadline: GQL_DateTime;
  /**
   * The user responsible for the task
   */
  assignedTo: GetMobileV2Cases_cases_jobs_assignedTo | null;
  /**
   * The location responsible to the task
   */
  location: GetMobileV2Cases_cases_jobs_location | null;
  /**
   * The case the task belongs to
   */
  case: GetMobileV2Cases_cases_jobs_case | null;
  /**
   * The movable responsible to the task
   */
  movable: GetMobileV2Cases_cases_jobs_movable | null;
  /**
   * Whether the task is completed
   */
  completed: boolean;
}

export interface GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_changes_user;
  /**
   * Value before
   */
  before: GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_changes_before;
  /**
   * Value after
   */
  after: GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_changes_after;
}

export interface GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_changes[];
  /**
   * Location of driving slip
   */
  location: GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_location;
  /**
   * Department of driving slip
   */
  department: GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
}

export interface GetMobileV2Cases_cases_drivingSlipSeries {
  /**
   * Unique id of driving slip series
   */
  id: string;
  /**
   * Driving slip series completed status
   */
  completed: boolean;
  /**
   * Driving slips on series
   */
  drivingSlips: GetMobileV2Cases_cases_drivingSlipSeries_drivingSlips[];
}

export interface GetMobileV2Cases_cases_alternativeContact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Comment on person
   */
  comment: string | null;
}

export interface GetMobileV2Cases_cases_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface GetMobileV2Cases_cases_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: GetMobileV2Cases_cases_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface GetMobileV2Cases_cases_requisitions_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
}

export interface GetMobileV2Cases_cases_requisitions_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
  /**
   * Id of damage category
   */
  id: string;
}

export interface GetMobileV2Cases_cases_requisitions_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of damage cause
   */
  id: string;
}

export interface GetMobileV2Cases_cases_requisitions_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2Cases_cases_requisitions_case_damage_contact {
  /**
   * Contact address information
   */
  address: GetMobileV2Cases_cases_requisitions_case_damage_contact_address;
}

export interface GetMobileV2Cases_cases_requisitions_case_damage {
  /**
   * Damage category
   */
  category: GetMobileV2Cases_cases_requisitions_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetMobileV2Cases_cases_requisitions_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: GetMobileV2Cases_cases_requisitions_case_damage_contact;
}

export interface GetMobileV2Cases_cases_requisitions_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetMobileV2Cases_cases_requisitions_case_debitor;
  /**
   * Case damage details
   */
  damage: GetMobileV2Cases_cases_requisitions_case_damage;
}

export interface GetMobileV2Cases_cases_requisitions_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
}

export interface GetMobileV2Cases_cases_requisitions_drivingSlip_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetMobileV2Cases_cases_requisitions_drivingSlip_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
}

export interface GetMobileV2Cases_cases_requisitions_drivingSlip_case_damage {
  /**
   * Damage category
   */
  category: GetMobileV2Cases_cases_requisitions_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetMobileV2Cases_cases_requisitions_drivingSlip_case_damage_cause;
}

export interface GetMobileV2Cases_cases_requisitions_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: GetMobileV2Cases_cases_requisitions_drivingSlip_case_damage;
}

export interface GetMobileV2Cases_cases_requisitions_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Case which driving slip is related to
   */
  case: GetMobileV2Cases_cases_requisitions_drivingSlip_case;
}

export interface GetMobileV2Cases_cases_requisitions_vendor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2Cases_cases_requisitions_vendor {
  /**
   * ERP reference no for vendor
   */
  erpReferenceNo: string;
  /**
   * The name of the vendor
   */
  company: string | null;
  /**
   * The address of the vendor
   */
  address: GetMobileV2Cases_cases_requisitions_vendor_address | null;
  /**
   * The email of the vendor
   */
  email: string | null;
  /**
   * The phone number of the vendor
   */
  phone: string | null;
  /**
   * The posting group of the vendor
   */
  vendorPostingGroup: string;
  /**
   * Attached SSG department of the vendor
   */
  ssgDepartment: string;
  /**
   * Attached SSG location of the vendor
   */
  ssgLocation: string;
  /**
   * Vendor type
   */
  type: string;
}

export interface GetMobileV2Cases_cases_requisitions_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface GetMobileV2Cases_cases_requisitions {
  /**
   * id of the requisition
   */
  id: string;
  /**
   * case owning the requisition
   */
  case: GetMobileV2Cases_cases_requisitions_case | null;
  /**
   * location owning the requisition
   */
  department: GetMobileV2Cases_cases_requisitions_department | null;
  /**
   * driving slip owning the requisition
   */
  drivingSlip: GetMobileV2Cases_cases_requisitions_drivingSlip | null;
  /**
   * description of the requisition
   */
  description: string;
  /**
   * orderNumber of the requisition
   */
  orderNumber: string;
  /**
   * the type of the requisition
   */
  type: RequisitionType;
  /**
   * Requisition status
   */
  status: boolean;
  /**
   * the vendor of the requisition
   */
  vendor: GetMobileV2Cases_cases_requisitions_vendor | null;
  /**
   * Timestamp of when the requisition was created
   */
  createdAt: GQL_DateTime;
  /**
   * User who created the requisition
   */
  createdBy: GetMobileV2Cases_cases_requisitions_createdBy;
  /**
   * email of the vendor
   */
  vendorEmail: string | null;
  /**
   * phone number of the vendor
   */
  vendorPhoneNumber: string;
  /**
   * name of the vendor
   */
  vendorName: string;
}

export interface GetMobileV2Cases_cases {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * ReportSent
   */
  reportSent: boolean;
  /**
   * Distance from ssg location to damage address
   */
  distance: string | null;
  /**
   * Whether notifications regarding machines on the case should be sent
   */
  machineNotification: boolean | null;
  /**
   * Whether time registration is allowed on the case
   */
  timeRegistration: boolean | null;
  /**
   * Case debitor details
   */
  debitor: GetMobileV2Cases_cases_debitor;
  /**
   * Case damage details
   */
  damage: GetMobileV2Cases_cases_damage;
  /**
   * Case policy holder details
   */
  policyHolder: GetMobileV2Cases_cases_policyHolder;
  /**
   * Case visitation details
   */
  visitation: GetMobileV2Cases_cases_visitation;
  /**
   * Case requisitioner details
   */
  requisitioner: GetMobileV2Cases_cases_requisitioner;
  /**
   * Case advisor details
   */
  adviser: GetMobileV2Cases_cases_adviser | null;
  /**
   * Case referer details
   */
  referrer: GetMobileV2Cases_cases_referrer | null;
  /**
   * Project manager attached to case
   */
  projectManager: GetMobileV2Cases_cases_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: GetMobileV2Cases_cases_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: GetMobileV2Cases_cases_ssgLocation;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: GetMobileV2Cases_cases_ssgDepartment;
  /**
   * Case ready for billing
   */
  billingReady: boolean;
  /**
   * Case work complete
   */
  workComplete: boolean;
  /**
   * List of case agreements
   */
  caseAgreements: GetMobileV2Cases_cases_caseAgreements[];
  /**
   * List of case emails
   */
  caseEmails: GetMobileV2Cases_cases_caseEmails[];
  /**
   * List of user favorite entries
   */
  userFavorite: GetMobileV2Cases_cases_userFavorite | null;
  /**
   * User who created the case
   */
  createdBy: GetMobileV2Cases_cases_createdBy | null;
  /**
   * Timestamp of when the case was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the case was created
   */
  createdAt: GQL_DateTime;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * The tasks associated with the task
   */
  jobs: GetMobileV2Cases_cases_jobs[] | null;
  /**
   * The driving slips series associated with the case
   */
  drivingSlipSeries: GetMobileV2Cases_cases_drivingSlipSeries[];
  /**
   * Important info on the case
   */
  importantInfo: string | null;
  /**
   * Alternative contact
   */
  alternativeContact: GetMobileV2Cases_cases_alternativeContact | null;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: GetMobileV2Cases_cases_riskEvaluationAnswers[] | null;
  /**
   * Optional comment for risk evaluation
   */
  riskEvaluationComment: string | null;
  /**
   * Requisition of the case
   */
  requisitions: GetMobileV2Cases_cases_requisitions[];
  scalePointStatus: ScalePointStatus;
  scalePointErrorMessage: string | null;
  arrived: boolean;
  arrivedDate: GQL_DateTime | null;
}

export interface GetMobileV2Cases {
  cases: GetMobileV2Cases_cases[];
}

export interface GetMobileV2CasesVariables {
  address?: AddressInput | null;
  awaiting?: boolean | null;
  damageServiceCompleted?: boolean | null;
  calledBack?: boolean | null;
  machinesOnCase?: boolean | null;
  ownCases?: boolean | null;
  appliedClosed?: boolean | null;
  closedCases?: boolean | null;
  debitor?: string[] | null;
  postalCode?: string[] | null;
  track?: number[] | null;
  department?: string[] | null;
  location?: string[] | null;
  damageCategory?: string[] | null;
  damageCause?: string[] | null;
  priority?: CasePriority[] | null;
  caseManager?: string[] | null;
  projectManager?: string[] | null;
  minDate?: GQL_Date | null;
  maxDate?: GQL_Date | null;
  erpReferenceNo?: string | null;
  erpReferenceNos?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMobileV2CasesByErpNo
// ====================================================

export interface GetMobileV2CasesByErpNo_cases_debitor {
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
}

export interface GetMobileV2CasesByErpNo_cases_damage_category {
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetMobileV2CasesByErpNo_cases_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
}

export interface GetMobileV2CasesByErpNo_cases_damage_businessArea {
  /**
   * Name of business area
   */
  name: string;
}

export interface GetMobileV2CasesByErpNo_cases_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2CasesByErpNo_cases_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetMobileV2CasesByErpNo_cases_damage_contact_address;
}

export interface GetMobileV2CasesByErpNo_cases_damage {
  /**
   * Damage category
   */
  category: GetMobileV2CasesByErpNo_cases_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetMobileV2CasesByErpNo_cases_damage_cause;
  /**
   * BusinessArea for damage
   */
  businessArea: GetMobileV2CasesByErpNo_cases_damage_businessArea;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: GetMobileV2CasesByErpNo_cases_damage_contact;
}

export interface GetMobileV2CasesByErpNo_cases_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetMobileV2CasesByErpNo_cases_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetMobileV2CasesByErpNo_cases {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * Whether time registration is allowed on the case
   */
  timeRegistration: boolean | null;
  /**
   * Case debitor details
   */
  debitor: GetMobileV2CasesByErpNo_cases_debitor;
  /**
   * Case damage details
   */
  damage: GetMobileV2CasesByErpNo_cases_damage;
  /**
   * Project manager attached to case
   */
  projectManager: GetMobileV2CasesByErpNo_cases_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: GetMobileV2CasesByErpNo_cases_caseManager | null;
}

export interface GetMobileV2CasesByErpNo {
  cases: GetMobileV2CasesByErpNo_cases[];
}

export interface GetMobileV2CasesByErpNoVariables {
  erpReferenceNo: string;
  closedCases?: boolean | null;
  appliedClosed?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMobileV2CasesForCatalogSuggestions
// ====================================================

export interface GetMobileV2CasesForCatalogSuggestions_cases_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
}

export interface GetMobileV2CasesForCatalogSuggestions_cases_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2CasesForCatalogSuggestions_cases_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetMobileV2CasesForCatalogSuggestions_cases_damage_contact_address;
}

export interface GetMobileV2CasesForCatalogSuggestions_cases_damage {
  /**
   * Damage location contact information
   */
  contact: GetMobileV2CasesForCatalogSuggestions_cases_damage_contact;
}

export interface GetMobileV2CasesForCatalogSuggestions_cases {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetMobileV2CasesForCatalogSuggestions_cases_debitor;
  /**
   * Case damage details
   */
  damage: GetMobileV2CasesForCatalogSuggestions_cases_damage;
}

export interface GetMobileV2CasesForCatalogSuggestions {
  cases: GetMobileV2CasesForCatalogSuggestions_cases[];
}

export interface GetMobileV2CasesForCatalogSuggestionsVariables {
  erpReferenceNo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendCaseEmail
// ====================================================

export interface SendCaseEmail_sendCaseEmail {
  /**
   * This value is not used for anything.
   */
  _: string;
}

export interface SendCaseEmail {
  sendCaseEmail: SendCaseEmail_sendCaseEmail | null;
}

export interface SendCaseEmailVariables {
  caseId: string;
  to: string[];
  cc: string[];
  bcc: string[];
  subject: string;
  body: string;
  includeCaseAgreements: boolean;
  quote: boolean;
  attachements?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCase
// ====================================================

export interface UpdateCase_updateCase_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCase_updateCase_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCase_updateCase_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: UpdateCase_updateCase_debitor_shippingAddress_address;
}

export interface UpdateCase_updateCase_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCase_updateCase_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: UpdateCase_updateCase_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: UpdateCase_updateCase_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: UpdateCase_updateCase_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface UpdateCase_updateCase_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface UpdateCase_updateCase_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface UpdateCase_updateCase_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface UpdateCase_updateCase_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface UpdateCase_updateCase_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface UpdateCase_updateCase_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: UpdateCase_updateCase_damage_cause_departments_location;
}

export interface UpdateCase_updateCase_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: UpdateCase_updateCase_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: UpdateCase_updateCase_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (UpdateCase_updateCase_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface UpdateCase_updateCase_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCase_updateCase_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: UpdateCase_updateCase_damage_contact_address;
}

export interface UpdateCase_updateCase_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface UpdateCase_updateCase_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: UpdateCase_updateCase_damage_businessArea;
  /**
   * Damage category
   */
  category: UpdateCase_updateCase_damage_category;
  /**
   * Cause behind damage
   */
  cause: UpdateCase_updateCase_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: UpdateCase_updateCase_damage_contact;
  /**
   * contacts for damage
   */
  contacts: UpdateCase_updateCase_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface UpdateCase_updateCase_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCase_updateCase_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: UpdateCase_updateCase_policyHolder_address;
}

export interface UpdateCase_updateCase_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface UpdateCase_updateCase_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface UpdateCase_updateCase_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
  /**
   * The question
   */
  title: string;
}

export interface UpdateCase_updateCase_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: UpdateCase_updateCase_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: UpdateCase_updateCase_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: UpdateCase_updateCase_visitation_answers[] | null;
  /**
   * If case needs a moisture report
   */
  isMoistureReportRequired: boolean | null;
  /**
   * If case needs an SP phase one report
   */
  isSpPhaseOneReportCreated: boolean | null;
}

export interface UpdateCase_updateCase_requisitioner {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Requisitioner relation
   */
  relation: string;
}

export interface UpdateCase_updateCase_adviser {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface UpdateCase_updateCase_referrer {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface UpdateCase_updateCase_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCase_updateCase_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCase_updateCase_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCase_updateCase_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: UpdateCase_updateCase_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface UpdateCase_updateCase_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCase_updateCase_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: UpdateCase_updateCase_ssgDepartment_location_address;
}

export interface UpdateCase_updateCase_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: UpdateCase_updateCase_ssgDepartment_location;
}

export interface UpdateCase_updateCase_caseAgreements_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCase_updateCase_caseAgreements {
  /**
   * Agreement content
   */
  content: string;
  /**
   * Agreement category
   */
  category: CaseAgreementCategory;
  /**
   * User who created agreement
   */
  user: UpdateCase_updateCase_caseAgreements_user;
  /**
   * Timestamp of when agreement was created
   */
  timestamp: GQL_DateTime;
  /**
   * Whether case agreement should be sent to ScalePoint
   */
  shouldSaveInScalePoint: boolean;
}

export interface UpdateCase_updateCase_caseEmails {
  /**
   * Email content
   */
  content: string;
  /**
   * List of email attachments
   */
  attachments: string[];
  /**
   * List of email receivers
   */
  receivers: string[];
  /**
   * Timestamp of when email was created
   */
  timestamp: GQL_DateTime;
}

export interface UpdateCase_updateCase_userFavorite {
  /**
   * Unique user id
   */
  user: string;
  /**
   * Color code set by user
   */
  color: string | null;
  /**
   * Favorite active status
   */
  active: boolean;
}

export interface UpdateCase_updateCase_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCase_updateCase_jobs_assignedTo {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface UpdateCase_updateCase_jobs_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface UpdateCase_updateCase_jobs_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
}

export interface UpdateCase_updateCase_jobs_movable_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface UpdateCase_updateCase_jobs_movable {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: UpdateCase_updateCase_jobs_movable_placement;
}

export interface UpdateCase_updateCase_jobs {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Type of case task
   */
  type: JobType;
  /**
   * Description of the case task
   */
  description: string;
  /**
   * Deadline of the case task
   */
  deadline: GQL_DateTime;
  /**
   * The user responsible for the task
   */
  assignedTo: UpdateCase_updateCase_jobs_assignedTo | null;
  /**
   * The location responsible to the task
   */
  location: UpdateCase_updateCase_jobs_location | null;
  /**
   * The case the task belongs to
   */
  case: UpdateCase_updateCase_jobs_case | null;
  /**
   * The movable responsible to the task
   */
  movable: UpdateCase_updateCase_jobs_movable | null;
  /**
   * Whether the task is completed
   */
  completed: boolean;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_user;
  /**
   * Value before
   */
  before: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_before;
  /**
   * Value after
   */
  after: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes_after;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface UpdateCase_updateCase_drivingSlipSeries_drivingSlips {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_changes[];
  /**
   * Location of driving slip
   */
  location: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_location;
  /**
   * Department of driving slip
   */
  department: UpdateCase_updateCase_drivingSlipSeries_drivingSlips_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
}

export interface UpdateCase_updateCase_drivingSlipSeries {
  /**
   * Unique id of driving slip series
   */
  id: string;
  /**
   * Driving slip series completed status
   */
  completed: boolean;
  /**
   * Driving slips on series
   */
  drivingSlips: UpdateCase_updateCase_drivingSlipSeries_drivingSlips[];
}

export interface UpdateCase_updateCase_alternativeContact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Comment on person
   */
  comment: string | null;
}

export interface UpdateCase_updateCase_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface UpdateCase_updateCase_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: UpdateCase_updateCase_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface UpdateCase_updateCase_requisitions_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
}

export interface UpdateCase_updateCase_requisitions_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
  /**
   * Id of damage category
   */
  id: string;
}

export interface UpdateCase_updateCase_requisitions_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of damage cause
   */
  id: string;
}

export interface UpdateCase_updateCase_requisitions_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCase_updateCase_requisitions_case_damage_contact {
  /**
   * Contact address information
   */
  address: UpdateCase_updateCase_requisitions_case_damage_contact_address;
}

export interface UpdateCase_updateCase_requisitions_case_damage {
  /**
   * Damage category
   */
  category: UpdateCase_updateCase_requisitions_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: UpdateCase_updateCase_requisitions_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: UpdateCase_updateCase_requisitions_case_damage_contact;
}

export interface UpdateCase_updateCase_requisitions_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: UpdateCase_updateCase_requisitions_case_debitor;
  /**
   * Case damage details
   */
  damage: UpdateCase_updateCase_requisitions_case_damage;
}

export interface UpdateCase_updateCase_requisitions_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
}

export interface UpdateCase_updateCase_requisitions_drivingSlip_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
}

export interface UpdateCase_updateCase_requisitions_drivingSlip_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
}

export interface UpdateCase_updateCase_requisitions_drivingSlip_case_damage {
  /**
   * Damage category
   */
  category: UpdateCase_updateCase_requisitions_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: UpdateCase_updateCase_requisitions_drivingSlip_case_damage_cause;
}

export interface UpdateCase_updateCase_requisitions_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: UpdateCase_updateCase_requisitions_drivingSlip_case_damage;
}

export interface UpdateCase_updateCase_requisitions_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Case which driving slip is related to
   */
  case: UpdateCase_updateCase_requisitions_drivingSlip_case;
}

export interface UpdateCase_updateCase_requisitions_vendor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCase_updateCase_requisitions_vendor {
  /**
   * ERP reference no for vendor
   */
  erpReferenceNo: string;
  /**
   * The name of the vendor
   */
  company: string | null;
  /**
   * The address of the vendor
   */
  address: UpdateCase_updateCase_requisitions_vendor_address | null;
  /**
   * The email of the vendor
   */
  email: string | null;
  /**
   * The phone number of the vendor
   */
  phone: string | null;
  /**
   * The posting group of the vendor
   */
  vendorPostingGroup: string;
  /**
   * Attached SSG department of the vendor
   */
  ssgDepartment: string;
  /**
   * Attached SSG location of the vendor
   */
  ssgLocation: string;
  /**
   * Vendor type
   */
  type: string;
}

export interface UpdateCase_updateCase_requisitions_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface UpdateCase_updateCase_requisitions {
  /**
   * id of the requisition
   */
  id: string;
  /**
   * case owning the requisition
   */
  case: UpdateCase_updateCase_requisitions_case | null;
  /**
   * location owning the requisition
   */
  department: UpdateCase_updateCase_requisitions_department | null;
  /**
   * driving slip owning the requisition
   */
  drivingSlip: UpdateCase_updateCase_requisitions_drivingSlip | null;
  /**
   * description of the requisition
   */
  description: string;
  /**
   * orderNumber of the requisition
   */
  orderNumber: string;
  /**
   * the type of the requisition
   */
  type: RequisitionType;
  /**
   * Requisition status
   */
  status: boolean;
  /**
   * the vendor of the requisition
   */
  vendor: UpdateCase_updateCase_requisitions_vendor | null;
  /**
   * Timestamp of when the requisition was created
   */
  createdAt: GQL_DateTime;
  /**
   * User who created the requisition
   */
  createdBy: UpdateCase_updateCase_requisitions_createdBy;
  /**
   * email of the vendor
   */
  vendorEmail: string | null;
  /**
   * phone number of the vendor
   */
  vendorPhoneNumber: string;
  /**
   * name of the vendor
   */
  vendorName: string;
}

export interface UpdateCase_updateCase {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * ReportSent
   */
  reportSent: boolean;
  /**
   * Distance from ssg location to damage address
   */
  distance: string | null;
  /**
   * Whether notifications regarding machines on the case should be sent
   */
  machineNotification: boolean | null;
  /**
   * Whether time registration is allowed on the case
   */
  timeRegistration: boolean | null;
  /**
   * Case debitor details
   */
  debitor: UpdateCase_updateCase_debitor;
  /**
   * Case damage details
   */
  damage: UpdateCase_updateCase_damage;
  /**
   * Case policy holder details
   */
  policyHolder: UpdateCase_updateCase_policyHolder;
  /**
   * Case visitation details
   */
  visitation: UpdateCase_updateCase_visitation;
  /**
   * Case requisitioner details
   */
  requisitioner: UpdateCase_updateCase_requisitioner;
  /**
   * Case advisor details
   */
  adviser: UpdateCase_updateCase_adviser | null;
  /**
   * Case referer details
   */
  referrer: UpdateCase_updateCase_referrer | null;
  /**
   * Project manager attached to case
   */
  projectManager: UpdateCase_updateCase_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: UpdateCase_updateCase_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: UpdateCase_updateCase_ssgLocation;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: UpdateCase_updateCase_ssgDepartment;
  /**
   * Case ready for billing
   */
  billingReady: boolean;
  /**
   * Case work complete
   */
  workComplete: boolean;
  /**
   * List of case agreements
   */
  caseAgreements: UpdateCase_updateCase_caseAgreements[];
  /**
   * List of case emails
   */
  caseEmails: UpdateCase_updateCase_caseEmails[];
  /**
   * List of user favorite entries
   */
  userFavorite: UpdateCase_updateCase_userFavorite | null;
  /**
   * User who created the case
   */
  createdBy: UpdateCase_updateCase_createdBy | null;
  /**
   * Timestamp of when the case was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the case was created
   */
  createdAt: GQL_DateTime;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * The tasks associated with the task
   */
  jobs: UpdateCase_updateCase_jobs[] | null;
  /**
   * The driving slips series associated with the case
   */
  drivingSlipSeries: UpdateCase_updateCase_drivingSlipSeries[];
  /**
   * Important info on the case
   */
  importantInfo: string | null;
  /**
   * Alternative contact
   */
  alternativeContact: UpdateCase_updateCase_alternativeContact | null;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: UpdateCase_updateCase_riskEvaluationAnswers[] | null;
  /**
   * Optional comment for risk evaluation
   */
  riskEvaluationComment: string | null;
  /**
   * Requisition of the case
   */
  requisitions: UpdateCase_updateCase_requisitions[];
  scalePointStatus: ScalePointStatus;
  scalePointErrorMessage: string | null;
  arrived: boolean;
  arrivedDate: GQL_DateTime | null;
}

export interface UpdateCase {
  updateCase: UpdateCase_updateCase;
}

export interface UpdateCaseVariables {
  id: string;
  status?: CaseStatus | null;
  damage?: DamageInput | null;
  policyHolder?: ContactInput | null;
  debitor?: DebitorInput | null;
  requisitioner?: RequisitionerInput | null;
  adviser?: CompanyContactInput | null;
  referrer?: CompanyContactInput | null;
  visitation?: VisitationInput | null;
  projectManager?: string | null;
  caseManager?: string | null;
  ssgLocation?: string | null;
  ssgDepartment?: string | null;
  billingReady?: boolean | null;
  workComplete?: boolean | null;
  importantInfo?: string | null;
  skafor?: boolean | null;
  alternativeContact?: PersonInput | null;
  track?: number | null;
  riskEvaluationAnswers?: RiskEvaluationAnswerInput[] | null;
  machineNotification?: boolean | null;
  timeRegistration?: boolean | null;
  fixedPriceOnCase?: GQL_Decimal | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCaseRiskEvaluationAnswers
// ====================================================

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_debitor_shippingAddress_address;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_damage_cause_departments_location;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_damage_contact_address;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_damage_businessArea;
  /**
   * Damage category
   */
  category: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_damage_category;
  /**
   * Cause behind damage
   */
  cause: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_damage_contact;
  /**
   * contacts for damage
   */
  contacts: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_policyHolder_address;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
  /**
   * The question
   */
  title: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_visitation_answers[] | null;
  /**
   * If case needs a moisture report
   */
  isMoistureReportRequired: boolean | null;
  /**
   * If case needs an SP phase one report
   */
  isSpPhaseOneReportCreated: boolean | null;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitioner {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Requisitioner relation
   */
  relation: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_adviser {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_referrer {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_ssgDepartment_location_address;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_ssgDepartment_location;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_caseAgreements_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_caseAgreements {
  /**
   * Agreement content
   */
  content: string;
  /**
   * Agreement category
   */
  category: CaseAgreementCategory;
  /**
   * User who created agreement
   */
  user: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_caseAgreements_user;
  /**
   * Timestamp of when agreement was created
   */
  timestamp: GQL_DateTime;
  /**
   * Whether case agreement should be sent to ScalePoint
   */
  shouldSaveInScalePoint: boolean;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_caseEmails {
  /**
   * Email content
   */
  content: string;
  /**
   * List of email attachments
   */
  attachments: string[];
  /**
   * List of email receivers
   */
  receivers: string[];
  /**
   * Timestamp of when email was created
   */
  timestamp: GQL_DateTime;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_userFavorite {
  /**
   * Unique user id
   */
  user: string;
  /**
   * Color code set by user
   */
  color: string | null;
  /**
   * Favorite active status
   */
  active: boolean;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_jobs_assignedTo {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_jobs_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_jobs_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_jobs_movable_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_jobs_movable {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_jobs_movable_placement;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_jobs {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Type of case task
   */
  type: JobType;
  /**
   * Description of the case task
   */
  description: string;
  /**
   * Deadline of the case task
   */
  deadline: GQL_DateTime;
  /**
   * The user responsible for the task
   */
  assignedTo: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_jobs_assignedTo | null;
  /**
   * The location responsible to the task
   */
  location: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_jobs_location | null;
  /**
   * The case the task belongs to
   */
  case: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_jobs_case | null;
  /**
   * The movable responsible to the task
   */
  movable: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_jobs_movable | null;
  /**
   * Whether the task is completed
   */
  completed: boolean;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_changes_user;
  /**
   * Value before
   */
  before: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_changes_before;
  /**
   * Value after
   */
  after: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_changes_after;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_changes[];
  /**
   * Location of driving slip
   */
  location: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_location;
  /**
   * Department of driving slip
   */
  department: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries {
  /**
   * Unique id of driving slip series
   */
  id: string;
  /**
   * Driving slip series completed status
   */
  completed: boolean;
  /**
   * Driving slips on series
   */
  drivingSlips: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries_drivingSlips[];
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_alternativeContact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Comment on person
   */
  comment: string | null;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
  /**
   * Id of damage category
   */
  id: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of damage cause
   */
  id: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_case_damage_contact {
  /**
   * Contact address information
   */
  address: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_case_damage_contact_address;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_case_damage {
  /**
   * Damage category
   */
  category: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_case_damage_contact;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_case_debitor;
  /**
   * Case damage details
   */
  damage: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_case_damage;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_drivingSlip_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_drivingSlip_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_drivingSlip_case_damage {
  /**
   * Damage category
   */
  category: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_drivingSlip_case_damage_cause;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_drivingSlip_case_damage;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Case which driving slip is related to
   */
  case: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_drivingSlip_case;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_vendor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_vendor {
  /**
   * ERP reference no for vendor
   */
  erpReferenceNo: string;
  /**
   * The name of the vendor
   */
  company: string | null;
  /**
   * The address of the vendor
   */
  address: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_vendor_address | null;
  /**
   * The email of the vendor
   */
  email: string | null;
  /**
   * The phone number of the vendor
   */
  phone: string | null;
  /**
   * The posting group of the vendor
   */
  vendorPostingGroup: string;
  /**
   * Attached SSG department of the vendor
   */
  ssgDepartment: string;
  /**
   * Attached SSG location of the vendor
   */
  ssgLocation: string;
  /**
   * Vendor type
   */
  type: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions {
  /**
   * id of the requisition
   */
  id: string;
  /**
   * case owning the requisition
   */
  case: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_case | null;
  /**
   * location owning the requisition
   */
  department: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_department | null;
  /**
   * driving slip owning the requisition
   */
  drivingSlip: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_drivingSlip | null;
  /**
   * description of the requisition
   */
  description: string;
  /**
   * orderNumber of the requisition
   */
  orderNumber: string;
  /**
   * the type of the requisition
   */
  type: RequisitionType;
  /**
   * Requisition status
   */
  status: boolean;
  /**
   * the vendor of the requisition
   */
  vendor: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_vendor | null;
  /**
   * Timestamp of when the requisition was created
   */
  createdAt: GQL_DateTime;
  /**
   * User who created the requisition
   */
  createdBy: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions_createdBy;
  /**
   * email of the vendor
   */
  vendorEmail: string | null;
  /**
   * phone number of the vendor
   */
  vendorPhoneNumber: string;
  /**
   * name of the vendor
   */
  vendorName: string;
}

export interface UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * ReportSent
   */
  reportSent: boolean;
  /**
   * Distance from ssg location to damage address
   */
  distance: string | null;
  /**
   * Whether notifications regarding machines on the case should be sent
   */
  machineNotification: boolean | null;
  /**
   * Whether time registration is allowed on the case
   */
  timeRegistration: boolean | null;
  /**
   * Case debitor details
   */
  debitor: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_debitor;
  /**
   * Case damage details
   */
  damage: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_damage;
  /**
   * Case policy holder details
   */
  policyHolder: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_policyHolder;
  /**
   * Case visitation details
   */
  visitation: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_visitation;
  /**
   * Case requisitioner details
   */
  requisitioner: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitioner;
  /**
   * Case advisor details
   */
  adviser: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_adviser | null;
  /**
   * Case referer details
   */
  referrer: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_referrer | null;
  /**
   * Project manager attached to case
   */
  projectManager: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_ssgLocation;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_ssgDepartment;
  /**
   * Case ready for billing
   */
  billingReady: boolean;
  /**
   * Case work complete
   */
  workComplete: boolean;
  /**
   * List of case agreements
   */
  caseAgreements: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_caseAgreements[];
  /**
   * List of case emails
   */
  caseEmails: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_caseEmails[];
  /**
   * List of user favorite entries
   */
  userFavorite: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_userFavorite | null;
  /**
   * User who created the case
   */
  createdBy: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_createdBy | null;
  /**
   * Timestamp of when the case was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the case was created
   */
  createdAt: GQL_DateTime;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * The tasks associated with the task
   */
  jobs: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_jobs[] | null;
  /**
   * The driving slips series associated with the case
   */
  drivingSlipSeries: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_drivingSlipSeries[];
  /**
   * Important info on the case
   */
  importantInfo: string | null;
  /**
   * Alternative contact
   */
  alternativeContact: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_alternativeContact | null;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_riskEvaluationAnswers[] | null;
  /**
   * Optional comment for risk evaluation
   */
  riskEvaluationComment: string | null;
  /**
   * Requisition of the case
   */
  requisitions: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers_requisitions[];
  scalePointStatus: ScalePointStatus;
  scalePointErrorMessage: string | null;
  arrived: boolean;
  arrivedDate: GQL_DateTime | null;
}

export interface UpdateCaseRiskEvaluationAnswers {
  updateCaseRiskEvaluationAnswers: UpdateCaseRiskEvaluationAnswers_updateCaseRiskEvaluationAnswers;
}

export interface UpdateCaseRiskEvaluationAnswersVariables {
  id: string;
  riskEvaluationAnswers: RiskEvaluationAnswerInput[];
  riskEvaluationComment?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePictureComment
// ====================================================

export interface UpdatePictureComment_updatePictureComment {
  /**
   * This value is not used for anything.
   */
  _: string;
}

export interface UpdatePictureComment {
  updatePictureComment: UpdatePictureComment_updatePictureComment | null;
}

export interface UpdatePictureCommentVariables {
  fileID: string;
  comment: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCatalogsWithCraftsmen
// ====================================================

export interface GetCatalogsWithCraftsmen_catalogs_craftsmen_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface GetCatalogsWithCraftsmen_catalogs_craftsmen_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface GetCatalogsWithCraftsmen_catalogs_craftsmen {
  /**
   * Id for catalog call contact
   */
  id: string;
  /**
   * Customer
   */
  customer: GetCatalogsWithCraftsmen_catalogs_craftsmen_customer;
  /**
   * Craftsman changed by
   */
  changedBy: string;
  /**
   * Craftsman type
   */
  type: string;
  /**
   * Craftsman name
   */
  contactName: string;
  /**
   * Informations for Craftsman
   */
  informations: GetCatalogsWithCraftsmen_catalogs_craftsmen_informations[];
  /**
   * Craftsman email
   */
  email: string | null;
  /**
   * Craftsman priority
   */
  priority: string;
  /**
   * Is contact global
   */
  global: boolean;
  /**
   * Global type for craftsman
   */
  globalType: CatalogGlobalType;
  /**
   * Is contact active
   */
  active: boolean;
  /**
   * Version for catalog craftsman
   */
  version: number;
  /**
   * Catalog ids
   */
  catalogIds: string[];
}

export interface GetCatalogsWithCraftsmen_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Craftsmen attached to catalog
   */
  craftsmen: GetCatalogsWithCraftsmen_catalogs_craftsmen[];
}

export interface GetCatalogsWithCraftsmen {
  catalogs: GetCatalogsWithCraftsmen_catalogs[];
}

export interface GetCatalogsWithCraftsmenVariables {
  debitors?: string[] | null;
  smsService?: boolean | null;
  verified?: boolean | null;
  debitorVerified?: boolean | null;
  address?: AddressInput | null;
  noDebitor?: boolean | null;
  thisCustomerOnly?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMobileV2Catalogs
// ====================================================

export interface GetMobileV2Catalogs_catalogs_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface GetMobileV2Catalogs_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface GetMobileV2Catalogs_catalogs_debitor {
  /**
   * Company identifier
   */
  company: string;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Sales Perseon Code
   */
  salesPersonCode: string;
  /**
   * Sales Perseon Name
   */
  salesPersonName: string;
}

export interface GetMobileV2Catalogs_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Contact number for the Catalog
   */
  contactNumber: string | null;
  /**
   * Hours contact number can be reached
   */
  contactHours: string | null;
  /**
   * Customer
   */
  customer: GetMobileV2Catalogs_catalogs_customer;
  /**
   * Catalog changed by
   */
  changedBy: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: GetMobileV2Catalogs_catalogs_addresses[];
  /**
   * Is sms service used for Catalog
   */
  smsService: boolean;
  /**
   * Catalog debitor details
   */
  debitor: GetMobileV2Catalogs_catalogs_debitor | null;
  /**
   * Is catalog verified
   */
  verified: boolean;
  /**
   * Is catalog debitor verified
   */
  debitorVerified: boolean;
  /**
   * Version for catalog
   */
  version: number;
  /**
   * Timestamp of when the catalog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the catalog was created
   */
  createdAt: GQL_DateTime;
}

export interface GetMobileV2Catalogs {
  catalogs: GetMobileV2Catalogs_catalogs[];
}

export interface GetMobileV2CatalogsVariables {
  debitors?: string[] | null;
  smsService?: boolean | null;
  verified?: boolean | null;
  debitorVerified?: boolean | null;
  address?: AddressInput | null;
  noDebitor?: boolean | null;
  thisCustomerOnly?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCollectionItems
// ====================================================

export interface GetCollectionItems_collectionItems {
  /**
   * Id of collection item
   */
  id: string;
  /**
   * Name of collection item
   */
  name: string;
  /**
   * Type of collection item
   */
  type: CollectionItemType;
}

export interface GetCollectionItems {
  collectionItems: GetCollectionItems_collectionItems[];
}

export interface GetCollectionItemsVariables {
  type?: CollectionItemType | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllDebitors
// ====================================================

export interface GetAllDebitors_allDebitors_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface GetAllDebitors_allDebitors {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Debitor address
   */
  address: GetAllDebitors_allDebitors_address | null;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
}

export interface GetAllDebitors {
  allDebitors: GetAllDebitors_allDebitors[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMobileV2Departments
// ====================================================

export interface GetMobileV2Departments_departments_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2Departments_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: GetMobileV2Departments_departments_location_address;
}

export interface GetMobileV2Departments_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: GetMobileV2Departments_departments_location;
}

export interface GetMobileV2Departments {
  departments: GetMobileV2Departments_departments[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDrivingSlipCategories
// ====================================================

export interface GetDrivingSlipCategories_drivingSlipCategories {
  /**
   * Category code
   */
  code: string;
  /**
   * Category name
   */
  name: string;
  /**
   * Category ERP tasks to be used as filter
   */
  erpReferenceTasks: string[];
}

export interface GetDrivingSlipCategories {
  drivingSlipCategories: GetDrivingSlipCategories_drivingSlipCategories[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDrivingSlipItems
// ====================================================

export interface GetDrivingSlipItems_drivingSlipItems {
  /**
   * item no
   */
  no: string;
  /**
   * description
   */
  description: string;
  /**
   * Base unit of messure
   */
  baseUOM: string;
  /**
   * ERP job task number
   */
  jobTask: string;
}

export interface GetDrivingSlipItems {
  drivingSlipItems: GetDrivingSlipItems_drivingSlipItems[];
}

export interface GetDrivingSlipItemsVariables {
  drivingSlipCategory?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMobileV2DrivingSlip
// ====================================================

export interface GetMobileV2DrivingSlip_drivingSlip_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetMobileV2DrivingSlip_drivingSlip_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface GetMobileV2DrivingSlip_drivingSlip_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: GetMobileV2DrivingSlip_drivingSlip_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface GetMobileV2DrivingSlip_drivingSlip_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: GetMobileV2DrivingSlip_drivingSlip_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface GetMobileV2DrivingSlip_drivingSlip_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: GetMobileV2DrivingSlip_drivingSlip_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface GetMobileV2DrivingSlip_drivingSlip_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetMobileV2DrivingSlip_drivingSlip_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetMobileV2DrivingSlip_drivingSlip_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetMobileV2DrivingSlip_drivingSlip_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetMobileV2DrivingSlip_drivingSlip_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetMobileV2DrivingSlip_drivingSlip_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetMobileV2DrivingSlip_drivingSlip_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetMobileV2DrivingSlip_drivingSlip_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetMobileV2DrivingSlip_drivingSlip_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetMobileV2DrivingSlip_drivingSlip_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetMobileV2DrivingSlip_drivingSlip_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetMobileV2DrivingSlip_drivingSlip_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: GetMobileV2DrivingSlip_drivingSlip_changes_user;
  /**
   * Value before
   */
  before: GetMobileV2DrivingSlip_drivingSlip_changes_before;
  /**
   * Value after
   */
  after: GetMobileV2DrivingSlip_drivingSlip_changes_after;
}

export interface GetMobileV2DrivingSlip_drivingSlip_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: GetMobileV2DrivingSlip_drivingSlip_case_debitor_shippingAddress_address;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: GetMobileV2DrivingSlip_drivingSlip_case_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: GetMobileV2DrivingSlip_drivingSlip_case_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: GetMobileV2DrivingSlip_drivingSlip_case_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetMobileV2DrivingSlip_drivingSlip_case_damage_contact_address;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetMobileV2DrivingSlip_drivingSlip_case_damage_cause_departments_location;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: GetMobileV2DrivingSlip_drivingSlip_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: GetMobileV2DrivingSlip_drivingSlip_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (GetMobileV2DrivingSlip_drivingSlip_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_damage {
  /**
   * Damage location contact information
   */
  contact: GetMobileV2DrivingSlip_drivingSlip_case_damage_contact;
  /**
   * Damage category
   */
  category: GetMobileV2DrivingSlip_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetMobileV2DrivingSlip_drivingSlip_case_damage_cause;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetMobileV2DrivingSlip_drivingSlip_case_policyHolder_address;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseManager {
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: GetMobileV2DrivingSlip_drivingSlip_case_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: GetMobileV2DrivingSlip_drivingSlip_case_ssgDepartment_location_address;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: GetMobileV2DrivingSlip_drivingSlip_case_ssgDepartment_location;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: GetMobileV2DrivingSlip_drivingSlip_case_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
  /**
   * The question
   */
  title: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: GetMobileV2DrivingSlip_drivingSlip_case_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: GetMobileV2DrivingSlip_drivingSlip_case_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: GetMobileV2DrivingSlip_drivingSlip_case_visitation_answers[] | null;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseReports_inspectionReportFormData_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseReports_inspectionReportFormData_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseReports_inspectionReportFormData_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseReports_inspectionReportFormData {
  /**
   * Famage image info
   */
  facadeImage: GetMobileV2DrivingSlip_drivingSlip_case_caseReports_inspectionReportFormData_facadeImage | null;
  /**
   * Information about what has happened until now
   */
  backgroundStory: string | null;
  /**
   * Observations about the site
   */
  observations: string | null;
  /**
   * Assessment and conclusion
   */
  assessmentAndConclusion: string | null;
  /**
   * Suggested action plan
   */
  suggestedActionPlan: string | null;
  /**
   * Any other comments from inspection
   */
  comments: string | null;
  /**
   * Floorplan image info
   */
  floorplanImage: GetMobileV2DrivingSlip_drivingSlip_case_caseReports_inspectionReportFormData_floorplanImage | null;
  /**
   * List of other relevant images
   */
  otherImages: GetMobileV2DrivingSlip_drivingSlip_case_caseReports_inspectionReportFormData_otherImages[] | null;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseReports_inspectionMovablesReportFormData_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseReports_inspectionMovablesReportFormData_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseReports_inspectionMovablesReportFormData_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseReports_inspectionMovablesReportFormData {
  /**
   * Famage image info
   */
  facadeImage: GetMobileV2DrivingSlip_drivingSlip_case_caseReports_inspectionMovablesReportFormData_facadeImage | null;
  /**
   * Information about what has happened until now
   */
  backgroundStory: string | null;
  /**
   * Observations about the site
   */
  observations: string | null;
  /**
   * Assessment and conclusion
   */
  assessmentAndConclusion: string | null;
  /**
   * Suggested action plan
   */
  suggestedActionPlan: string | null;
  /**
   * Any other comments from inspection
   */
  comments: string | null;
  /**
   * Floorplan image info
   */
  floorplanImage: GetMobileV2DrivingSlip_drivingSlip_case_caseReports_inspectionMovablesReportFormData_floorplanImage | null;
  /**
   * List of other relevant images
   */
  otherImages: GetMobileV2DrivingSlip_drivingSlip_case_caseReports_inspectionMovablesReportFormData_otherImages[] | null;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureReportFormData_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureReportFormData_damageCauseImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureReportFormData_floorReport_observations {
  /**
   * Describes where the damage is
   */
  roomOrApartment: string;
  /**
   * Construction observations
   */
  construction: string | null;
  /**
   * Ceiling observations
   */
  ceiling: string | null;
  /**
   * Walls observations
   */
  walls: string | null;
  /**
   * Floor observations
   */
  floor: string | null;
  /**
   * Observed scope of damage
   */
  scope: string;
  /**
   * Recommendations
   */
  recommendations: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureReportFormData_floorReport_moistureMeassurements {
  /**
   * Construction
   */
  construction: string | null;
  /**
   * Material
   */
  material: string | null;
  /**
   * Description
   */
  description: string | null;
  /**
   * Method
   */
  method: string | null;
  /**
   * Value
   */
  value: string;
  /**
   * Expected final value
   */
  expectedFinalValue: string | null;
  /**
   * Reference value
   */
  referenceValue: string | null;
  /**
   * Reference date
   */
  referenceDate: string | null;
  /**
   * Control value
   */
  controlValue: string | null;
  /**
   * Control date
   */
  controlDate: string | null;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureReportFormData_floorReport_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureReportFormData_floorReport_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureReportFormData_floorReport {
  /**
   * Floor Name
   */
  name: string;
  /**
   * Report observations
   */
  observations: GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureReportFormData_floorReport_observations;
  /**
   * Moisture meassurements
   */
  moistureMeassurements: GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureReportFormData_floorReport_moistureMeassurements[] | null;
  /**
   * Floorplan image info
   */
  floorplanImage: GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureReportFormData_floorReport_floorplanImage | null;
  /**
   * List of other relevant images
   */
  otherImages: GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureReportFormData_floorReport_otherImages[] | null;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureReportFormData {
  /**
   * Famage image info
   */
  facadeImage: GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureReportFormData_facadeImage | null;
  /**
   * Date of damage
   */
  inspectionDate: GQL_Date;
  /**
   * Conclusions
   */
  conclusion: string;
  /**
   * Damage cause description
   */
  damageCause: string;
  /**
   * Damage scope
   */
  scope: string;
  /**
   * Damage site contacts
   */
  contacts: string;
  /**
   * Images of damage cause
   */
  damageCauseImages: GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureReportFormData_damageCauseImages[] | null;
  /**
   * Gulvfugt info
   */
  floorReport: GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureReportFormData_floorReport[];
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureTrygReportFormData_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureTrygReportFormData_floorReport_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureTrygReportFormData_floorReport_moistureMeassurements {
  /**
   * Construction
   */
  construction: string | null;
  /**
   * Material
   */
  material: string | null;
  /**
   * Description
   */
  description: string | null;
  /**
   * Method
   */
  method: string | null;
  /**
   * Paints
   */
  paints: string;
  /**
   * Paints Date
   */
  paintsDate: string | null;
  /**
   * Expected final value
   */
  expectedFinalValue: string | null;
  /**
   * Expected final date
   */
  expectedFinalDate: string | null;
  /**
   * Reference value
   */
  referenceValue: string | null;
  /**
   * Reference date
   */
  referenceDate: string | null;
  /**
   * Control value
   */
  controlValue: string | null;
  /**
   * Control date
   */
  controlDate: string | null;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureTrygReportFormData_floorReport_airMeassurements {
  /**
   * Temperature inside (celcius)
   */
  temperatureInside: number;
  /**
   * Temperature outside (celcius)
   */
  temperatureOutside: number;
  /**
   * Relative humidity inside (percentage)
   */
  relativeHumidityInside: number;
  /**
   * Relative humidity outside (percentage)
   */
  relativeHumidityOutside: number;
  /**
   * Water amount inside (g/m3)
   */
  waterAmountInside: number;
  /**
   * Water amount outside (g/m3)
   */
  waterAmountOutside: number;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureTrygReportFormData_floorReport_damageDescriptionImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureTrygReportFormData_floorReport_damageDrawingImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureTrygReportFormData_floorReport_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureTrygReportFormData_floorReport {
  /**
   * Name of floor
   */
  name: string | null;
  /**
   * Damage cause description
   */
  damageCause: string | null;
  /**
   * Multiple damage causes description
   */
  multipleDamageCauses: string | null;
  /**
   * Description of relation between damage cause and moisture
   */
  moistureDamageCause: string | null;
  /**
   * Damage description
   */
  damageDescription: string | null;
  /**
   * Floorplan image info
   */
  floorplanImage: GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureTrygReportFormData_floorReport_floorplanImage | null;
  /**
   * Moisture meassurements
   */
  moistureMeassurements: GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureTrygReportFormData_floorReport_moistureMeassurements[] | null;
  /**
   * Air meassurements
   */
  airMeassurements: GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureTrygReportFormData_floorReport_airMeassurements;
  /**
   * Damage description image info
   */
  damageDescriptionImage: GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureTrygReportFormData_floorReport_damageDescriptionImage[] | null;
  /**
   * Damage drawing image info
   */
  damageDrawingImage: GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureTrygReportFormData_floorReport_damageDrawingImage | null;
  /**
   * Recommendations (if any)
   */
  recommendations: string | null;
  /**
   * Urgent actions (if any)
   */
  urgentActions: string | null;
  /**
   * List of other relevant images
   */
  otherImages: GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureTrygReportFormData_floorReport_otherImages[] | null;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureTrygReportFormData {
  /**
   * Famage image info
   */
  facadeImage: GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureTrygReportFormData_facadeImage | null;
  /**
   * Date of damage
   */
  inspectionDate: GQL_Date | null;
  /**
   * Gulvfugt info
   */
  floorReport: GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureTrygReportFormData_floorReport[] | null;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseReports_indoorClimateReportFormData_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseReports_indoorClimateReportFormData_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseReports_indoorClimateReportFormData_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseReports_indoorClimateReportFormData_indoorClimateMeassurements {
  /**
   * Room
   */
  room: string | null;
  /**
   * Construction
   */
  construction: string | null;
  /**
   * Material
   */
  material: string | null;
  /**
   * Description
   */
  description: string | null;
  /**
   * Instrument
   */
  instrument: string | null;
  /**
   * Value
   */
  value: string | null;
  /**
   * Reference value
   */
  referenceValue: string | null;
  /**
   * Calculated value
   */
  calculatedValue: string | null;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseReports_indoorClimateReportFormData {
  /**
   * Famage image info
   */
  facadeImage: GetMobileV2DrivingSlip_drivingSlip_case_caseReports_indoorClimateReportFormData_facadeImage | null;
  /**
   * Date of damage
   */
  inspectionDate: GQL_Date | null;
  /**
   * Purpose of the report
   */
  purpose: string | null;
  /**
   * Year of the building being built
   */
  buildYear: string | null;
  /**
   * Square meters of the area
   */
  squareMeters: string | null;
  /**
   * Daily users of the building
   */
  dailyUsers: string | null;
  /**
   * Other participants on the scene
   */
  participants: string | null;
  /**
   * Information about what has happened until now
   */
  backgroundStory: string | null;
  /**
   * Observations
   */
  observations: string | null;
  /**
   * Refer to appendix for lab answers
   */
  showLabAnswers: boolean;
  /**
   * Refer to appendix for the data log
   */
  showDataLog: boolean | null;
  /**
   * Assessment and conclusion
   */
  assessmentAndConclusion: string | null;
  /**
   * Suggested action plan
   */
  suggestedActionPlan: string | null;
  /**
   * Floorplan image info
   */
  floorplanImage: GetMobileV2DrivingSlip_drivingSlip_case_caseReports_indoorClimateReportFormData_floorplanImage | null;
  /**
   * List of other relevant images
   */
  otherImages: GetMobileV2DrivingSlip_drivingSlip_case_caseReports_indoorClimateReportFormData_otherImages[] | null;
  /**
   * Meassurements of indoor climate
   */
  indoorClimateMeassurements: GetMobileV2DrivingSlip_drivingSlip_case_caseReports_indoorClimateReportFormData_indoorClimateMeassurements[] | null;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseReports {
  /**
   * Inspection report form data populated by the user
   */
  inspectionReportFormData: GetMobileV2DrivingSlip_drivingSlip_case_caseReports_inspectionReportFormData | null;
  /**
   * Inspection report form data populated by the user
   */
  inspectionMovablesReportFormData: GetMobileV2DrivingSlip_drivingSlip_case_caseReports_inspectionMovablesReportFormData | null;
  /**
   * Moisture report form data populated by the user
   */
  moistureReportFormData: GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureReportFormData | null;
  /**
   * Moisture TRYG report form data populated by the user
   */
  moistureTrygReportFormData: GetMobileV2DrivingSlip_drivingSlip_case_caseReports_moistureTrygReportFormData | null;
  /**
   * Indooor climate report form data populated by the user
   */
  indoorClimateReportFormData: GetMobileV2DrivingSlip_drivingSlip_case_caseReports_indoorClimateReportFormData | null;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_requisitioner {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Requisitioner relation
   */
  relation: string;
}

export interface GetMobileV2DrivingSlip_drivingSlip_case_caseDraftItemsUsage {
  /**
   * ERP system reference id
   */
  erpId: string;
  /**
   * ERP system reference key
   */
  erpKey: string;
  /**
   * Usage line title
   */
  title: string;
  /**
   * Usage line quantity
   */
  quantity: GQL_Decimal;
  /**
   * Date of posting
   */
  date: GQL_Date;
  /**
   * Posted By Username
   */
  postedBy: string;
  /**
   * Description
   */
  description: string;
  __typename: "CaseDraftUsageLine";
}

export interface GetMobileV2DrivingSlip_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * Case debitor details
   */
  debitor: GetMobileV2DrivingSlip_drivingSlip_case_debitor;
  /**
   * Case damage details
   */
  damage: GetMobileV2DrivingSlip_drivingSlip_case_damage;
  /**
   * Case policy holder details
   */
  policyHolder: GetMobileV2DrivingSlip_drivingSlip_case_policyHolder;
  /**
   * Project manager attached to case
   */
  projectManager: GetMobileV2DrivingSlip_drivingSlip_case_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: GetMobileV2DrivingSlip_drivingSlip_case_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: GetMobileV2DrivingSlip_drivingSlip_case_ssgLocation;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: GetMobileV2DrivingSlip_drivingSlip_case_ssgDepartment;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: GetMobileV2DrivingSlip_drivingSlip_case_riskEvaluationAnswers[] | null;
  /**
   * Optional comment for risk evaluation
   */
  riskEvaluationComment: string | null;
  /**
   * Case visitation details
   */
  visitation: GetMobileV2DrivingSlip_drivingSlip_case_visitation;
  /**
   * Whether time registration is allowed on the case
   */
  timeRegistration: boolean | null;
  caseReports: GetMobileV2DrivingSlip_drivingSlip_case_caseReports | null;
  /**
   * Case requisitioner details
   */
  requisitioner: GetMobileV2DrivingSlip_drivingSlip_case_requisitioner;
  /**
   * List of draft case items usage
   */
  caseDraftItemsUsage: GetMobileV2DrivingSlip_drivingSlip_case_caseDraftItemsUsage[];
  /**
   * Case change version number
   */
  version: number;
}

export interface GetMobileV2DrivingSlip_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: GetMobileV2DrivingSlip_drivingSlip_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: GetMobileV2DrivingSlip_drivingSlip_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetMobileV2DrivingSlip_drivingSlip_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: GetMobileV2DrivingSlip_drivingSlip_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: GetMobileV2DrivingSlip_drivingSlip_changes[];
  /**
   * Location of driving slip
   */
  location: GetMobileV2DrivingSlip_drivingSlip_location;
  /**
   * Department of driving slip
   */
  department: GetMobileV2DrivingSlip_drivingSlip_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
  /**
   * Case which driving slip is related to
   */
  case: GetMobileV2DrivingSlip_drivingSlip_case;
}

export interface GetMobileV2DrivingSlip {
  drivingSlip: GetMobileV2DrivingSlip_drivingSlip;
}

export interface GetMobileV2DrivingSlipVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMobileV2DrivingSlips
// ====================================================

export interface GetMobileV2DrivingSlips_drivingSlips_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetMobileV2DrivingSlips_drivingSlips_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface GetMobileV2DrivingSlips_drivingSlips_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: GetMobileV2DrivingSlips_drivingSlips_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface GetMobileV2DrivingSlips_drivingSlips_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: GetMobileV2DrivingSlips_drivingSlips_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface GetMobileV2DrivingSlips_drivingSlips_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: GetMobileV2DrivingSlips_drivingSlips_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface GetMobileV2DrivingSlips_drivingSlips_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetMobileV2DrivingSlips_drivingSlips_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetMobileV2DrivingSlips_drivingSlips_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetMobileV2DrivingSlips_drivingSlips_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetMobileV2DrivingSlips_drivingSlips_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetMobileV2DrivingSlips_drivingSlips_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetMobileV2DrivingSlips_drivingSlips_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetMobileV2DrivingSlips_drivingSlips_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetMobileV2DrivingSlips_drivingSlips_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetMobileV2DrivingSlips_drivingSlips_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetMobileV2DrivingSlips_drivingSlips_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetMobileV2DrivingSlips_drivingSlips_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: GetMobileV2DrivingSlips_drivingSlips_changes_user;
  /**
   * Value before
   */
  before: GetMobileV2DrivingSlips_drivingSlips_changes_before;
  /**
   * Value after
   */
  after: GetMobileV2DrivingSlips_drivingSlips_changes_after;
}

export interface GetMobileV2DrivingSlips_drivingSlips_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: GetMobileV2DrivingSlips_drivingSlips_case_debitor_shippingAddress_address;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: GetMobileV2DrivingSlips_drivingSlips_case_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: GetMobileV2DrivingSlips_drivingSlips_case_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: GetMobileV2DrivingSlips_drivingSlips_case_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetMobileV2DrivingSlips_drivingSlips_case_damage_contact_address;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetMobileV2DrivingSlips_drivingSlips_case_damage_cause_departments_location;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: GetMobileV2DrivingSlips_drivingSlips_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: GetMobileV2DrivingSlips_drivingSlips_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (GetMobileV2DrivingSlips_drivingSlips_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_damage {
  /**
   * Damage location contact information
   */
  contact: GetMobileV2DrivingSlips_drivingSlips_case_damage_contact;
  /**
   * Damage category
   */
  category: GetMobileV2DrivingSlips_drivingSlips_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetMobileV2DrivingSlips_drivingSlips_case_damage_cause;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetMobileV2DrivingSlips_drivingSlips_case_policyHolder_address;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseManager {
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: GetMobileV2DrivingSlips_drivingSlips_case_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: GetMobileV2DrivingSlips_drivingSlips_case_ssgDepartment_location_address;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: GetMobileV2DrivingSlips_drivingSlips_case_ssgDepartment_location;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: GetMobileV2DrivingSlips_drivingSlips_case_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
  /**
   * The question
   */
  title: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: GetMobileV2DrivingSlips_drivingSlips_case_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: GetMobileV2DrivingSlips_drivingSlips_case_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: GetMobileV2DrivingSlips_drivingSlips_case_visitation_answers[] | null;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseReports_inspectionReportFormData_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseReports_inspectionReportFormData_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseReports_inspectionReportFormData_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseReports_inspectionReportFormData {
  /**
   * Famage image info
   */
  facadeImage: GetMobileV2DrivingSlips_drivingSlips_case_caseReports_inspectionReportFormData_facadeImage | null;
  /**
   * Information about what has happened until now
   */
  backgroundStory: string | null;
  /**
   * Observations about the site
   */
  observations: string | null;
  /**
   * Assessment and conclusion
   */
  assessmentAndConclusion: string | null;
  /**
   * Suggested action plan
   */
  suggestedActionPlan: string | null;
  /**
   * Any other comments from inspection
   */
  comments: string | null;
  /**
   * Floorplan image info
   */
  floorplanImage: GetMobileV2DrivingSlips_drivingSlips_case_caseReports_inspectionReportFormData_floorplanImage | null;
  /**
   * List of other relevant images
   */
  otherImages: GetMobileV2DrivingSlips_drivingSlips_case_caseReports_inspectionReportFormData_otherImages[] | null;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseReports_inspectionMovablesReportFormData_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseReports_inspectionMovablesReportFormData_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseReports_inspectionMovablesReportFormData_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseReports_inspectionMovablesReportFormData {
  /**
   * Famage image info
   */
  facadeImage: GetMobileV2DrivingSlips_drivingSlips_case_caseReports_inspectionMovablesReportFormData_facadeImage | null;
  /**
   * Information about what has happened until now
   */
  backgroundStory: string | null;
  /**
   * Observations about the site
   */
  observations: string | null;
  /**
   * Assessment and conclusion
   */
  assessmentAndConclusion: string | null;
  /**
   * Suggested action plan
   */
  suggestedActionPlan: string | null;
  /**
   * Any other comments from inspection
   */
  comments: string | null;
  /**
   * Floorplan image info
   */
  floorplanImage: GetMobileV2DrivingSlips_drivingSlips_case_caseReports_inspectionMovablesReportFormData_floorplanImage | null;
  /**
   * List of other relevant images
   */
  otherImages: GetMobileV2DrivingSlips_drivingSlips_case_caseReports_inspectionMovablesReportFormData_otherImages[] | null;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureReportFormData_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureReportFormData_damageCauseImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureReportFormData_floorReport_observations {
  /**
   * Describes where the damage is
   */
  roomOrApartment: string;
  /**
   * Construction observations
   */
  construction: string | null;
  /**
   * Ceiling observations
   */
  ceiling: string | null;
  /**
   * Walls observations
   */
  walls: string | null;
  /**
   * Floor observations
   */
  floor: string | null;
  /**
   * Observed scope of damage
   */
  scope: string;
  /**
   * Recommendations
   */
  recommendations: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureReportFormData_floorReport_moistureMeassurements {
  /**
   * Construction
   */
  construction: string | null;
  /**
   * Material
   */
  material: string | null;
  /**
   * Description
   */
  description: string | null;
  /**
   * Method
   */
  method: string | null;
  /**
   * Value
   */
  value: string;
  /**
   * Expected final value
   */
  expectedFinalValue: string | null;
  /**
   * Reference value
   */
  referenceValue: string | null;
  /**
   * Reference date
   */
  referenceDate: string | null;
  /**
   * Control value
   */
  controlValue: string | null;
  /**
   * Control date
   */
  controlDate: string | null;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureReportFormData_floorReport_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureReportFormData_floorReport_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureReportFormData_floorReport {
  /**
   * Floor Name
   */
  name: string;
  /**
   * Report observations
   */
  observations: GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureReportFormData_floorReport_observations;
  /**
   * Moisture meassurements
   */
  moistureMeassurements: GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureReportFormData_floorReport_moistureMeassurements[] | null;
  /**
   * Floorplan image info
   */
  floorplanImage: GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureReportFormData_floorReport_floorplanImage | null;
  /**
   * List of other relevant images
   */
  otherImages: GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureReportFormData_floorReport_otherImages[] | null;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureReportFormData {
  /**
   * Famage image info
   */
  facadeImage: GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureReportFormData_facadeImage | null;
  /**
   * Date of damage
   */
  inspectionDate: GQL_Date;
  /**
   * Conclusions
   */
  conclusion: string;
  /**
   * Damage cause description
   */
  damageCause: string;
  /**
   * Damage scope
   */
  scope: string;
  /**
   * Damage site contacts
   */
  contacts: string;
  /**
   * Images of damage cause
   */
  damageCauseImages: GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureReportFormData_damageCauseImages[] | null;
  /**
   * Gulvfugt info
   */
  floorReport: GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureReportFormData_floorReport[];
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureTrygReportFormData_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureTrygReportFormData_floorReport_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureTrygReportFormData_floorReport_moistureMeassurements {
  /**
   * Construction
   */
  construction: string | null;
  /**
   * Material
   */
  material: string | null;
  /**
   * Description
   */
  description: string | null;
  /**
   * Method
   */
  method: string | null;
  /**
   * Paints
   */
  paints: string;
  /**
   * Paints Date
   */
  paintsDate: string | null;
  /**
   * Expected final value
   */
  expectedFinalValue: string | null;
  /**
   * Expected final date
   */
  expectedFinalDate: string | null;
  /**
   * Reference value
   */
  referenceValue: string | null;
  /**
   * Reference date
   */
  referenceDate: string | null;
  /**
   * Control value
   */
  controlValue: string | null;
  /**
   * Control date
   */
  controlDate: string | null;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureTrygReportFormData_floorReport_airMeassurements {
  /**
   * Temperature inside (celcius)
   */
  temperatureInside: number;
  /**
   * Temperature outside (celcius)
   */
  temperatureOutside: number;
  /**
   * Relative humidity inside (percentage)
   */
  relativeHumidityInside: number;
  /**
   * Relative humidity outside (percentage)
   */
  relativeHumidityOutside: number;
  /**
   * Water amount inside (g/m3)
   */
  waterAmountInside: number;
  /**
   * Water amount outside (g/m3)
   */
  waterAmountOutside: number;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureTrygReportFormData_floorReport_damageDescriptionImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureTrygReportFormData_floorReport_damageDrawingImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureTrygReportFormData_floorReport_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureTrygReportFormData_floorReport {
  /**
   * Name of floor
   */
  name: string | null;
  /**
   * Damage cause description
   */
  damageCause: string | null;
  /**
   * Multiple damage causes description
   */
  multipleDamageCauses: string | null;
  /**
   * Description of relation between damage cause and moisture
   */
  moistureDamageCause: string | null;
  /**
   * Damage description
   */
  damageDescription: string | null;
  /**
   * Floorplan image info
   */
  floorplanImage: GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureTrygReportFormData_floorReport_floorplanImage | null;
  /**
   * Moisture meassurements
   */
  moistureMeassurements: GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureTrygReportFormData_floorReport_moistureMeassurements[] | null;
  /**
   * Air meassurements
   */
  airMeassurements: GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureTrygReportFormData_floorReport_airMeassurements;
  /**
   * Damage description image info
   */
  damageDescriptionImage: GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureTrygReportFormData_floorReport_damageDescriptionImage[] | null;
  /**
   * Damage drawing image info
   */
  damageDrawingImage: GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureTrygReportFormData_floorReport_damageDrawingImage | null;
  /**
   * Recommendations (if any)
   */
  recommendations: string | null;
  /**
   * Urgent actions (if any)
   */
  urgentActions: string | null;
  /**
   * List of other relevant images
   */
  otherImages: GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureTrygReportFormData_floorReport_otherImages[] | null;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureTrygReportFormData {
  /**
   * Famage image info
   */
  facadeImage: GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureTrygReportFormData_facadeImage | null;
  /**
   * Date of damage
   */
  inspectionDate: GQL_Date | null;
  /**
   * Gulvfugt info
   */
  floorReport: GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureTrygReportFormData_floorReport[] | null;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseReports_indoorClimateReportFormData_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseReports_indoorClimateReportFormData_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseReports_indoorClimateReportFormData_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseReports_indoorClimateReportFormData_indoorClimateMeassurements {
  /**
   * Room
   */
  room: string | null;
  /**
   * Construction
   */
  construction: string | null;
  /**
   * Material
   */
  material: string | null;
  /**
   * Description
   */
  description: string | null;
  /**
   * Instrument
   */
  instrument: string | null;
  /**
   * Value
   */
  value: string | null;
  /**
   * Reference value
   */
  referenceValue: string | null;
  /**
   * Calculated value
   */
  calculatedValue: string | null;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseReports_indoorClimateReportFormData {
  /**
   * Famage image info
   */
  facadeImage: GetMobileV2DrivingSlips_drivingSlips_case_caseReports_indoorClimateReportFormData_facadeImage | null;
  /**
   * Date of damage
   */
  inspectionDate: GQL_Date | null;
  /**
   * Purpose of the report
   */
  purpose: string | null;
  /**
   * Year of the building being built
   */
  buildYear: string | null;
  /**
   * Square meters of the area
   */
  squareMeters: string | null;
  /**
   * Daily users of the building
   */
  dailyUsers: string | null;
  /**
   * Other participants on the scene
   */
  participants: string | null;
  /**
   * Information about what has happened until now
   */
  backgroundStory: string | null;
  /**
   * Observations
   */
  observations: string | null;
  /**
   * Refer to appendix for lab answers
   */
  showLabAnswers: boolean;
  /**
   * Refer to appendix for the data log
   */
  showDataLog: boolean | null;
  /**
   * Assessment and conclusion
   */
  assessmentAndConclusion: string | null;
  /**
   * Suggested action plan
   */
  suggestedActionPlan: string | null;
  /**
   * Floorplan image info
   */
  floorplanImage: GetMobileV2DrivingSlips_drivingSlips_case_caseReports_indoorClimateReportFormData_floorplanImage | null;
  /**
   * List of other relevant images
   */
  otherImages: GetMobileV2DrivingSlips_drivingSlips_case_caseReports_indoorClimateReportFormData_otherImages[] | null;
  /**
   * Meassurements of indoor climate
   */
  indoorClimateMeassurements: GetMobileV2DrivingSlips_drivingSlips_case_caseReports_indoorClimateReportFormData_indoorClimateMeassurements[] | null;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseReports {
  /**
   * Inspection report form data populated by the user
   */
  inspectionReportFormData: GetMobileV2DrivingSlips_drivingSlips_case_caseReports_inspectionReportFormData | null;
  /**
   * Inspection report form data populated by the user
   */
  inspectionMovablesReportFormData: GetMobileV2DrivingSlips_drivingSlips_case_caseReports_inspectionMovablesReportFormData | null;
  /**
   * Moisture report form data populated by the user
   */
  moistureReportFormData: GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureReportFormData | null;
  /**
   * Moisture TRYG report form data populated by the user
   */
  moistureTrygReportFormData: GetMobileV2DrivingSlips_drivingSlips_case_caseReports_moistureTrygReportFormData | null;
  /**
   * Indooor climate report form data populated by the user
   */
  indoorClimateReportFormData: GetMobileV2DrivingSlips_drivingSlips_case_caseReports_indoorClimateReportFormData | null;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_requisitioner {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Requisitioner relation
   */
  relation: string;
}

export interface GetMobileV2DrivingSlips_drivingSlips_case_caseDraftItemsUsage {
  /**
   * ERP system reference id
   */
  erpId: string;
  /**
   * ERP system reference key
   */
  erpKey: string;
  /**
   * Usage line title
   */
  title: string;
  /**
   * Usage line quantity
   */
  quantity: GQL_Decimal;
  /**
   * Date of posting
   */
  date: GQL_Date;
  /**
   * Posted By Username
   */
  postedBy: string;
  /**
   * Description
   */
  description: string;
  __typename: "CaseDraftUsageLine";
}

export interface GetMobileV2DrivingSlips_drivingSlips_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * Case debitor details
   */
  debitor: GetMobileV2DrivingSlips_drivingSlips_case_debitor;
  /**
   * Case damage details
   */
  damage: GetMobileV2DrivingSlips_drivingSlips_case_damage;
  /**
   * Case policy holder details
   */
  policyHolder: GetMobileV2DrivingSlips_drivingSlips_case_policyHolder;
  /**
   * Project manager attached to case
   */
  projectManager: GetMobileV2DrivingSlips_drivingSlips_case_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: GetMobileV2DrivingSlips_drivingSlips_case_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: GetMobileV2DrivingSlips_drivingSlips_case_ssgLocation;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: GetMobileV2DrivingSlips_drivingSlips_case_ssgDepartment;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: GetMobileV2DrivingSlips_drivingSlips_case_riskEvaluationAnswers[] | null;
  /**
   * Optional comment for risk evaluation
   */
  riskEvaluationComment: string | null;
  /**
   * Case visitation details
   */
  visitation: GetMobileV2DrivingSlips_drivingSlips_case_visitation;
  /**
   * Whether time registration is allowed on the case
   */
  timeRegistration: boolean | null;
  caseReports: GetMobileV2DrivingSlips_drivingSlips_case_caseReports | null;
  /**
   * Case requisitioner details
   */
  requisitioner: GetMobileV2DrivingSlips_drivingSlips_case_requisitioner;
  /**
   * List of draft case items usage
   */
  caseDraftItemsUsage: GetMobileV2DrivingSlips_drivingSlips_case_caseDraftItemsUsage[];
  /**
   * Case change version number
   */
  version: number;
}

export interface GetMobileV2DrivingSlips_drivingSlips {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: GetMobileV2DrivingSlips_drivingSlips_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: GetMobileV2DrivingSlips_drivingSlips_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetMobileV2DrivingSlips_drivingSlips_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: GetMobileV2DrivingSlips_drivingSlips_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: GetMobileV2DrivingSlips_drivingSlips_changes[];
  /**
   * Location of driving slip
   */
  location: GetMobileV2DrivingSlips_drivingSlips_location;
  /**
   * Department of driving slip
   */
  department: GetMobileV2DrivingSlips_drivingSlips_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
  /**
   * Case which driving slip is related to
   */
  case: GetMobileV2DrivingSlips_drivingSlips_case;
}

export interface GetMobileV2DrivingSlips {
  drivingSlips: GetMobileV2DrivingSlips_drivingSlips[];
}

export interface GetMobileV2DrivingSlipsVariables {
  personalOnly?: boolean | null;
  dateRange?: DateRangeInput | null;
  status?: DrivingSlipStatus | null;
  urgent?: boolean | null;
  exceeded?: boolean | null;
  departments?: string[] | null;
  locations?: string[] | null;
  damageCauses?: string[] | null;
  damageCategories?: string[] | null;
  drivers?: string[] | null;
  minStartDate?: GQL_Date | null;
  maxStartDate?: GQL_Date | null;
  minEndDate?: GQL_Date | null;
  maxEndDate?: GQL_Date | null;
  noDriver?: boolean | null;
  caseERPReferenceNo?: string | null;
  postalCode?: string | null;
  debitors?: string[] | null;
  offset?: number | null;
  limit?: number | null;
  mobile?: boolean | null;
  excludeTotalCount?: boolean | null;
  seriesId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMobileV2DrivingSlipsNoCase
// ====================================================

export interface GetMobileV2DrivingSlipsNoCase_drivingSlips {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
}

export interface GetMobileV2DrivingSlipsNoCase {
  drivingSlips: GetMobileV2DrivingSlipsNoCase_drivingSlips[];
}

export interface GetMobileV2DrivingSlipsNoCaseVariables {
  personalOnly?: boolean | null;
  dateRange?: DateRangeInput | null;
  status?: DrivingSlipStatus | null;
  urgent?: boolean | null;
  exceeded?: boolean | null;
  departments?: string[] | null;
  locations?: string[] | null;
  damageCauses?: string[] | null;
  damageCategories?: string[] | null;
  drivers?: string[] | null;
  minStartDate?: GQL_Date | null;
  maxStartDate?: GQL_Date | null;
  minEndDate?: GQL_Date | null;
  maxEndDate?: GQL_Date | null;
  noDriver?: boolean | null;
  case?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPersonalDrivingSlips
// ====================================================

export interface GetPersonalDrivingSlips_drivingSlips_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetPersonalDrivingSlips_drivingSlips_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetPersonalDrivingSlips_drivingSlips_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetPersonalDrivingSlips_drivingSlips_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface GetPersonalDrivingSlips_drivingSlips_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: GetPersonalDrivingSlips_drivingSlips_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface GetPersonalDrivingSlips_drivingSlips_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: GetPersonalDrivingSlips_drivingSlips_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface GetPersonalDrivingSlips_drivingSlips_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: GetPersonalDrivingSlips_drivingSlips_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface GetPersonalDrivingSlips_drivingSlips_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetPersonalDrivingSlips_drivingSlips_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetPersonalDrivingSlips_drivingSlips_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetPersonalDrivingSlips_drivingSlips_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetPersonalDrivingSlips_drivingSlips_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetPersonalDrivingSlips_drivingSlips_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetPersonalDrivingSlips_drivingSlips_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetPersonalDrivingSlips_drivingSlips_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetPersonalDrivingSlips_drivingSlips_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface GetPersonalDrivingSlips_drivingSlips_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetPersonalDrivingSlips_drivingSlips_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface GetPersonalDrivingSlips_drivingSlips_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetPersonalDrivingSlips_drivingSlips_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: GetPersonalDrivingSlips_drivingSlips_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: GetPersonalDrivingSlips_drivingSlips_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface GetPersonalDrivingSlips_drivingSlips_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: GetPersonalDrivingSlips_drivingSlips_changes_user;
  /**
   * Value before
   */
  before: GetPersonalDrivingSlips_drivingSlips_changes_before;
  /**
   * Value after
   */
  after: GetPersonalDrivingSlips_drivingSlips_changes_after;
}

export interface GetPersonalDrivingSlips_drivingSlips_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetPersonalDrivingSlips_drivingSlips_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface GetPersonalDrivingSlips_drivingSlips {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: GetPersonalDrivingSlips_drivingSlips_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: GetPersonalDrivingSlips_drivingSlips_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetPersonalDrivingSlips_drivingSlips_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: GetPersonalDrivingSlips_drivingSlips_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: GetPersonalDrivingSlips_drivingSlips_changes[];
  /**
   * Location of driving slip
   */
  location: GetPersonalDrivingSlips_drivingSlips_location;
  /**
   * Department of driving slip
   */
  department: GetPersonalDrivingSlips_drivingSlips_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
}

export interface GetPersonalDrivingSlips {
  drivingSlips: GetPersonalDrivingSlips_drivingSlips[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSeriesDrivingSlips
// ====================================================

export interface GetSeriesDrivingSlips_drivingSlips_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetSeriesDrivingSlips_drivingSlips_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface GetSeriesDrivingSlips_drivingSlips_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: GetSeriesDrivingSlips_drivingSlips_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface GetSeriesDrivingSlips_drivingSlips_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: GetSeriesDrivingSlips_drivingSlips_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface GetSeriesDrivingSlips_drivingSlips_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: GetSeriesDrivingSlips_drivingSlips_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface GetSeriesDrivingSlips_drivingSlips {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: GetSeriesDrivingSlips_drivingSlips_driver | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: GetSeriesDrivingSlips_drivingSlips_questionnaire | null;
}

export interface GetSeriesDrivingSlips {
  drivingSlips: GetSeriesDrivingSlips_drivingSlips[];
}

export interface GetSeriesDrivingSlipsVariables {
  personalOnly?: boolean | null;
  dateRange?: DateRangeInput | null;
  status?: DrivingSlipStatus | null;
  urgent?: boolean | null;
  exceeded?: boolean | null;
  departments?: string[] | null;
  locations?: string[] | null;
  damageCauses?: string[] | null;
  damageCategories?: string[] | null;
  drivers?: string[] | null;
  minStartDate?: GQL_Date | null;
  maxStartDate?: GQL_Date | null;
  minEndDate?: GQL_Date | null;
  maxEndDate?: GQL_Date | null;
  noDriver?: boolean | null;
  caseERPReferenceNo?: string | null;
  postalCode?: string | null;
  debitors?: string[] | null;
  offset?: number | null;
  limit?: number | null;
  mobile?: boolean | null;
  excludeTotalCount?: boolean | null;
  seriesId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetDrivingSlipDrivingStarted
// ====================================================

export interface SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_changes_user;
  /**
   * Value before
   */
  before: SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_changes_before;
  /**
   * Value after
   */
  after: SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_changes_after;
}

export interface SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_changes[];
  /**
   * Location of driving slip
   */
  location: SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_location;
  /**
   * Department of driving slip
   */
  department: SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
}

export interface SetDrivingSlipDrivingStarted {
  setDrivingSlipDrivingStarted: SetDrivingSlipDrivingStarted_setDrivingSlipDrivingStarted;
}

export interface SetDrivingSlipDrivingStartedVariables {
  id: string;
  drivingStarted: GQL_DateTime;
  locationCoordinates?: GeoCoordinateInput | null;
  carNumber?: string | null;
  passenger?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetDrivingSlipFacadePictureTaken
// ====================================================

export interface SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_changes_user;
  /**
   * Value before
   */
  before: SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_changes_before;
  /**
   * Value after
   */
  after: SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_changes_after;
}

export interface SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_changes[];
  /**
   * Location of driving slip
   */
  location: SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_location;
  /**
   * Department of driving slip
   */
  department: SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
}

export interface SetDrivingSlipFacadePictureTaken {
  setDrivingSlipFacadePictureTaken: SetDrivingSlipFacadePictureTaken_setDrivingSlipFacadePictureTaken;
}

export interface SetDrivingSlipFacadePictureTakenVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetDrivingSlipNoWasteUsed
// ====================================================

export interface SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_changes_user;
  /**
   * Value before
   */
  before: SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_changes_before;
  /**
   * Value after
   */
  after: SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_changes_after;
}

export interface SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_changes[];
  /**
   * Location of driving slip
   */
  location: SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_location;
  /**
   * Department of driving slip
   */
  department: SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
}

export interface SetDrivingSlipNoWasteUsed {
  setDrivingSlipNoWasteUsed: SetDrivingSlipNoWasteUsed_setDrivingSlipNoWasteUsed;
}

export interface SetDrivingSlipNoWasteUsedVariables {
  id: string;
  noWasteUsed: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetDrivingSlipQuestionnaireCompletionStatus
// ====================================================

export interface SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_changes_user;
  /**
   * Value before
   */
  before: SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_changes_before;
  /**
   * Value after
   */
  after: SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_changes_after;
}

export interface SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_changes[];
  /**
   * Location of driving slip
   */
  location: SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_location;
  /**
   * Department of driving slip
   */
  department: SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
}

export interface SetDrivingSlipQuestionnaireCompletionStatus {
  setDrivingSlipQuestionnaireCompletionStatus: SetDrivingSlipQuestionnaireCompletionStatus_setDrivingSlipQuestionnaireCompletionStatus;
}

export interface SetDrivingSlipQuestionnaireCompletionStatusVariables {
  id: string;
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetDrivingSlipRiskEvaluationStatus
// ====================================================

export interface SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_changes_user;
  /**
   * Value before
   */
  before: SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_changes_before;
  /**
   * Value after
   */
  after: SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_changes_after;
}

export interface SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_changes[];
  /**
   * Location of driving slip
   */
  location: SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_location;
  /**
   * Department of driving slip
   */
  department: SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
}

export interface SetDrivingSlipRiskEvaluationStatus {
  setDrivingSlipRiskEvaluationStatus: SetDrivingSlipRiskEvaluationStatus_setDrivingSlipRiskEvaluationStatus;
}

export interface SetDrivingSlipRiskEvaluationStatusVariables {
  id: string;
  riskEvaluationStatus: RiskEvaluationStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetMobileV2DrivingSlipQuestionnaire
// ====================================================

export interface SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_changes_user;
  /**
   * Value before
   */
  before: SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_changes_before;
  /**
   * Value after
   */
  after: SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_changes_after;
}

export interface SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_changes[];
  /**
   * Location of driving slip
   */
  location: SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_location;
  /**
   * Department of driving slip
   */
  department: SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
}

export interface SetMobileV2DrivingSlipQuestionnaire {
  setDrivingSlipQuestionnaire: SetMobileV2DrivingSlipQuestionnaire_setDrivingSlipQuestionnaire;
}

export interface SetMobileV2DrivingSlipQuestionnaireVariables {
  id: string;
  questionnaire: DrivingSlipQuestionnaireInputType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetMobileV2DrivingSlipStatus
// ====================================================

export interface SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_changes_user;
  /**
   * Value before
   */
  before: SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_changes_before;
  /**
   * Value after
   */
  after: SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_changes_after;
}

export interface SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface SetMobileV2DrivingSlipStatus_setDrivingSlipStatus {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_changes[];
  /**
   * Location of driving slip
   */
  location: SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_location;
  /**
   * Department of driving slip
   */
  department: SetMobileV2DrivingSlipStatus_setDrivingSlipStatus_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
}

export interface SetMobileV2DrivingSlipStatus {
  setDrivingSlipStatus: SetMobileV2DrivingSlipStatus_setDrivingSlipStatus;
}

export interface SetMobileV2DrivingSlipStatusVariables {
  id: string;
  status: DrivingSlipStatus;
  applyDrivingCost?: boolean | null;
  locationCoordinates?: GeoCoordinateInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteDrivingSlipFile
// ====================================================

export interface DeleteDrivingSlipFile_deleteDrivingSlipFile {
  /**
   * This value is not used for anything.
   */
  _: string;
}

export interface DeleteDrivingSlipFile {
  deleteDrivingSlipFile: DeleteDrivingSlipFile_deleteDrivingSlipFile | null;
}

export interface DeleteDrivingSlipFileVariables {
  caseNo: string;
  drivingSlipId: string;
  fileName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCaseESDHFiles
// ====================================================

export interface GetCaseESDHFiles_case_documents_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface GetCaseESDHFiles_case_documents {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: GetCaseESDHFiles_case_documents_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

export interface GetCaseESDHFiles_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * List of document file names and thumbnails
   */
  documents: GetCaseESDHFiles_case_documents[];
}

export interface GetCaseESDHFiles {
  case: GetCaseESDHFiles_case;
}

export interface GetCaseESDHFilesVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCaseFiles
// ====================================================

export interface GetCaseFiles_caseFiles_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface GetCaseFiles_caseFiles {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: GetCaseFiles_caseFiles_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

export interface GetCaseFiles {
  caseFiles: GetCaseFiles_caseFiles[];
}

export interface GetCaseFilesVariables {
  folder?: string | null;
  caseErpNo?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCatalogESDHFiles
// ====================================================

export interface GetCatalogESDHFiles_catalog_documents_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface GetCatalogESDHFiles_catalog_documents {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: GetCatalogESDHFiles_catalog_documents_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

export interface GetCatalogESDHFiles_catalog {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * List of document file names and thumbnails
   */
  documents: GetCatalogESDHFiles_catalog_documents[];
}

export interface GetCatalogESDHFiles {
  catalog: GetCatalogESDHFiles_catalog;
}

export interface GetCatalogESDHFilesVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDrivingSlipFiles
// ====================================================

export interface GetDrivingSlipFiles_drivingSlipFiles_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface GetDrivingSlipFiles_drivingSlipFiles {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: GetDrivingSlipFiles_drivingSlipFiles_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

export interface GetDrivingSlipFiles {
  drivingSlipFiles: GetDrivingSlipFiles_drivingSlipFiles[] | null;
}

export interface GetDrivingSlipFilesVariables {
  caseNo: string;
  drivingSlipId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMobileV2CaseFile
// ====================================================

export interface GetMobileV2CaseFile_caseFile {
  /**
   * File data as byte array
   */
  fileData: GQL_Byte[];
  /**
   * File name
   */
  filename: string;
  /**
   * File data as base64
   */
  fileDataBase64: string;
}

export interface GetMobileV2CaseFile {
  caseFile: GetMobileV2CaseFile_caseFile;
}

export interface GetMobileV2CaseFileVariables {
  fileName: string;
  folder?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMobileV2DrivingSlipFile
// ====================================================

export interface GetMobileV2DrivingSlipFile_drivingSlipFile {
  /**
   * File data as byte array
   */
  fileData: GQL_Byte[];
  /**
   * File name
   */
  filename: string;
  /**
   * File data as base64
   */
  fileDataBase64: string;
}

export interface GetMobileV2DrivingSlipFile {
  drivingSlipFile: GetMobileV2DrivingSlipFile_drivingSlipFile;
}

export interface GetMobileV2DrivingSlipFileVariables {
  caseNo: string;
  drivingSlipId: string;
  fileName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ModifyDrivingSlipFileMetadata
// ====================================================

export interface ModifyDrivingSlipFileMetadata_modifyDrivingSlipFileMetadata_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface ModifyDrivingSlipFileMetadata_modifyDrivingSlipFileMetadata {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: ModifyDrivingSlipFileMetadata_modifyDrivingSlipFileMetadata_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

export interface ModifyDrivingSlipFileMetadata {
  modifyDrivingSlipFileMetadata: ModifyDrivingSlipFileMetadata_modifyDrivingSlipFileMetadata;
}

export interface ModifyDrivingSlipFileMetadataVariables {
  caseNo: string;
  drivingSlipId: string;
  fileName: string;
  metadata: FileMetadataInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: QueueUploadDrivingSlipFile
// ====================================================

export interface QueueUploadDrivingSlipFile_queueUploadDrivingSlipFile {
  /**
   * This value is not used for anything.
   */
  _: string;
}

export interface QueueUploadDrivingSlipFile {
  queueUploadDrivingSlipFile: QueueUploadDrivingSlipFile_queueUploadDrivingSlipFile | null;
}

export interface QueueUploadDrivingSlipFileVariables {
  caseNo: string;
  drivingSlipId: string;
  fileName: string;
  file: GQL_Byte[];
  metadata?: FileMetadataInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: QueueUploadDrivingSlipFiles
// ====================================================

export interface QueueUploadDrivingSlipFiles_queueUploadDrivingSlipFiles {
  /**
   * This value is not used for anything.
   */
  _: string;
}

export interface QueueUploadDrivingSlipFiles {
  queueUploadDrivingSlipFiles: QueueUploadDrivingSlipFiles_queueUploadDrivingSlipFiles | null;
}

export interface QueueUploadDrivingSlipFilesVariables {
  caseNo: string;
  drivingSlipId: string;
  files: FileInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UploadDrivingSlipFile
// ====================================================

export interface UploadDrivingSlipFile_uploadDrivingSlipFile_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface UploadDrivingSlipFile_uploadDrivingSlipFile {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: UploadDrivingSlipFile_uploadDrivingSlipFile_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

export interface UploadDrivingSlipFile {
  uploadDrivingSlipFile: UploadDrivingSlipFile_uploadDrivingSlipFile;
}

export interface UploadDrivingSlipFileVariables {
  caseNo: string;
  drivingSlipId: string;
  fileName: string;
  file: GQL_Byte[];
  metadata?: FileMetadataInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UploadDrivingSlipFiles
// ====================================================

export interface UploadDrivingSlipFiles_uploadDrivingSlipFiles_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface UploadDrivingSlipFiles_uploadDrivingSlipFiles {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: UploadDrivingSlipFiles_uploadDrivingSlipFiles_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

export interface UploadDrivingSlipFiles {
  uploadDrivingSlipFiles: UploadDrivingSlipFiles_uploadDrivingSlipFiles[];
}

export interface UploadDrivingSlipFilesVariables {
  caseNo: string;
  drivingSlipId: string;
  files: FileInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMachine
// ====================================================

export interface GetMachine_machine {
  /**
   * BC identifier
   */
  erpReferenceNo: string;
  /**
   * Name of machine
   */
  name: string;
  /**
   * Start of machineUsage
   */
  dateStart: GQL_Date;
  /**
   * end of machineUsage
   */
  dateEnd: GQL_Date;
  /**
   * Case number if case
   */
  eRPCaseReference: string | null;
  /**
   * location number if location
   */
  eRPLocationReference: string | null;
  /**
   * Consumption
   */
  consumption: GQL_Decimal;
  /**
   * Name of user
   */
  userName: string;
  /**
   * Ressource type/allocation Type
   */
  type: string;
  /**
   * bool to determine if machine is active
   */
  active: boolean;
  /**
   * Current placement of machine
   */
  placement: string;
  /**
   * Original location of machine
   */
  originLocation: string;
  /**
   * Hibernation state
   */
  hibernate: boolean;
  /**
   * Hibernation state
   */
  hibernationReason: string;
  /**
   * Does the machine require specific education
   */
  educationPrerequisit: string[] | null;
  /**
   * Start time
   */
  startTime: string;
  /**
   * Date of next service
   */
  serviceDate: GQL_DateTime;
  /**
   * Factor for date to kwh conversion
   */
  consumptionFactor: GQL_Decimal;
  /**
   * Whether the type is days, kwh
   */
  consumptionType: MachineConsumptionType;
  /**
   * machines model number
   */
  modelNumber: string;
  /**
   * Reservation Start
   */
  reservationStart: GQL_DateTime | null;
  /**
   * Reservation End
   */
  reservationEnd: GQL_DateTime | null;
  /**
   * reservation description
   */
  reservationDescription: string | null;
  /**
   * Reservation by ressource
   */
  reservationByRessource: string | null;
  /**
   * reservation on Case
   */
  reservationOnCase: string | null;
  /**
   * Is the machine blocked
   */
  blocked: boolean;
  /**
   * Where the machine has been placed, e.g. Basement, 1st floor bedroom etc.
   */
  placementDescription: string | null;
  /**
   * The total consumption after machine has been used
   */
  usageMeterEnd: GQL_Decimal;
  /**
   * Unavailable reason
   */
  unavailableReason: string;
  /**
   * Marks that machine has been discontinued
   */
  discontinued: boolean;
}

export interface GetMachine {
  machine: GetMachine_machine | null;
}

export interface GetMachineVariables {
  machineNo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMachineGuides
// ====================================================

export interface GetMachineGuides_machineGuideFiles {
  /**
   * File name
   */
  name: string;
}

export interface GetMachineGuides {
  machineGuideFiles: GetMachineGuides_machineGuideFiles[];
}

export interface GetMachineGuidesVariables {
  filter?: FileFilterInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMobileV2MachineGuideFiles
// ====================================================

export interface GetMobileV2MachineGuideFiles_machineGuideFiles_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface GetMobileV2MachineGuideFiles_machineGuideFiles {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: GetMobileV2MachineGuideFiles_machineGuideFiles_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

export interface GetMobileV2MachineGuideFiles {
  machineGuideFiles: GetMobileV2MachineGuideFiles_machineGuideFiles[];
}

export interface GetMobileV2MachineGuideFilesVariables {
  filter?: FileFilterInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMobileV2MachineLocations
// ====================================================

export interface GetMobileV2MachineLocations_machineLocations {
  /**
   * Name
   */
  name: string;
  /**
   * Identifier of location
   */
  locationCode: string;
}

export interface GetMobileV2MachineLocations {
  machineLocations: GetMobileV2MachineLocations_machineLocations[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMobileV2MachinesOnCase
// ====================================================

export interface GetMobileV2MachinesOnCase_machinesOnCase {
  /**
   * BC identifier
   */
  erpReferenceNo: string;
  /**
   * Name of machine
   */
  name: string;
  /**
   * Start of machineUsage
   */
  dateStart: GQL_Date;
  /**
   * end of machineUsage
   */
  dateEnd: GQL_Date;
  /**
   * Case number if case
   */
  eRPCaseReference: string | null;
  /**
   * location number if location
   */
  eRPLocationReference: string | null;
  /**
   * Consumption
   */
  consumption: GQL_Decimal;
  /**
   * Name of user
   */
  userName: string;
  /**
   * Ressource type/allocation Type
   */
  type: string;
  /**
   * bool to determine if machine is active
   */
  active: boolean;
  /**
   * Current placement of machine
   */
  placement: string;
  /**
   * Original location of machine
   */
  originLocation: string;
  /**
   * Hibernation state
   */
  hibernate: boolean;
  /**
   * Hibernation state
   */
  hibernationReason: string;
  /**
   * Does the machine require specific education
   */
  educationPrerequisit: string[] | null;
  /**
   * Start time
   */
  startTime: string;
  /**
   * Date of next service
   */
  serviceDate: GQL_DateTime;
  /**
   * Factor for date to kwh conversion
   */
  consumptionFactor: GQL_Decimal;
  /**
   * Whether the type is days, kwh
   */
  consumptionType: MachineConsumptionType;
  /**
   * machines model number
   */
  modelNumber: string;
  /**
   * Reservation Start
   */
  reservationStart: GQL_DateTime | null;
  /**
   * Reservation End
   */
  reservationEnd: GQL_DateTime | null;
  /**
   * reservation description
   */
  reservationDescription: string | null;
  /**
   * Reservation by ressource
   */
  reservationByRessource: string | null;
  /**
   * reservation on Case
   */
  reservationOnCase: string | null;
  /**
   * Is the machine blocked
   */
  blocked: boolean;
  /**
   * Where the machine has been placed, e.g. Basement, 1st floor bedroom etc.
   */
  placementDescription: string | null;
  /**
   * The total consumption after machine has been used
   */
  usageMeterEnd: GQL_Decimal;
  /**
   * Unavailable reason
   */
  unavailableReason: string;
  /**
   * Marks that machine has been discontinued
   */
  discontinued: boolean;
}

export interface GetMobileV2MachinesOnCase {
  machinesOnCase: GetMobileV2MachinesOnCase_machinesOnCase[];
}

export interface GetMobileV2MachinesOnCaseVariables {
  caseNo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MoveMobileV2Machine
// ====================================================

export interface MoveMobileV2Machine_moveMachine {
  /**
   * BC identifier
   */
  erpReferenceNo: string;
}

export interface MoveMobileV2Machine {
  moveMachine: MoveMobileV2Machine_moveMachine | null;
}

export interface MoveMobileV2MachineVariables {
  machineInput: MachineInput;
  caseNo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReserveMachine
// ====================================================

export interface ReserveMachine_machineReserve {
  /**
   * BC identifier
   */
  erpReferenceNo: string;
}

export interface ReserveMachine {
  machineReserve: ReserveMachine_machineReserve | null;
}

export interface ReserveMachineVariables {
  machineInput: MachineInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeOfferStatus
// ====================================================

export interface ChangeOfferStatus {
  changeOfferStatus: string | null;
}

export interface ChangeOfferStatusVariables {
  caseERPReferenceNo: string;
  offerERPReferenceNo: string;
  reasonCode: string;
  action: SalesHeaderEnum;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Assortments
// ====================================================

export interface Assortments_assortments {
  /**
   * item no
   */
  no: string;
  /**
   * Type of assortment
   */
  type: string;
  /**
   * description
   */
  description: string;
  /**
   * Base unit of messure
   */
  uom: string;
}

export interface Assortments {
  assortments: Assortments_assortments[] | null;
}

export interface AssortmentsVariables {
  assortmentCode: string;
  type: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CloseOppertunityCodes
// ====================================================

export interface CloseOppertunityCodes_closeOppertunityCodes {
  /**
   * Type
   */
  type: string;
  /**
   * description
   */
  description: string;
  /**
   * Code
   */
  code: string;
}

export interface CloseOppertunityCodes {
  closeOppertunityCodes: CloseOppertunityCodes_closeOppertunityCodes[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JobTasks
// ====================================================

export interface JobTasks_jobTasks {
  /**
   * case no
   */
  erpRefrenceNo: string;
  /**
   * JobTask No
   */
  jobTaskNo: string;
  /**
   * Description
   */
  description: string;
  /**
   * Price of the JobTask
   */
  price: GQL_Decimal;
  /**
   * Work type for the job task
   */
  workTypeCode: string;
  /**
   * Exclude In the enviromental fee
   */
  excludeInEnvFee: boolean | null;
}

export interface JobTasks {
  jobTasks: (JobTasks_jobTasks | null)[] | null;
}

export interface JobTasksVariables {
  erpReferenceNo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Offers
// ====================================================

export interface Offers_offers_lines {
  /**
   * document nr / e.g Screening
   */
  documentNo: string;
  /**
   * Item / resource reference id
   */
  no: string;
  /**
   * Date of planning
   */
  planningDate: string;
  /**
   * Description
   */
  description: string;
  /**
   * Quantity
   */
  quantity: GQL_Decimal;
  /**
   * Price of unit
   */
  unitPrice: GQL_Decimal;
  /**
   * new Price of unit
   */
  newUnitPrice: GQL_Decimal | null;
  /**
   * Line amount
   */
  lineAmount: GQL_Decimal;
  /**
   * Currencycode
   */
  currencyCode: string | null;
  /**
   * Type of work
   */
  workType: string | null;
  /**
   * Id of entity
   */
  systemId: string | null;
  /**
   * ERP Reference task
   */
  eRPReferenceTask: string;
  /**
   * Assortment type
   */
  type: OfferRessourceType;
  /**
   * option
   */
  option: boolean;
  /**
   * hideSum
   */
  hideSum: boolean;
  /**
   * is line a header
   */
  header: boolean;
  /**
   * Refrence id to line in screening template
   */
  screeningLineId: string | null;
  /**
   * Sorting index (header and lines are grouped by sorting index)
   */
  sortingIndex: number;
  /**
   * Line number
   */
  lineNo: number;
  /**
   * Unit Cost
   */
  unitCost: GQL_Decimal;
}

export interface Offers_offers {
  /**
   * BC job number
   */
  jobNo: string;
  /**
   * Offer lines
   */
  lines: Offers_offers_lines[];
  /**
   * current status on Quote
   */
  opportunityStatus: string | null;
  /**
   * header text
   */
  headerText: string | null;
  /**
   * footer text
   */
  footerText: string | null;
  /**
   * Quote Type
   */
  quoteType: OfferQuoteTypeEnum | null;
  /**
   * is offer competition offer
   */
  competitionOffer: boolean;
}

export interface Offers {
  offers: Offers_offers[] | null;
}

export interface OffersVariables {
  caseERPReferenceNo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSamplePriceForLine
// ====================================================

export interface GetSamplePriceForLine_samplePriceForLine {
  /**
   * Total cost
   */
  totalCost: GQL_Decimal;
  /**
   * Total line amount
   */
  lineAmount: GQL_Decimal;
}

export interface GetSamplePriceForLine {
  samplePriceForLine: GetSamplePriceForLine_samplePriceForLine;
}

export interface GetSamplePriceForLineVariables {
  offerLine: OfferLineInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: HandleOffer
// ====================================================

export interface HandleOffer_handleOffer_lines {
  /**
   * document nr / e.g Screening
   */
  documentNo: string;
  /**
   * Item / resource reference id
   */
  no: string;
  /**
   * Date of planning
   */
  planningDate: string;
  /**
   * Description
   */
  description: string;
  /**
   * Quantity
   */
  quantity: GQL_Decimal;
  /**
   * Price of unit
   */
  unitPrice: GQL_Decimal;
  /**
   * new Price of unit
   */
  newUnitPrice: GQL_Decimal | null;
  /**
   * Line amount
   */
  lineAmount: GQL_Decimal;
  /**
   * Currencycode
   */
  currencyCode: string | null;
  /**
   * Type of work
   */
  workType: string | null;
  /**
   * Id of entity
   */
  systemId: string | null;
  /**
   * ERP Reference task
   */
  eRPReferenceTask: string;
  /**
   * Assortment type
   */
  type: OfferRessourceType;
  /**
   * option
   */
  option: boolean;
  /**
   * hideSum
   */
  hideSum: boolean;
  /**
   * is line a header
   */
  header: boolean;
  /**
   * Refrence id to line in screening template
   */
  screeningLineId: string | null;
  /**
   * Sorting index (header and lines are grouped by sorting index)
   */
  sortingIndex: number;
  /**
   * Line number
   */
  lineNo: number;
  /**
   * Unit Cost
   */
  unitCost: GQL_Decimal;
}

export interface HandleOffer_handleOffer {
  /**
   * BC job number
   */
  jobNo: string;
  /**
   * Offer lines
   */
  lines: HandleOffer_handleOffer_lines[];
  /**
   * current status on Quote
   */
  opportunityStatus: string | null;
  /**
   * header text
   */
  headerText: string | null;
  /**
   * footer text
   */
  footerText: string | null;
  /**
   * Quote Type
   */
  quoteType: OfferQuoteTypeEnum | null;
  /**
   * is offer competition offer
   */
  competitionOffer: boolean;
}

export interface HandleOffer {
  handleOffer: HandleOffer_handleOffer;
}

export interface HandleOfferVariables {
  offer: OfferInput;
  caseId: string;
  offerERPReferenceNo?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetQuestionnaireTemplateByDrivingSlip
// ====================================================

export interface GetQuestionnaireTemplateByDrivingSlip_questionnaireTemplateByDrivingSlip_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface GetQuestionnaireTemplateByDrivingSlip_questionnaireTemplateByDrivingSlip_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: GetQuestionnaireTemplateByDrivingSlip_questionnaireTemplateByDrivingSlip_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface GetQuestionnaireTemplateByDrivingSlip_questionnaireTemplateByDrivingSlip {
  /**
   * Unique id of questionnaire
   */
  id: string;
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: GetQuestionnaireTemplateByDrivingSlip_questionnaireTemplateByDrivingSlip_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire driving slip categories
   */
  drivingSlipCategories: string[];
  /**
   * Questionnaire change version number
   */
  version: number;
  /**
   * Timestamp of when the questionnaire was created
   */
  createdAt: GQL_DateTime;
  /**
   * Timestamp of when the questionnaire was last updated
   */
  updatedAt: GQL_DateTime;
}

export interface GetQuestionnaireTemplateByDrivingSlip {
  questionnaireTemplateByDrivingSlip: GetQuestionnaireTemplateByDrivingSlip_questionnaireTemplateByDrivingSlip | null;
}

export interface GetQuestionnaireTemplateByDrivingSlipVariables {
  drivingSlipCategoryCode?: string | null;
  damageCauseId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetQuestionnaireTemplatesByDrivingSlips
// ====================================================

export interface GetQuestionnaireTemplatesByDrivingSlips_questionnaireTemplatesByDrivingSlips_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface GetQuestionnaireTemplatesByDrivingSlips_questionnaireTemplatesByDrivingSlips_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: GetQuestionnaireTemplatesByDrivingSlips_questionnaireTemplatesByDrivingSlips_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface GetQuestionnaireTemplatesByDrivingSlips_questionnaireTemplatesByDrivingSlips {
  /**
   * Unique id of questionnaire
   */
  id: string;
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: GetQuestionnaireTemplatesByDrivingSlips_questionnaireTemplatesByDrivingSlips_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire driving slip categories
   */
  drivingSlipCategories: string[];
  /**
   * Questionnaire change version number
   */
  version: number;
  /**
   * Timestamp of when the questionnaire was created
   */
  createdAt: GQL_DateTime;
  /**
   * Timestamp of when the questionnaire was last updated
   */
  updatedAt: GQL_DateTime;
}

export interface GetQuestionnaireTemplatesByDrivingSlips {
  questionnaireTemplatesByDrivingSlips: GetQuestionnaireTemplatesByDrivingSlips_questionnaireTemplatesByDrivingSlips[];
}

export interface GetQuestionnaireTemplatesByDrivingSlipsVariables {
  drivingSlips: QuestionnaireTemplateByDrivingSlipInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateFalckDehumidifierReport
// ====================================================

export interface CreateFalckDehumidifierReport_createFalckDehumidifierReport_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface CreateFalckDehumidifierReport_createFalckDehumidifierReport {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: CreateFalckDehumidifierReport_createFalckDehumidifierReport_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

export interface CreateFalckDehumidifierReport {
  /**
   * Create Falck dehumidifier report for case
   */
  createFalckDehumidifierReport: CreateFalckDehumidifierReport_createFalckDehumidifierReport;
}

export interface CreateFalckDehumidifierReportVariables {
  caseId: string;
  formData: FalckDehumidifierReportFormDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateMobileIndoorClimateReport
// ====================================================

export interface CreateMobileIndoorClimateReport_createIndoorClimateReport {
  /**
   * File name
   */
  name: string;
}

export interface CreateMobileIndoorClimateReport {
  /**
   * Create indoor climate report
   */
  createIndoorClimateReport: CreateMobileIndoorClimateReport_createIndoorClimateReport | null;
}

export interface CreateMobileIndoorClimateReportVariables {
  caseId: string;
  formData: IndoorClimateReportFormDataInput;
  reportFormat: ReportFormatType;
  onlySave: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateMobileV2DamageReport
// ====================================================

export interface CreateMobileV2DamageReport_createDamageReport_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface CreateMobileV2DamageReport_createDamageReport {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: CreateMobileV2DamageReport_createDamageReport_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

export interface CreateMobileV2DamageReport {
  /**
   * Create damage report for case
   */
  createDamageReport: CreateMobileV2DamageReport_createDamageReport;
}

export interface CreateMobileV2DamageReportVariables {
  caseId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateMobileV2InspectionMovablesReport
// ====================================================

export interface CreateMobileV2InspectionMovablesReport_createInspectionMovablesReport_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface CreateMobileV2InspectionMovablesReport_createInspectionMovablesReport {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: CreateMobileV2InspectionMovablesReport_createInspectionMovablesReport_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

export interface CreateMobileV2InspectionMovablesReport {
  /**
   * Create inspection report for case
   */
  createInspectionMovablesReport: CreateMobileV2InspectionMovablesReport_createInspectionMovablesReport | null;
}

export interface CreateMobileV2InspectionMovablesReportVariables {
  caseId: string;
  formData: InspectionReportFormDataInput;
  onlySave: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateMobileV2InspectionReport
// ====================================================

export interface CreateMobileV2InspectionReport_createInspectionReport_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface CreateMobileV2InspectionReport_createInspectionReport {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: CreateMobileV2InspectionReport_createInspectionReport_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

export interface CreateMobileV2InspectionReport {
  /**
   * Create inspection report for case
   */
  createInspectionReport: CreateMobileV2InspectionReport_createInspectionReport | null;
}

export interface CreateMobileV2InspectionReportVariables {
  caseId: string;
  formData: InspectionReportFormDataInput;
  onlySave: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateMobileV2MoistureReport
// ====================================================

export interface CreateMobileV2MoistureReport_createMoistureReport_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface CreateMobileV2MoistureReport_createMoistureReport {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: CreateMobileV2MoistureReport_createMoistureReport_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

export interface CreateMobileV2MoistureReport {
  /**
   * Create moisture report for case
   */
  createMoistureReport: CreateMobileV2MoistureReport_createMoistureReport | null;
}

export interface CreateMobileV2MoistureReportVariables {
  caseId: string;
  formData: MoistureReportFormDataInput;
  reportFormat: ReportFormatType;
  onlySave: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateMobileV2MoistureTrygReport
// ====================================================

export interface CreateMobileV2MoistureTrygReport_createMoistureTrygReport_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface CreateMobileV2MoistureTrygReport_createMoistureTrygReport {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: CreateMobileV2MoistureTrygReport_createMoistureTrygReport_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

export interface CreateMobileV2MoistureTrygReport {
  /**
   * Create moisture report for case
   */
  createMoistureTrygReport: CreateMobileV2MoistureTrygReport_createMoistureTrygReport | null;
}

export interface CreateMobileV2MoistureTrygReportVariables {
  caseId: string;
  formData: MoistureTrygReportFormDataInput;
  reportFormat: ReportFormatType;
  onlySave: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCaseReportsAndDocuments
// ====================================================

export interface GetCaseReportsAndDocuments_case_documents_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface GetCaseReportsAndDocuments_case_documents {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: GetCaseReportsAndDocuments_case_documents_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_inspectionReportFormData_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_inspectionReportFormData_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_inspectionReportFormData_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_inspectionReportFormData {
  /**
   * Famage image info
   */
  facadeImage: GetCaseReportsAndDocuments_case_caseReports_inspectionReportFormData_facadeImage | null;
  /**
   * Information about what has happened until now
   */
  backgroundStory: string | null;
  /**
   * Observations about the site
   */
  observations: string | null;
  /**
   * Assessment and conclusion
   */
  assessmentAndConclusion: string | null;
  /**
   * Suggested action plan
   */
  suggestedActionPlan: string | null;
  /**
   * Any other comments from inspection
   */
  comments: string | null;
  /**
   * Floorplan image info
   */
  floorplanImage: GetCaseReportsAndDocuments_case_caseReports_inspectionReportFormData_floorplanImage | null;
  /**
   * List of other relevant images
   */
  otherImages: GetCaseReportsAndDocuments_case_caseReports_inspectionReportFormData_otherImages[] | null;
}

export interface GetCaseReportsAndDocuments_case_caseReports_inspectionMovablesReportFormData_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_inspectionMovablesReportFormData_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_inspectionMovablesReportFormData_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_inspectionMovablesReportFormData {
  /**
   * Famage image info
   */
  facadeImage: GetCaseReportsAndDocuments_case_caseReports_inspectionMovablesReportFormData_facadeImage | null;
  /**
   * Information about what has happened until now
   */
  backgroundStory: string | null;
  /**
   * Observations about the site
   */
  observations: string | null;
  /**
   * Assessment and conclusion
   */
  assessmentAndConclusion: string | null;
  /**
   * Suggested action plan
   */
  suggestedActionPlan: string | null;
  /**
   * Any other comments from inspection
   */
  comments: string | null;
  /**
   * Floorplan image info
   */
  floorplanImage: GetCaseReportsAndDocuments_case_caseReports_inspectionMovablesReportFormData_floorplanImage | null;
  /**
   * List of other relevant images
   */
  otherImages: GetCaseReportsAndDocuments_case_caseReports_inspectionMovablesReportFormData_otherImages[] | null;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData_damageCauseImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData_floorReport_observations {
  /**
   * Describes where the damage is
   */
  roomOrApartment: string;
  /**
   * Construction observations
   */
  construction: string | null;
  /**
   * Ceiling observations
   */
  ceiling: string | null;
  /**
   * Walls observations
   */
  walls: string | null;
  /**
   * Floor observations
   */
  floor: string | null;
  /**
   * Observed scope of damage
   */
  scope: string;
  /**
   * Recommendations
   */
  recommendations: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData_floorReport_moistureMeassurements {
  /**
   * Construction
   */
  construction: string | null;
  /**
   * Material
   */
  material: string | null;
  /**
   * Description
   */
  description: string | null;
  /**
   * Method
   */
  method: string | null;
  /**
   * Value
   */
  value: string;
  /**
   * Expected final value
   */
  expectedFinalValue: string | null;
  /**
   * Reference value
   */
  referenceValue: string | null;
  /**
   * Reference date
   */
  referenceDate: string | null;
  /**
   * Control value
   */
  controlValue: string | null;
  /**
   * Control date
   */
  controlDate: string | null;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData_floorReport_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData_floorReport_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData_floorReport {
  /**
   * Floor Name
   */
  name: string;
  /**
   * Report observations
   */
  observations: GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData_floorReport_observations;
  /**
   * Moisture meassurements
   */
  moistureMeassurements: GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData_floorReport_moistureMeassurements[] | null;
  /**
   * Floorplan image info
   */
  floorplanImage: GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData_floorReport_floorplanImage | null;
  /**
   * List of other relevant images
   */
  otherImages: GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData_floorReport_otherImages[] | null;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData {
  /**
   * Famage image info
   */
  facadeImage: GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData_facadeImage | null;
  /**
   * Date of damage
   */
  inspectionDate: GQL_Date;
  /**
   * Conclusions
   */
  conclusion: string;
  /**
   * Damage cause description
   */
  damageCause: string;
  /**
   * Damage scope
   */
  scope: string;
  /**
   * Damage site contacts
   */
  contacts: string;
  /**
   * Images of damage cause
   */
  damageCauseImages: GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData_damageCauseImages[] | null;
  /**
   * Gulvfugt info
   */
  floorReport: GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData_floorReport[];
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_floorReport_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_floorReport_moistureMeassurements {
  /**
   * Construction
   */
  construction: string | null;
  /**
   * Material
   */
  material: string | null;
  /**
   * Description
   */
  description: string | null;
  /**
   * Method
   */
  method: string | null;
  /**
   * Paints
   */
  paints: string;
  /**
   * Paints Date
   */
  paintsDate: string | null;
  /**
   * Expected final value
   */
  expectedFinalValue: string | null;
  /**
   * Expected final date
   */
  expectedFinalDate: string | null;
  /**
   * Reference value
   */
  referenceValue: string | null;
  /**
   * Reference date
   */
  referenceDate: string | null;
  /**
   * Control value
   */
  controlValue: string | null;
  /**
   * Control date
   */
  controlDate: string | null;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_floorReport_airMeassurements {
  /**
   * Temperature inside (celcius)
   */
  temperatureInside: number;
  /**
   * Temperature outside (celcius)
   */
  temperatureOutside: number;
  /**
   * Relative humidity inside (percentage)
   */
  relativeHumidityInside: number;
  /**
   * Relative humidity outside (percentage)
   */
  relativeHumidityOutside: number;
  /**
   * Water amount inside (g/m3)
   */
  waterAmountInside: number;
  /**
   * Water amount outside (g/m3)
   */
  waterAmountOutside: number;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_floorReport_damageDescriptionImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_floorReport_damageDrawingImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_floorReport_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_floorReport {
  /**
   * Name of floor
   */
  name: string | null;
  /**
   * Damage cause description
   */
  damageCause: string | null;
  /**
   * Multiple damage causes description
   */
  multipleDamageCauses: string | null;
  /**
   * Description of relation between damage cause and moisture
   */
  moistureDamageCause: string | null;
  /**
   * Damage description
   */
  damageDescription: string | null;
  /**
   * Floorplan image info
   */
  floorplanImage: GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_floorReport_floorplanImage | null;
  /**
   * Moisture meassurements
   */
  moistureMeassurements: GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_floorReport_moistureMeassurements[] | null;
  /**
   * Air meassurements
   */
  airMeassurements: GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_floorReport_airMeassurements;
  /**
   * Damage description image info
   */
  damageDescriptionImage: GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_floorReport_damageDescriptionImage[] | null;
  /**
   * Damage drawing image info
   */
  damageDrawingImage: GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_floorReport_damageDrawingImage | null;
  /**
   * Recommendations (if any)
   */
  recommendations: string | null;
  /**
   * Urgent actions (if any)
   */
  urgentActions: string | null;
  /**
   * List of other relevant images
   */
  otherImages: GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_floorReport_otherImages[] | null;
}

export interface GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData {
  /**
   * Famage image info
   */
  facadeImage: GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_facadeImage | null;
  /**
   * Date of damage
   */
  inspectionDate: GQL_Date | null;
  /**
   * Gulvfugt info
   */
  floorReport: GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData_floorReport[] | null;
}

export interface GetCaseReportsAndDocuments_case_caseReports_indoorClimateReportFormData_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_indoorClimateReportFormData_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_indoorClimateReportFormData_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface GetCaseReportsAndDocuments_case_caseReports_indoorClimateReportFormData_indoorClimateMeassurements {
  /**
   * Room
   */
  room: string | null;
  /**
   * Construction
   */
  construction: string | null;
  /**
   * Material
   */
  material: string | null;
  /**
   * Description
   */
  description: string | null;
  /**
   * Instrument
   */
  instrument: string | null;
  /**
   * Value
   */
  value: string | null;
  /**
   * Reference value
   */
  referenceValue: string | null;
  /**
   * Calculated value
   */
  calculatedValue: string | null;
}

export interface GetCaseReportsAndDocuments_case_caseReports_indoorClimateReportFormData {
  /**
   * Famage image info
   */
  facadeImage: GetCaseReportsAndDocuments_case_caseReports_indoorClimateReportFormData_facadeImage | null;
  /**
   * Date of damage
   */
  inspectionDate: GQL_Date | null;
  /**
   * Purpose of the report
   */
  purpose: string | null;
  /**
   * Year of the building being built
   */
  buildYear: string | null;
  /**
   * Square meters of the area
   */
  squareMeters: string | null;
  /**
   * Daily users of the building
   */
  dailyUsers: string | null;
  /**
   * Other participants on the scene
   */
  participants: string | null;
  /**
   * Information about what has happened until now
   */
  backgroundStory: string | null;
  /**
   * Observations
   */
  observations: string | null;
  /**
   * Refer to appendix for lab answers
   */
  showLabAnswers: boolean;
  /**
   * Refer to appendix for the data log
   */
  showDataLog: boolean | null;
  /**
   * Assessment and conclusion
   */
  assessmentAndConclusion: string | null;
  /**
   * Suggested action plan
   */
  suggestedActionPlan: string | null;
  /**
   * Floorplan image info
   */
  floorplanImage: GetCaseReportsAndDocuments_case_caseReports_indoorClimateReportFormData_floorplanImage | null;
  /**
   * List of other relevant images
   */
  otherImages: GetCaseReportsAndDocuments_case_caseReports_indoorClimateReportFormData_otherImages[] | null;
  /**
   * Meassurements of indoor climate
   */
  indoorClimateMeassurements: GetCaseReportsAndDocuments_case_caseReports_indoorClimateReportFormData_indoorClimateMeassurements[] | null;
}

export interface GetCaseReportsAndDocuments_case_caseReports {
  /**
   * Inspection report form data populated by the user
   */
  inspectionReportFormData: GetCaseReportsAndDocuments_case_caseReports_inspectionReportFormData | null;
  /**
   * Inspection report form data populated by the user
   */
  inspectionMovablesReportFormData: GetCaseReportsAndDocuments_case_caseReports_inspectionMovablesReportFormData | null;
  /**
   * Moisture report form data populated by the user
   */
  moistureReportFormData: GetCaseReportsAndDocuments_case_caseReports_moistureReportFormData | null;
  /**
   * Moisture TRYG report form data populated by the user
   */
  moistureTrygReportFormData: GetCaseReportsAndDocuments_case_caseReports_moistureTrygReportFormData | null;
  /**
   * Indooor climate report form data populated by the user
   */
  indoorClimateReportFormData: GetCaseReportsAndDocuments_case_caseReports_indoorClimateReportFormData | null;
}

export interface GetCaseReportsAndDocuments_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * List of document file names and thumbnails
   */
  documents: GetCaseReportsAndDocuments_case_documents[];
  caseReports: GetCaseReportsAndDocuments_case_caseReports | null;
}

export interface GetCaseReportsAndDocuments {
  case: GetCaseReportsAndDocuments_case;
}

export interface GetCaseReportsAndDocumentsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCaseRequisition
// ====================================================

export interface CreateCaseRequisition_createCaseRequisition_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
}

export interface CreateCaseRequisition_createCaseRequisition_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
  /**
   * Id of damage category
   */
  id: string;
}

export interface CreateCaseRequisition_createCaseRequisition_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of damage cause
   */
  id: string;
}

export interface CreateCaseRequisition_createCaseRequisition_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCaseRequisition_createCaseRequisition_case_damage_contact {
  /**
   * Contact address information
   */
  address: CreateCaseRequisition_createCaseRequisition_case_damage_contact_address;
}

export interface CreateCaseRequisition_createCaseRequisition_case_damage {
  /**
   * Damage category
   */
  category: CreateCaseRequisition_createCaseRequisition_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: CreateCaseRequisition_createCaseRequisition_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: CreateCaseRequisition_createCaseRequisition_case_damage_contact;
}

export interface CreateCaseRequisition_createCaseRequisition_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: CreateCaseRequisition_createCaseRequisition_case_debitor;
  /**
   * Case damage details
   */
  damage: CreateCaseRequisition_createCaseRequisition_case_damage;
}

export interface CreateCaseRequisition_createCaseRequisition_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
}

export interface CreateCaseRequisition_createCaseRequisition_drivingSlip_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
}

export interface CreateCaseRequisition_createCaseRequisition_drivingSlip_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
}

export interface CreateCaseRequisition_createCaseRequisition_drivingSlip_case_damage {
  /**
   * Damage category
   */
  category: CreateCaseRequisition_createCaseRequisition_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: CreateCaseRequisition_createCaseRequisition_drivingSlip_case_damage_cause;
}

export interface CreateCaseRequisition_createCaseRequisition_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: CreateCaseRequisition_createCaseRequisition_drivingSlip_case_damage;
}

export interface CreateCaseRequisition_createCaseRequisition_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Case which driving slip is related to
   */
  case: CreateCaseRequisition_createCaseRequisition_drivingSlip_case;
}

export interface CreateCaseRequisition_createCaseRequisition_vendor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateCaseRequisition_createCaseRequisition_vendor {
  /**
   * ERP reference no for vendor
   */
  erpReferenceNo: string;
  /**
   * The name of the vendor
   */
  company: string | null;
  /**
   * The address of the vendor
   */
  address: CreateCaseRequisition_createCaseRequisition_vendor_address | null;
  /**
   * The email of the vendor
   */
  email: string | null;
  /**
   * The phone number of the vendor
   */
  phone: string | null;
  /**
   * The posting group of the vendor
   */
  vendorPostingGroup: string;
  /**
   * Attached SSG department of the vendor
   */
  ssgDepartment: string;
  /**
   * Attached SSG location of the vendor
   */
  ssgLocation: string;
  /**
   * Vendor type
   */
  type: string;
}

export interface CreateCaseRequisition_createCaseRequisition_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface CreateCaseRequisition_createCaseRequisition {
  /**
   * id of the requisition
   */
  id: string;
  /**
   * case owning the requisition
   */
  case: CreateCaseRequisition_createCaseRequisition_case | null;
  /**
   * location owning the requisition
   */
  department: CreateCaseRequisition_createCaseRequisition_department | null;
  /**
   * driving slip owning the requisition
   */
  drivingSlip: CreateCaseRequisition_createCaseRequisition_drivingSlip | null;
  /**
   * description of the requisition
   */
  description: string;
  /**
   * orderNumber of the requisition
   */
  orderNumber: string;
  /**
   * the type of the requisition
   */
  type: RequisitionType;
  /**
   * Requisition status
   */
  status: boolean;
  /**
   * the vendor of the requisition
   */
  vendor: CreateCaseRequisition_createCaseRequisition_vendor | null;
  /**
   * Timestamp of when the requisition was created
   */
  createdAt: GQL_DateTime;
  /**
   * User who created the requisition
   */
  createdBy: CreateCaseRequisition_createCaseRequisition_createdBy;
  /**
   * email of the vendor
   */
  vendorEmail: string | null;
  /**
   * phone number of the vendor
   */
  vendorPhoneNumber: string;
  /**
   * name of the vendor
   */
  vendorName: string;
}

export interface CreateCaseRequisition {
  createCaseRequisition: CreateCaseRequisition_createCaseRequisition;
}

export interface CreateCaseRequisitionVariables {
  caseId: string;
  requisition: RequisitionInput;
  shouldSendEmail: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateDepartmentRequisition
// ====================================================

export interface CreateDepartmentRequisition_createDepartmentRequisition_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
  /**
   * Id of damage category
   */
  id: string;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of damage cause
   */
  id: string;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition_case_damage_contact {
  /**
   * Contact address information
   */
  address: CreateDepartmentRequisition_createDepartmentRequisition_case_damage_contact_address;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition_case_damage {
  /**
   * Damage category
   */
  category: CreateDepartmentRequisition_createDepartmentRequisition_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: CreateDepartmentRequisition_createDepartmentRequisition_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: CreateDepartmentRequisition_createDepartmentRequisition_case_damage_contact;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: CreateDepartmentRequisition_createDepartmentRequisition_case_debitor;
  /**
   * Case damage details
   */
  damage: CreateDepartmentRequisition_createDepartmentRequisition_case_damage;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition_drivingSlip_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition_drivingSlip_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition_drivingSlip_case_damage {
  /**
   * Damage category
   */
  category: CreateDepartmentRequisition_createDepartmentRequisition_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: CreateDepartmentRequisition_createDepartmentRequisition_drivingSlip_case_damage_cause;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: CreateDepartmentRequisition_createDepartmentRequisition_drivingSlip_case_damage;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Case which driving slip is related to
   */
  case: CreateDepartmentRequisition_createDepartmentRequisition_drivingSlip_case;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition_vendor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition_vendor {
  /**
   * ERP reference no for vendor
   */
  erpReferenceNo: string;
  /**
   * The name of the vendor
   */
  company: string | null;
  /**
   * The address of the vendor
   */
  address: CreateDepartmentRequisition_createDepartmentRequisition_vendor_address | null;
  /**
   * The email of the vendor
   */
  email: string | null;
  /**
   * The phone number of the vendor
   */
  phone: string | null;
  /**
   * The posting group of the vendor
   */
  vendorPostingGroup: string;
  /**
   * Attached SSG department of the vendor
   */
  ssgDepartment: string;
  /**
   * Attached SSG location of the vendor
   */
  ssgLocation: string;
  /**
   * Vendor type
   */
  type: string;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface CreateDepartmentRequisition_createDepartmentRequisition {
  /**
   * id of the requisition
   */
  id: string;
  /**
   * case owning the requisition
   */
  case: CreateDepartmentRequisition_createDepartmentRequisition_case | null;
  /**
   * location owning the requisition
   */
  department: CreateDepartmentRequisition_createDepartmentRequisition_department | null;
  /**
   * driving slip owning the requisition
   */
  drivingSlip: CreateDepartmentRequisition_createDepartmentRequisition_drivingSlip | null;
  /**
   * description of the requisition
   */
  description: string;
  /**
   * orderNumber of the requisition
   */
  orderNumber: string;
  /**
   * the type of the requisition
   */
  type: RequisitionType;
  /**
   * Requisition status
   */
  status: boolean;
  /**
   * the vendor of the requisition
   */
  vendor: CreateDepartmentRequisition_createDepartmentRequisition_vendor | null;
  /**
   * Timestamp of when the requisition was created
   */
  createdAt: GQL_DateTime;
  /**
   * User who created the requisition
   */
  createdBy: CreateDepartmentRequisition_createDepartmentRequisition_createdBy;
  /**
   * email of the vendor
   */
  vendorEmail: string | null;
  /**
   * phone number of the vendor
   */
  vendorPhoneNumber: string;
  /**
   * name of the vendor
   */
  vendorName: string;
}

export interface CreateDepartmentRequisition {
  createDepartmentRequisition: CreateDepartmentRequisition_createDepartmentRequisition;
}

export interface CreateDepartmentRequisitionVariables {
  departmentId: string;
  requisition: RequisitionInput;
  shouldSendEmail: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMobileV2Requisitions
// ====================================================

export interface GetMobileV2Requisitions_requisitions_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
}

export interface GetMobileV2Requisitions_requisitions_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
  /**
   * Id of damage category
   */
  id: string;
}

export interface GetMobileV2Requisitions_requisitions_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of damage cause
   */
  id: string;
}

export interface GetMobileV2Requisitions_requisitions_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2Requisitions_requisitions_case_damage_contact {
  /**
   * Contact address information
   */
  address: GetMobileV2Requisitions_requisitions_case_damage_contact_address;
}

export interface GetMobileV2Requisitions_requisitions_case_damage {
  /**
   * Damage category
   */
  category: GetMobileV2Requisitions_requisitions_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetMobileV2Requisitions_requisitions_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: GetMobileV2Requisitions_requisitions_case_damage_contact;
}

export interface GetMobileV2Requisitions_requisitions_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetMobileV2Requisitions_requisitions_case_debitor;
  /**
   * Case damage details
   */
  damage: GetMobileV2Requisitions_requisitions_case_damage;
}

export interface GetMobileV2Requisitions_requisitions_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
}

export interface GetMobileV2Requisitions_requisitions_drivingSlip_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetMobileV2Requisitions_requisitions_drivingSlip_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
}

export interface GetMobileV2Requisitions_requisitions_drivingSlip_case_damage {
  /**
   * Damage category
   */
  category: GetMobileV2Requisitions_requisitions_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetMobileV2Requisitions_requisitions_drivingSlip_case_damage_cause;
}

export interface GetMobileV2Requisitions_requisitions_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: GetMobileV2Requisitions_requisitions_drivingSlip_case_damage;
}

export interface GetMobileV2Requisitions_requisitions_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Case which driving slip is related to
   */
  case: GetMobileV2Requisitions_requisitions_drivingSlip_case;
}

export interface GetMobileV2Requisitions_requisitions_vendor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2Requisitions_requisitions_vendor {
  /**
   * ERP reference no for vendor
   */
  erpReferenceNo: string;
  /**
   * The name of the vendor
   */
  company: string | null;
  /**
   * The address of the vendor
   */
  address: GetMobileV2Requisitions_requisitions_vendor_address | null;
  /**
   * The email of the vendor
   */
  email: string | null;
  /**
   * The phone number of the vendor
   */
  phone: string | null;
  /**
   * The posting group of the vendor
   */
  vendorPostingGroup: string;
  /**
   * Attached SSG department of the vendor
   */
  ssgDepartment: string;
  /**
   * Attached SSG location of the vendor
   */
  ssgLocation: string;
  /**
   * Vendor type
   */
  type: string;
}

export interface GetMobileV2Requisitions_requisitions_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface GetMobileV2Requisitions_requisitions {
  /**
   * id of the requisition
   */
  id: string;
  /**
   * case owning the requisition
   */
  case: GetMobileV2Requisitions_requisitions_case | null;
  /**
   * location owning the requisition
   */
  department: GetMobileV2Requisitions_requisitions_department | null;
  /**
   * driving slip owning the requisition
   */
  drivingSlip: GetMobileV2Requisitions_requisitions_drivingSlip | null;
  /**
   * description of the requisition
   */
  description: string;
  /**
   * orderNumber of the requisition
   */
  orderNumber: string;
  /**
   * the type of the requisition
   */
  type: RequisitionType;
  /**
   * Requisition status
   */
  status: boolean;
  /**
   * the vendor of the requisition
   */
  vendor: GetMobileV2Requisitions_requisitions_vendor | null;
  /**
   * Timestamp of when the requisition was created
   */
  createdAt: GQL_DateTime;
  /**
   * User who created the requisition
   */
  createdBy: GetMobileV2Requisitions_requisitions_createdBy;
  /**
   * email of the vendor
   */
  vendorEmail: string | null;
  /**
   * phone number of the vendor
   */
  vendorPhoneNumber: string;
  /**
   * name of the vendor
   */
  vendorName: string;
}

export interface GetMobileV2Requisitions {
  requisitions: GetMobileV2Requisitions_requisitions[];
}

export interface GetMobileV2RequisitionsVariables {
  myRequisitionsOnly?: boolean | null;
  caseId?: string | null;
  offset?: number | null;
  limit?: number | null;
  excludeTotalCount?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRequisitionsOnCase
// ====================================================

export interface GetRequisitionsOnCase_requisitions_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
}

export interface GetRequisitionsOnCase_requisitions_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
  /**
   * Id of damage category
   */
  id: string;
}

export interface GetRequisitionsOnCase_requisitions_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of damage cause
   */
  id: string;
}

export interface GetRequisitionsOnCase_requisitions_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetRequisitionsOnCase_requisitions_case_damage_contact {
  /**
   * Contact address information
   */
  address: GetRequisitionsOnCase_requisitions_case_damage_contact_address;
}

export interface GetRequisitionsOnCase_requisitions_case_damage {
  /**
   * Damage category
   */
  category: GetRequisitionsOnCase_requisitions_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetRequisitionsOnCase_requisitions_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: GetRequisitionsOnCase_requisitions_case_damage_contact;
}

export interface GetRequisitionsOnCase_requisitions_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetRequisitionsOnCase_requisitions_case_debitor;
  /**
   * Case damage details
   */
  damage: GetRequisitionsOnCase_requisitions_case_damage;
}

export interface GetRequisitionsOnCase_requisitions_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
}

export interface GetRequisitionsOnCase_requisitions_drivingSlip_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetRequisitionsOnCase_requisitions_drivingSlip_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
}

export interface GetRequisitionsOnCase_requisitions_drivingSlip_case_damage {
  /**
   * Damage category
   */
  category: GetRequisitionsOnCase_requisitions_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetRequisitionsOnCase_requisitions_drivingSlip_case_damage_cause;
}

export interface GetRequisitionsOnCase_requisitions_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: GetRequisitionsOnCase_requisitions_drivingSlip_case_damage;
}

export interface GetRequisitionsOnCase_requisitions_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Case which driving slip is related to
   */
  case: GetRequisitionsOnCase_requisitions_drivingSlip_case;
}

export interface GetRequisitionsOnCase_requisitions_vendor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetRequisitionsOnCase_requisitions_vendor {
  /**
   * ERP reference no for vendor
   */
  erpReferenceNo: string;
  /**
   * The name of the vendor
   */
  company: string | null;
  /**
   * The address of the vendor
   */
  address: GetRequisitionsOnCase_requisitions_vendor_address | null;
  /**
   * The email of the vendor
   */
  email: string | null;
  /**
   * The phone number of the vendor
   */
  phone: string | null;
  /**
   * The posting group of the vendor
   */
  vendorPostingGroup: string;
  /**
   * Attached SSG department of the vendor
   */
  ssgDepartment: string;
  /**
   * Attached SSG location of the vendor
   */
  ssgLocation: string;
  /**
   * Vendor type
   */
  type: string;
}

export interface GetRequisitionsOnCase_requisitions_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface GetRequisitionsOnCase_requisitions {
  /**
   * id of the requisition
   */
  id: string;
  /**
   * case owning the requisition
   */
  case: GetRequisitionsOnCase_requisitions_case | null;
  /**
   * location owning the requisition
   */
  department: GetRequisitionsOnCase_requisitions_department | null;
  /**
   * driving slip owning the requisition
   */
  drivingSlip: GetRequisitionsOnCase_requisitions_drivingSlip | null;
  /**
   * description of the requisition
   */
  description: string;
  /**
   * orderNumber of the requisition
   */
  orderNumber: string;
  /**
   * the type of the requisition
   */
  type: RequisitionType;
  /**
   * Requisition status
   */
  status: boolean;
  /**
   * the vendor of the requisition
   */
  vendor: GetRequisitionsOnCase_requisitions_vendor | null;
  /**
   * Timestamp of when the requisition was created
   */
  createdAt: GQL_DateTime;
  /**
   * User who created the requisition
   */
  createdBy: GetRequisitionsOnCase_requisitions_createdBy;
  /**
   * email of the vendor
   */
  vendorEmail: string | null;
  /**
   * phone number of the vendor
   */
  vendorPhoneNumber: string;
  /**
   * name of the vendor
   */
  vendorName: string;
}

export interface GetRequisitionsOnCase {
  requisitions: GetRequisitionsOnCase_requisitions[];
}

export interface GetRequisitionsOnCaseVariables {
  caseId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetVendors
// ====================================================

export interface GetVendors_vendors_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetVendors_vendors {
  /**
   * ERP reference no for vendor
   */
  erpReferenceNo: string;
  /**
   * The name of the vendor
   */
  company: string | null;
  /**
   * The address of the vendor
   */
  address: GetVendors_vendors_address | null;
  /**
   * The email of the vendor
   */
  email: string | null;
  /**
   * The phone number of the vendor
   */
  phone: string | null;
  /**
   * The posting group of the vendor
   */
  vendorPostingGroup: string;
  /**
   * Attached SSG department of the vendor
   */
  ssgDepartment: string;
  /**
   * Attached SSG location of the vendor
   */
  ssgLocation: string;
  /**
   * Vendor type
   */
  type: string;
}

export interface GetVendors {
  vendors: GetVendors_vendors[];
}

export interface GetVendorsVariables {
  company?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRiskEvaluationCategories
// ====================================================

export interface GetRiskEvaluationCategories_riskEvaluationCategories {
  /**
   * Id of risk category
   */
  id: string;
  /**
   * Name of risk category
   */
  categoryName: string;
}

export interface GetRiskEvaluationCategories {
  riskEvaluationCategories: GetRiskEvaluationCategories_riskEvaluationCategories[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRiskEvaluationQuestions
// ====================================================

export interface GetRiskEvaluationQuestions_riskEvaluationQuestions_category {
  /**
   * Id of risk category
   */
  id: string;
  /**
   * Name of risk category
   */
  categoryName: string;
}

export interface GetRiskEvaluationQuestions_riskEvaluationQuestions {
  /**
   * Id of question
   */
  id: string;
  /**
   * Id of category question is linked to
   */
  category: GetRiskEvaluationQuestions_riskEvaluationQuestions_category;
  /**
   * Question
   */
  question: string;
  /**
   * Help text for question
   */
  helpText: string;
  /**
   * If a drivingslip is made during casecreation this question will be marked true on risk evaluation
   */
  defaultChecked: boolean;
}

export interface GetRiskEvaluationQuestions {
  riskEvaluationQuestions: GetRiskEvaluationQuestions_riskEvaluationQuestions[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTimeTracking
// ====================================================

export interface CreateTimeTracking_createTimeTracking_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface CreateTimeTracking_createTimeTracking_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface CreateTimeTracking_createTimeTracking_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface CreateTimeTracking_createTimeTracking_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface CreateTimeTracking_createTimeTracking_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface CreateTimeTracking_createTimeTracking_case_damage_contact {
  /**
   * Contact address information
   */
  address: CreateTimeTracking_createTimeTracking_case_damage_contact_address;
}

export interface CreateTimeTracking_createTimeTracking_case_damage {
  /**
   * Damage category
   */
  category: CreateTimeTracking_createTimeTracking_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: CreateTimeTracking_createTimeTracking_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: CreateTimeTracking_createTimeTracking_case_damage_contact;
}

export interface CreateTimeTracking_createTimeTracking_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: CreateTimeTracking_createTimeTracking_case_debitor;
  /**
   * Case damage details
   */
  damage: CreateTimeTracking_createTimeTracking_case_damage;
}

export interface CreateTimeTracking_createTimeTracking_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface CreateTimeTracking_createTimeTracking_addonLines_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface CreateTimeTracking_createTimeTracking_addonLines_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface CreateTimeTracking_createTimeTracking_addonLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: CreateTimeTracking_createTimeTracking_addonLines_case_debitor;
}

export interface CreateTimeTracking_createTimeTracking_addonLines_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface CreateTimeTracking_createTimeTracking_addonLines {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * AD user
   */
  user: CreateTimeTracking_createTimeTracking_addonLines_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: CreateTimeTracking_createTimeTracking_addonLines_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: CreateTimeTracking_createTimeTracking_addonLines_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

export interface CreateTimeTracking_createTimeTracking {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: CreateTimeTracking_createTimeTracking_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Case for time tracking
   */
  case: CreateTimeTracking_createTimeTracking_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: CreateTimeTracking_createTimeTracking_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
  /**
   * List of addon lines
   */
  addonLines: CreateTimeTracking_createTimeTracking_addonLines[] | null;
}

export interface CreateTimeTracking {
  createTimeTracking: CreateTimeTracking_createTimeTracking;
}

export interface CreateTimeTrackingVariables {
  timeTracking: TimeTrackingInputType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteTimeTracking
// ====================================================

export interface DeleteTimeTracking_deleteTimeTracking {
  /**
   * This value is not used for anything.
   */
  _: string;
}

export interface DeleteTimeTracking {
  deleteTimeTracking: DeleteTimeTracking_deleteTimeTracking | null;
}

export interface DeleteTimeTrackingVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetClosedDayByUser
// ====================================================

export interface GetClosedDayByUser_closedDayByUser_user {
  /**
   * Unique id of user
   */
  id: string;
}

export interface GetClosedDayByUser_closedDayByUser {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * AD user
   */
  user: GetClosedDayByUser_closedDayByUser_user;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
}

export interface GetClosedDayByUser {
  closedDayByUser: GetClosedDayByUser_closedDayByUser | null;
}

export interface GetClosedDayByUserVariables {
  user: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLatestDayByUser
// ====================================================

export interface GetLatestDayByUser_latestDayByUser {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
}

export interface GetLatestDayByUser {
  latestDayByUser: GetLatestDayByUser_latestDayByUser | null;
}

export interface GetLatestDayByUserVariables {
  user: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMobileV2CasesForTimeTracking
// ====================================================

export interface GetMobileV2CasesForTimeTracking_cases_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2CasesForTimeTracking_cases_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2CasesForTimeTracking_cases_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: GetMobileV2CasesForTimeTracking_cases_debitor_shippingAddress_address;
}

export interface GetMobileV2CasesForTimeTracking_cases_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2CasesForTimeTracking_cases_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: GetMobileV2CasesForTimeTracking_cases_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: GetMobileV2CasesForTimeTracking_cases_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: GetMobileV2CasesForTimeTracking_cases_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface GetMobileV2CasesForTimeTracking_cases_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetMobileV2CasesForTimeTracking_cases_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetMobileV2CasesForTimeTracking_cases_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface GetMobileV2CasesForTimeTracking_cases_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetMobileV2CasesForTimeTracking_cases_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface GetMobileV2CasesForTimeTracking_cases_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: GetMobileV2CasesForTimeTracking_cases_damage_cause_departments_location;
}

export interface GetMobileV2CasesForTimeTracking_cases_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: GetMobileV2CasesForTimeTracking_cases_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: GetMobileV2CasesForTimeTracking_cases_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (GetMobileV2CasesForTimeTracking_cases_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface GetMobileV2CasesForTimeTracking_cases_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface GetMobileV2CasesForTimeTracking_cases_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: GetMobileV2CasesForTimeTracking_cases_damage_contact_address;
}

export interface GetMobileV2CasesForTimeTracking_cases_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface GetMobileV2CasesForTimeTracking_cases_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: GetMobileV2CasesForTimeTracking_cases_damage_businessArea;
  /**
   * Damage category
   */
  category: GetMobileV2CasesForTimeTracking_cases_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetMobileV2CasesForTimeTracking_cases_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: GetMobileV2CasesForTimeTracking_cases_damage_contact;
  /**
   * contacts for damage
   */
  contacts: GetMobileV2CasesForTimeTracking_cases_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface GetMobileV2CasesForTimeTracking_cases {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * Whether time registration is allowed on the case
   */
  timeRegistration: boolean | null;
  /**
   * Case debitor details
   */
  debitor: GetMobileV2CasesForTimeTracking_cases_debitor;
  /**
   * Case damage details
   */
  damage: GetMobileV2CasesForTimeTracking_cases_damage;
}

export interface GetMobileV2CasesForTimeTracking {
  cases: GetMobileV2CasesForTimeTracking_cases[];
}

export interface GetMobileV2CasesForTimeTrackingVariables {
  erpReferenceNos?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPaymentSupplements
// ====================================================

export interface GetPaymentSupplements_paymentSupplements {
  /**
   * Id of payment supplement
   */
  id: string;
  /**
   * Payment supplement code
   */
  code: string | null;
  /**
   * Payment supplement type
   */
  type: string | null;
  /**
   * Description
   */
  description: string;
  /**
   * Template JobNo indicates if supplement is internal or external
   */
  templateJobNo: string;
  /**
   * Blocked
   */
  blocked: boolean | null;
  /**
   * Unit of measure
   */
  uOM: string;
  /**
   * Work type for the supplement
   */
  workTypeCode: string;
}

export interface GetPaymentSupplements {
  paymentSupplements: (GetPaymentSupplements_paymentSupplements | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRejectedTimeTrackingsForUser
// ====================================================

export interface GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_case_damage_contact {
  /**
   * Contact address information
   */
  address: GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_case_damage_contact_address;
}

export interface GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_case_damage {
  /**
   * Damage category
   */
  category: GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_case_damage_contact;
}

export interface GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_case_debitor;
  /**
   * Case damage details
   */
  damage: GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_case_damage;
}

export interface GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_addonLines_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_addonLines_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_addonLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_addonLines_case_debitor;
}

export interface GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_addonLines_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_addonLines {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * AD user
   */
  user: GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_addonLines_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_addonLines_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_addonLines_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

export interface GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Case for time tracking
   */
  case: GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
  /**
   * List of addon lines
   */
  addonLines: GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser_addonLines[] | null;
}

export interface GetRejectedTimeTrackingsForUser {
  /**
   * Gets all the rejected time trackings for a given user. It looks in the database and in BC to get all rejections
   */
  rejectedTimeTrackingsForUser: GetRejectedTimeTrackingsForUser_rejectedTimeTrackingsForUser[];
}

export interface GetRejectedTimeTrackingsForUserVariables {
  user: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTimeTracking
// ====================================================

export interface GetTimeTracking_timeTracking_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetTimeTracking_timeTracking_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface GetTimeTracking_timeTracking_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetTimeTracking_timeTracking_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface GetTimeTracking_timeTracking_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface GetTimeTracking_timeTracking_case_damage_contact {
  /**
   * Contact address information
   */
  address: GetTimeTracking_timeTracking_case_damage_contact_address;
}

export interface GetTimeTracking_timeTracking_case_damage {
  /**
   * Damage category
   */
  category: GetTimeTracking_timeTracking_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetTimeTracking_timeTracking_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: GetTimeTracking_timeTracking_case_damage_contact;
}

export interface GetTimeTracking_timeTracking_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetTimeTracking_timeTracking_case_debitor;
  /**
   * Case damage details
   */
  damage: GetTimeTracking_timeTracking_case_damage;
}

export interface GetTimeTracking_timeTracking_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface GetTimeTracking_timeTracking_addonLines_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetTimeTracking_timeTracking_addonLines_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface GetTimeTracking_timeTracking_addonLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetTimeTracking_timeTracking_addonLines_case_debitor;
}

export interface GetTimeTracking_timeTracking_addonLines_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface GetTimeTracking_timeTracking_addonLines {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * AD user
   */
  user: GetTimeTracking_timeTracking_addonLines_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: GetTimeTracking_timeTracking_addonLines_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: GetTimeTracking_timeTracking_addonLines_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

export interface GetTimeTracking_timeTracking {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: GetTimeTracking_timeTracking_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Case for time tracking
   */
  case: GetTimeTracking_timeTracking_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: GetTimeTracking_timeTracking_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
  /**
   * List of addon lines
   */
  addonLines: GetTimeTracking_timeTracking_addonLines[] | null;
}

export interface GetTimeTracking {
  timeTracking: GetTimeTracking_timeTracking;
}

export interface GetTimeTrackingVariables {
  id: string;
  minDate?: GQL_Date | null;
  maxDate?: GQL_Date | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTimeTrackingsForUser
// ====================================================

export interface GetTimeTrackingsForUser_timeTrackingsForUser_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetTimeTrackingsForUser_timeTrackingsForUser_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface GetTimeTrackingsForUser_timeTrackingsForUser_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetTimeTrackingsForUser_timeTrackingsForUser_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface GetTimeTrackingsForUser_timeTrackingsForUser_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface GetTimeTrackingsForUser_timeTrackingsForUser_case_damage_contact {
  /**
   * Contact address information
   */
  address: GetTimeTrackingsForUser_timeTrackingsForUser_case_damage_contact_address;
}

export interface GetTimeTrackingsForUser_timeTrackingsForUser_case_damage {
  /**
   * Damage category
   */
  category: GetTimeTrackingsForUser_timeTrackingsForUser_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetTimeTrackingsForUser_timeTrackingsForUser_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: GetTimeTrackingsForUser_timeTrackingsForUser_case_damage_contact;
}

export interface GetTimeTrackingsForUser_timeTrackingsForUser_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetTimeTrackingsForUser_timeTrackingsForUser_case_debitor;
  /**
   * Case damage details
   */
  damage: GetTimeTrackingsForUser_timeTrackingsForUser_case_damage;
}

export interface GetTimeTrackingsForUser_timeTrackingsForUser_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface GetTimeTrackingsForUser_timeTrackingsForUser_addonLines_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetTimeTrackingsForUser_timeTrackingsForUser_addonLines_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface GetTimeTrackingsForUser_timeTrackingsForUser_addonLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetTimeTrackingsForUser_timeTrackingsForUser_addonLines_case_debitor;
}

export interface GetTimeTrackingsForUser_timeTrackingsForUser_addonLines_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface GetTimeTrackingsForUser_timeTrackingsForUser_addonLines {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * AD user
   */
  user: GetTimeTrackingsForUser_timeTrackingsForUser_addonLines_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: GetTimeTrackingsForUser_timeTrackingsForUser_addonLines_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: GetTimeTrackingsForUser_timeTrackingsForUser_addonLines_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

export interface GetTimeTrackingsForUser_timeTrackingsForUser {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: GetTimeTrackingsForUser_timeTrackingsForUser_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Case for time tracking
   */
  case: GetTimeTrackingsForUser_timeTrackingsForUser_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: GetTimeTrackingsForUser_timeTrackingsForUser_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
  /**
   * List of addon lines
   */
  addonLines: GetTimeTrackingsForUser_timeTrackingsForUser_addonLines[] | null;
}

export interface GetTimeTrackingsForUser {
  timeTrackingsForUser: GetTimeTrackingsForUser_timeTrackingsForUser[];
}

export interface GetTimeTrackingsForUserVariables {
  user: string;
  minDate?: GQL_Date | null;
  maxDate?: GQL_Date | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTimeTrackings
// ====================================================

export interface GetTimeTrackings_timeTrackings_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetTimeTrackings_timeTrackings_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface GetTimeTrackings_timeTrackings_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetTimeTrackings_timeTrackings_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface GetTimeTrackings_timeTrackings_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface GetTimeTrackings_timeTrackings_case_damage_contact {
  /**
   * Contact address information
   */
  address: GetTimeTrackings_timeTrackings_case_damage_contact_address;
}

export interface GetTimeTrackings_timeTrackings_case_damage {
  /**
   * Damage category
   */
  category: GetTimeTrackings_timeTrackings_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: GetTimeTrackings_timeTrackings_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: GetTimeTrackings_timeTrackings_case_damage_contact;
}

export interface GetTimeTrackings_timeTrackings_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetTimeTrackings_timeTrackings_case_debitor;
  /**
   * Case damage details
   */
  damage: GetTimeTrackings_timeTrackings_case_damage;
}

export interface GetTimeTrackings_timeTrackings_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface GetTimeTrackings_timeTrackings_addonLines_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface GetTimeTrackings_timeTrackings_addonLines_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface GetTimeTrackings_timeTrackings_addonLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: GetTimeTrackings_timeTrackings_addonLines_case_debitor;
}

export interface GetTimeTrackings_timeTrackings_addonLines_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface GetTimeTrackings_timeTrackings_addonLines {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * AD user
   */
  user: GetTimeTrackings_timeTrackings_addonLines_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: GetTimeTrackings_timeTrackings_addonLines_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: GetTimeTrackings_timeTrackings_addonLines_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

export interface GetTimeTrackings_timeTrackings {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: GetTimeTrackings_timeTrackings_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Case for time tracking
   */
  case: GetTimeTrackings_timeTrackings_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: GetTimeTrackings_timeTrackings_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
  /**
   * List of addon lines
   */
  addonLines: GetTimeTrackings_timeTrackings_addonLines[] | null;
}

export interface GetTimeTrackings {
  timeTrackings: GetTimeTrackings_timeTrackings[];
}

export interface GetTimeTrackingsVariables {
  trackingType?: TrackingType | null;
  minDate?: GQL_Date | null;
  maxDate?: GQL_Date | null;
  users?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubmitDay
// ====================================================

export interface SubmitDay_submitDay_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface SubmitDay_submitDay_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface SubmitDay_submitDay_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface SubmitDay_submitDay_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface SubmitDay_submitDay_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface SubmitDay_submitDay_case_damage_contact {
  /**
   * Contact address information
   */
  address: SubmitDay_submitDay_case_damage_contact_address;
}

export interface SubmitDay_submitDay_case_damage {
  /**
   * Damage category
   */
  category: SubmitDay_submitDay_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: SubmitDay_submitDay_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: SubmitDay_submitDay_case_damage_contact;
}

export interface SubmitDay_submitDay_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: SubmitDay_submitDay_case_debitor;
  /**
   * Case damage details
   */
  damage: SubmitDay_submitDay_case_damage;
}

export interface SubmitDay_submitDay_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface SubmitDay_submitDay_addonLines_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface SubmitDay_submitDay_addonLines_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface SubmitDay_submitDay_addonLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: SubmitDay_submitDay_addonLines_case_debitor;
}

export interface SubmitDay_submitDay_addonLines_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface SubmitDay_submitDay_addonLines {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * AD user
   */
  user: SubmitDay_submitDay_addonLines_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: SubmitDay_submitDay_addonLines_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: SubmitDay_submitDay_addonLines_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

export interface SubmitDay_submitDay {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: SubmitDay_submitDay_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Case for time tracking
   */
  case: SubmitDay_submitDay_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: SubmitDay_submitDay_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
  /**
   * List of addon lines
   */
  addonLines: SubmitDay_submitDay_addonLines[] | null;
}

export interface SubmitDay {
  submitDay: SubmitDay_submitDay | null;
}

export interface SubmitDayVariables {
  timeTracking: TimeTrackingInputType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTimeTracking
// ====================================================

export interface UpdateTimeTracking_updateTimeTracking_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface UpdateTimeTracking_updateTimeTracking_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface UpdateTimeTracking_updateTimeTracking_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface UpdateTimeTracking_updateTimeTracking_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface UpdateTimeTracking_updateTimeTracking_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface UpdateTimeTracking_updateTimeTracking_case_damage_contact {
  /**
   * Contact address information
   */
  address: UpdateTimeTracking_updateTimeTracking_case_damage_contact_address;
}

export interface UpdateTimeTracking_updateTimeTracking_case_damage {
  /**
   * Damage category
   */
  category: UpdateTimeTracking_updateTimeTracking_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: UpdateTimeTracking_updateTimeTracking_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: UpdateTimeTracking_updateTimeTracking_case_damage_contact;
}

export interface UpdateTimeTracking_updateTimeTracking_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: UpdateTimeTracking_updateTimeTracking_case_debitor;
  /**
   * Case damage details
   */
  damage: UpdateTimeTracking_updateTimeTracking_case_damage;
}

export interface UpdateTimeTracking_updateTimeTracking_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface UpdateTimeTracking_updateTimeTracking_addonLines_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface UpdateTimeTracking_updateTimeTracking_addonLines_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface UpdateTimeTracking_updateTimeTracking_addonLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: UpdateTimeTracking_updateTimeTracking_addonLines_case_debitor;
}

export interface UpdateTimeTracking_updateTimeTracking_addonLines_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface UpdateTimeTracking_updateTimeTracking_addonLines {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * AD user
   */
  user: UpdateTimeTracking_updateTimeTracking_addonLines_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: UpdateTimeTracking_updateTimeTracking_addonLines_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: UpdateTimeTracking_updateTimeTracking_addonLines_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

export interface UpdateTimeTracking_updateTimeTracking {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: UpdateTimeTracking_updateTimeTracking_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Case for time tracking
   */
  case: UpdateTimeTracking_updateTimeTracking_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: UpdateTimeTracking_updateTimeTracking_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
  /**
   * List of addon lines
   */
  addonLines: UpdateTimeTracking_updateTimeTracking_addonLines[] | null;
}

export interface UpdateTimeTracking {
  updateTimeTracking: UpdateTimeTracking_updateTimeTracking;
}

export interface UpdateTimeTrackingVariables {
  id: string;
  user: string;
  date: GQL_Date;
  hours: number;
  case: string;
  drivingSlip: string;
  remark: string;
  jobTaskNo: string;
  jobTaskName?: string | null;
  workTypeCode: string;
  timeTrackingStatus: TimeTrackingStatus;
  pieceworkAmount?: number | null;
  paymentSupplementCode?: string | null;
  paymentSupplementName?: string | null;
  supplementType?: string | null;
  supplementUom?: string | null;
  addonLines?: TimeTrackingInputType[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserBasisTime
// ====================================================

export interface UserBasisTime {
  userBasisTime: number;
}

export interface UserBasisTimeVariables {
  user: string;
  date: GQL_Date;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMobileV2CurrentUser
// ====================================================

export interface GetMobileV2CurrentUser_currentUser {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
  /**
   * permissions of user
   */
  permissions: Permissions[];
  /**
   * Employee hire start date
   */
  employeeHireStartDate: GQL_DateTime | null;
  /**
   * Employee salary type
   */
  employeeSalaryType: string | null;
}

export interface GetMobileV2CurrentUser {
  currentUser: GetMobileV2CurrentUser_currentUser | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SearchMobileV2Users
// ====================================================

export interface SearchMobileV2Users_searchUsers {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
  /**
   * Employee hire start date
   */
  employeeHireStartDate: GQL_DateTime | null;
  /**
   * Employee salary type
   */
  employeeSalaryType: string | null;
}

export interface SearchMobileV2Users {
  searchUsers: SearchMobileV2Users_searchUsers[];
}

export interface SearchMobileV2UsersVariables {
  searchText: string;
  onlyEmployees?: boolean | null;
  checkInBc?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetQuestions
// ====================================================

export interface GetQuestions_questions_damageCause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface GetQuestions_questions_damageCategory {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface GetQuestions_questions {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question to ask based on damage cause
   */
  question: string;
  /**
   * Which damage cause the question is based on
   */
  damageCause: GetQuestions_questions_damageCause;
  /**
   * Which damage category the question is based on
   */
  damageCategory: GetQuestions_questions_damageCategory;
}

export interface GetQuestions {
  questions: GetQuestions_questions[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AddressFragment
// ====================================================

export interface AddressFragment {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseAPVFragment
// ====================================================

export interface BaseAPVFragment_damageCategory {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface BaseAPVFragment_damageCause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface BaseAPVFragment {
  /**
   * Unique id of apv
   */
  id: string;
  /**
   * Url to file location
   */
  url: string;
  /**
   * Id of associated category
   */
  damageCategory: BaseAPVFragment_damageCategory;
  /**
   * Id of associated cause
   */
  damageCause: BaseAPVFragment_damageCause;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseBusinessAreaFragment
// ====================================================

export interface BaseBusinessAreaFragment {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseCarFragment
// ====================================================

export interface BaseCarFragment {
  /**
   * Unique id of car
   */
  id: string;
  /**
   * Name of car
   */
  name: string;
  /**
   * Department car is attached to
   */
  department: string | null;
  /**
   * Email of car
   */
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseCaseFragment
// ====================================================

export interface BaseCaseFragment_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseCaseFragment_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseCaseFragment_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: BaseCaseFragment_debitor_shippingAddress_address;
}

export interface BaseCaseFragment_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseCaseFragment_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: BaseCaseFragment_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: BaseCaseFragment_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: BaseCaseFragment_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface BaseCaseFragment_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface BaseCaseFragment_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface BaseCaseFragment_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface BaseCaseFragment_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface BaseCaseFragment_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface BaseCaseFragment_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: BaseCaseFragment_damage_cause_departments_location;
}

export interface BaseCaseFragment_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: BaseCaseFragment_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: BaseCaseFragment_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (BaseCaseFragment_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface BaseCaseFragment_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseCaseFragment_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: BaseCaseFragment_damage_contact_address;
}

export interface BaseCaseFragment_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface BaseCaseFragment_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: BaseCaseFragment_damage_businessArea;
  /**
   * Damage category
   */
  category: BaseCaseFragment_damage_category;
  /**
   * Cause behind damage
   */
  cause: BaseCaseFragment_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: BaseCaseFragment_damage_contact;
  /**
   * contacts for damage
   */
  contacts: BaseCaseFragment_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface BaseCaseFragment_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseCaseFragment_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: BaseCaseFragment_policyHolder_address;
}

export interface BaseCaseFragment_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface BaseCaseFragment_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface BaseCaseFragment_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
  /**
   * The question
   */
  title: string;
}

export interface BaseCaseFragment_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: BaseCaseFragment_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: BaseCaseFragment_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: BaseCaseFragment_visitation_answers[] | null;
  /**
   * If case needs a moisture report
   */
  isMoistureReportRequired: boolean | null;
  /**
   * If case needs an SP phase one report
   */
  isSpPhaseOneReportCreated: boolean | null;
}

export interface BaseCaseFragment_requisitioner {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Requisitioner relation
   */
  relation: string;
}

export interface BaseCaseFragment_adviser {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface BaseCaseFragment_referrer {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface BaseCaseFragment_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseCaseFragment_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseCaseFragment_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseCaseFragment_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: BaseCaseFragment_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface BaseCaseFragment_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseCaseFragment_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: BaseCaseFragment_ssgDepartment_location_address;
}

export interface BaseCaseFragment_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: BaseCaseFragment_ssgDepartment_location;
}

export interface BaseCaseFragment_caseAgreements_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseCaseFragment_caseAgreements {
  /**
   * Agreement content
   */
  content: string;
  /**
   * Agreement category
   */
  category: CaseAgreementCategory;
  /**
   * User who created agreement
   */
  user: BaseCaseFragment_caseAgreements_user;
  /**
   * Timestamp of when agreement was created
   */
  timestamp: GQL_DateTime;
  /**
   * Whether case agreement should be sent to ScalePoint
   */
  shouldSaveInScalePoint: boolean;
}

export interface BaseCaseFragment_caseEmails {
  /**
   * Email content
   */
  content: string;
  /**
   * List of email attachments
   */
  attachments: string[];
  /**
   * List of email receivers
   */
  receivers: string[];
  /**
   * Timestamp of when email was created
   */
  timestamp: GQL_DateTime;
}

export interface BaseCaseFragment_userFavorite {
  /**
   * Unique user id
   */
  user: string;
  /**
   * Color code set by user
   */
  color: string | null;
  /**
   * Favorite active status
   */
  active: boolean;
}

export interface BaseCaseFragment_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseCaseFragment_jobs_assignedTo {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface BaseCaseFragment_jobs_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface BaseCaseFragment_jobs_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
}

export interface BaseCaseFragment_jobs_movable_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface BaseCaseFragment_jobs_movable {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: BaseCaseFragment_jobs_movable_placement;
}

export interface BaseCaseFragment_jobs {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Type of case task
   */
  type: JobType;
  /**
   * Description of the case task
   */
  description: string;
  /**
   * Deadline of the case task
   */
  deadline: GQL_DateTime;
  /**
   * The user responsible for the task
   */
  assignedTo: BaseCaseFragment_jobs_assignedTo | null;
  /**
   * The location responsible to the task
   */
  location: BaseCaseFragment_jobs_location | null;
  /**
   * The case the task belongs to
   */
  case: BaseCaseFragment_jobs_case | null;
  /**
   * The movable responsible to the task
   */
  movable: BaseCaseFragment_jobs_movable | null;
  /**
   * Whether the task is completed
   */
  completed: boolean;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: BaseCaseFragment_drivingSlipSeries_drivingSlips_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: BaseCaseFragment_drivingSlipSeries_drivingSlips_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: BaseCaseFragment_drivingSlipSeries_drivingSlips_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_user;
  /**
   * Value before
   */
  before: BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_before;
  /**
   * Value after
   */
  after: BaseCaseFragment_drivingSlipSeries_drivingSlips_changes_after;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface BaseCaseFragment_drivingSlipSeries_drivingSlips {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: BaseCaseFragment_drivingSlipSeries_drivingSlips_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: BaseCaseFragment_drivingSlipSeries_drivingSlips_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: BaseCaseFragment_drivingSlipSeries_drivingSlips_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: BaseCaseFragment_drivingSlipSeries_drivingSlips_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: BaseCaseFragment_drivingSlipSeries_drivingSlips_changes[];
  /**
   * Location of driving slip
   */
  location: BaseCaseFragment_drivingSlipSeries_drivingSlips_location;
  /**
   * Department of driving slip
   */
  department: BaseCaseFragment_drivingSlipSeries_drivingSlips_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
}

export interface BaseCaseFragment_drivingSlipSeries {
  /**
   * Unique id of driving slip series
   */
  id: string;
  /**
   * Driving slip series completed status
   */
  completed: boolean;
  /**
   * Driving slips on series
   */
  drivingSlips: BaseCaseFragment_drivingSlipSeries_drivingSlips[];
}

export interface BaseCaseFragment_alternativeContact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Comment on person
   */
  comment: string | null;
}

export interface BaseCaseFragment_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface BaseCaseFragment_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: BaseCaseFragment_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface BaseCaseFragment_requisitions_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
}

export interface BaseCaseFragment_requisitions_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
  /**
   * Id of damage category
   */
  id: string;
}

export interface BaseCaseFragment_requisitions_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of damage cause
   */
  id: string;
}

export interface BaseCaseFragment_requisitions_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseCaseFragment_requisitions_case_damage_contact {
  /**
   * Contact address information
   */
  address: BaseCaseFragment_requisitions_case_damage_contact_address;
}

export interface BaseCaseFragment_requisitions_case_damage {
  /**
   * Damage category
   */
  category: BaseCaseFragment_requisitions_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: BaseCaseFragment_requisitions_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: BaseCaseFragment_requisitions_case_damage_contact;
}

export interface BaseCaseFragment_requisitions_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: BaseCaseFragment_requisitions_case_debitor;
  /**
   * Case damage details
   */
  damage: BaseCaseFragment_requisitions_case_damage;
}

export interface BaseCaseFragment_requisitions_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
}

export interface BaseCaseFragment_requisitions_drivingSlip_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
}

export interface BaseCaseFragment_requisitions_drivingSlip_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
}

export interface BaseCaseFragment_requisitions_drivingSlip_case_damage {
  /**
   * Damage category
   */
  category: BaseCaseFragment_requisitions_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: BaseCaseFragment_requisitions_drivingSlip_case_damage_cause;
}

export interface BaseCaseFragment_requisitions_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: BaseCaseFragment_requisitions_drivingSlip_case_damage;
}

export interface BaseCaseFragment_requisitions_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Case which driving slip is related to
   */
  case: BaseCaseFragment_requisitions_drivingSlip_case;
}

export interface BaseCaseFragment_requisitions_vendor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseCaseFragment_requisitions_vendor {
  /**
   * ERP reference no for vendor
   */
  erpReferenceNo: string;
  /**
   * The name of the vendor
   */
  company: string | null;
  /**
   * The address of the vendor
   */
  address: BaseCaseFragment_requisitions_vendor_address | null;
  /**
   * The email of the vendor
   */
  email: string | null;
  /**
   * The phone number of the vendor
   */
  phone: string | null;
  /**
   * The posting group of the vendor
   */
  vendorPostingGroup: string;
  /**
   * Attached SSG department of the vendor
   */
  ssgDepartment: string;
  /**
   * Attached SSG location of the vendor
   */
  ssgLocation: string;
  /**
   * Vendor type
   */
  type: string;
}

export interface BaseCaseFragment_requisitions_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface BaseCaseFragment_requisitions {
  /**
   * id of the requisition
   */
  id: string;
  /**
   * case owning the requisition
   */
  case: BaseCaseFragment_requisitions_case | null;
  /**
   * location owning the requisition
   */
  department: BaseCaseFragment_requisitions_department | null;
  /**
   * driving slip owning the requisition
   */
  drivingSlip: BaseCaseFragment_requisitions_drivingSlip | null;
  /**
   * description of the requisition
   */
  description: string;
  /**
   * orderNumber of the requisition
   */
  orderNumber: string;
  /**
   * the type of the requisition
   */
  type: RequisitionType;
  /**
   * Requisition status
   */
  status: boolean;
  /**
   * the vendor of the requisition
   */
  vendor: BaseCaseFragment_requisitions_vendor | null;
  /**
   * Timestamp of when the requisition was created
   */
  createdAt: GQL_DateTime;
  /**
   * User who created the requisition
   */
  createdBy: BaseCaseFragment_requisitions_createdBy;
  /**
   * email of the vendor
   */
  vendorEmail: string | null;
  /**
   * phone number of the vendor
   */
  vendorPhoneNumber: string;
  /**
   * name of the vendor
   */
  vendorName: string;
}

export interface BaseCaseFragment {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * ReportSent
   */
  reportSent: boolean;
  /**
   * Distance from ssg location to damage address
   */
  distance: string | null;
  /**
   * Whether notifications regarding machines on the case should be sent
   */
  machineNotification: boolean | null;
  /**
   * Whether time registration is allowed on the case
   */
  timeRegistration: boolean | null;
  /**
   * Case debitor details
   */
  debitor: BaseCaseFragment_debitor;
  /**
   * Case damage details
   */
  damage: BaseCaseFragment_damage;
  /**
   * Case policy holder details
   */
  policyHolder: BaseCaseFragment_policyHolder;
  /**
   * Case visitation details
   */
  visitation: BaseCaseFragment_visitation;
  /**
   * Case requisitioner details
   */
  requisitioner: BaseCaseFragment_requisitioner;
  /**
   * Case advisor details
   */
  adviser: BaseCaseFragment_adviser | null;
  /**
   * Case referer details
   */
  referrer: BaseCaseFragment_referrer | null;
  /**
   * Project manager attached to case
   */
  projectManager: BaseCaseFragment_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: BaseCaseFragment_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: BaseCaseFragment_ssgLocation;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: BaseCaseFragment_ssgDepartment;
  /**
   * Case ready for billing
   */
  billingReady: boolean;
  /**
   * Case work complete
   */
  workComplete: boolean;
  /**
   * List of case agreements
   */
  caseAgreements: BaseCaseFragment_caseAgreements[];
  /**
   * List of case emails
   */
  caseEmails: BaseCaseFragment_caseEmails[];
  /**
   * List of user favorite entries
   */
  userFavorite: BaseCaseFragment_userFavorite | null;
  /**
   * User who created the case
   */
  createdBy: BaseCaseFragment_createdBy | null;
  /**
   * Timestamp of when the case was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the case was created
   */
  createdAt: GQL_DateTime;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * The tasks associated with the task
   */
  jobs: BaseCaseFragment_jobs[] | null;
  /**
   * The driving slips series associated with the case
   */
  drivingSlipSeries: BaseCaseFragment_drivingSlipSeries[];
  /**
   * Important info on the case
   */
  importantInfo: string | null;
  /**
   * Alternative contact
   */
  alternativeContact: BaseCaseFragment_alternativeContact | null;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: BaseCaseFragment_riskEvaluationAnswers[] | null;
  /**
   * Optional comment for risk evaluation
   */
  riskEvaluationComment: string | null;
  /**
   * Requisition of the case
   */
  requisitions: BaseCaseFragment_requisitions[];
  scalePointStatus: ScalePointStatus;
  scalePointErrorMessage: string | null;
  arrived: boolean;
  arrivedDate: GQL_DateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseCaseNoDrivingSlipsFragment
// ====================================================

export interface BaseCaseNoDrivingSlipsFragment_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseCaseNoDrivingSlipsFragment_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseCaseNoDrivingSlipsFragment_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: BaseCaseNoDrivingSlipsFragment_debitor_shippingAddress_address;
}

export interface BaseCaseNoDrivingSlipsFragment_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseCaseNoDrivingSlipsFragment_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: BaseCaseNoDrivingSlipsFragment_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: BaseCaseNoDrivingSlipsFragment_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: BaseCaseNoDrivingSlipsFragment_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface BaseCaseNoDrivingSlipsFragment_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface BaseCaseNoDrivingSlipsFragment_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface BaseCaseNoDrivingSlipsFragment_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface BaseCaseNoDrivingSlipsFragment_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface BaseCaseNoDrivingSlipsFragment_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface BaseCaseNoDrivingSlipsFragment_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: BaseCaseNoDrivingSlipsFragment_damage_cause_departments_location;
}

export interface BaseCaseNoDrivingSlipsFragment_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: BaseCaseNoDrivingSlipsFragment_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: BaseCaseNoDrivingSlipsFragment_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (BaseCaseNoDrivingSlipsFragment_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface BaseCaseNoDrivingSlipsFragment_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseCaseNoDrivingSlipsFragment_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: BaseCaseNoDrivingSlipsFragment_damage_contact_address;
}

export interface BaseCaseNoDrivingSlipsFragment_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface BaseCaseNoDrivingSlipsFragment_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: BaseCaseNoDrivingSlipsFragment_damage_businessArea;
  /**
   * Damage category
   */
  category: BaseCaseNoDrivingSlipsFragment_damage_category;
  /**
   * Cause behind damage
   */
  cause: BaseCaseNoDrivingSlipsFragment_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: BaseCaseNoDrivingSlipsFragment_damage_contact;
  /**
   * contacts for damage
   */
  contacts: BaseCaseNoDrivingSlipsFragment_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface BaseCaseNoDrivingSlipsFragment_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface BaseCaseNoDrivingSlipsFragment_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: BaseCaseNoDrivingSlipsFragment_visitation_calledBack;
}

export interface BaseCaseNoDrivingSlipsFragment_requisitioner {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Requisitioner relation
   */
  relation: string;
}

export interface BaseCaseNoDrivingSlipsFragment_adviser {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface BaseCaseNoDrivingSlipsFragment_referrer {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Name of company
   */
  companyName: string | null;
  /**
   * Main phone number of company
   */
  companyPhone: string | null;
}

export interface BaseCaseNoDrivingSlipsFragment_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseCaseNoDrivingSlipsFragment_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseCaseNoDrivingSlipsFragment_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseCaseNoDrivingSlipsFragment_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: BaseCaseNoDrivingSlipsFragment_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface BaseCaseNoDrivingSlipsFragment_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseCaseNoDrivingSlipsFragment_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: BaseCaseNoDrivingSlipsFragment_ssgDepartment_location_address;
}

export interface BaseCaseNoDrivingSlipsFragment_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: BaseCaseNoDrivingSlipsFragment_ssgDepartment_location;
}

export interface BaseCaseNoDrivingSlipsFragment_caseAgreements_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseCaseNoDrivingSlipsFragment_caseAgreements {
  /**
   * Agreement content
   */
  content: string;
  /**
   * Agreement category
   */
  category: CaseAgreementCategory;
  /**
   * User who created agreement
   */
  user: BaseCaseNoDrivingSlipsFragment_caseAgreements_user;
  /**
   * Timestamp of when agreement was created
   */
  timestamp: GQL_DateTime;
  /**
   * Whether case agreement should be sent to ScalePoint
   */
  shouldSaveInScalePoint: boolean;
}

export interface BaseCaseNoDrivingSlipsFragment_caseEmails {
  /**
   * Email content
   */
  content: string;
  /**
   * List of email attachments
   */
  attachments: string[];
  /**
   * List of email receivers
   */
  receivers: string[];
  /**
   * Timestamp of when email was created
   */
  timestamp: GQL_DateTime;
}

export interface BaseCaseNoDrivingSlipsFragment_caseMachineUsages {
  /**
   * Machine identifier
   */
  machine: string;
  /**
   * Start time
   */
  startTime: GQL_DateTime;
  /**
   * End time
   */
  endTime: GQL_DateTime | null;
  /**
   * Meter reading at start time
   */
  startMeterReading: GQL_Decimal;
  /**
   * Meter reading at end time
   */
  endMeterReading: GQL_Decimal | null;
}

export interface BaseCaseNoDrivingSlipsFragment_userFavorite {
  /**
   * Unique user id
   */
  user: string;
  /**
   * Color code set by user
   */
  color: string | null;
  /**
   * Favorite active status
   */
  active: boolean;
}

export interface BaseCaseNoDrivingSlipsFragment_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseCaseNoDrivingSlipsFragment_jobs_assignedTo {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface BaseCaseNoDrivingSlipsFragment_jobs_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface BaseCaseNoDrivingSlipsFragment_jobs_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
}

export interface BaseCaseNoDrivingSlipsFragment_jobs_movable_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface BaseCaseNoDrivingSlipsFragment_jobs_movable {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: BaseCaseNoDrivingSlipsFragment_jobs_movable_placement;
}

export interface BaseCaseNoDrivingSlipsFragment_jobs {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Type of case task
   */
  type: JobType;
  /**
   * Description of the case task
   */
  description: string;
  /**
   * Deadline of the case task
   */
  deadline: GQL_DateTime;
  /**
   * The user responsible for the task
   */
  assignedTo: BaseCaseNoDrivingSlipsFragment_jobs_assignedTo | null;
  /**
   * The location responsible to the task
   */
  location: BaseCaseNoDrivingSlipsFragment_jobs_location | null;
  /**
   * The case the task belongs to
   */
  case: BaseCaseNoDrivingSlipsFragment_jobs_case | null;
  /**
   * The movable responsible to the task
   */
  movable: BaseCaseNoDrivingSlipsFragment_jobs_movable | null;
  /**
   * Whether the task is completed
   */
  completed: boolean;
}

export interface BaseCaseNoDrivingSlipsFragment_policyHolder {
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
}

export interface BaseCaseNoDrivingSlipsFragment_alternativeContact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Comment on person
   */
  comment: string | null;
}

export interface BaseCaseNoDrivingSlipsFragment {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Case status
   */
  status: CaseStatus;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case track
   */
  track: number;
  /**
   * Case debitor details
   */
  debitor: BaseCaseNoDrivingSlipsFragment_debitor;
  /**
   * Case damage details
   */
  damage: BaseCaseNoDrivingSlipsFragment_damage;
  /**
   * Case visitation details
   */
  visitation: BaseCaseNoDrivingSlipsFragment_visitation;
  /**
   * Case requisitioner details
   */
  requisitioner: BaseCaseNoDrivingSlipsFragment_requisitioner;
  /**
   * Case advisor details
   */
  adviser: BaseCaseNoDrivingSlipsFragment_adviser | null;
  /**
   * Case referer details
   */
  referrer: BaseCaseNoDrivingSlipsFragment_referrer | null;
  /**
   * Project manager attached to case
   */
  projectManager: BaseCaseNoDrivingSlipsFragment_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: BaseCaseNoDrivingSlipsFragment_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: BaseCaseNoDrivingSlipsFragment_ssgLocation;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: BaseCaseNoDrivingSlipsFragment_ssgDepartment;
  /**
   * Case ready for billing
   */
  billingReady: boolean;
  /**
   * Case work complete
   */
  workComplete: boolean;
  /**
   * List of case agreements
   */
  caseAgreements: BaseCaseNoDrivingSlipsFragment_caseAgreements[];
  /**
   * List of case emails
   */
  caseEmails: BaseCaseNoDrivingSlipsFragment_caseEmails[];
  /**
   * List of case machine usages
   */
  caseMachineUsages: BaseCaseNoDrivingSlipsFragment_caseMachineUsages[];
  /**
   * List of user favorite entries
   */
  userFavorite: BaseCaseNoDrivingSlipsFragment_userFavorite | null;
  /**
   * User who created the case
   */
  createdBy: BaseCaseNoDrivingSlipsFragment_createdBy | null;
  /**
   * Timestamp of when the case was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the case was created
   */
  createdAt: GQL_DateTime;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * The tasks associated with the task
   */
  jobs: BaseCaseNoDrivingSlipsFragment_jobs[] | null;
  /**
   * Case policy holder details
   */
  policyHolder: BaseCaseNoDrivingSlipsFragment_policyHolder;
  /**
   * Important info on the case
   */
  importantInfo: string | null;
  /**
   * Alternative contact
   */
  alternativeContact: BaseCaseNoDrivingSlipsFragment_alternativeContact | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseCatalogAddressFragment
// ====================================================

export interface BaseCatalogAddressFragment {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseCatalogContactFragment
// ====================================================

export interface BaseCatalogContactFragment_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface BaseCatalogContactFragment_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface BaseCatalogContactFragment {
  /**
   * Id for catalog contact
   */
  id: string;
  /**
   * Customer
   */
  customer: BaseCatalogContactFragment_customer;
  /**
   * Contact changed by
   */
  changedBy: string;
  /**
   * Contact name
   */
  contactName: string;
  /**
   * Contact type
   */
  type: string | null;
  /**
   * Contact collection type
   */
  contactType: CatalogContacts;
  /**
   * Contact email
   */
  email: string | null;
  /**
   * Informations for Contacts
   */
  informations: (BaseCatalogContactFragment_informations | null)[];
  /**
   * Is contact global
   */
  global: boolean;
  /**
   * Global type for contact
   */
  globalType: CatalogGlobalType;
  /**
   * Is contact active
   */
  active: boolean;
  /**
   * Catalog ids
   */
  catalogIds: string[];
  /**
   * Version for catalog contact
   */
  version: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseCatalogCraftsmanFragment
// ====================================================

export interface BaseCatalogCraftsmanFragment_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface BaseCatalogCraftsmanFragment_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface BaseCatalogCraftsmanFragment {
  /**
   * Id for catalog call contact
   */
  id: string;
  /**
   * Customer
   */
  customer: BaseCatalogCraftsmanFragment_customer;
  /**
   * Craftsman changed by
   */
  changedBy: string;
  /**
   * Craftsman type
   */
  type: string;
  /**
   * Craftsman name
   */
  contactName: string;
  /**
   * Informations for Craftsman
   */
  informations: BaseCatalogCraftsmanFragment_informations[];
  /**
   * Craftsman email
   */
  email: string | null;
  /**
   * Craftsman priority
   */
  priority: string;
  /**
   * Is contact global
   */
  global: boolean;
  /**
   * Global type for craftsman
   */
  globalType: CatalogGlobalType;
  /**
   * Is contact active
   */
  active: boolean;
  /**
   * Version for catalog craftsman
   */
  version: number;
  /**
   * Catalog ids
   */
  catalogIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseCatalogCustomerFragment
// ====================================================

export interface BaseCatalogCustomerFragment_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface BaseCatalogCustomerFragment_users {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface BaseCatalogCustomerFragment {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
  /**
   * AD user
   */
  user: BaseCatalogCustomerFragment_user | null;
  /**
   * AD users connected to the customer
   */
  users: BaseCatalogCustomerFragment_users[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseCatalogFragment
// ====================================================

export interface BaseCatalogFragment_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface BaseCatalogFragment_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface BaseCatalogFragment_debitor {
  /**
   * Company identifier
   */
  company: string;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Sales Perseon Code
   */
  salesPersonCode: string;
  /**
   * Sales Perseon Name
   */
  salesPersonName: string;
}

export interface BaseCatalogFragment {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Contact number for the Catalog
   */
  contactNumber: string | null;
  /**
   * Hours contact number can be reached
   */
  contactHours: string | null;
  /**
   * Customer
   */
  customer: BaseCatalogFragment_customer;
  /**
   * Catalog changed by
   */
  changedBy: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: BaseCatalogFragment_addresses[];
  /**
   * Is sms service used for Catalog
   */
  smsService: boolean;
  /**
   * Catalog debitor details
   */
  debitor: BaseCatalogFragment_debitor | null;
  /**
   * Is catalog verified
   */
  verified: boolean;
  /**
   * Is catalog debitor verified
   */
  debitorVerified: boolean;
  /**
   * Version for catalog
   */
  version: number;
  /**
   * Timestamp of when the catalog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the catalog was created
   */
  createdAt: GQL_DateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseCatalogFragmentChanges
// ====================================================

export interface BaseCatalogFragmentChanges_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface BaseCatalogFragmentChanges_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface BaseCatalogFragmentChanges_debitor {
  /**
   * Company identifier
   */
  company: string;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
}

export interface BaseCatalogFragmentChanges_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseCatalogFragmentChanges_changes_before_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface BaseCatalogFragmentChanges_changes_before_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface BaseCatalogFragmentChanges_changes_before_contacts_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface BaseCatalogFragmentChanges_changes_before_contacts_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface BaseCatalogFragmentChanges_changes_before_contacts_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface BaseCatalogFragmentChanges_changes_before_contacts_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: BaseCatalogFragmentChanges_changes_before_contacts_catalogs_addresses[];
}

export interface BaseCatalogFragmentChanges_changes_before_contacts {
  customer: BaseCatalogFragmentChanges_changes_before_contacts_customer | null;
  changedBy: string | null;
  /**
   * Contact name
   */
  contactName: string | null;
  type: string | null;
  contactType: CatalogContacts | null;
  /**
   * Contact email
   */
  email: string | null;
  /**
   * Informations for Contacts
   */
  informations: BaseCatalogFragmentChanges_changes_before_contacts_informations[] | null;
  /**
   * Is contact global
   */
  global: boolean | null;
  /**
   * Global type for contact
   */
  globalType: CatalogGlobalType | null;
  /**
   * Is contact active
   */
  active: boolean | null;
  catalogs: BaseCatalogFragmentChanges_changes_before_contacts_catalogs[] | null;
}

export interface BaseCatalogFragmentChanges_changes_before_craftsmen_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface BaseCatalogFragmentChanges_changes_before_craftsmen_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface BaseCatalogFragmentChanges_changes_before_craftsmen_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface BaseCatalogFragmentChanges_changes_before_craftsmen_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: BaseCatalogFragmentChanges_changes_before_craftsmen_catalogs_addresses[];
}

export interface BaseCatalogFragmentChanges_changes_before_craftsmen {
  customer: BaseCatalogFragmentChanges_changes_before_craftsmen_customer | null;
  changedBy: string | null;
  /**
   * Type for call contact
   */
  type: string | null;
  /**
   * Call contact name
   */
  contactName: string | null;
  /**
   * Informations for Craftsman
   */
  informations: BaseCatalogFragmentChanges_changes_before_craftsmen_informations[] | null;
  /**
   * Call contact email
   */
  email: string | null;
  /**
   * Priority for call contact
   */
  priority: string | null;
  /**
   * Is call contact global
   */
  global: boolean | null;
  /**
   * Global type for craftsman
   */
  globalType: CatalogGlobalType | null;
  /**
   * Is call contact active
   */
  active: boolean | null;
  catalogs: BaseCatalogFragmentChanges_changes_before_craftsmen_catalogs[] | null;
}

export interface BaseCatalogFragmentChanges_changes_before_timedMessages_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface BaseCatalogFragmentChanges_changes_before_timedMessages_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface BaseCatalogFragmentChanges_changes_before_timedMessages_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: BaseCatalogFragmentChanges_changes_before_timedMessages_catalogs_addresses[];
}

export interface BaseCatalogFragmentChanges_changes_before_timedMessages {
  customer: BaseCatalogFragmentChanges_changes_before_timedMessages_customer | null;
  changedBy: string | null;
  startTime: GQL_DateTime | null;
  endTime: GQL_DateTime | null;
  message: string | null;
  global: boolean | null;
  globalType: CatalogGlobalType | null;
  active: boolean | null;
  catalogs: BaseCatalogFragmentChanges_changes_before_timedMessages_catalogs[] | null;
}

export interface BaseCatalogFragmentChanges_changes_before_debitor {
  /**
   * Company identifier
   */
  company: string;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
}

export interface BaseCatalogFragmentChanges_changes_before {
  ssgAccountable: string | null;
  propertyNumber: string | null;
  customer: BaseCatalogFragmentChanges_changes_before_customer | null;
  changedBy: string | null;
  description: string | null;
  contactNumber: string | null;
  contactHours: string | null;
  addresses: BaseCatalogFragmentChanges_changes_before_addresses[] | null;
  contacts: BaseCatalogFragmentChanges_changes_before_contacts | null;
  craftsmen: BaseCatalogFragmentChanges_changes_before_craftsmen | null;
  timedMessages: BaseCatalogFragmentChanges_changes_before_timedMessages | null;
  filenames: string | null;
  smsService: boolean | null;
  /**
   * Is catalog verified
   */
  verified: boolean;
  /**
   * Is catalog debitor verified
   */
  debitorVerified: boolean;
  /**
   * Catalog debitor details
   */
  debitor: BaseCatalogFragmentChanges_changes_before_debitor | null;
}

export interface BaseCatalogFragmentChanges_changes_after_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface BaseCatalogFragmentChanges_changes_after_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface BaseCatalogFragmentChanges_changes_after_contacts_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface BaseCatalogFragmentChanges_changes_after_contacts_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface BaseCatalogFragmentChanges_changes_after_contacts_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface BaseCatalogFragmentChanges_changes_after_contacts_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: BaseCatalogFragmentChanges_changes_after_contacts_catalogs_addresses[];
}

export interface BaseCatalogFragmentChanges_changes_after_contacts {
  customer: BaseCatalogFragmentChanges_changes_after_contacts_customer | null;
  changedBy: string | null;
  /**
   * Contact name
   */
  contactName: string | null;
  type: string | null;
  contactType: CatalogContacts | null;
  /**
   * Contact email
   */
  email: string | null;
  /**
   * Informations for Contacts
   */
  informations: BaseCatalogFragmentChanges_changes_after_contacts_informations[] | null;
  /**
   * Is contact global
   */
  global: boolean | null;
  /**
   * Global type for contact
   */
  globalType: CatalogGlobalType | null;
  /**
   * Is contact active
   */
  active: boolean | null;
  catalogs: BaseCatalogFragmentChanges_changes_after_contacts_catalogs[] | null;
}

export interface BaseCatalogFragmentChanges_changes_after_craftsmen_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface BaseCatalogFragmentChanges_changes_after_craftsmen_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface BaseCatalogFragmentChanges_changes_after_craftsmen_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface BaseCatalogFragmentChanges_changes_after_craftsmen_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: BaseCatalogFragmentChanges_changes_after_craftsmen_catalogs_addresses[];
}

export interface BaseCatalogFragmentChanges_changes_after_craftsmen {
  customer: BaseCatalogFragmentChanges_changes_after_craftsmen_customer | null;
  changedBy: string | null;
  /**
   * Type for call contact
   */
  type: string | null;
  /**
   * Call contact name
   */
  contactName: string | null;
  /**
   * Informations for Craftsman
   */
  informations: BaseCatalogFragmentChanges_changes_after_craftsmen_informations[] | null;
  /**
   * Call contact email
   */
  email: string | null;
  /**
   * Priority for call contact
   */
  priority: string | null;
  /**
   * Is call contact global
   */
  global: boolean | null;
  /**
   * Global type for craftsman
   */
  globalType: CatalogGlobalType | null;
  /**
   * Is call contact active
   */
  active: boolean | null;
  catalogs: BaseCatalogFragmentChanges_changes_after_craftsmen_catalogs[] | null;
}

export interface BaseCatalogFragmentChanges_changes_after_timedMessages_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface BaseCatalogFragmentChanges_changes_after_timedMessages_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface BaseCatalogFragmentChanges_changes_after_timedMessages_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: BaseCatalogFragmentChanges_changes_after_timedMessages_catalogs_addresses[];
}

export interface BaseCatalogFragmentChanges_changes_after_timedMessages {
  customer: BaseCatalogFragmentChanges_changes_after_timedMessages_customer | null;
  changedBy: string | null;
  startTime: GQL_DateTime | null;
  endTime: GQL_DateTime | null;
  message: string | null;
  global: boolean | null;
  globalType: CatalogGlobalType | null;
  active: boolean | null;
  catalogs: BaseCatalogFragmentChanges_changes_after_timedMessages_catalogs[] | null;
}

export interface BaseCatalogFragmentChanges_changes_after_debitor {
  /**
   * Company identifier
   */
  company: string;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
}

export interface BaseCatalogFragmentChanges_changes_after {
  ssgAccountable: string | null;
  propertyNumber: string | null;
  customer: BaseCatalogFragmentChanges_changes_after_customer | null;
  changedBy: string | null;
  description: string | null;
  contactNumber: string | null;
  contactHours: string | null;
  addresses: BaseCatalogFragmentChanges_changes_after_addresses[] | null;
  contacts: BaseCatalogFragmentChanges_changes_after_contacts | null;
  craftsmen: BaseCatalogFragmentChanges_changes_after_craftsmen | null;
  timedMessages: BaseCatalogFragmentChanges_changes_after_timedMessages | null;
  filenames: string | null;
  smsService: boolean | null;
  /**
   * Is catalog verified
   */
  verified: boolean;
  /**
   * Is catalog debitor verified
   */
  debitorVerified: boolean;
  /**
   * Catalog debitor details
   */
  debitor: BaseCatalogFragmentChanges_changes_after_debitor | null;
}

export interface BaseCatalogFragmentChanges_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: BaseCatalogFragmentChanges_changes_user;
  /**
   * Changed by
   */
  changedBy: string | null;
  /**
   * Value before
   */
  before: BaseCatalogFragmentChanges_changes_before;
  /**
   * Value after
   */
  after: BaseCatalogFragmentChanges_changes_after;
}

export interface BaseCatalogFragmentChanges {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Customer
   */
  customer: BaseCatalogFragmentChanges_customer;
  /**
   * Catalog changed by
   */
  changedBy: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: BaseCatalogFragmentChanges_addresses[];
  /**
   * SGGAccountable for catalog
   */
  ssgAccountable: string | null;
  /**
   * Description for catalog
   */
  description: string | null;
  /**
   * Contact number for the Catalog
   */
  contactNumber: string | null;
  /**
   * Hours contact number can be reached
   */
  contactHours: string | null;
  /**
   * Is sms service used for Catalog
   */
  smsService: boolean;
  /**
   * Catalog debitor details
   */
  debitor: BaseCatalogFragmentChanges_debitor | null;
  /**
   * Is catalog verified
   */
  verified: boolean;
  /**
   * Is catalog debitor verified
   */
  debitorVerified: boolean;
  /**
   * Version for catalog
   */
  version: number;
  /**
   * Timestamp of when the catalog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the catalog was created
   */
  createdAt: GQL_DateTime;
  /**
   * Catalog Change log
   */
  changes: BaseCatalogFragmentChanges_changes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseCollectionItemFragment
// ====================================================

export interface BaseCollectionItemFragment {
  /**
   * Id of collection item
   */
  id: string;
  /**
   * Name of collection item
   */
  name: string;
  /**
   * Type of collection item
   */
  type: CollectionItemType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseDamageCategoryFragment
// ====================================================

export interface BaseDamageCategoryFragment {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseDamageCauseFragment
// ====================================================

export interface BaseDamageCauseFragment_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface BaseDamageCauseFragment_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface BaseDamageCauseFragment_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface BaseDamageCauseFragment_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: BaseDamageCauseFragment_departments_location;
}

export interface BaseDamageCauseFragment {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: BaseDamageCauseFragment_businessArea;
  /**
   * Id of associated category
   */
  category: BaseDamageCauseFragment_category;
  /**
   * Departments associated to damage cause
   */
  departments: (BaseDamageCauseFragment_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseDebitorFragment
// ====================================================

export interface BaseDebitorFragment_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseDebitorFragment_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseDebitorFragment_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: BaseDebitorFragment_shippingAddress_address;
}

export interface BaseDebitorFragment_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseDebitorFragment {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: BaseDebitorFragment_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: BaseDebitorFragment_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: BaseDebitorFragment_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseDepartmentFragment
// ====================================================

export interface BaseDepartmentFragment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseDepartmentFragment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: BaseDepartmentFragment_location_address;
}

export interface BaseDepartmentFragment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: BaseDepartmentFragment_location;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseDrivingSlipCaseFragment
// ====================================================

export interface BaseDrivingSlipCaseFragment_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseDrivingSlipCaseFragment_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseDrivingSlipCaseFragment_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: BaseDrivingSlipCaseFragment_debitor_shippingAddress_address;
}

export interface BaseDrivingSlipCaseFragment_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseDrivingSlipCaseFragment_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: BaseDrivingSlipCaseFragment_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: BaseDrivingSlipCaseFragment_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: BaseDrivingSlipCaseFragment_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface BaseDrivingSlipCaseFragment_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseDrivingSlipCaseFragment_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: BaseDrivingSlipCaseFragment_damage_contact_address;
}

export interface BaseDrivingSlipCaseFragment_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface BaseDrivingSlipCaseFragment_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface BaseDrivingSlipCaseFragment_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface BaseDrivingSlipCaseFragment_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface BaseDrivingSlipCaseFragment_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: BaseDrivingSlipCaseFragment_damage_cause_departments_location;
}

export interface BaseDrivingSlipCaseFragment_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: BaseDrivingSlipCaseFragment_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: BaseDrivingSlipCaseFragment_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (BaseDrivingSlipCaseFragment_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface BaseDrivingSlipCaseFragment_damage {
  /**
   * Damage location contact information
   */
  contact: BaseDrivingSlipCaseFragment_damage_contact;
  /**
   * Damage category
   */
  category: BaseDrivingSlipCaseFragment_damage_category;
  /**
   * Cause behind damage
   */
  cause: BaseDrivingSlipCaseFragment_damage_cause;
}

export interface BaseDrivingSlipCaseFragment_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseDrivingSlipCaseFragment_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: BaseDrivingSlipCaseFragment_policyHolder_address;
}

export interface BaseDrivingSlipCaseFragment_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseDrivingSlipCaseFragment_caseManager {
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface BaseDrivingSlipCaseFragment_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseDrivingSlipCaseFragment_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: BaseDrivingSlipCaseFragment_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface BaseDrivingSlipCaseFragment_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseDrivingSlipCaseFragment_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: BaseDrivingSlipCaseFragment_ssgDepartment_location_address;
}

export interface BaseDrivingSlipCaseFragment_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: BaseDrivingSlipCaseFragment_ssgDepartment_location;
}

export interface BaseDrivingSlipCaseFragment_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface BaseDrivingSlipCaseFragment_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: BaseDrivingSlipCaseFragment_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface BaseDrivingSlipCaseFragment_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface BaseDrivingSlipCaseFragment_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface BaseDrivingSlipCaseFragment_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
  /**
   * The question
   */
  title: string;
}

export interface BaseDrivingSlipCaseFragment_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: BaseDrivingSlipCaseFragment_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: BaseDrivingSlipCaseFragment_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: BaseDrivingSlipCaseFragment_visitation_answers[] | null;
}

export interface BaseDrivingSlipCaseFragment {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * Case debitor details
   */
  debitor: BaseDrivingSlipCaseFragment_debitor;
  /**
   * Case damage details
   */
  damage: BaseDrivingSlipCaseFragment_damage;
  /**
   * Case policy holder details
   */
  policyHolder: BaseDrivingSlipCaseFragment_policyHolder;
  /**
   * Project manager attached to case
   */
  projectManager: BaseDrivingSlipCaseFragment_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: BaseDrivingSlipCaseFragment_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: BaseDrivingSlipCaseFragment_ssgLocation;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: BaseDrivingSlipCaseFragment_ssgDepartment;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: BaseDrivingSlipCaseFragment_riskEvaluationAnswers[] | null;
  /**
   * Optional comment for risk evaluation
   */
  riskEvaluationComment: string | null;
  /**
   * Case visitation details
   */
  visitation: BaseDrivingSlipCaseFragment_visitation;
  /**
   * Whether time registration is allowed on the case
   */
  timeRegistration: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseDrivingSlipFragment
// ====================================================

export interface BaseDrivingSlipFragment_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface BaseDrivingSlipFragment_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface BaseDrivingSlipFragment_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseDrivingSlipFragment_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface BaseDrivingSlipFragment_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: BaseDrivingSlipFragment_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface BaseDrivingSlipFragment_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: BaseDrivingSlipFragment_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface BaseDrivingSlipFragment_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: BaseDrivingSlipFragment_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface BaseDrivingSlipFragment_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseDrivingSlipFragment_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseDrivingSlipFragment_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface BaseDrivingSlipFragment_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface BaseDrivingSlipFragment_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: BaseDrivingSlipFragment_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: BaseDrivingSlipFragment_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: BaseDrivingSlipFragment_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface BaseDrivingSlipFragment_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseDrivingSlipFragment_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface BaseDrivingSlipFragment_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface BaseDrivingSlipFragment_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: BaseDrivingSlipFragment_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: BaseDrivingSlipFragment_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: BaseDrivingSlipFragment_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface BaseDrivingSlipFragment_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: BaseDrivingSlipFragment_changes_user;
  /**
   * Value before
   */
  before: BaseDrivingSlipFragment_changes_before;
  /**
   * Value after
   */
  after: BaseDrivingSlipFragment_changes_after;
}

export interface BaseDrivingSlipFragment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface BaseDrivingSlipFragment_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface BaseDrivingSlipFragment {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: BaseDrivingSlipFragment_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: BaseDrivingSlipFragment_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: BaseDrivingSlipFragment_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: BaseDrivingSlipFragment_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: BaseDrivingSlipFragment_changes[];
  /**
   * Location of driving slip
   */
  location: BaseDrivingSlipFragment_location;
  /**
   * Department of driving slip
   */
  department: BaseDrivingSlipFragment_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseDrivingSlipQuestionnaireFragment
// ====================================================

export interface BaseDrivingSlipQuestionnaireFragment_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface BaseDrivingSlipQuestionnaireFragment_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: BaseDrivingSlipQuestionnaireFragment_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface BaseDrivingSlipQuestionnaireFragment_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: BaseDrivingSlipQuestionnaireFragment_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface BaseDrivingSlipQuestionnaireFragment {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: BaseDrivingSlipQuestionnaireFragment_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseDrivingSlipQuestionnaireTemplateFragment
// ====================================================

export interface BaseDrivingSlipQuestionnaireTemplateFragment_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface BaseDrivingSlipQuestionnaireTemplateFragment_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: BaseDrivingSlipQuestionnaireTemplateFragment_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface BaseDrivingSlipQuestionnaireTemplateFragment {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: BaseDrivingSlipQuestionnaireTemplateFragment_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseGDPRFragment
// ====================================================

export interface BaseGDPRFragment_userId {
  /**
   * Unique id of user
   */
  id: string;
}

export interface BaseGDPRFragment {
  /**
   * Unique id of gdpr accept
   */
  id: string;
  /**
   * Timestamp of acceptance
   */
  timestamp: GQL_DateTime;
  /**
   * User who accepted GDPR
   */
  userId: BaseGDPRFragment_userId;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseGroupPermissionFragment
// ====================================================

export interface BaseGroupPermissionFragment_groupDebitors {
  /**
   * List item value
   */
  value: string;
  /**
   * List item label
   */
  label: string;
}

export interface BaseGroupPermissionFragment {
  /**
   * Unique id of group permission
   */
  id: string;
  /**
   * Unique name of AD permission group
   */
  name: string;
  /**
   * Unique id of AD permission group
   */
  groupPermissionId: string;
  /**
   * Group permissions
   */
  groupPermissions: Permissions[];
  /**
   * AD permission group is New
   */
  isNew: boolean;
  /**
   * Limited debitors this group can pick from
   */
  groupDebitors: BaseGroupPermissionFragment_groupDebitors[];
  /**
   * Limited debitor groups this group can pick from
   */
  groupDebitorGroups: string[];
  /**
   * Limited locations this group can pick from
   */
  groupLocations: string[];
  /**
   * Limited departments this group can pick from
   */
  groupDepartments: string[];
  /**
   * Limited damage categories this group can pick from
   */
  groupDamageCategories: string[];
  /**
   * Limited damage causes this group can pick from
   */
  groupDamageCauses: string[];
  /**
   * Limeted departments this group can make requisitions for
   */
  groupRequisitionDepartments: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseInspectionReportFormDataFragment
// ====================================================

export interface BaseInspectionReportFormDataFragment_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface BaseInspectionReportFormDataFragment_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface BaseInspectionReportFormDataFragment_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface BaseInspectionReportFormDataFragment {
  /**
   * Famage image info
   */
  facadeImage: BaseInspectionReportFormDataFragment_facadeImage | null;
  /**
   * Information about what has happened until now
   */
  backgroundStory: string | null;
  /**
   * Observations about the site
   */
  observations: string | null;
  /**
   * Assessment and conclusion
   */
  assessmentAndConclusion: string | null;
  /**
   * Suggested action plan
   */
  suggestedActionPlan: string | null;
  /**
   * Any other comments from inspection
   */
  comments: string | null;
  /**
   * Floorplan image info
   */
  floorplanImage: BaseInspectionReportFormDataFragment_floorplanImage | null;
  /**
   * List of other relevant images
   */
  otherImages: BaseInspectionReportFormDataFragment_otherImages[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseJobFragment
// ====================================================

export interface BaseJobFragment_assignedTo {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface BaseJobFragment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface BaseJobFragment_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
}

export interface BaseJobFragment_movable_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface BaseJobFragment_movable {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: BaseJobFragment_movable_placement;
}

export interface BaseJobFragment {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * Type of case task
   */
  type: JobType;
  /**
   * Description of the case task
   */
  description: string;
  /**
   * Deadline of the case task
   */
  deadline: GQL_DateTime;
  /**
   * The user responsible for the task
   */
  assignedTo: BaseJobFragment_assignedTo | null;
  /**
   * The location responsible to the task
   */
  location: BaseJobFragment_location | null;
  /**
   * The case the task belongs to
   */
  case: BaseJobFragment_case | null;
  /**
   * The movable responsible to the task
   */
  movable: BaseJobFragment_movable | null;
  /**
   * Whether the task is completed
   */
  completed: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseLocationFragment
// ====================================================

export interface BaseLocationFragment_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseLocationFragment {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: BaseLocationFragment_address;
  /**
   * Email attached to location
   */
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseMachineFragment
// ====================================================

export interface BaseMachineFragment {
  /**
   * BC identifier
   */
  erpReferenceNo: string;
  /**
   * Name of machine
   */
  name: string;
  /**
   * Start of machineUsage
   */
  dateStart: GQL_Date;
  /**
   * end of machineUsage
   */
  dateEnd: GQL_Date;
  /**
   * Case number if case
   */
  eRPCaseReference: string | null;
  /**
   * location number if location
   */
  eRPLocationReference: string | null;
  /**
   * Consumption
   */
  consumption: GQL_Decimal;
  /**
   * Name of user
   */
  userName: string;
  /**
   * Ressource type/allocation Type
   */
  type: string;
  /**
   * bool to determine if machine is active
   */
  active: boolean;
  /**
   * Current placement of machine
   */
  placement: string;
  /**
   * Original location of machine
   */
  originLocation: string;
  /**
   * Hibernation state
   */
  hibernate: boolean;
  /**
   * Hibernation state
   */
  hibernationReason: string;
  /**
   * Does the machine require specific education
   */
  educationPrerequisit: string[] | null;
  /**
   * Start time
   */
  startTime: string;
  /**
   * Date of next service
   */
  serviceDate: GQL_DateTime;
  /**
   * Factor for date to kwh conversion
   */
  consumptionFactor: GQL_Decimal;
  /**
   * Whether the type is days, kwh
   */
  consumptionType: MachineConsumptionType;
  /**
   * machines model number
   */
  modelNumber: string;
  /**
   * Reservation Start
   */
  reservationStart: GQL_DateTime | null;
  /**
   * Reservation End
   */
  reservationEnd: GQL_DateTime | null;
  /**
   * reservation description
   */
  reservationDescription: string | null;
  /**
   * Reservation by ressource
   */
  reservationByRessource: string | null;
  /**
   * reservation on Case
   */
  reservationOnCase: string | null;
  /**
   * Is the machine blocked
   */
  blocked: boolean;
  /**
   * Where the machine has been placed, e.g. Basement, 1st floor bedroom etc.
   */
  placementDescription: string | null;
  /**
   * The total consumption after machine has been used
   */
  usageMeterEnd: GQL_Decimal;
  /**
   * Unavailable reason
   */
  unavailableReason: string;
  /**
   * Marks that machine has been discontinued
   */
  discontinued: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseMachineLocationFragment
// ====================================================

export interface BaseMachineLocationFragment {
  /**
   * Identifier of location
   */
  locationCode: string;
  /**
   * address line
   */
  address: string;
  /**
   * City
   */
  city: string;
  /**
   * Contact person
   */
  contact: string;
  /**
   * Name
   */
  name: string;
  /**
   * Post code
   */
  postCode: string;
  /**
   * True if location is unavaliable
   */
  unavaliable: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseMoistureReportFormDataFragment
// ====================================================

export interface BaseMoistureReportFormDataFragment_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface BaseMoistureReportFormDataFragment_damageCauseImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface BaseMoistureReportFormDataFragment_floorReport_observations {
  /**
   * Describes where the damage is
   */
  roomOrApartment: string;
  /**
   * Construction observations
   */
  construction: string | null;
  /**
   * Ceiling observations
   */
  ceiling: string | null;
  /**
   * Walls observations
   */
  walls: string | null;
  /**
   * Floor observations
   */
  floor: string | null;
  /**
   * Observed scope of damage
   */
  scope: string;
  /**
   * Recommendations
   */
  recommendations: string;
}

export interface BaseMoistureReportFormDataFragment_floorReport_moistureMeassurements {
  /**
   * Construction
   */
  construction: string | null;
  /**
   * Material
   */
  material: string | null;
  /**
   * Description
   */
  description: string | null;
  /**
   * Method
   */
  method: string | null;
  /**
   * Value
   */
  value: string;
  /**
   * Expected final value
   */
  expectedFinalValue: string | null;
  /**
   * Reference value
   */
  referenceValue: string | null;
  /**
   * Reference date
   */
  referenceDate: string | null;
  /**
   * Control value
   */
  controlValue: string | null;
  /**
   * Control date
   */
  controlDate: string | null;
}

export interface BaseMoistureReportFormDataFragment_floorReport_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface BaseMoistureReportFormDataFragment_floorReport_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface BaseMoistureReportFormDataFragment_floorReport {
  /**
   * Floor Name
   */
  name: string;
  /**
   * Report observations
   */
  observations: BaseMoistureReportFormDataFragment_floorReport_observations;
  /**
   * Moisture meassurements
   */
  moistureMeassurements: BaseMoistureReportFormDataFragment_floorReport_moistureMeassurements[] | null;
  /**
   * Floorplan image info
   */
  floorplanImage: BaseMoistureReportFormDataFragment_floorReport_floorplanImage | null;
  /**
   * List of other relevant images
   */
  otherImages: BaseMoistureReportFormDataFragment_floorReport_otherImages[] | null;
}

export interface BaseMoistureReportFormDataFragment {
  /**
   * Famage image info
   */
  facadeImage: BaseMoistureReportFormDataFragment_facadeImage | null;
  /**
   * Date of damage
   */
  inspectionDate: GQL_Date;
  /**
   * Conclusions
   */
  conclusion: string;
  /**
   * Damage cause description
   */
  damageCause: string;
  /**
   * Damage scope
   */
  scope: string;
  /**
   * Damage site contacts
   */
  contacts: string;
  /**
   * Images of damage cause
   */
  damageCauseImages: BaseMoistureReportFormDataFragment_damageCauseImages[] | null;
  /**
   * Gulvfugt info
   */
  floorReport: BaseMoistureReportFormDataFragment_floorReport[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseMoistureTrygReportFormDataFragment
// ====================================================

export interface BaseMoistureTrygReportFormDataFragment_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface BaseMoistureTrygReportFormDataFragment_floorReport_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface BaseMoistureTrygReportFormDataFragment_floorReport_moistureMeassurements {
  /**
   * Construction
   */
  construction: string | null;
  /**
   * Material
   */
  material: string | null;
  /**
   * Description
   */
  description: string | null;
  /**
   * Method
   */
  method: string | null;
  /**
   * Paints
   */
  paints: string;
  /**
   * Paints Date
   */
  paintsDate: string | null;
  /**
   * Expected final value
   */
  expectedFinalValue: string | null;
  /**
   * Expected final date
   */
  expectedFinalDate: string | null;
  /**
   * Reference value
   */
  referenceValue: string | null;
  /**
   * Reference date
   */
  referenceDate: string | null;
  /**
   * Control value
   */
  controlValue: string | null;
  /**
   * Control date
   */
  controlDate: string | null;
}

export interface BaseMoistureTrygReportFormDataFragment_floorReport_airMeassurements {
  /**
   * Temperature inside (celcius)
   */
  temperatureInside: number;
  /**
   * Temperature outside (celcius)
   */
  temperatureOutside: number;
  /**
   * Relative humidity inside (percentage)
   */
  relativeHumidityInside: number;
  /**
   * Relative humidity outside (percentage)
   */
  relativeHumidityOutside: number;
  /**
   * Water amount inside (g/m3)
   */
  waterAmountInside: number;
  /**
   * Water amount outside (g/m3)
   */
  waterAmountOutside: number;
}

export interface BaseMoistureTrygReportFormDataFragment_floorReport_damageDescriptionImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface BaseMoistureTrygReportFormDataFragment_floorReport_damageDrawingImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface BaseMoistureTrygReportFormDataFragment_floorReport_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface BaseMoistureTrygReportFormDataFragment_floorReport {
  /**
   * Name of floor
   */
  name: string | null;
  /**
   * Damage cause description
   */
  damageCause: string | null;
  /**
   * Multiple damage causes description
   */
  multipleDamageCauses: string | null;
  /**
   * Description of relation between damage cause and moisture
   */
  moistureDamageCause: string | null;
  /**
   * Damage description
   */
  damageDescription: string | null;
  /**
   * Floorplan image info
   */
  floorplanImage: BaseMoistureTrygReportFormDataFragment_floorReport_floorplanImage | null;
  /**
   * Moisture meassurements
   */
  moistureMeassurements: BaseMoistureTrygReportFormDataFragment_floorReport_moistureMeassurements[] | null;
  /**
   * Air meassurements
   */
  airMeassurements: BaseMoistureTrygReportFormDataFragment_floorReport_airMeassurements;
  /**
   * Damage description image info
   */
  damageDescriptionImage: BaseMoistureTrygReportFormDataFragment_floorReport_damageDescriptionImage[] | null;
  /**
   * Damage drawing image info
   */
  damageDrawingImage: BaseMoistureTrygReportFormDataFragment_floorReport_damageDrawingImage | null;
  /**
   * Recommendations (if any)
   */
  recommendations: string | null;
  /**
   * Urgent actions (if any)
   */
  urgentActions: string | null;
  /**
   * List of other relevant images
   */
  otherImages: BaseMoistureTrygReportFormDataFragment_floorReport_otherImages[] | null;
}

export interface BaseMoistureTrygReportFormDataFragment {
  /**
   * Famage image info
   */
  facadeImage: BaseMoistureTrygReportFormDataFragment_facadeImage | null;
  /**
   * Date of damage
   */
  inspectionDate: GQL_Date | null;
  /**
   * Gulvfugt info
   */
  floorReport: BaseMoistureTrygReportFormDataFragment_floorReport[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseMovableFragment
// ====================================================

export interface BaseMovableFragment_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
}

export interface BaseMovableFragment_placement_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseMovableFragment_placement_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: BaseMovableFragment_placement_location_address;
}

export interface BaseMovableFragment_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: BaseMovableFragment_placement_location;
}

export interface BaseMovableFragment_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * Email of user
   */
  email: string;
}

export interface BaseMovableFragment_changes_before_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface BaseMovableFragment_changes_before {
  /**
   * Description of movable
   */
  description: string | null;
  /**
   * Movable volume in m3
   */
  volume: GQL_Decimal | null;
  /**
   * Movable completed indicator
   */
  completed: boolean | null;
  /**
   * Movable status
   */
  status: string | null;
  /**
   * Movable placement
   */
  placement: BaseMovableFragment_changes_before_placement | null;
}

export interface BaseMovableFragment_changes_after_placement {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
}

export interface BaseMovableFragment_changes_after {
  /**
   * Description of movable
   */
  description: string | null;
  /**
   * Movable volume in m3
   */
  volume: GQL_Decimal | null;
  /**
   * Movable completed indicator
   */
  completed: boolean | null;
  /**
   * Movable status
   */
  status: string | null;
  /**
   * Movable placement
   */
  placement: BaseMovableFragment_changes_after_placement | null;
}

export interface BaseMovableFragment_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: BaseMovableFragment_changes_user;
  /**
   * Value before
   */
  before: BaseMovableFragment_changes_before;
  /**
   * Value after
   */
  after: BaseMovableFragment_changes_after;
}

export interface BaseMovableFragment {
  /**
   * Unique id of movable
   */
  id: string;
  /**
   * Description of movable
   */
  description: string;
  /**
   * Movable volume in m3
   */
  volume: GQL_Decimal;
  /**
   * Case which movable is attached to
   */
  case: BaseMovableFragment_case;
  /**
   * Movable completed indicator
   */
  completed: boolean;
  /**
   * Movable status
   */
  status: string;
  /**
   * Movable placement
   */
  placement: BaseMovableFragment_placement;
  /**
   * Movable change version number
   */
  version: number;
  /**
   * Movable change log
   */
  changes: BaseMovableFragment_changes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseMovablesLocationFragment
// ====================================================

export interface BaseMovablesLocationFragment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseMovablesLocationFragment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: BaseMovablesLocationFragment_location_address;
}

export interface BaseMovablesLocationFragment {
  /**
   * Unique id of movables location
   */
  id: string;
  /**
   * Name of movables location
   */
  name: string;
  /**
   * Movable location volume in m3
   */
  volume: GQL_Decimal;
  /**
   * Accumulated location volume in m3
   */
  accVolume: GQL_Decimal;
  /**
   * Location which movables location is attached to
   */
  location: BaseMovablesLocationFragment_location;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseNotificationFragment
// ====================================================

export interface BaseNotificationFragment {
  /**
   * Unique id of notification
   */
  id: string;
  /**
   * Trigger of the notification
   */
  trigger: NotificationTrigger;
  /**
   * Id of the email body template used from SharePoint
   */
  emailBodyTemplateId: number | null;
  /**
   * Id of the sms template used from SharePoint
   */
  smsTemplateId: number | null;
  /**
   * Template of the email subject
   */
  emailSubjectTemplate: string | null;
  /**
   * Display name of the notification
   */
  displayName: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseNotificationItemFragment
// ====================================================

export interface BaseNotificationItemFragment {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Trigger for notification
   */
  trigger: NotificationTrigger;
  /**
   * Receivers of notification
   */
  sendTo: string | null;
  /**
   * Object type for notification
   */
  objectType: string;
  /**
   * Object id for notification
   */
  objectId: string;
  /**
   * type of notification
   */
  type: string;
  /**
   * Is notification sent successfully
   */
  sentSuccessfully: boolean;
  /**
   * Error for notification
   */
  error: string | null;
  /**
   * Notification sent at
   */
  sentAt: GQL_DateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseOfferFragment
// ====================================================

export interface BaseOfferFragment_lines {
  /**
   * document nr / e.g Screening
   */
  documentNo: string;
  /**
   * Item / resource reference id
   */
  no: string;
  /**
   * Date of planning
   */
  planningDate: string;
  /**
   * Description
   */
  description: string;
  /**
   * Quantity
   */
  quantity: GQL_Decimal;
  /**
   * Price of unit
   */
  unitPrice: GQL_Decimal;
  /**
   * new Price of unit
   */
  newUnitPrice: GQL_Decimal | null;
  /**
   * Line amount
   */
  lineAmount: GQL_Decimal;
  /**
   * Currencycode
   */
  currencyCode: string | null;
  /**
   * Type of work
   */
  workType: string | null;
  /**
   * Id of entity
   */
  systemId: string | null;
  /**
   * ERP Reference task
   */
  eRPReferenceTask: string;
  /**
   * Assortment type
   */
  type: OfferRessourceType;
  /**
   * option
   */
  option: boolean;
  /**
   * hideSum
   */
  hideSum: boolean;
  /**
   * is line a header
   */
  header: boolean;
  /**
   * Refrence id to line in screening template
   */
  screeningLineId: string | null;
  /**
   * Sorting index (header and lines are grouped by sorting index)
   */
  sortingIndex: number;
  /**
   * Line number
   */
  lineNo: number;
  /**
   * Unit Cost
   */
  unitCost: GQL_Decimal;
}

export interface BaseOfferFragment {
  /**
   * BC job number
   */
  jobNo: string;
  /**
   * Offer lines
   */
  lines: BaseOfferFragment_lines[];
  /**
   * current status on Quote
   */
  opportunityStatus: string | null;
  /**
   * header text
   */
  headerText: string | null;
  /**
   * footer text
   */
  footerText: string | null;
  /**
   * Quote Type
   */
  quoteType: OfferQuoteTypeEnum | null;
  /**
   * is offer competition offer
   */
  competitionOffer: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseOfferItemFragment
// ====================================================

export interface BaseOfferItemFragment {
  /**
   * item no
   */
  no: string;
  /**
   * description
   */
  description: string;
  /**
   * line type
   */
  type: string;
  /**
   * Base unit of messure
   */
  baseUOM: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseOfferJobTaskFragment
// ====================================================

export interface BaseOfferJobTaskFragment {
  /**
   * case no
   */
  erpRefrenceNo: string;
  /**
   * JobTask No
   */
  jobTaskNo: string;
  /**
   * Description
   */
  description: string;
  /**
   * Price of the JobTask
   */
  price: GQL_Decimal;
  /**
   * Work type for the job task
   */
  workTypeCode: string;
  /**
   * Exclude In the enviromental fee
   */
  excludeInEnvFee: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseOfferResourceFragment
// ====================================================

export interface BaseOfferResourceFragment {
  /**
   * Resource No
   */
  no: string;
  /**
   * Resource Name
   */
  name: string;
  /**
   * Resource type
   */
  type: string;
  /**
   * baseUOM e.g Time 
   */
  baseUOM: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseQuestionnaireTemplateFragment
// ====================================================

export interface BaseQuestionnaireTemplateFragment_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface BaseQuestionnaireTemplateFragment_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: BaseQuestionnaireTemplateFragment_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface BaseQuestionnaireTemplateFragment {
  /**
   * Unique id of questionnaire
   */
  id: string;
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: BaseQuestionnaireTemplateFragment_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire driving slip categories
   */
  drivingSlipCategories: string[];
  /**
   * Questionnaire change version number
   */
  version: number;
  /**
   * Timestamp of when the questionnaire was created
   */
  createdAt: GQL_DateTime;
  /**
   * Timestamp of when the questionnaire was last updated
   */
  updatedAt: GQL_DateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseReportFile
// ====================================================

export interface BaseReportFile {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseRiskEvaluationQuestionFragment
// ====================================================

export interface BaseRiskEvaluationQuestionFragment_category {
  /**
   * Id of risk category
   */
  id: string;
  /**
   * Name of risk category
   */
  categoryName: string;
}

export interface BaseRiskEvaluationQuestionFragment {
  /**
   * Id of question
   */
  id: string;
  /**
   * Id of category question is linked to
   */
  category: BaseRiskEvaluationQuestionFragment_category;
  /**
   * Question
   */
  question: string;
  /**
   * Help text for question
   */
  helpText: string;
  /**
   * If a drivingslip is made during casecreation this question will be marked true on risk evaluation
   */
  defaultChecked: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseScreeningPositionTextFragment
// ====================================================

export interface BaseScreeningPositionTextFragment {
  /**
   * Unique id of entry
   */
  id: string;
  /**
   * Screening template code
   */
  templateCode: string;
  /**
   * Screening line position
   */
  position: string;
  /**
   * Screening line position help text
   */
  text: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseTimedMessageFragment
// ====================================================

export interface BaseTimedMessageFragment_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface BaseTimedMessageFragment {
  /**
   * id to display
   */
  id: string;
  /**
   * Customer
   */
  customer: BaseTimedMessageFragment_customer;
  /**
   * Craftsman changed by
   */
  changedBy: string;
  /**
   * Time at which the message should show from
   */
  startTime: GQL_DateTime;
  /**
   * Time at which the message should stop showing
   */
  endTime: GQL_DateTime;
  /**
   * message to display
   */
  message: string;
  /**
   * Is timed message global
   */
  global: boolean;
  /**
   * Global type for timed message
   */
  globalType: CatalogGlobalType;
  /**
   * Is timed message active
   */
  active: boolean;
  /**
   * Version for timed message
   */
  version: number;
  /**
   * Catalog ids
   */
  catalogIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseTimeTrackingFragment
// ====================================================

export interface BaseTimeTrackingFragment_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface BaseTimeTrackingFragment_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface BaseTimeTrackingFragment_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface BaseTimeTrackingFragment_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface BaseTimeTrackingFragment_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
}

export interface BaseTimeTrackingFragment_case_damage_contact {
  /**
   * Contact address information
   */
  address: BaseTimeTrackingFragment_case_damage_contact_address;
}

export interface BaseTimeTrackingFragment_case_damage {
  /**
   * Damage category
   */
  category: BaseTimeTrackingFragment_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: BaseTimeTrackingFragment_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: BaseTimeTrackingFragment_case_damage_contact;
}

export interface BaseTimeTrackingFragment_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: BaseTimeTrackingFragment_case_debitor;
  /**
   * Case damage details
   */
  damage: BaseTimeTrackingFragment_case_damage;
}

export interface BaseTimeTrackingFragment_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface BaseTimeTrackingFragment_addonLines_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface BaseTimeTrackingFragment_addonLines_case_debitor {
  /**
   * Company identifier
   */
  company: string;
}

export interface BaseTimeTrackingFragment_addonLines_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference id
   */
  erpId: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: BaseTimeTrackingFragment_addonLines_case_debitor;
}

export interface BaseTimeTrackingFragment_addonLines_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
}

export interface BaseTimeTrackingFragment_addonLines {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * AD user
   */
  user: BaseTimeTrackingFragment_addonLines_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * Case for time tracking
   */
  case: BaseTimeTrackingFragment_addonLines_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: BaseTimeTrackingFragment_addonLines_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
}

export interface BaseTimeTrackingFragment {
  /**
   * Id of time tracking
   */
  id: string;
  /**
   * Timestamp of when the timetracking was created
   */
  createdAt: GQL_DateTime;
  /**
   * AD user
   */
  user: BaseTimeTrackingFragment_user;
  /**
   * Date for time tracking
   */
  date: GQL_Date;
  /**
   * Hours for time tracking
   */
  hours: number;
  /**
   * BC case no
   */
  jobNo: string | null;
  /**
   * Case for time tracking
   */
  case: BaseTimeTrackingFragment_case | null;
  /**
   * DrivingSlip
   */
  drivingSlip: BaseTimeTrackingFragment_drivingSlip | null;
  /**
   * Remark for time tracking
   */
  remark: string | null;
  /**
   * Job task no for time tracking
   */
  jobTaskNo: string;
  /**
   * Was the task on call
   */
  onCall: boolean;
  /**
   * Job task name for time tracking
   */
  jobTaskName: string | null;
  /**
   * Work type for time tracking
   */
  workTypeCode: string;
  /**
   * Time tracking status
   */
  timeTrackingStatus: TimeTrackingStatus;
  /**
   * Type of time tracking: Timeregistration, dayclosed
   */
  trackingType: TrackingType;
  /**
   * piecework amount
   */
  pieceworkAmount: number | null;
  /**
   * Type of supplement: Stand alone, piecework, surcharge
   */
  supplementType: SupplementType | null;
  /**
   * Payment supplement code
   */
  paymentSupplementCode: string | null;
  /**
   * Payment supplement name
   */
  paymentSupplementName: string | null;
  /**
   * Payment Supplement unit of measure
   */
  supplementUom: string | null;
  /**
   * Reject message for timetracking line
   */
  rejectMessage: string | null;
  /**
   * List of addon lines
   */
  addonLines: BaseTimeTrackingFragment_addonLines[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseUserFragment
// ====================================================

export interface BaseUserFragment {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseVisitationQuestionFragment
// ====================================================

export interface BaseVisitationQuestionFragment_damageCause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
}

export interface BaseVisitationQuestionFragment_damageCategory {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface BaseVisitationQuestionFragment {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question to ask based on damage cause
   */
  question: string;
  /**
   * Which damage cause the question is based on
   */
  damageCause: BaseVisitationQuestionFragment_damageCause;
  /**
   * Which damage category the question is based on
   */
  damageCategory: BaseVisitationQuestionFragment_damageCategory;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CalendarEventFragment
// ====================================================

export interface CalendarEventFragment_timeRange {
  /**
   * From date
   */
  from: GQL_DateTime;
  /**
   * To date
   */
  to: GQL_DateTime;
}

export interface CalendarEventFragment_drivingSlip_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CalendarEventFragment_drivingSlip_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CalendarEventFragment_drivingSlip_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CalendarEventFragment_drivingSlip_questionnaire_template_sections_choices {
  /**
   * Questionnaire choice id
   */
  id: string;
  /**
   * Questionnaire choice parent id
   */
  parentId: string;
  /**
   * Questionnaire choice type
   */
  type: QuestionnaireChoiceType;
  /**
   * Questionnaire choice label
   */
  label: string;
  /**
   * Questionnaire choice value
   */
  value: string | null;
  /**
   * Questionnaire choice placeholder
   */
  placeholder: string | null;
  /**
   * Questionnaire choice help text
   */
  helpText: string | null;
  /**
   * Questionnaire choice is optional
   */
  isOptional: boolean;
  /**
   * Questionnaire choice will trigger a change to track two if case is track one (only for list options)
   */
  triggersTrackTwoChange: boolean;
  /**
   * Unit of measurement for the choice value
   */
  unitOfMeasure: string | null;
  /**
   * Questionnaire choice is MultiSelect
   */
  isMultiSelectAllowed: boolean;
  /**
   * Questionnaire MultiSelect Values
   */
  multiSelectValues: string[] | null;
}

export interface CalendarEventFragment_drivingSlip_questionnaire_template_sections {
  /**
   * Questionnaire section label
   */
  label: string;
  /**
   * Questionnaire section choices
   */
  choices: CalendarEventFragment_drivingSlip_questionnaire_template_sections_choices[];
  /**
   * A photo is required to complete the questionnaire section
   */
  photoRequired: boolean;
  /**
   * Defines whether it should be saved in ScalePoint via case agreements
   */
  shouldSaveInScalePoint: boolean;
  /**
   * Correlates the section to a case agreement category
   */
  caseAgreementCategory: CaseAgreementCategory;
}

export interface CalendarEventFragment_drivingSlip_questionnaire_template {
  /**
   * Questionnaire label
   */
  label: string;
  /**
   * Questionnaire sections
   */
  sections: CalendarEventFragment_drivingSlip_questionnaire_template_sections[];
  /**
   * Questionnaire damage causes
   */
  damageCauses: string[];
  /**
   * Questionnaire damage causes
   */
  drivingSlipCategories: string[];
}

export interface CalendarEventFragment_drivingSlip_questionnaire {
  /**
   * Unique Id of template
   */
  id: string;
  /**
   * Id of the source questionnaire template it is based on
   */
  sourceTemplateId: string;
  /**
   * Version of the source questionnaire template it is based on
   */
  sourceTemplateVersion: number;
  /**
   * Driving slip questionnaire template
   */
  template: CalendarEventFragment_drivingSlip_questionnaire_template;
  /**
   * Last modified
   */
  updatedAt: GQL_DateTime;
}

export interface CalendarEventFragment_drivingSlip_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CalendarEventFragment_drivingSlip_changes_before_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CalendarEventFragment_drivingSlip_changes_before_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CalendarEventFragment_drivingSlip_changes_before_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CalendarEventFragment_drivingSlip_changes_before {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: CalendarEventFragment_drivingSlip_changes_before_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: CalendarEventFragment_drivingSlip_changes_before_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: CalendarEventFragment_drivingSlip_changes_before_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface CalendarEventFragment_drivingSlip_changes_after_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CalendarEventFragment_drivingSlip_changes_after_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CalendarEventFragment_drivingSlip_changes_after_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface CalendarEventFragment_drivingSlip_changes_after {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: CalendarEventFragment_drivingSlip_changes_after_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: CalendarEventFragment_drivingSlip_changes_after_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: CalendarEventFragment_drivingSlip_changes_after_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

export interface CalendarEventFragment_drivingSlip_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: CalendarEventFragment_drivingSlip_changes_user;
  /**
   * Value before
   */
  before: CalendarEventFragment_drivingSlip_changes_before;
  /**
   * Value after
   */
  after: CalendarEventFragment_drivingSlip_changes_after;
}

export interface CalendarEventFragment_drivingSlip_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface CalendarEventFragment_drivingSlip_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
}

export interface CalendarEventFragment_drivingSlip_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CalendarEventFragment_drivingSlip_case_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: CalendarEventFragment_drivingSlip_case_damage_contact_address;
}

export interface CalendarEventFragment_drivingSlip_case_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface CalendarEventFragment_drivingSlip_case_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface CalendarEventFragment_drivingSlip_case_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface CalendarEventFragment_drivingSlip_case_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface CalendarEventFragment_drivingSlip_case_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: CalendarEventFragment_drivingSlip_case_damage_cause_departments_location;
}

export interface CalendarEventFragment_drivingSlip_case_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: CalendarEventFragment_drivingSlip_case_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: CalendarEventFragment_drivingSlip_case_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (CalendarEventFragment_drivingSlip_case_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface CalendarEventFragment_drivingSlip_case_damage {
  /**
   * Damage location contact information
   */
  contact: CalendarEventFragment_drivingSlip_case_damage_contact;
  /**
   * Damage category
   */
  category: CalendarEventFragment_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: CalendarEventFragment_drivingSlip_case_damage_cause;
}

export interface CalendarEventFragment_drivingSlip_case_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CalendarEventFragment_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: CalendarEventFragment_drivingSlip_case_damage;
  /**
   * Project manager attached to case
   */
  projectManager: CalendarEventFragment_drivingSlip_case_projectManager | null;
}

export interface CalendarEventFragment_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip series id
   */
  series: string;
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Car added to the drivingslip
   */
  car: CalendarEventFragment_drivingSlip_car | null;
  /**
   * Materials added to the drivingslip
   */
  materials: CalendarEventFragment_drivingSlip_materials[];
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: CalendarEventFragment_drivingSlip_driver | null;
  /**
   * Estimated hours to complete driving slip
   */
  estimatedHours: GQL_Decimal | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Date and time of driving slip deadline
   */
  deadline: GQL_DateTime | null;
  /**
   * Questionnaire to be completed for the driving slip
   */
  questionnaire: CalendarEventFragment_drivingSlip_questionnaire | null;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
  /**
   * Driving slip change version number
   */
  version: number;
  /**
   * Timestamp of when the catelog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driver on slip is passenger
   */
  passenger: boolean;
  /**
   * Driving slip lasts half a day
   */
  halfDay: boolean;
  /**
   * Driving slip change log
   */
  changes: CalendarEventFragment_drivingSlip_changes[];
  /**
   * Location of driving slip
   */
  location: CalendarEventFragment_drivingSlip_location;
  /**
   * Department of driving slip
   */
  department: CalendarEventFragment_drivingSlip_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Risk evaluation status
   */
  riskEvaluationStatus: RiskEvaluationStatus;
  /**
   * Questionnaire completion status
   */
  questionnaireCompletionStatus: QuestionnaireCompletionStatus;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
  /**
   * Date and time of when driving has started
   */
  drivingStarted: GQL_DateTime | null;
  /**
   * Event ReferenceId. Null if not put in calendar
   */
  eventReferenceId: string | null;
  /**
   * Indicates that no waste was used/registered by person carrying out the driving slip
   */
  noWasteUsed: boolean;
  /**
   * Message for planner indicating the resources assigned
   */
  plannerMessage: string | null;
  /**
   * Star driven KM
   */
  starDrivenKM: GQL_Decimal | null;
  /**
   * Star driven KM
   */
  actualDrivenKM: GQL_Decimal | null;
  /**
   * Case which driving slip is related to
   */
  case: CalendarEventFragment_drivingSlip_case;
}

export interface CalendarEventFragment {
  /**
   * Unique calendar reference id
   */
  referenceId: string;
  /**
   * Calendar event title
   */
  title: string;
  /**
   * Type of calendar event
   */
  type: CalendarEventType;
  /**
   * Calendar event time range
   */
  timeRange: CalendarEventFragment_timeRange;
  /**
   * Calendar event time range
   */
  isAllDay: boolean;
  /**
   * Indicates if calendar event was created through 5C
   */
  systemCreatedEvent: boolean;
  /**
   * Optional unique driving slip id
   */
  drivingSlip: CalendarEventFragment_drivingSlip | null;
  __typename: "CalendarEvent";
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CaseChangesFragment
// ====================================================

export interface CaseChangesFragment_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CaseChangesFragment_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor billing address
   */
  billingAddress: CaseChangesFragment_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
}

export interface CaseChangesFragment_damage_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface CaseChangesFragment_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface CaseChangesFragment_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface CaseChangesFragment_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface CaseChangesFragment_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface CaseChangesFragment_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: CaseChangesFragment_damage_cause_departments_location;
}

export interface CaseChangesFragment_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: CaseChangesFragment_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: CaseChangesFragment_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (CaseChangesFragment_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface CaseChangesFragment_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CaseChangesFragment_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: CaseChangesFragment_damage_contact_address;
}

export interface CaseChangesFragment_damage_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface CaseChangesFragment_damage {
  /**
   * BusinessArea for damage
   */
  businessArea: CaseChangesFragment_damage_businessArea;
  /**
   * Damage category
   */
  category: CaseChangesFragment_damage_category;
  /**
   * Cause behind damage
   */
  cause: CaseChangesFragment_damage_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: CaseChangesFragment_damage_contact;
  /**
   * contacts for damage
   */
  contacts: CaseChangesFragment_damage_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

export interface CaseChangesFragment_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CaseChangesFragment_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: CaseChangesFragment_policyHolder_address;
}

export interface CaseChangesFragment_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface CaseChangesFragment_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface CaseChangesFragment_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
}

export interface CaseChangesFragment_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: CaseChangesFragment_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: CaseChangesFragment_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: CaseChangesFragment_visitation_answers[] | null;
}

export interface CaseChangesFragment_requisitioner {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Requisitioner relation
   */
  relation: string;
}

export interface CaseChangesFragment_drivingSlips_driver {
  /**
   * Name of user
   */
  name: string;
}

export interface CaseChangesFragment_drivingSlips_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
}

export interface CaseChangesFragment_drivingSlips {
  /**
   * Driving slip lock state
   */
  locked: boolean;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: CaseChangesFragment_drivingSlips_driver | null;
  /**
   * Car added to the drivingslip
   */
  car: CaseChangesFragment_drivingSlips_car | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime;
  /**
   * Optional comment for the driver
   */
  comment: string | null;
}

export interface CaseChangesFragment_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CaseChangesFragment_caseManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface CaseChangesFragment_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CaseChangesFragment_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: CaseChangesFragment_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface CaseChangesFragment_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface CaseChangesFragment_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: CaseChangesFragment_ssgDepartment_location_address;
}

export interface CaseChangesFragment_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: CaseChangesFragment_ssgDepartment_location;
}

export interface CaseChangesFragment_caseEmails {
  /**
   * Email content
   */
  content: string;
  /**
   * List of email attachments
   */
  attachments: string[];
  /**
   * List of email receivers
   */
  receivers: string[];
  /**
   * Timestamp of when email was created
   */
  timestamp: GQL_DateTime;
}

export interface CaseChangesFragment_caseMachineUsages {
  /**
   * Machine identifier
   */
  machine: string;
  /**
   * Start time
   */
  startTime: GQL_DateTime;
  /**
   * End time
   */
  endTime: GQL_DateTime | null;
  /**
   * Meter reading at start time
   */
  startMeterReading: GQL_Decimal;
  /**
   * Meter reading at end time
   */
  endMeterReading: GQL_Decimal | null;
}

export interface CaseChangesFragment_alternativeContact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Comment on person
   */
  comment: string | null;
}

export interface CaseChangesFragment_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface CaseChangesFragment_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: CaseChangesFragment_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface CaseChangesFragment {
  /**
   * Case status
   */
  status: CaseStatus | null;
  /**
   * Case track
   */
  track: number | null;
  /**
   * Case debitor details
   */
  debitor: CaseChangesFragment_debitor | null;
  /**
   * Case damage details
   */
  damage: CaseChangesFragment_damage | null;
  /**
   * Case policy holder details
   */
  policyHolder: CaseChangesFragment_policyHolder | null;
  /**
   * Case visitation details
   */
  visitation: CaseChangesFragment_visitation | null;
  /**
   * Case requisitioner details
   */
  requisitioner: CaseChangesFragment_requisitioner | null;
  /**
   * The driving slip changed on case
   */
  drivingSlips: CaseChangesFragment_drivingSlips | null;
  /**
   * Project manager attached to case
   */
  projectManager: CaseChangesFragment_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: CaseChangesFragment_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: CaseChangesFragment_ssgLocation | null;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: CaseChangesFragment_ssgDepartment | null;
  /**
   * List of case emails
   */
  caseEmails: CaseChangesFragment_caseEmails[] | null;
  /**
   * List of case machine usages
   */
  caseMachineUsages: CaseChangesFragment_caseMachineUsages[] | null;
  /**
   * Important info on case
   */
  importantInfo: string | null;
  /**
   * Requisition from fire department
   */
  skafor: boolean | null;
  /**
   * Alternative contact person
   */
  alternativeContact: CaseChangesFragment_alternativeContact | null;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: CaseChangesFragment_riskEvaluationAnswers[] | null;
  /**
   * Name of files
   */
  fileNames: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CaseUsageFragment
// ====================================================

export interface CaseUsageFragment {
  /**
   * ERP system reference id
   */
  erpId: string;
  /**
   * ERP system reference key
   */
  erpKey: string;
  /**
   * Usage line title
   */
  title: string;
  /**
   * Usage line quantity
   */
  quantity: GQL_Decimal;
  /**
   * Usage line unit
   */
  unit: string;
  /**
   * Usage line cost per unit
   */
  lineCost: GQL_Decimal;
  /**
   * Usage line total cost
   */
  totalCost: GQL_Decimal;
  /**
   * Usage line attachments
   */
  attachments: string[];
  /**
   * Name of ressource
   */
  ressourceName: string;
  /**
   * Date of posting
   */
  postingDate: string;
  /**
   * Original date of posting
   */
  originalPostingDate: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CatalogChangesFragment
// ====================================================

export interface CatalogChangesFragment_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface CatalogChangesFragment_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface CatalogChangesFragment_contacts_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface CatalogChangesFragment_contacts_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface CatalogChangesFragment_contacts_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface CatalogChangesFragment_contacts_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: CatalogChangesFragment_contacts_catalogs_addresses[];
}

export interface CatalogChangesFragment_contacts {
  customer: CatalogChangesFragment_contacts_customer | null;
  changedBy: string | null;
  /**
   * Contact name
   */
  contactName: string | null;
  type: string | null;
  contactType: CatalogContacts | null;
  /**
   * Contact email
   */
  email: string | null;
  /**
   * Informations for Contacts
   */
  informations: CatalogChangesFragment_contacts_informations[] | null;
  /**
   * Is contact global
   */
  global: boolean | null;
  /**
   * Global type for contact
   */
  globalType: CatalogGlobalType | null;
  /**
   * Is contact active
   */
  active: boolean | null;
  catalogs: CatalogChangesFragment_contacts_catalogs[] | null;
}

export interface CatalogChangesFragment_craftsmen_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface CatalogChangesFragment_craftsmen_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface CatalogChangesFragment_craftsmen_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface CatalogChangesFragment_craftsmen_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: CatalogChangesFragment_craftsmen_catalogs_addresses[];
}

export interface CatalogChangesFragment_craftsmen {
  customer: CatalogChangesFragment_craftsmen_customer | null;
  changedBy: string | null;
  /**
   * Type for call contact
   */
  type: string | null;
  /**
   * Call contact name
   */
  contactName: string | null;
  /**
   * Informations for Craftsman
   */
  informations: CatalogChangesFragment_craftsmen_informations[] | null;
  /**
   * Call contact email
   */
  email: string | null;
  /**
   * Priority for call contact
   */
  priority: string | null;
  /**
   * Is call contact global
   */
  global: boolean | null;
  /**
   * Global type for craftsman
   */
  globalType: CatalogGlobalType | null;
  /**
   * Is call contact active
   */
  active: boolean | null;
  catalogs: CatalogChangesFragment_craftsmen_catalogs[] | null;
}

export interface CatalogChangesFragment_timedMessages_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface CatalogChangesFragment_timedMessages_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface CatalogChangesFragment_timedMessages_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: CatalogChangesFragment_timedMessages_catalogs_addresses[];
}

export interface CatalogChangesFragment_timedMessages {
  customer: CatalogChangesFragment_timedMessages_customer | null;
  changedBy: string | null;
  startTime: GQL_DateTime | null;
  endTime: GQL_DateTime | null;
  message: string | null;
  global: boolean | null;
  globalType: CatalogGlobalType | null;
  active: boolean | null;
  catalogs: CatalogChangesFragment_timedMessages_catalogs[] | null;
}

export interface CatalogChangesFragment_debitor {
  /**
   * Company identifier
   */
  company: string;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
}

export interface CatalogChangesFragment {
  ssgAccountable: string | null;
  propertyNumber: string | null;
  customer: CatalogChangesFragment_customer | null;
  changedBy: string | null;
  description: string | null;
  contactNumber: string | null;
  contactHours: string | null;
  addresses: CatalogChangesFragment_addresses[] | null;
  contacts: CatalogChangesFragment_contacts | null;
  craftsmen: CatalogChangesFragment_craftsmen | null;
  timedMessages: CatalogChangesFragment_timedMessages | null;
  filenames: string | null;
  smsService: boolean | null;
  /**
   * Is catalog verified
   */
  verified: boolean;
  /**
   * Is catalog debitor verified
   */
  debitorVerified: boolean;
  /**
   * Catalog debitor details
   */
  debitor: CatalogChangesFragment_debitor | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CatalogContactChangesFragment
// ====================================================

export interface CatalogContactChangesFragment_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface CatalogContactChangesFragment_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface CatalogContactChangesFragment_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface CatalogContactChangesFragment_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: CatalogContactChangesFragment_catalogs_addresses[];
}

export interface CatalogContactChangesFragment {
  customer: CatalogContactChangesFragment_customer | null;
  changedBy: string | null;
  /**
   * Contact name
   */
  contactName: string | null;
  type: string | null;
  contactType: CatalogContacts | null;
  /**
   * Contact email
   */
  email: string | null;
  /**
   * Informations for Contacts
   */
  informations: CatalogContactChangesFragment_informations[] | null;
  /**
   * Is contact global
   */
  global: boolean | null;
  /**
   * Global type for contact
   */
  globalType: CatalogGlobalType | null;
  /**
   * Is contact active
   */
  active: boolean | null;
  catalogs: CatalogContactChangesFragment_catalogs[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CatalogCraftsmanChangesFragment
// ====================================================

export interface CatalogCraftsmanChangesFragment_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface CatalogCraftsmanChangesFragment_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface CatalogCraftsmanChangesFragment_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface CatalogCraftsmanChangesFragment_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: CatalogCraftsmanChangesFragment_catalogs_addresses[];
}

export interface CatalogCraftsmanChangesFragment {
  customer: CatalogCraftsmanChangesFragment_customer | null;
  changedBy: string | null;
  /**
   * Type for call contact
   */
  type: string | null;
  /**
   * Call contact name
   */
  contactName: string | null;
  /**
   * Informations for Craftsman
   */
  informations: CatalogCraftsmanChangesFragment_informations[] | null;
  /**
   * Call contact email
   */
  email: string | null;
  /**
   * Priority for call contact
   */
  priority: string | null;
  /**
   * Is call contact global
   */
  global: boolean | null;
  /**
   * Global type for craftsman
   */
  globalType: CatalogGlobalType | null;
  /**
   * Is call contact active
   */
  active: boolean | null;
  catalogs: CatalogCraftsmanChangesFragment_catalogs[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CatalogFragment
// ====================================================

export interface CatalogFragment_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface CatalogFragment_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface CatalogFragment_debitor {
  /**
   * Company identifier
   */
  company: string;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Sales Perseon Code
   */
  salesPersonCode: string;
  /**
   * Sales Perseon Name
   */
  salesPersonName: string;
}

export interface CatalogFragment_craftsmen_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface CatalogFragment_craftsmen_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface CatalogFragment_craftsmen {
  /**
   * Id for catalog call contact
   */
  id: string;
  /**
   * Customer
   */
  customer: CatalogFragment_craftsmen_customer;
  /**
   * Craftsman changed by
   */
  changedBy: string;
  /**
   * Craftsman type
   */
  type: string;
  /**
   * Craftsman name
   */
  contactName: string;
  /**
   * Informations for Craftsman
   */
  informations: CatalogFragment_craftsmen_informations[];
  /**
   * Craftsman email
   */
  email: string | null;
  /**
   * Craftsman priority
   */
  priority: string;
  /**
   * Is contact global
   */
  global: boolean;
  /**
   * Global type for craftsman
   */
  globalType: CatalogGlobalType;
  /**
   * Is contact active
   */
  active: boolean;
  /**
   * Version for catalog craftsman
   */
  version: number;
  /**
   * Catalog ids
   */
  catalogIds: string[];
}

export interface CatalogFragment_contacts_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface CatalogFragment_contacts_informations {
  /**
   * Information phone number
   */
  phoneNumber: string;
  /**
   * Remark for information
   */
  remark: string | null;
}

export interface CatalogFragment_contacts {
  /**
   * Id for catalog contact
   */
  id: string;
  /**
   * Customer
   */
  customer: CatalogFragment_contacts_customer;
  /**
   * Contact changed by
   */
  changedBy: string;
  /**
   * Contact name
   */
  contactName: string;
  /**
   * Contact type
   */
  type: string | null;
  /**
   * Contact collection type
   */
  contactType: CatalogContacts;
  /**
   * Contact email
   */
  email: string | null;
  /**
   * Informations for Contacts
   */
  informations: (CatalogFragment_contacts_informations | null)[];
  /**
   * Is contact global
   */
  global: boolean;
  /**
   * Global type for contact
   */
  globalType: CatalogGlobalType;
  /**
   * Is contact active
   */
  active: boolean;
  /**
   * Catalog ids
   */
  catalogIds: string[];
  /**
   * Version for catalog contact
   */
  version: number;
}

export interface CatalogFragment_timedMessages_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface CatalogFragment_timedMessages {
  /**
   * id to display
   */
  id: string;
  /**
   * Customer
   */
  customer: CatalogFragment_timedMessages_customer;
  /**
   * Craftsman changed by
   */
  changedBy: string;
  /**
   * Time at which the message should show from
   */
  startTime: GQL_DateTime;
  /**
   * Time at which the message should stop showing
   */
  endTime: GQL_DateTime;
  /**
   * message to display
   */
  message: string;
  /**
   * Is timed message global
   */
  global: boolean;
  /**
   * Global type for timed message
   */
  globalType: CatalogGlobalType;
  /**
   * Is timed message active
   */
  active: boolean;
  /**
   * Version for timed message
   */
  version: number;
  /**
   * Catalog ids
   */
  catalogIds: string[];
}

export interface CatalogFragment {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Contact number for the Catalog
   */
  contactNumber: string | null;
  /**
   * Hours contact number can be reached
   */
  contactHours: string | null;
  /**
   * Customer
   */
  customer: CatalogFragment_customer;
  /**
   * Catalog changed by
   */
  changedBy: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: CatalogFragment_addresses[];
  /**
   * Is sms service used for Catalog
   */
  smsService: boolean;
  /**
   * Catalog debitor details
   */
  debitor: CatalogFragment_debitor | null;
  /**
   * Is catalog verified
   */
  verified: boolean;
  /**
   * Is catalog debitor verified
   */
  debitorVerified: boolean;
  /**
   * Version for catalog
   */
  version: number;
  /**
   * Timestamp of when the catalog was last updated
   */
  updatedAt: GQL_DateTime;
  /**
   * Timestamp of when the catalog was created
   */
  createdAt: GQL_DateTime;
  /**
   * SGGAccountable for catalog
   */
  ssgAccountable: string | null;
  /**
   * Description for catalog
   */
  description: string | null;
  /**
   * Craftsmen attached to catalog
   */
  craftsmen: CatalogFragment_craftsmen[];
  /**
   * Contacs attached to catalog
   */
  contacts: CatalogFragment_contacts[];
  /**
   * Timed messages attached to catalog
   */
  timedMessages: CatalogFragment_timedMessages[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DamageFragment
// ====================================================

export interface DamageFragment_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface DamageFragment_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface DamageFragment_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface DamageFragment_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface DamageFragment_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface DamageFragment_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: DamageFragment_cause_departments_location;
}

export interface DamageFragment_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: DamageFragment_cause_businessArea;
  /**
   * Id of associated category
   */
  category: DamageFragment_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (DamageFragment_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface DamageFragment_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface DamageFragment_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: DamageFragment_contact_address;
}

export interface DamageFragment_contacts {
  /**
   * Name of damage contact
   */
  name: string;
  /**
   * Role of damage contact
   */
  role: string;
  /**
   * Phone number of damage contact
   */
  phone: string | null;
  /**
   * Email of damage contact
   */
  email: string | null;
}

export interface DamageFragment {
  /**
   * BusinessArea for damage
   */
  businessArea: DamageFragment_businessArea;
  /**
   * Damage category
   */
  category: DamageFragment_category;
  /**
   * Cause behind damage
   */
  cause: DamageFragment_cause;
  /**
   * Date of damage
   */
  date: GQL_Date;
  /**
   * Description of damage
   */
  description: string;
  /**
   * Damage location contact information
   */
  contact: DamageFragment_contact;
  /**
   * contacts for damage
   */
  contacts: DamageFragment_contacts[];
  /**
   * Access conditions
   */
  accessConditions: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DraftInvoiceFragment
// ====================================================

export interface DraftInvoiceFragment_validationRuleBypasses {
  /**
   * Rule that was bypassed
   */
  rule: string;
  /**
   * Reason code id
   */
  reasonCode: string;
  /**
   * User comment
   */
  comment: string | null;
}

export interface DraftInvoiceFragment_lines {
  /**
   * System Id
   */
  systemId: string;
  /**
   * Sort index
   */
  sortIndex: number;
  /**
   * Case number
   */
  caseNo: string;
  /**
   * Document number (planningline reference)
   */
  documentNo: string;
  /**
   * Unique identifier
   */
  no: string;
  /**
   * ERP Reference Task
   */
  erpReferenceTask: string;
  /**
   * Line amount
   */
  lineAmount: GQL_Decimal | null;
  /**
   * Line Type
   */
  lineType: string;
  /**
   * Type
   */
  type: string;
  /**
   * Tells ERP system to attach document for subcontractor lines
   */
  attachDocument: boolean | null;
  /**
   * If allow attach document checkbox should be shown
   */
  allowAttachDocument: boolean;
  /**
   * Description
   */
  description: string;
  /**
   * Quantity
   */
  quantity: GQL_Decimal | null;
  /**
   * Quote Line Id
   */
  quoteLineId: string;
  /**
   * Name of screening this line belongs to
   */
  screeningTitle: string;
  /**
   * Screening line
   */
  screeningLine: boolean;
  /**
   * Screening Line Id
   */
  screeningLineId: string;
  /**
   * Screening Line position no
   */
  screeningLinePosition: string | null;
  /**
   * Price of unit
   */
  unitPrice: GQL_Decimal | null;
  /**
   * Cost of unit
   */
  unitCost: GQL_Decimal | null;
  /**
   * New price of unit
   */
  newUnitPrice: GQL_Decimal | null;
  /**
   * Planning date
   */
  planningDate: GQL_Date;
  /**
   * Work type code
   */
  workTypeCode: string;
}

export interface DraftInvoiceFragment {
  /**
   * Reference no invoice
   */
  erpReferenceNo: string;
  /**
   * Category of invoice
   */
  category: InvoiceCategory;
  /**
   * Invoice status
   */
  status: InvoiceStatus;
  /**
   * Date the invoice is posted
   */
  invoicePostingDate: GQL_Date;
  /**
   * Start date of the invoicing period
   */
  invoiceStartDate: GQL_Date;
  /**
   * End date of the invoicing period
   */
  invoiceEndDate: GQL_Date;
  /**
   * Invoice validation rule bypasses
   */
  validationRuleBypasses: DraftInvoiceFragment_validationRuleBypasses[];
  /**
   * Invoice lines
   */
  lines: DraftInvoiceFragment_lines[];
  /**
   * Determines whether draft lines have been suggested for this invoice
   */
  hasSuggestedLines: boolean;
  /**
   * Description to put on the invoice
   */
  invoiceDescription: string;
  /**
   * Rejection reason from manager
   */
  rejectionReason: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DraftInvoiceLineFragment
// ====================================================

export interface DraftInvoiceLineFragment {
  /**
   * System Id
   */
  systemId: string;
  /**
   * Sort index
   */
  sortIndex: number;
  /**
   * Case number
   */
  caseNo: string;
  /**
   * Document number (planningline reference)
   */
  documentNo: string;
  /**
   * Unique identifier
   */
  no: string;
  /**
   * ERP Reference Task
   */
  erpReferenceTask: string;
  /**
   * Line amount
   */
  lineAmount: GQL_Decimal | null;
  /**
   * Line Type
   */
  lineType: string;
  /**
   * Type
   */
  type: string;
  /**
   * Tells ERP system to attach document for subcontractor lines
   */
  attachDocument: boolean | null;
  /**
   * If allow attach document checkbox should be shown
   */
  allowAttachDocument: boolean;
  /**
   * Description
   */
  description: string;
  /**
   * Quantity
   */
  quantity: GQL_Decimal | null;
  /**
   * Quote Line Id
   */
  quoteLineId: string;
  /**
   * Name of screening this line belongs to
   */
  screeningTitle: string;
  /**
   * Screening line
   */
  screeningLine: boolean;
  /**
   * Screening Line Id
   */
  screeningLineId: string;
  /**
   * Screening Line position no
   */
  screeningLinePosition: string | null;
  /**
   * Price of unit
   */
  unitPrice: GQL_Decimal | null;
  /**
   * Cost of unit
   */
  unitCost: GQL_Decimal | null;
  /**
   * New price of unit
   */
  newUnitPrice: GQL_Decimal | null;
  /**
   * Planning date
   */
  planningDate: GQL_Date;
  /**
   * Work type code
   */
  workTypeCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DrivingSlipChangesFragment
// ====================================================

export interface DrivingSlipChangesFragment_driver {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface DrivingSlipChangesFragment_car {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface DrivingSlipChangesFragment_materials {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

export interface DrivingSlipChangesFragment {
  /**
   * Driving slip lock state
   */
  locked: boolean | null;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean | null;
  /**
   * File changes for driving slip
   */
  fileNames: string | null;
  /**
   * Driver who is responsible for the driving slip visit
   */
  driver: DrivingSlipChangesFragment_driver | null;
  /**
   * Car used for the driving slip visit
   */
  car: DrivingSlipChangesFragment_car | null;
  /**
   * Materials used for the driving slip visit
   */
  materials: DrivingSlipChangesFragment_materials[] | null;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime | null;
  /**
   * Event id in outlook
   */
  eventReferenceId: string | null;
  /**
   * Date and time where driving slip is planned to be/was completed
   */
  end: GQL_DateTime | null;
  /**
   * Comment for driving slip
   */
  comment: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ESDHFileFragment
// ====================================================

export interface ESDHFileFragment_metadata {
  /**
   * KVP key
   */
  key: string;
  /**
   * KVP value
   */
  value: string;
}

export interface ESDHFileFragment {
  /**
   * File name
   */
  name: string;
  /**
   * File url from ESDH
   */
  url: string;
  /**
   * File thumbnail URL
   */
  thumbnail: string;
  /**
   * File extension
   */
  extension: string;
  /**
   * File grouping key
   */
  groupingKey: string;
  /**
   * File created
   */
  created: GQL_DateTime;
  /**
   * File metadata from ESDH
   */
  metadata: ESDHFileFragment_metadata[];
  /**
   * File comments from ESDH
   */
  comments: string | null;
  /**
   * File extension
   */
  fileId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IndoorClimateReportFormDataFragment
// ====================================================

export interface IndoorClimateReportFormDataFragment_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface IndoorClimateReportFormDataFragment_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface IndoorClimateReportFormDataFragment_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface IndoorClimateReportFormDataFragment_indoorClimateMeassurements {
  /**
   * Room
   */
  room: string | null;
  /**
   * Construction
   */
  construction: string | null;
  /**
   * Material
   */
  material: string | null;
  /**
   * Description
   */
  description: string | null;
  /**
   * Instrument
   */
  instrument: string | null;
  /**
   * Value
   */
  value: string | null;
  /**
   * Reference value
   */
  referenceValue: string | null;
  /**
   * Calculated value
   */
  calculatedValue: string | null;
}

export interface IndoorClimateReportFormDataFragment {
  /**
   * Famage image info
   */
  facadeImage: IndoorClimateReportFormDataFragment_facadeImage | null;
  /**
   * Date of damage
   */
  inspectionDate: GQL_Date | null;
  /**
   * Purpose of the report
   */
  purpose: string | null;
  /**
   * Year of the building being built
   */
  buildYear: string | null;
  /**
   * Square meters of the area
   */
  squareMeters: string | null;
  /**
   * Daily users of the building
   */
  dailyUsers: string | null;
  /**
   * Other participants on the scene
   */
  participants: string | null;
  /**
   * Information about what has happened until now
   */
  backgroundStory: string | null;
  /**
   * Observations
   */
  observations: string | null;
  /**
   * Refer to appendix for lab answers
   */
  showLabAnswers: boolean;
  /**
   * Refer to appendix for the data log
   */
  showDataLog: boolean | null;
  /**
   * Assessment and conclusion
   */
  assessmentAndConclusion: string | null;
  /**
   * Suggested action plan
   */
  suggestedActionPlan: string | null;
  /**
   * Floorplan image info
   */
  floorplanImage: IndoorClimateReportFormDataFragment_floorplanImage | null;
  /**
   * List of other relevant images
   */
  otherImages: IndoorClimateReportFormDataFragment_otherImages[] | null;
  /**
   * Meassurements of indoor climate
   */
  indoorClimateMeassurements: IndoorClimateReportFormDataFragment_indoorClimateMeassurements[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InvoiceReasonCodeFragment
// ====================================================

export interface InvoiceReasonCodeFragment {
  /**
   * Id of invoice reason code
   */
  id: string;
  /**
   * Name of invoice reason code
   */
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RequisitionFragment
// ====================================================

export interface RequisitionFragment_case_debitor {
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
}

export interface RequisitionFragment_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
  /**
   * Id of damage category
   */
  id: string;
}

export interface RequisitionFragment_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of damage cause
   */
  id: string;
}

export interface RequisitionFragment_case_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface RequisitionFragment_case_damage_contact {
  /**
   * Contact address information
   */
  address: RequisitionFragment_case_damage_contact_address;
}

export interface RequisitionFragment_case_damage {
  /**
   * Damage category
   */
  category: RequisitionFragment_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: RequisitionFragment_case_damage_cause;
  /**
   * Damage location contact information
   */
  contact: RequisitionFragment_case_damage_contact;
}

export interface RequisitionFragment_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case debitor details
   */
  debitor: RequisitionFragment_case_debitor;
  /**
   * Case damage details
   */
  damage: RequisitionFragment_case_damage;
}

export interface RequisitionFragment_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
}

export interface RequisitionFragment_drivingSlip_case_damage_category {
  /**
   * Name of damage category
   */
  name: string;
}

export interface RequisitionFragment_drivingSlip_case_damage_cause {
  /**
   * Name of damage cause
   */
  name: string;
}

export interface RequisitionFragment_drivingSlip_case_damage {
  /**
   * Damage category
   */
  category: RequisitionFragment_drivingSlip_case_damage_category;
  /**
   * Cause behind damage
   */
  cause: RequisitionFragment_drivingSlip_case_damage_cause;
}

export interface RequisitionFragment_drivingSlip_case {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Case damage details
   */
  damage: RequisitionFragment_drivingSlip_case_damage;
}

export interface RequisitionFragment_drivingSlip {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Case which driving slip is related to
   */
  case: RequisitionFragment_drivingSlip_case;
}

export interface RequisitionFragment_vendor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface RequisitionFragment_vendor {
  /**
   * ERP reference no for vendor
   */
  erpReferenceNo: string;
  /**
   * The name of the vendor
   */
  company: string | null;
  /**
   * The address of the vendor
   */
  address: RequisitionFragment_vendor_address | null;
  /**
   * The email of the vendor
   */
  email: string | null;
  /**
   * The phone number of the vendor
   */
  phone: string | null;
  /**
   * The posting group of the vendor
   */
  vendorPostingGroup: string;
  /**
   * Attached SSG department of the vendor
   */
  ssgDepartment: string;
  /**
   * Attached SSG location of the vendor
   */
  ssgLocation: string;
  /**
   * Vendor type
   */
  type: string;
}

export interface RequisitionFragment_createdBy {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface RequisitionFragment {
  /**
   * id of the requisition
   */
  id: string;
  /**
   * case owning the requisition
   */
  case: RequisitionFragment_case | null;
  /**
   * location owning the requisition
   */
  department: RequisitionFragment_department | null;
  /**
   * driving slip owning the requisition
   */
  drivingSlip: RequisitionFragment_drivingSlip | null;
  /**
   * description of the requisition
   */
  description: string;
  /**
   * orderNumber of the requisition
   */
  orderNumber: string;
  /**
   * the type of the requisition
   */
  type: RequisitionType;
  /**
   * Requisition status
   */
  status: boolean;
  /**
   * the vendor of the requisition
   */
  vendor: RequisitionFragment_vendor | null;
  /**
   * Timestamp of when the requisition was created
   */
  createdAt: GQL_DateTime;
  /**
   * User who created the requisition
   */
  createdBy: RequisitionFragment_createdBy;
  /**
   * email of the vendor
   */
  vendorEmail: string | null;
  /**
   * phone number of the vendor
   */
  vendorPhoneNumber: string;
  /**
   * name of the vendor
   */
  vendorName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SalesInvoiceLineFragment
// ====================================================

export interface SalesInvoiceLineFragment {
  /**
   * System Id
   */
  systemId: string;
  /**
   * Document number (planningline reference)
   */
  documentNo: string | null;
  /**
   * Line identifier
   */
  lineNo: number | null;
  /**
   * Unique identifier
   */
  no: string | null;
  /**
   * line type
   */
  type: string | null;
  /**
   * Description
   */
  description: string | null;
  /**
   * Quantity
   */
  quantity: GQL_Decimal | null;
  /**
   * Unit of measure
   */
  uom: string | null;
  /**
   * Price of unit
   */
  unitPrice: GQL_Decimal | null;
  /**
   * Line discount amount
   */
  lineDiscountAmount: GQL_Decimal | null;
  /**
   * Line discount percentage
   */
  lineDiscountPercentage: GQL_Decimal | null;
  /**
   * Amount including vat Tax
   */
  amountIncludingVAT: GQL_Decimal | null;
  /**
   * Vat tax base amount
   */
  vatBaseAmount: GQL_Decimal | null;
  /**
   * Vat tax percentage
   */
  vatPercentage: GQL_Decimal | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TimedMessageChangesFragment
// ====================================================

export interface TimedMessageChangesFragment_customer {
  /**
   * Id of catalog customer
   */
  id: string;
  /**
   * Name of catalog customer
   */
  name: string;
}

export interface TimedMessageChangesFragment_catalogs_addresses {
  /**
   * Address line
   */
  addressLine: string;
  /**
   * zipCode for address
   */
  zipCode: string;
  /**
   * City for address
   */
  city: string;
  /**
   * Start number for address number range
   */
  startNumber: string;
  /**
   * End number for address number range
   */
  endNumber: string;
  /**
   * House numbers can be Both, Equal or Odd
   */
  houseNumbers: HouseNumbers;
  /**
   * Comment for address
   */
  comment: string;
}

export interface TimedMessageChangesFragment_catalogs {
  /**
   * Unique id of catalog
   */
  id: string;
  /**
   * Property number for catalog
   */
  propertyNumber: string;
  /**
   * Addressse affiliated with catalog
   */
  addresses: TimedMessageChangesFragment_catalogs_addresses[];
}

export interface TimedMessageChangesFragment {
  customer: TimedMessageChangesFragment_customer | null;
  changedBy: string | null;
  startTime: GQL_DateTime | null;
  endTime: GQL_DateTime | null;
  message: string | null;
  global: boolean | null;
  globalType: CatalogGlobalType | null;
  active: boolean | null;
  catalogs: TimedMessageChangesFragment_catalogs[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Vehicle
// ====================================================

export interface Vehicle {
  /**
   * Vehicle Number
   */
  vehicleNumber: string;
  /**
   * Location Code
   */
  locationCode: string;
  /**
   * Registration Number
   */
  registrationNumber: string;
  /**
   * Department Code
   */
  departmentCode: string;
  /**
   * Brand
   */
  brand: string | null;
  /**
   * Username
   */
  username: string | null;
  /**
   * Basically Employee ID
   */
  user: string | null;
  /**
   * Vehicle Category
   */
  vehicleCategory: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: VendorFragment
// ====================================================

export interface VendorFragment_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface VendorFragment {
  /**
   * ERP reference no for vendor
   */
  erpReferenceNo: string;
  /**
   * The name of the vendor
   */
  company: string | null;
  /**
   * The address of the vendor
   */
  address: VendorFragment_address | null;
  /**
   * The email of the vendor
   */
  email: string | null;
  /**
   * The phone number of the vendor
   */
  phone: string | null;
  /**
   * The posting group of the vendor
   */
  vendorPostingGroup: string;
  /**
   * Attached SSG department of the vendor
   */
  ssgDepartment: string;
  /**
   * Attached SSG location of the vendor
   */
  ssgLocation: string;
  /**
   * Vendor type
   */
  type: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseDrivingSlipCaseMobileFragment
// ====================================================

export interface BaseDrivingSlipCaseMobileFragment_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseDrivingSlipCaseMobileFragment_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseDrivingSlipCaseMobileFragment_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: BaseDrivingSlipCaseMobileFragment_debitor_shippingAddress_address;
}

export interface BaseDrivingSlipCaseMobileFragment_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseDrivingSlipCaseMobileFragment_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: BaseDrivingSlipCaseMobileFragment_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: BaseDrivingSlipCaseMobileFragment_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: BaseDrivingSlipCaseMobileFragment_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface BaseDrivingSlipCaseMobileFragment_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseDrivingSlipCaseMobileFragment_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: BaseDrivingSlipCaseMobileFragment_damage_contact_address;
}

export interface BaseDrivingSlipCaseMobileFragment_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface BaseDrivingSlipCaseMobileFragment_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface BaseDrivingSlipCaseMobileFragment_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface BaseDrivingSlipCaseMobileFragment_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface BaseDrivingSlipCaseMobileFragment_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: BaseDrivingSlipCaseMobileFragment_damage_cause_departments_location;
}

export interface BaseDrivingSlipCaseMobileFragment_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: BaseDrivingSlipCaseMobileFragment_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: BaseDrivingSlipCaseMobileFragment_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (BaseDrivingSlipCaseMobileFragment_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface BaseDrivingSlipCaseMobileFragment_damage {
  /**
   * Damage location contact information
   */
  contact: BaseDrivingSlipCaseMobileFragment_damage_contact;
  /**
   * Damage category
   */
  category: BaseDrivingSlipCaseMobileFragment_damage_category;
  /**
   * Cause behind damage
   */
  cause: BaseDrivingSlipCaseMobileFragment_damage_cause;
}

export interface BaseDrivingSlipCaseMobileFragment_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
}

export interface BaseDrivingSlipCaseMobileFragment_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface BaseDrivingSlipCaseMobileFragment_caseManager {
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface BaseDrivingSlipCaseMobileFragment_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseDrivingSlipCaseMobileFragment_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: BaseDrivingSlipCaseMobileFragment_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface BaseDrivingSlipCaseMobileFragment_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface BaseDrivingSlipCaseMobileFragment_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: BaseDrivingSlipCaseMobileFragment_ssgDepartment_location_address;
}

export interface BaseDrivingSlipCaseMobileFragment_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: BaseDrivingSlipCaseMobileFragment_ssgDepartment_location;
}

export interface BaseDrivingSlipCaseMobileFragment_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface BaseDrivingSlipCaseMobileFragment_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: BaseDrivingSlipCaseMobileFragment_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface BaseDrivingSlipCaseMobileFragment_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface BaseDrivingSlipCaseMobileFragment_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface BaseDrivingSlipCaseMobileFragment_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
  /**
   * The question
   */
  title: string;
}

export interface BaseDrivingSlipCaseMobileFragment_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: BaseDrivingSlipCaseMobileFragment_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: BaseDrivingSlipCaseMobileFragment_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: BaseDrivingSlipCaseMobileFragment_visitation_answers[] | null;
}

export interface BaseDrivingSlipCaseMobileFragment {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * Case debitor details
   */
  debitor: BaseDrivingSlipCaseMobileFragment_debitor;
  /**
   * Case damage details
   */
  damage: BaseDrivingSlipCaseMobileFragment_damage;
  /**
   * Case policy holder details
   */
  policyHolder: BaseDrivingSlipCaseMobileFragment_policyHolder;
  /**
   * Project manager attached to case
   */
  projectManager: BaseDrivingSlipCaseMobileFragment_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: BaseDrivingSlipCaseMobileFragment_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: BaseDrivingSlipCaseMobileFragment_ssgLocation;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: BaseDrivingSlipCaseMobileFragment_ssgDepartment;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: BaseDrivingSlipCaseMobileFragment_riskEvaluationAnswers[] | null;
  /**
   * Optional comment for risk evaluation
   */
  riskEvaluationComment: string | null;
  /**
   * Case visitation details
   */
  visitation: BaseDrivingSlipCaseMobileFragment_visitation;
  /**
   * Whether time registration is allowed on the case
   */
  timeRegistration: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseDrivingSlipMobileFragment
// ====================================================

export interface BaseDrivingSlipMobileFragment_changes_user {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * Name of user
   */
  name: string;
}

export interface BaseDrivingSlipMobileFragment_changes_before {
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
}

export interface BaseDrivingSlipMobileFragment_changes_after {
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus | null;
}

export interface BaseDrivingSlipMobileFragment_changes {
  /**
   * Time of change
   */
  timestamp: GQL_DateTime;
  /**
   * User who performed the change
   */
  user: BaseDrivingSlipMobileFragment_changes_user;
  /**
   * Value before
   */
  before: BaseDrivingSlipMobileFragment_changes_before;
  /**
   * Value after
   */
  after: BaseDrivingSlipMobileFragment_changes_after;
}

export interface BaseDrivingSlipMobileFragment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface BaseDrivingSlipMobileFragment_department {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
}

export interface BaseDrivingSlipMobileFragment {
  /**
   * Unique id of driving slip
   */
  id: string;
  /**
   * Driving slip status
   */
  status: DrivingSlipStatus;
  /**
   * Indicates if driving slip was marked as urgent
   */
  urgent: boolean;
  /**
   * Date and time where driving slip is planned to be/was started
   */
  start: GQL_DateTime;
  /**
   * Timestamp of when the driving slip was created
   */
  createdAt: GQL_DateTime;
  /**
   * Driving slip change log
   */
  changes: BaseDrivingSlipMobileFragment_changes[];
  /**
   * Location of driving slip
   */
  location: BaseDrivingSlipMobileFragment_location;
  /**
   * Department of driving slip
   */
  department: BaseDrivingSlipMobileFragment_department;
  /**
   * Category of driving slip
   */
  category: string | null;
  /**
   * Indicates if picture of facade has been taken
   */
  facadePictureTaken: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CaseDraftUsageLineFragment
// ====================================================

export interface CaseDraftUsageLineFragment {
  /**
   * ERP system reference id
   */
  erpId: string;
  /**
   * ERP system reference key
   */
  erpKey: string;
  /**
   * Usage line title
   */
  title: string;
  /**
   * Usage line quantity
   */
  quantity: GQL_Decimal;
  /**
   * Date of posting
   */
  date: GQL_Date;
  /**
   * Posted By Username
   */
  postedBy: string;
  /**
   * Description
   */
  description: string;
  __typename: "CaseDraftUsageLine";
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: V2DrivingSlipCaseFragment
// ====================================================

export interface V2DrivingSlipCaseFragment_debitor_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface V2DrivingSlipCaseFragment_debitor_shippingAddress_address {
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * City
   */
  city: string;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * Country
   */
  country: string;
}

export interface V2DrivingSlipCaseFragment_debitor_shippingAddress {
  /**
   * ERP reference no for debitor shipping address
   */
  id: string;
  /**
   * GLN for debitor shipping address
   */
  gln: string;
  /**
   * Shipping address
   */
  address: V2DrivingSlipCaseFragment_debitor_shippingAddress_address;
}

export interface V2DrivingSlipCaseFragment_debitor_billingAddress {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface V2DrivingSlipCaseFragment_debitor {
  /**
   * ERP system id for debitor
   */
  id: string;
  /**
   * Type of customer
   */
  type: DebitorType;
  /**
   * ERP system id for debitor
   */
  debitorId: string;
  /**
   * Company identifier
   */
  company: string;
  /**
   * Optional company attention text
   */
  attention: string | null;
  /**
   * Debitor address
   */
  address: V2DrivingSlipCaseFragment_debitor_address | null;
  /**
   * Debitor shipping address
   */
  shippingAddress: V2DrivingSlipCaseFragment_debitor_shippingAddress | null;
  /**
   * Debitor billing address
   */
  billingAddress: V2DrivingSlipCaseFragment_debitor_billingAddress | null;
  /**
   * Optional excess amount
   */
  excess: GQL_Decimal | null;
  /**
   * Insurance police number
   */
  policeNumber: string;
  /**
   * CVR number
   */
  cvrNumber: string;
  /**
   * Debitor GLN
   */
  gln: string | null;
  /**
   * Debitor phone number
   */
  phone: string | null;
  /**
   * Debitor email
   */
  email: string | null;
  /**
   * Default screening template for debitor
   */
  defaultScreeningTemplate: string | null;
  /**
   * Assortmentcode of debitor
   */
  assortmentCode: string | null;
  /**
   * If debitor is unknown or to be created
   */
  unknown: boolean;
  /**
   * If cases for debitor should have a check "Create in ECB" by default
   */
  createInECB: boolean;
  /**
   * If cases for debitor can be of call center type
   */
  callCenterActive: boolean;
  /**
   * Group debitor is a member of
   */
  debitorGroup: string | null;
  /**
   * Environment Fee Percentage
   */
  environmentFeePercentage: GQL_Decimal;
  /**
   * Max Environment Fee Amount
   */
  maxEnvironmentFeeAmount: GQL_Decimal;
  /**
   * Indicates if customer is priority
   */
  priorityCustomer: boolean;
}

export interface V2DrivingSlipCaseFragment_damage_contact_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface V2DrivingSlipCaseFragment_damage_contact {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: V2DrivingSlipCaseFragment_damage_contact_address;
}

export interface V2DrivingSlipCaseFragment_damage_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface V2DrivingSlipCaseFragment_damage_cause_businessArea {
  /**
   * Id of business area
   */
  id: string;
  /**
   * Name of business area
   */
  name: string;
}

export interface V2DrivingSlipCaseFragment_damage_cause_category {
  /**
   * Id of damage category
   */
  id: string;
  /**
   * Name of damage category
   */
  name: string;
}

export interface V2DrivingSlipCaseFragment_damage_cause_departments_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
}

export interface V2DrivingSlipCaseFragment_damage_cause_departments {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Department name
   */
  name: string;
  /**
   * Location which movables location is attached to
   */
  location: V2DrivingSlipCaseFragment_damage_cause_departments_location;
}

export interface V2DrivingSlipCaseFragment_damage_cause {
  /**
   * Id of damage cause
   */
  id: string;
  /**
   * Name of damage cause
   */
  name: string;
  /**
   * Id of associated business area
   */
  businessArea: V2DrivingSlipCaseFragment_damage_cause_businessArea;
  /**
   * Id of associated category
   */
  category: V2DrivingSlipCaseFragment_damage_cause_category;
  /**
   * Departments associated to damage cause
   */
  departments: (V2DrivingSlipCaseFragment_damage_cause_departments | null)[] | null;
  /**
   * Track for damage cause
   */
  track: number;
  /**
   * Is damage cause urgent
   */
  urgent: boolean;
}

export interface V2DrivingSlipCaseFragment_damage {
  /**
   * Damage location contact information
   */
  contact: V2DrivingSlipCaseFragment_damage_contact;
  /**
   * Damage category
   */
  category: V2DrivingSlipCaseFragment_damage_category;
  /**
   * Cause behind damage
   */
  cause: V2DrivingSlipCaseFragment_damage_cause;
}

export interface V2DrivingSlipCaseFragment_policyHolder_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface V2DrivingSlipCaseFragment_policyHolder {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Contact address information
   */
  address: V2DrivingSlipCaseFragment_policyHolder_address;
}

export interface V2DrivingSlipCaseFragment_projectManager {
  /**
   * Unique id of user
   */
  id: string;
  /**
   * User employee id
   */
  employeeId: string | null;
  /**
   * Name of user
   */
  name: string;
  /**
   * Department user is attached to
   */
  department: string | null;
  /**
   * Area user is attached to
   */
  area: string | null;
  /**
   * Job function of user
   */
  jobFunction: string | null;
  /**
   * Email of user
   */
  email: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Users default language
   */
  defaultLanguage: string | null;
}

export interface V2DrivingSlipCaseFragment_caseManager {
  /**
   * Name of user
   */
  name: string;
  /**
   * User phone number
   */
  phone: string | null;
  /**
   * Email of user
   */
  email: string;
}

export interface V2DrivingSlipCaseFragment_ssgLocation_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface V2DrivingSlipCaseFragment_ssgLocation {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Whether the location can contain movables
   */
  movables: boolean;
  /**
   * Address of location
   */
  address: V2DrivingSlipCaseFragment_ssgLocation_address;
  /**
   * Email attached to location
   */
  email: string;
}

export interface V2DrivingSlipCaseFragment_ssgDepartment_location_address {
  /**
   * Road name
   */
  road: string;
  /**
   * House number
   */
  houseNumber: string;
  /**
   * Apartment floor and door
   */
  floor: string | null;
  /**
   * Address line
   */
  addressLine: string | null;
  /**
   * Optional alternative address line
   */
  addressLineAlt: string | null;
  /**
   * Postal code
   */
  postalCode: string;
  /**
   * City
   */
  city: string;
  /**
   * Country
   */
  country: string;
}

export interface V2DrivingSlipCaseFragment_ssgDepartment_location {
  /**
   * Unique id of location
   */
  id: string;
  /**
   * Name of location
   */
  name: string;
  /**
   * Address of location
   */
  address: V2DrivingSlipCaseFragment_ssgDepartment_location_address;
}

export interface V2DrivingSlipCaseFragment_ssgDepartment {
  /**
   * Unique id for department
   */
  id: string;
  /**
   * Department name
   */
  name: string;
  /**
   * Department number
   */
  departmentNumber: number;
  /**
   * Location which movables location is attached to
   */
  location: V2DrivingSlipCaseFragment_ssgDepartment_location;
}

export interface V2DrivingSlipCaseFragment_riskEvaluationAnswers_question {
  /**
   * Id of question
   */
  id: string;
  /**
   * Question
   */
  question: string;
}

export interface V2DrivingSlipCaseFragment_riskEvaluationAnswers {
  /**
   * Id of question answer is linked to
   */
  question: V2DrivingSlipCaseFragment_riskEvaluationAnswers_question;
  /**
   * Answer given
   */
  answer: boolean;
}

export interface V2DrivingSlipCaseFragment_visitation_calledBack {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface V2DrivingSlipCaseFragment_visitation_awaiting {
  /**
   * Timestamp of when value was last set
   */
  timestamp: GQL_DateTime | null;
  /**
   * Boolean value
   */
  value: boolean;
  /**
   * Value set comment
   */
  comment: string | null;
}

export interface V2DrivingSlipCaseFragment_visitation_answers {
  /**
   * Id of question
   */
  question: string;
  /**
   * Answer given
   */
  answer: string;
  /**
   * The question
   */
  title: string;
}

export interface V2DrivingSlipCaseFragment_visitation {
  /**
   * If case is considered urgent
   */
  urgent: boolean;
  /**
   * Case priority flags
   */
  priorities: CasePriority[];
  /**
   * If case has a regress opportunity
   */
  regress: boolean | null;
  /**
   * If there has been called back
   */
  calledBack: V2DrivingSlipCaseFragment_visitation_calledBack;
  /**
   * If case is awaiting
   */
  awaiting: V2DrivingSlipCaseFragment_visitation_awaiting;
  /**
   * A set of answers related to the damage cause
   */
  answers: V2DrivingSlipCaseFragment_visitation_answers[] | null;
}

export interface V2DrivingSlipCaseFragment_caseReports_inspectionReportFormData_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface V2DrivingSlipCaseFragment_caseReports_inspectionReportFormData_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface V2DrivingSlipCaseFragment_caseReports_inspectionReportFormData_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface V2DrivingSlipCaseFragment_caseReports_inspectionReportFormData {
  /**
   * Famage image info
   */
  facadeImage: V2DrivingSlipCaseFragment_caseReports_inspectionReportFormData_facadeImage | null;
  /**
   * Information about what has happened until now
   */
  backgroundStory: string | null;
  /**
   * Observations about the site
   */
  observations: string | null;
  /**
   * Assessment and conclusion
   */
  assessmentAndConclusion: string | null;
  /**
   * Suggested action plan
   */
  suggestedActionPlan: string | null;
  /**
   * Any other comments from inspection
   */
  comments: string | null;
  /**
   * Floorplan image info
   */
  floorplanImage: V2DrivingSlipCaseFragment_caseReports_inspectionReportFormData_floorplanImage | null;
  /**
   * List of other relevant images
   */
  otherImages: V2DrivingSlipCaseFragment_caseReports_inspectionReportFormData_otherImages[] | null;
}

export interface V2DrivingSlipCaseFragment_caseReports_inspectionMovablesReportFormData_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface V2DrivingSlipCaseFragment_caseReports_inspectionMovablesReportFormData_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface V2DrivingSlipCaseFragment_caseReports_inspectionMovablesReportFormData_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface V2DrivingSlipCaseFragment_caseReports_inspectionMovablesReportFormData {
  /**
   * Famage image info
   */
  facadeImage: V2DrivingSlipCaseFragment_caseReports_inspectionMovablesReportFormData_facadeImage | null;
  /**
   * Information about what has happened until now
   */
  backgroundStory: string | null;
  /**
   * Observations about the site
   */
  observations: string | null;
  /**
   * Assessment and conclusion
   */
  assessmentAndConclusion: string | null;
  /**
   * Suggested action plan
   */
  suggestedActionPlan: string | null;
  /**
   * Any other comments from inspection
   */
  comments: string | null;
  /**
   * Floorplan image info
   */
  floorplanImage: V2DrivingSlipCaseFragment_caseReports_inspectionMovablesReportFormData_floorplanImage | null;
  /**
   * List of other relevant images
   */
  otherImages: V2DrivingSlipCaseFragment_caseReports_inspectionMovablesReportFormData_otherImages[] | null;
}

export interface V2DrivingSlipCaseFragment_caseReports_moistureReportFormData_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface V2DrivingSlipCaseFragment_caseReports_moistureReportFormData_damageCauseImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface V2DrivingSlipCaseFragment_caseReports_moistureReportFormData_floorReport_observations {
  /**
   * Describes where the damage is
   */
  roomOrApartment: string;
  /**
   * Construction observations
   */
  construction: string | null;
  /**
   * Ceiling observations
   */
  ceiling: string | null;
  /**
   * Walls observations
   */
  walls: string | null;
  /**
   * Floor observations
   */
  floor: string | null;
  /**
   * Observed scope of damage
   */
  scope: string;
  /**
   * Recommendations
   */
  recommendations: string;
}

export interface V2DrivingSlipCaseFragment_caseReports_moistureReportFormData_floorReport_moistureMeassurements {
  /**
   * Construction
   */
  construction: string | null;
  /**
   * Material
   */
  material: string | null;
  /**
   * Description
   */
  description: string | null;
  /**
   * Method
   */
  method: string | null;
  /**
   * Value
   */
  value: string;
  /**
   * Expected final value
   */
  expectedFinalValue: string | null;
  /**
   * Reference value
   */
  referenceValue: string | null;
  /**
   * Reference date
   */
  referenceDate: string | null;
  /**
   * Control value
   */
  controlValue: string | null;
  /**
   * Control date
   */
  controlDate: string | null;
}

export interface V2DrivingSlipCaseFragment_caseReports_moistureReportFormData_floorReport_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface V2DrivingSlipCaseFragment_caseReports_moistureReportFormData_floorReport_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface V2DrivingSlipCaseFragment_caseReports_moistureReportFormData_floorReport {
  /**
   * Floor Name
   */
  name: string;
  /**
   * Report observations
   */
  observations: V2DrivingSlipCaseFragment_caseReports_moistureReportFormData_floorReport_observations;
  /**
   * Moisture meassurements
   */
  moistureMeassurements: V2DrivingSlipCaseFragment_caseReports_moistureReportFormData_floorReport_moistureMeassurements[] | null;
  /**
   * Floorplan image info
   */
  floorplanImage: V2DrivingSlipCaseFragment_caseReports_moistureReportFormData_floorReport_floorplanImage | null;
  /**
   * List of other relevant images
   */
  otherImages: V2DrivingSlipCaseFragment_caseReports_moistureReportFormData_floorReport_otherImages[] | null;
}

export interface V2DrivingSlipCaseFragment_caseReports_moistureReportFormData {
  /**
   * Famage image info
   */
  facadeImage: V2DrivingSlipCaseFragment_caseReports_moistureReportFormData_facadeImage | null;
  /**
   * Date of damage
   */
  inspectionDate: GQL_Date;
  /**
   * Conclusions
   */
  conclusion: string;
  /**
   * Damage cause description
   */
  damageCause: string;
  /**
   * Damage scope
   */
  scope: string;
  /**
   * Damage site contacts
   */
  contacts: string;
  /**
   * Images of damage cause
   */
  damageCauseImages: V2DrivingSlipCaseFragment_caseReports_moistureReportFormData_damageCauseImages[] | null;
  /**
   * Gulvfugt info
   */
  floorReport: V2DrivingSlipCaseFragment_caseReports_moistureReportFormData_floorReport[];
}

export interface V2DrivingSlipCaseFragment_caseReports_moistureTrygReportFormData_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface V2DrivingSlipCaseFragment_caseReports_moistureTrygReportFormData_floorReport_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface V2DrivingSlipCaseFragment_caseReports_moistureTrygReportFormData_floorReport_moistureMeassurements {
  /**
   * Construction
   */
  construction: string | null;
  /**
   * Material
   */
  material: string | null;
  /**
   * Description
   */
  description: string | null;
  /**
   * Method
   */
  method: string | null;
  /**
   * Paints
   */
  paints: string;
  /**
   * Paints Date
   */
  paintsDate: string | null;
  /**
   * Expected final value
   */
  expectedFinalValue: string | null;
  /**
   * Expected final date
   */
  expectedFinalDate: string | null;
  /**
   * Reference value
   */
  referenceValue: string | null;
  /**
   * Reference date
   */
  referenceDate: string | null;
  /**
   * Control value
   */
  controlValue: string | null;
  /**
   * Control date
   */
  controlDate: string | null;
}

export interface V2DrivingSlipCaseFragment_caseReports_moistureTrygReportFormData_floorReport_airMeassurements {
  /**
   * Temperature inside (celcius)
   */
  temperatureInside: number;
  /**
   * Temperature outside (celcius)
   */
  temperatureOutside: number;
  /**
   * Relative humidity inside (percentage)
   */
  relativeHumidityInside: number;
  /**
   * Relative humidity outside (percentage)
   */
  relativeHumidityOutside: number;
  /**
   * Water amount inside (g/m3)
   */
  waterAmountInside: number;
  /**
   * Water amount outside (g/m3)
   */
  waterAmountOutside: number;
}

export interface V2DrivingSlipCaseFragment_caseReports_moistureTrygReportFormData_floorReport_damageDescriptionImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface V2DrivingSlipCaseFragment_caseReports_moistureTrygReportFormData_floorReport_damageDrawingImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface V2DrivingSlipCaseFragment_caseReports_moistureTrygReportFormData_floorReport_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface V2DrivingSlipCaseFragment_caseReports_moistureTrygReportFormData_floorReport {
  /**
   * Name of floor
   */
  name: string | null;
  /**
   * Damage cause description
   */
  damageCause: string | null;
  /**
   * Multiple damage causes description
   */
  multipleDamageCauses: string | null;
  /**
   * Description of relation between damage cause and moisture
   */
  moistureDamageCause: string | null;
  /**
   * Damage description
   */
  damageDescription: string | null;
  /**
   * Floorplan image info
   */
  floorplanImage: V2DrivingSlipCaseFragment_caseReports_moistureTrygReportFormData_floorReport_floorplanImage | null;
  /**
   * Moisture meassurements
   */
  moistureMeassurements: V2DrivingSlipCaseFragment_caseReports_moistureTrygReportFormData_floorReport_moistureMeassurements[] | null;
  /**
   * Air meassurements
   */
  airMeassurements: V2DrivingSlipCaseFragment_caseReports_moistureTrygReportFormData_floorReport_airMeassurements;
  /**
   * Damage description image info
   */
  damageDescriptionImage: V2DrivingSlipCaseFragment_caseReports_moistureTrygReportFormData_floorReport_damageDescriptionImage[] | null;
  /**
   * Damage drawing image info
   */
  damageDrawingImage: V2DrivingSlipCaseFragment_caseReports_moistureTrygReportFormData_floorReport_damageDrawingImage | null;
  /**
   * Recommendations (if any)
   */
  recommendations: string | null;
  /**
   * Urgent actions (if any)
   */
  urgentActions: string | null;
  /**
   * List of other relevant images
   */
  otherImages: V2DrivingSlipCaseFragment_caseReports_moistureTrygReportFormData_floorReport_otherImages[] | null;
}

export interface V2DrivingSlipCaseFragment_caseReports_moistureTrygReportFormData {
  /**
   * Famage image info
   */
  facadeImage: V2DrivingSlipCaseFragment_caseReports_moistureTrygReportFormData_facadeImage | null;
  /**
   * Date of damage
   */
  inspectionDate: GQL_Date | null;
  /**
   * Gulvfugt info
   */
  floorReport: V2DrivingSlipCaseFragment_caseReports_moistureTrygReportFormData_floorReport[] | null;
}

export interface V2DrivingSlipCaseFragment_caseReports_indoorClimateReportFormData_facadeImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface V2DrivingSlipCaseFragment_caseReports_indoorClimateReportFormData_floorplanImage {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface V2DrivingSlipCaseFragment_caseReports_indoorClimateReportFormData_otherImages {
  /**
   * Name of file (with extension)
   */
  fileName: string;
  /**
   * User provided file description
   */
  description: string;
}

export interface V2DrivingSlipCaseFragment_caseReports_indoorClimateReportFormData_indoorClimateMeassurements {
  /**
   * Room
   */
  room: string | null;
  /**
   * Construction
   */
  construction: string | null;
  /**
   * Material
   */
  material: string | null;
  /**
   * Description
   */
  description: string | null;
  /**
   * Instrument
   */
  instrument: string | null;
  /**
   * Value
   */
  value: string | null;
  /**
   * Reference value
   */
  referenceValue: string | null;
  /**
   * Calculated value
   */
  calculatedValue: string | null;
}

export interface V2DrivingSlipCaseFragment_caseReports_indoorClimateReportFormData {
  /**
   * Famage image info
   */
  facadeImage: V2DrivingSlipCaseFragment_caseReports_indoorClimateReportFormData_facadeImage | null;
  /**
   * Date of damage
   */
  inspectionDate: GQL_Date | null;
  /**
   * Purpose of the report
   */
  purpose: string | null;
  /**
   * Year of the building being built
   */
  buildYear: string | null;
  /**
   * Square meters of the area
   */
  squareMeters: string | null;
  /**
   * Daily users of the building
   */
  dailyUsers: string | null;
  /**
   * Other participants on the scene
   */
  participants: string | null;
  /**
   * Information about what has happened until now
   */
  backgroundStory: string | null;
  /**
   * Observations
   */
  observations: string | null;
  /**
   * Refer to appendix for lab answers
   */
  showLabAnswers: boolean;
  /**
   * Refer to appendix for the data log
   */
  showDataLog: boolean | null;
  /**
   * Assessment and conclusion
   */
  assessmentAndConclusion: string | null;
  /**
   * Suggested action plan
   */
  suggestedActionPlan: string | null;
  /**
   * Floorplan image info
   */
  floorplanImage: V2DrivingSlipCaseFragment_caseReports_indoorClimateReportFormData_floorplanImage | null;
  /**
   * List of other relevant images
   */
  otherImages: V2DrivingSlipCaseFragment_caseReports_indoorClimateReportFormData_otherImages[] | null;
  /**
   * Meassurements of indoor climate
   */
  indoorClimateMeassurements: V2DrivingSlipCaseFragment_caseReports_indoorClimateReportFormData_indoorClimateMeassurements[] | null;
}

export interface V2DrivingSlipCaseFragment_caseReports {
  /**
   * Inspection report form data populated by the user
   */
  inspectionReportFormData: V2DrivingSlipCaseFragment_caseReports_inspectionReportFormData | null;
  /**
   * Inspection report form data populated by the user
   */
  inspectionMovablesReportFormData: V2DrivingSlipCaseFragment_caseReports_inspectionMovablesReportFormData | null;
  /**
   * Moisture report form data populated by the user
   */
  moistureReportFormData: V2DrivingSlipCaseFragment_caseReports_moistureReportFormData | null;
  /**
   * Moisture TRYG report form data populated by the user
   */
  moistureTrygReportFormData: V2DrivingSlipCaseFragment_caseReports_moistureTrygReportFormData | null;
  /**
   * Indooor climate report form data populated by the user
   */
  indoorClimateReportFormData: V2DrivingSlipCaseFragment_caseReports_indoorClimateReportFormData | null;
}

export interface V2DrivingSlipCaseFragment_requisitioner {
  /**
   * Name of person
   */
  name: string;
  /**
   * Optional person phone number
   */
  phone: string;
  /**
   * Person email address
   */
  email: string;
  /**
   * Requisitioner relation
   */
  relation: string;
}

export interface V2DrivingSlipCaseFragment_caseDraftItemsUsage {
  /**
   * ERP system reference id
   */
  erpId: string;
  /**
   * ERP system reference key
   */
  erpKey: string;
  /**
   * Usage line title
   */
  title: string;
  /**
   * Usage line quantity
   */
  quantity: GQL_Decimal;
  /**
   * Date of posting
   */
  date: GQL_Date;
  /**
   * Posted By Username
   */
  postedBy: string;
  /**
   * Description
   */
  description: string;
  __typename: "CaseDraftUsageLine";
}

export interface V2DrivingSlipCaseFragment {
  /**
   * Unique id of case
   */
  id: string;
  /**
   * ERP reference no
   */
  erpNo: string;
  /**
   * Requisition from fire department
   */
  skafor: boolean;
  /**
   * Case debitor details
   */
  debitor: V2DrivingSlipCaseFragment_debitor;
  /**
   * Case damage details
   */
  damage: V2DrivingSlipCaseFragment_damage;
  /**
   * Case policy holder details
   */
  policyHolder: V2DrivingSlipCaseFragment_policyHolder;
  /**
   * Project manager attached to case
   */
  projectManager: V2DrivingSlipCaseFragment_projectManager | null;
  /**
   * Case manager attached to case
   */
  caseManager: V2DrivingSlipCaseFragment_caseManager | null;
  /**
   * SSG location that is responsible for the case
   */
  ssgLocation: V2DrivingSlipCaseFragment_ssgLocation;
  /**
   * SSG department that is responsible for the case
   */
  ssgDepartment: V2DrivingSlipCaseFragment_ssgDepartment;
  /**
   * Risk evaluation answers
   */
  riskEvaluationAnswers: V2DrivingSlipCaseFragment_riskEvaluationAnswers[] | null;
  /**
   * Optional comment for risk evaluation
   */
  riskEvaluationComment: string | null;
  /**
   * Case visitation details
   */
  visitation: V2DrivingSlipCaseFragment_visitation;
  /**
   * Whether time registration is allowed on the case
   */
  timeRegistration: boolean | null;
  caseReports: V2DrivingSlipCaseFragment_caseReports | null;
  /**
   * Case requisitioner details
   */
  requisitioner: V2DrivingSlipCaseFragment_requisitioner;
  /**
   * List of draft case items usage
   */
  caseDraftItemsUsage: V2DrivingSlipCaseFragment_caseDraftItemsUsage[];
  /**
   * Case change version number
   */
  version: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CalendarEventType {
  DRIVING_SLIP = "DRIVING_SLIP",
  EDUCATION = "EDUCATION",
  ON_CALL = "ON_CALL",
  SICK_LEAVE = "SICK_LEAVE",
  SLEEP = "SLEEP",
  UNKNOWN = "UNKNOWN",
  VACATION = "VACATION",
}

export enum CaseAgreementCategory {
  CAUSE = "CAUSE",
  FURTHER_ACTION = "FURTHER_ACTION",
  OTHER = "OTHER",
  PRIVATE = "PRIVATE",
  SCOPE = "SCOPE",
  WORK_COMPLETED = "WORK_COMPLETED",
}

export enum CasePriority {
  LOSS_OF_BUSINESS_EARNING_CAPACITY = "LOSS_OF_BUSINESS_EARNING_CAPACITY",
  RESIDENTIAL_AREA = "RESIDENTIAL_AREA",
  SOCIAL_IMPACT = "SOCIAL_IMPACT",
}

export enum CaseStatus {
  APPLIED_CLOSED = "APPLIED_CLOSED",
  CLOSED = "CLOSED",
  OPEN = "OPEN",
  _NULL_ = "_NULL_",
}

export enum CatalogContacts {
  CLOSED_CONTACTS = "CLOSED_CONTACTS",
  CLOSED_CONTACTS_MAIL_CONTACTS = "CLOSED_CONTACTS_MAIL_CONTACTS",
  MAIL_CONTACTS = "MAIL_CONTACTS",
  OPEN_CONTACTS = "OPEN_CONTACTS",
  OPEN_CONTACTS_CLOSED_CONTACTS = "OPEN_CONTACTS_CLOSED_CONTACTS",
  OPEN_CONTACTS_CLOSED_CONTACTS_MAIL_CONTACTS = "OPEN_CONTACTS_CLOSED_CONTACTS_MAIL_CONTACTS",
  OPEN_CONTACTS_MAIL_CONTACTS = "OPEN_CONTACTS_MAIL_CONTACTS",
}

export enum CatalogGlobalType {
  CATALOGS_GLOBAL = "CATALOGS_GLOBAL",
  CUSTOMER_GLOBAL = "CUSTOMER_GLOBAL",
  NOT_GLOBAL = "NOT_GLOBAL",
}

export enum CollectionItemType {
  ACCESS_CONDITION = "ACCESS_CONDITION",
  CATALOG_CRAFTSMEN_PRIORITY = "CATALOG_CRAFTSMEN_PRIORITY",
  CATALOG_CRAFTSMEN_TYPE = "CATALOG_CRAFTSMEN_TYPE",
  DAMAGE_BOX_DESCRIPTION = "DAMAGE_BOX_DESCRIPTION",
  DAMAGE_BOX_REQUESTER_RELATIONSHIP = "DAMAGE_BOX_REQUESTER_RELATIONSHIP",
  DAMAGE_CONTACTS_ROLE = "DAMAGE_CONTACTS_ROLE",
  NOT_CASE_RELATED_WORK = "NOT_CASE_RELATED_WORK",
  VISITATION_DESCRIPTION_AWAIT = "VISITATION_DESCRIPTION_AWAIT",
  VISITATION_DESCRIPTION_CALL_BACK = "VISITATION_DESCRIPTION_CALL_BACK",
}

export enum DebitorType {
  AGRICULTURE = "AGRICULTURE",
  BUSINESS = "BUSINESS",
  PRIVATE = "PRIVATE",
  UNKNOWN = "UNKNOWN",
}

export enum DrivingSlipStatus {
  ARRIVED = "ARRIVED",
  COMPLETED = "COMPLETED",
  CONVERTED_TO_REQUISITION = "CONVERTED_TO_REQUISITION",
  OBSOLETE = "OBSOLETE",
  ON_HOLD = "ON_HOLD",
  PLANNED = "PLANNED",
  STARTED = "STARTED",
  UNPLANNED = "UNPLANNED",
  _NULL_ = "_NULL_",
}

/**
 * Metadata fields that can be set in Sharepoint.
 */
export enum FileMetadataField {
  Comments = "Comments",
  DocumentType = "DocumentType",
  IsFacadePicture = "IsFacadePicture",
  QuestionnaireSection = "QuestionnaireSection",
  ReportName = "ReportName",
  ReportSection = "ReportSection",
}

export enum HouseNumbers {
  BOTH = "BOTH",
  EQUAL = "EQUAL",
  ODD = "ODD",
}

export enum InvoiceCategory {
  ACCOUNT = "ACCOUNT",
  FINAL = "FINAL",
  SCREENING = "SCREENING",
  SUB = "SUB",
  TRACK_ONE = "TRACK_ONE",
}

export enum InvoiceStatus {
  APPROVED = "APPROVED",
  AWAITING_APPROVAL = "AWAITING_APPROVAL",
  DRAFT = "DRAFT",
  POSTED = "POSTED",
  REJECTED = "REJECTED",
}

export enum JobType {
  CASE = "CASE",
  GENERAL = "GENERAL",
  MOVABLE = "MOVABLE",
}

export enum MachineConsumptionType {
  DAYS = "DAYS",
  HOURS = "HOURS",
  KWH = "KWH",
  NONE = "NONE",
}

export enum MachineStatus {
  ALL = "ALL",
  AVAILABLE = "AVAILABLE",
  DEPLOYED = "DEPLOYED",
}

export enum NotificationTrigger {
  CASE_CLOSED = "CASE_CLOSED",
  CASE_CLOSED_REQUEST = "CASE_CLOSED_REQUEST",
  CASE_CREATED = "CASE_CREATED",
  CASE_CREATED_ANONYMOUS = "CASE_CREATED_ANONYMOUS",
  CASE_CREATED_EXTERNAL_PARTNER = "CASE_CREATED_EXTERNAL_PARTNER",
  CASE_ESTIMATE_EDITED = "CASE_ESTIMATE_EDITED",
  CASE_MASTER_DATA_EDITED = "CASE_MASTER_DATA_EDITED",
  CASE_MATERIAL_PURCHASED = "CASE_MATERIAL_PURCHASED",
  CASE_REQUISITION_CREATED = "CASE_REQUISITION_CREATED",
  CASE_SEND_EMAIL = "CASE_SEND_EMAIL",
  CATALOG_CREATED = "CATALOG_CREATED",
  CATALOG_DELETED = "CATALOG_DELETED",
  CATALOG_EDITED = "CATALOG_EDITED",
  DEPARTMENT_REQUISITION_CREATED = "DEPARTMENT_REQUISITION_CREATED",
  DRIVING_SLIP_ASSIGNED = "DRIVING_SLIP_ASSIGNED",
  DRIVING_SLIP_EDITED = "DRIVING_SLIP_EDITED",
  DRIVING_SLIP_REMOVED = "DRIVING_SLIP_REMOVED",
  INVOICE_APPROVAL_REQUESTED = "INVOICE_APPROVAL_REQUESTED",
  INVOICE_APPROVED = "INVOICE_APPROVED",
  INVOICE_REJECTED = "INVOICE_REJECTED",
  JOB_PERSONAL_ASSIGNED = "JOB_PERSONAL_ASSIGNED",
  JOB_PERSONAL_DEADLINE = "JOB_PERSONAL_DEADLINE",
  MACHINE_HIBERNATION = "MACHINE_HIBERNATION",
  MACHINE_MOVED = "MACHINE_MOVED",
  TIME_TRACKING_ENTRY_DECLINED = "TIME_TRACKING_ENTRY_DECLINED",
  WARNING_CASE_ESTIMATE_EXCEEDED = "WARNING_CASE_ESTIMATE_EXCEEDED",
  WARNING_CASE_MILESTONE_MISSED = "WARNING_CASE_MILESTONE_MISSED",
  WARNING_MACHINE_OVERDUE = "WARNING_MACHINE_OVERDUE",
}

export enum OfferLineStatus {
  CREATE = "CREATE",
  DELETE = "DELETE",
  NONE = "NONE",
  UPDATE = "UPDATE",
}

export enum OfferQuoteTypeEnum {
  ESTIMATE = "ESTIMATE",
  GUESSTIMATE = "GUESSTIMATE",
  QUOTE = "QUOTE",
}

export enum OfferRessourceType {
  GL_ACCOUNT = "GL_ACCOUNT",
  ITEM = "ITEM",
  RESOURCE = "RESOURCE",
  TEXT = "TEXT",
}

export enum Permissions {
  ADMINISTRATION_CATALOGS_OWNER = "ADMINISTRATION_CATALOGS_OWNER",
  ADMINISTRATION_GDPR = "ADMINISTRATION_GDPR",
  ADMINISTRATION_SITE_VIEW = "ADMINISTRATION_SITE_VIEW",
  ADMINISTRATION_USER_MANAGEMENT = "ADMINISTRATION_USER_MANAGEMENT",
  ADMINISTRATION_WORKPLACE_ASSESSMENT = "ADMINISTRATION_WORKPLACE_ASSESSMENT",
  ALL_MACHINE_TOKEN = "ALL_MACHINE_TOKEN",
  CASES_AGREEMENTS_EDIT = "CASES_AGREEMENTS_EDIT",
  CASES_AGREEMENTS_PRIVATE = "CASES_AGREEMENTS_PRIVATE",
  CASES_CLOSE = "CASES_CLOSE",
  CASES_CREATE = "CASES_CREATE",
  CASES_DOCUMENTS_DELETE = "CASES_DOCUMENTS_DELETE",
  CASES_DOCUMENTS_VIEW = "CASES_DOCUMENTS_VIEW",
  CASES_DRIVING_SLIPS_EDIT = "CASES_DRIVING_SLIPS_EDIT",
  CASES_ECONOMY_INVOICES_EDIT = "CASES_ECONOMY_INVOICES_EDIT",
  CASES_ECONOMY_INVOICES_VIEW = "CASES_ECONOMY_INVOICES_VIEW",
  CASES_ECONOMY_VIEW = "CASES_ECONOMY_VIEW",
  CASES_EMAIL_EDIT = "CASES_EMAIL_EDIT",
  CASES_ESTIMATE_CALCULATION = "CASES_ESTIMATE_CALCULATION",
  CASES_EXTERNAL_SYSTEM_CREATE = "CASES_EXTERNAL_SYSTEM_CREATE",
  CASES_MASTER_DATA_EDIT = "CASES_MASTER_DATA_EDIT",
  CASES_MASTER_DATA_INVOICES_ADDRESS_VIEW = "CASES_MASTER_DATA_INVOICES_ADDRESS_VIEW",
  CASES_ONGOING_WORK_VIEW = "CASES_ONGOING_WORK_VIEW",
  CASES_OPEN = "CASES_OPEN",
  CASES_OWN_CASES_ONLY = "CASES_OWN_CASES_ONLY",
  CASES_RELATED_CASE_CREATE = "CASES_RELATED_CASE_CREATE",
  CASES_REPORT_EDIT = "CASES_REPORT_EDIT",
  CASES_USAGE_VIEW = "CASES_USAGE_VIEW",
  CASES_VIEW = "CASES_VIEW",
  CASES_VIEW_EXTERNAL = "CASES_VIEW_EXTERNAL",
  CASES_WORKPLACE_ASSESSMENT = "CASES_WORKPLACE_ASSESSMENT",
  CATALOGS_DEBITOR_VERIFY = "CATALOGS_DEBITOR_VERIFY",
  CATALOGS_DELETE = "CATALOGS_DELETE",
  CATALOGS_EDIT = "CATALOGS_EDIT",
  CATALOGS_INFORMATION_BOX_EDIT = "CATALOGS_INFORMATION_BOX_EDIT",
  CATALOGS_VERIFY = "CATALOGS_VERIFY",
  CATALOGS_VIEW = "CATALOGS_VIEW",
  DRIVING_SLIPS_VIEW = "DRIVING_SLIPS_VIEW",
  KP_IS_VIEW = "KP_IS_VIEW",
  MACHINES_EDIT = "MACHINES_EDIT",
  MACHINES_VIEW = "MACHINES_VIEW",
  MOVABLES_EDIT = "MOVABLES_EDIT",
  MOVABLES_VIEW = "MOVABLES_VIEW",
  MY_PAGE_VIEW = "MY_PAGE_VIEW",
  NONE = "NONE",
  PLANNING_EDIT = "PLANNING_EDIT",
  PLANNING_VIEW = "PLANNING_VIEW",
  REQUISITION_EDIT = "REQUISITION_EDIT",
  REQUISITION_VIEW = "REQUISITION_VIEW",
  TASKS_EDIT = "TASKS_EDIT",
  TASKS_VIEW = "TASKS_VIEW",
  TIME_TRACKING = "TIME_TRACKING",
  TIME_TRACKING_APPROVER = "TIME_TRACKING_APPROVER",
  TIME_TRACKING_DATE_PICKER = "TIME_TRACKING_DATE_PICKER",
  TIME_TRACKING_RESPONSIBLE = "TIME_TRACKING_RESPONSIBLE",
}

export enum QuestionnaireChoiceType {
  DROPDOWN = "DROPDOWN",
  FREE_TEXT = "FREE_TEXT",
  NUMBER = "NUMBER",
  RADIO_GROUP = "RADIO_GROUP",
  SELECT_OPTION = "SELECT_OPTION",
  _NULL_ = "_NULL_",
}

export enum QuestionnaireCompletionStatus {
  COMPLETED = "COMPLETED",
  NOT_COMPLETE = "NOT_COMPLETE",
  _NULL_ = "_NULL_",
}

export enum ReportFormatType {
  PDF = "PDF",
  WORD = "WORD",
}

export enum RequisitionType {
  MATERIAL = "MATERIAL",
  SUBCONTRACTOR = "SUBCONTRACTOR",
}

export enum RiskEvaluationStatus {
  ACCEPTED = "ACCEPTED",
  NOT_ACCEPTED = "NOT_ACCEPTED",
  _NULL_ = "_NULL_",
}

export enum SalesHeaderEnum {
  ACTIVATE = "ACTIVATE",
  CANCEL = "CANCEL",
  UPDATE = "UPDATE",
}

export enum ScalePointStatus {
  APPROVED = "APPROVED",
  CONNECTED = "CONNECTED",
  ERRORED = "ERRORED",
  NOT_CONNECTED = "NOT_CONNECTED",
  REJECTED = "REJECTED",
}

export enum SupplementType {
  PIECEWORK = "PIECEWORK",
  STAND_ALONE = "STAND_ALONE",
  SURCHARGE = "SURCHARGE",
  TIMEREGISTRATION = "TIMEREGISTRATION",
}

export enum TimeTrackingStatus {
  APPROVED = "APPROVED",
  AWAITING = "AWAITING",
  OPEN = "OPEN",
  REJECTED = "REJECTED",
}

export enum TrackingType {
  DAYCLOSED = "DAYCLOSED",
  REJECTEDDAY = "REJECTEDDAY",
  TIMEREGISTRATION = "TIMEREGISTRATION",
}

export enum UserCaseFilterViewType {
  ALL = "ALL",
  FAVORITES = "FAVORITES",
  INVOICE_READY = "INVOICE_READY",
  NOT_PLANNED = "NOT_PLANNED",
  OWN = "OWN",
}

export interface AddressInput {
  road: string;
  houseNumber: string;
  floor?: string | null;
  addressLine?: string | null;
  addressLineAlt?: string | null;
  postalCode: string;
  city: string;
  country?: string | null;
}

export interface AirMeassurementDetailsInput {
  temperatureInside: number;
  temperatureOutside: number;
  relativeHumidityInside: number;
  relativeHumidityOutside: number;
  waterAmountInside: number;
  waterAmountOutside: number;
}

export interface AnswerInput {
  question: string;
  answer: string;
}

export interface BoolWithCommentInput {
  value: boolean;
  comment?: string | null;
}

export interface CaseAgreementInput {
  content: string;
  category: CaseAgreementCategory;
  shouldSaveInScalePoint: boolean;
}

export interface CompanyContactInput {
  name: string;
  phone: string;
  email: string;
  comment?: string | null;
  companyName?: string | null;
  companyPhone?: string | null;
}

export interface ContactInput {
  name: string;
  phone: string;
  email: string;
  comment?: string | null;
  address: AddressInput;
}

export interface DamageContactInput {
  name: string;
  role: string;
  phone?: string | null;
  email?: string | null;
}

export interface DamageInput {
  businessArea: string;
  category: string;
  cause: string;
  date: GQL_Date;
  description: string;
  contact: ContactInput;
  accessConditions?: string | null;
  contacts?: DamageContactInput[] | null;
}

export interface DateRangeInput {
  from: GQL_Date;
  to: GQL_Date;
}

export interface DebitorInput {
  type: DebitorType;
  company: string;
  attention?: string | null;
  shippingAddressReference?: string | null;
  billingAddress?: AddressInput | null;
  excess?: GQL_Decimal | null;
  policeNumber: string;
  cvrNumber: string;
  debitorId: string;
}

export interface DrivingSlipQuestionnaireInputType {
  template: DrivingSlipQuestionnaireTemplateInput;
  sourceTemplateId: string;
  sourceTemplateVersion: number;
}

export interface DrivingSlipQuestionnaireTemplateInput {
  label: string;
  sections: QuestionnaireSectionInputType[];
  damageCauses: string[];
  drivingSlipCategories: string[];
}

export interface FalckDehumidifierReportFormDataInput {
  subscriptionNumber: string;
  dehumidifierNumbers: string[];
  signature?: ReportFileInput | null;
  customerEmail?: string | null;
}

export interface FileFilterInput {
  filterName: string;
  filterQuery: string;
}

export interface FileInput {
  filename: string;
  fileData: GQL_Byte[];
  fileMetadata?: FileMetadataInput[] | null;
}

export interface FileMetadataInput {
  key: FileMetadataField;
  value: string;
}

export interface FloorMoistureReportFormDataInput {
  name: string;
  observations: MoistureReportObservationsInput;
  moistureMeassurements?: MoistureMeassurementInput[] | null;
  floorplanImage?: ReportFileInput | null;
  otherImages?: ReportFileInput[] | null;
}

export interface FloorMoistureTrygReportFormDataInput {
  name?: string | null;
  damageCause?: string | null;
  multipleDamageCauses?: string | null;
  moistureDamageCause?: string | null;
  damageDescription?: string | null;
  floorplanImage?: ReportFileInput | null;
  moistureMeassurements?: TrygMoistureMeassurementInput[] | null;
  airMeassurements: AirMeassurementDetailsInput;
  damageDescriptionImage?: ReportFileInput[] | null;
  damageDrawingImage?: ReportFileInput | null;
  recommendations?: string | null;
  urgentActions?: string | null;
  otherImages?: ReportFileInput[] | null;
}

export interface GeoCoordinateInput {
  latitude: number;
  longitude: number;
}

export interface IndoorClimateMeassurementInput {
  room?: string | null;
  construction?: string | null;
  material?: string | null;
  description?: string | null;
  instrument?: string | null;
  value?: string | null;
  referenceValue?: string | null;
  calculatedValue?: string | null;
}

export interface IndoorClimateReportFormDataInput {
  facadeImage?: ReportFileInput | null;
  inspectionDate?: GQL_Date | null;
  purpose?: string | null;
  buildYear?: string | null;
  squareMeters?: string | null;
  dailyUsers?: string | null;
  participants?: string | null;
  backgroundStory?: string | null;
  observations?: string | null;
  showLabAnswers?: boolean | null;
  showDataLog?: boolean | null;
  indoorClimateMeassurements?: IndoorClimateMeassurementInput[] | null;
  assessmentAndConclusion?: string | null;
  suggestedActionPlan?: string | null;
  floorplanImage?: ReportFileInput | null;
  otherImages?: ReportFileInput[] | null;
}

export interface InspectionReportFormDataInput {
  facadeImage?: ReportFileInput | null;
  backgroundStory?: string | null;
  observations?: string | null;
  assessmentAndConclusion?: string | null;
  suggestedActionPlan?: string | null;
  comments?: string | null;
  floorplanImage?: ReportFileInput | null;
  otherImages?: ReportFileInput[] | null;
}

export interface MachineInput {
  erpReferenceNo: string;
  startingDate: string;
  startingTime: string;
  erpCaseReference: string;
  consumption: GQL_Decimal;
  hibernate: boolean;
  hibernationReason: string;
  unavailableReason: string;
  erpLocationReference: string;
  allocationType: string;
  reservationByRessource?: string | null;
  reservationDescription?: string | null;
  reservationOnCase?: string | null;
  reservationStart?: string | null;
  reservationEnd?: string | null;
  placementDescription?: string | null;
}

export interface MoistureMeassurementInput {
  construction?: string | null;
  material?: string | null;
  description?: string | null;
  method?: string | null;
  value: string;
  expectedFinalValue?: string | null;
  referenceValue?: string | null;
  referenceDate?: string | null;
  controlValue?: string | null;
  controlDate?: string | null;
}

export interface MoistureReportFormDataInput {
  floorMoistureReportFormDataInput?: FloorMoistureReportFormDataInput[] | null;
  facadeImage?: ReportFileInput | null;
  inspectionDate: GQL_Date;
  damageCause: string;
  scope: string;
  contacts: string;
  damageCauseImages?: ReportFileInput[] | null;
  conclusion?: string | null;
}

export interface MoistureReportObservationsInput {
  roomOrApartment: string;
  construction?: string | null;
  ceiling?: string | null;
  walls?: string | null;
  floor?: string | null;
  scope: string;
  recommendations: string;
}

export interface MoistureTrygReportFormDataInput {
  facadeImage?: ReportFileInput | null;
  inspectionDate?: GQL_Date | null;
  floorMoistureTrygReportData?: FloorMoistureTrygReportFormDataInput[] | null;
}

export interface OfferInput {
  jobNo: string;
  lines: OfferLineInput[];
  oppertunityStatusCode?: string | null;
  headerText?: string | null;
  footerText?: string | null;
  quoteType: OfferQuoteTypeEnum;
  competitionOffer: boolean;
}

export interface OfferLineInput {
  caseERPReferenceNo: string;
  planningDate: string;
  description: string;
  quantity: GQL_Decimal;
  unitPrice: GQL_Decimal;
  newUnitPrice: GQL_Decimal;
  no: string;
  workType: string;
  documentNo?: string | null;
  systemId?: string | null;
  offerLineStatus: OfferLineStatus;
  eRPReferenceTask: string;
  type: OfferRessourceType;
  option: boolean;
  hideSum: boolean;
  header: boolean;
  sortingIndex: number;
  lineNo?: number | null;
}

export interface PersonInput {
  name: string;
  phone: string;
  email: string;
  comment?: string | null;
}

export interface QuestionnaireChoiceInputType {
  id: string;
  parentId: string;
  type: QuestionnaireChoiceType;
  label: string;
  value?: string | null;
  placeholder?: string | null;
  helpText?: string | null;
  isOptional: boolean;
  triggersTrackTwoChange?: boolean | null;
  unitOfMeasure?: string | null;
  isMultiSelectAllowed: boolean;
  multiSelectValues?: string[] | null;
}

export interface QuestionnaireSectionInputType {
  label: string;
  choices: QuestionnaireChoiceInputType[];
  photoRequired: boolean;
  shouldSaveInScalePoint: boolean;
  caseAgreementCategory: CaseAgreementCategory;
}

export interface QuestionnaireTemplateByDrivingSlipInput {
  categoryCode?: string | null;
  damageCauseId: string;
}

export interface ReportFileInput {
  fileName: string;
  description?: string | null;
}

export interface RequisitionInput {
  description: string;
  type: RequisitionType;
  vendorName: string;
  vendorPhoneNumber: string;
  vendorNo?: string | null;
  vendorEmail?: string | null;
}

export interface RequisitionerInput {
  name: string;
  phone: string;
  email: string;
  comment?: string | null;
  relation: string;
}

export interface RiskEvaluationAnswerInput {
  question: string;
  answer: boolean;
}

export interface TimeTrackingInputType {
  user: string;
  trackingType: TrackingType;
  date: GQL_Date;
  hours: number;
  case?: string | null;
  jobNo?: string | null;
  drivingSlip?: string | null;
  remark?: string | null;
  jobTaskNo?: string | null;
  onCall?: boolean | null;
  jobTaskName?: string | null;
  workTypeCode?: string | null;
  timeTrackingStatus?: TimeTrackingStatus | null;
  supplementType?: SupplementType | null;
  pieceworkAmount?: number | null;
  paymentSupplementCode?: string | null;
  paymentSupplementName?: string | null;
  supplementUom?: string | null;
  addonLines?: TimeTrackingInputType[] | null;
}

export interface TrygMoistureMeassurementInput {
  construction?: string | null;
  material?: string | null;
  description?: string | null;
  method?: string | null;
  paints?: string | null;
  paintsDate?: string | null;
  expectedFinalValue?: string | null;
  expectedFinalDate?: string | null;
  referenceValue?: string | null;
  referenceDate?: string | null;
  controlValue?: string | null;
  controlDate?: string | null;
}

export interface VisitationInput {
  urgent: boolean;
  priorities: CasePriority[];
  regress?: boolean | null;
  isMoistureReportRequired?: boolean | null;
  isSpPhaseOneReportCreated?: boolean | null;
  calledBack: BoolWithCommentInput;
  awaiting: BoolWithCommentInput;
  answers?: AnswerInput[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
