/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { GetAllMatchingOptions, QueueStoreEntry, UnidentifiedQueueStoreEntry } from './types';
import { IDBPDatabase, openDB } from 'idb';

export class QueueStore<T extends Record<string, any>> {
	private readonly _databaseName: string;
	private readonly _databaseVersion: number | undefined;
	private readonly _objectStoreName: string;
	private readonly _indexedPropName: string;
	private readonly _queueName: string;

	private _db: IDBPDatabase<unknown> | undefined;

	constructor(databaseName: string, databaseVersion: number | undefined, objectStoreName: string, indexedPropName: string, queueName: string) {
		this._databaseName = databaseName;
		this._databaseVersion = databaseVersion;
		this._objectStoreName = objectStoreName;
		this._indexedPropName = indexedPropName;
		this._queueName = queueName;
	}

	async initialize(): Promise<void> {
		this._db = await openDB(this._databaseName, this._databaseVersion, {
			upgrade: (db, oldVersion, newVersion, transaction, event) => {
				console.log('Queue Store: upgrade', {
					db,
					oldVersion,
					newVersion,
					transaction,
					event,
				});

				if (!this._databaseVersion || (event.oldVersion > 0 && event.oldVersion < this._databaseVersion)) {
					if (db.objectStoreNames.contains(this._objectStoreName)) {
						db.deleteObjectStore(this._objectStoreName);
					}
				}

				const objStore = db.createObjectStore(this._objectStoreName, {
					autoIncrement: true,
					keyPath: 'id',
				});

				objStore.createIndex(this._indexedPropName, this._indexedPropName, { unique: false });
			},
			blocked: (currentVersion, blockedVersion, event) => {
				console.log('Queue Store: blocked', {
					currentVersion,
					blockedVersion,
					event,
				});
			},
			blocking: (currentVersion, blockedVersion, event) => {
				console.log('Queue Store: blocking', {
					currentVersion,
					blockedVersion,
					event,
				});
			},
			terminated: () => {
				console.log('Queue Store: terminated');
			},
		});
	}

	async pushEntry(entry: UnidentifiedQueueStoreEntry<T>): Promise<void> {
		if (!this._db) return;
		// Don't specify an ID since one is automatically generated.
		delete entry.id;
		entry.queueName = this._queueName;

		await this._db.add(this._objectStoreName, entry);
	}

	async unshiftEntry(entry: UnidentifiedQueueStoreEntry<T>): Promise<void> {
		if (!this._db) return;

		const [firstEntry] = (await this._getAllMatching(this._objectStoreName, {
			count: 1,
		})) as QueueStoreEntry<T>[];

		if (firstEntry) {
			// Pick an ID one less than the lowest ID in the object store.
			entry.id = firstEntry.id - 1;
		} else {
			// Otherwise let the auto-incrementor assign the ID.
			delete entry.id;
		}

		entry.queueName = this._queueName;

		await this._db.add(this._objectStoreName, entry);
	}

	async popEntry(): Promise<QueueStoreEntry<T> | undefined> {
		return this._removeEntry({ direction: 'prev' });
	}

	async shiftEntry(): Promise<QueueStoreEntry<T> | undefined> {
		return this._removeEntry({ direction: 'next' });
	}

	async getAll(): Promise<QueueStoreEntry<T>[]> {
		if (!this._db) return [];

		return await this._getAllMatching<QueueStoreEntry<T>>(this._objectStoreName, {
			index: this._indexedPropName,
			query: IDBKeyRange.only(this._queueName),
		});
	}

	async deleteEntry(id: number): Promise<void> {
		if (!this._db) return;

		await this._db.delete(this._objectStoreName, id);
	}

	async _removeEntry({ direction }: { direction?: IDBCursorDirection }) {
		if (!this._db) return;

		const [entry] = await this._getAllMatching<QueueStoreEntry<T>>(this._objectStoreName, {
			direction,
			index: this._indexedPropName,
			query: IDBKeyRange.only(this._queueName),
			count: 1,
		});

		if (entry) {
			await this.deleteEntry(entry.id);
			return entry;
		}
	}

	async _getAllMatching<T>(
		storeName: string,
		{
			index,
			query = null, // IE/Edge errors if query === `undefined`.
			direction = 'next',
			count,
			includeKeys = false,
		}: GetAllMatchingOptions = {},
	): Promise<T[]> {
		if (!this._db) return [];

		const tx = this._db.transaction(storeName, 'readonly');
		const store = tx.store;
		const target = index ? store.index(index) : store;
		const results: T[] = [];

		let cursor = await target.openCursor(query, direction);
		while (cursor) {
			results.push(includeKeys ? cursor : cursor.value);

			if (count && results.length >= count) {
				return results;
			} else {
				cursor = await cursor.continue();
			}
		}

		return results;
	}

	async getCount(): Promise<number> {
		if (!this._db) return 0;
		// const tx = this._db.transaction(this._objectStoreName, 'readonly');
		// const count = await tx.store.index(this._indexedPropName).count();
		// //const count = await store.count();
		// //const count = await this._db.count(this._objectStoreName, IDBKeyRange.only(this._queueName));
		// console.log(count);
		// return count;
		const tx = this._db.transaction(this._objectStoreName, 'readonly');
		const store = tx.store;
		const target = store.index(this._indexedPropName);
		let count = 0;

		let cursor = await target.openCursor(IDBKeyRange.only(this._queueName), 'next');
		while (cursor) {
			count++;
			// if (count && results.length >= count) {
			// 	return results;
			// } else {
			cursor = await cursor.continue();
			// }
		}

		return count;
	}
}

