import { IQueue, QueueEntry, QueueOptions, UnidentifiedQueueStoreEntry } from './types';
import { MAX_RETENTION_TIME } from './constants';
import { QueueStore } from './QueueStore';

const queueNames = new Set<string>();

const convertEntry = <T extends Record<string, any>>(queueStoreEntry: UnidentifiedQueueStoreEntry<T>): QueueEntry<T> => {
	const queueEntry: QueueEntry<T> = {
		data: queueStoreEntry.data,
		id: queueStoreEntry.id,
		timestamp: queueStoreEntry.timestamp,
	};

	if (queueStoreEntry.metadata) {
		queueEntry.metadata = queueStoreEntry.metadata;
	}

	return queueEntry;
};

export class Queue<T extends Record<string, any>> implements IQueue<T> {
	private readonly _name: string;
	private readonly _maxRetentionTime: number;
	private readonly _queueStore: QueueStore<T> | undefined;

	constructor(databaseName: string, databaseVersion: number | undefined, objectStoreName: string, indexedPropName: string, queueName: string, { maxRetentionTime }: QueueOptions = {}) {
		// Ensure the store name is not already being used
		if (queueNames.has(queueName)) {
			throw new Error('Duplicate queue name: queueName');
		} else {
			queueNames.add(queueName);
		}

		this._name = queueName;
		this._maxRetentionTime = maxRetentionTime ?? MAX_RETENTION_TIME;
		this._queueStore = new QueueStore<T>(databaseName, databaseVersion, objectStoreName, indexedPropName, this._name);
	}

	get name(): string {
		return this._name;
	}

	async initialize(): Promise<void> {
		if (!this._queueStore) return;

		await this._queueStore.initialize();
	}

	async pushEntry(entry: QueueEntry<T>): Promise<void> {
		await this._addEntry(entry, 'push');
	}

	async unshiftEntry(entry: QueueEntry<T>): Promise<void> {
		await this._addEntry(entry, 'unshift');
	}

	async popEntry(): Promise<QueueEntry<T> | undefined> {
		return this._removeEntry('pop');
	}

	async shiftEntry(): Promise<QueueEntry<T> | undefined> {
		return this._removeEntry('shift');
	}

	async deleteEntry(id: number): Promise<void> {
		if (!this._queueStore) return;

		return this._queueStore.deleteEntry(id);
	}

	async getAll(): Promise<QueueEntry<T>[]> {
		if (!this._queueStore) return [];

		const allEntries = await this._queueStore.getAll();
		const now = Date.now();

		const unexpiredEntries: QueueEntry<T>[] = [];
		for (const entry of allEntries) {
			// Ignore entries older than maxRetentionTime. Call this function
			// recursively until an unexpired request is found.
			const maxRetentionTimeInMs = this._maxRetentionTime * 60 * 1000;
			if (now - entry.timestamp > maxRetentionTimeInMs) {
				await this._queueStore.deleteEntry(entry.id);
			} else {
				unexpiredEntries.push(convertEntry(entry));
			}
		}

		return unexpiredEntries;
	}

	async getCount(): Promise<number> {
		if (!this._queueStore) return 0;

		return await this._queueStore.getCount();
	}

	/**
	 * Adds the entry to the QueueStore and registers for a sync event.
	 *
	 * @private
	 */
	async _addEntry({ data, metadata, timestamp = Date.now() }: QueueEntry<T>, operation: 'push' | 'unshift'): Promise<void> {
		if (!this._queueStore) return;

		const entry: UnidentifiedQueueStoreEntry<T> = {
			data,
			timestamp,
		};

		// Only include metadata if it's present.
		if (metadata) {
			entry.metadata = metadata;
		}

		await this._queueStore[`${operation}Entry` as 'pushEntry' | 'unshiftEntry'](entry);

		if (process.env.NODE_ENV !== 'production') {
			console.log(`Item has been added to background sync queue '${this._name}'.`);
		}
	}

	/**
	 * Removes entry from the QueueStore.
	 *
	 * @private
	 */
	async _removeEntry(operation: 'pop' | 'shift'): Promise<QueueEntry<T> | undefined> {
		if (!this._queueStore) return;

		const now = Date.now();
		const entry = await this._queueStore[`${operation}Entry` as 'popEntry' | 'shiftEntry']();

		if (entry) {
			// Ignore entries older than maxRetentionTime. Call this function
			// recursively until an unexpired entry is found.
			const maxRetentionTimeInMs = this._maxRetentionTime * 60 * 1000;
			if (now - entry.timestamp > maxRetentionTimeInMs) {
				return this._removeEntry(operation);
			}

			return convertEntry(entry);
		} else {
			return undefined;
		}
	}

	/**
	 * Returns the set of queue names. This is primarily used to reset the list
	 * of queue names in tests.
	 *
	 * @return {Set}
	 *
	 * @private
	 */
	static get _queueNames(): Set<string> {
		return queueNames;
	}
}

