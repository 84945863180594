import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTrashAlt, faLockOpen } from '@fortawesome/pro-regular-svg-icons';
import { Trans, useTranslation } from 'react-i18next';
import { countOnCallHours, useDeleteTimeTrackingDay, useReopenDay, useTimeTrackingsDayForUserData } from './TimeRegHelpers';
import { BaseTimeTrackingEntryFragment, TimeTrackingDay, TimeTrackingDayStatus, User } from '@ssg/common/GraphQL/indexV2';
import { formatDateForInputEU } from '../Helpers/dateToDateOnlyString';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '../FeatureFlagEnums';
import BoxContainer from '@ssg/common/Components/BoxContainer';
import classNames from 'classnames';
import DayScheduler from './DayScheduler';
import TimeRegistrationForm from './TimeRegistrationForm';
import Modal from '@ssg/common/Components/Modal';
import Button from '../Components/Button';

interface Props {
	userContext: {
		id: string,
		permissions: {
			approver: boolean | undefined;
			responsible: boolean | undefined;
			datePicker: boolean | undefined;
		},
		salaryType: string | null;
		hireDate: string | null;
	};
	compactMode?: boolean;
	rejectModal?: JSX.Element;
}

const TimeRegistrationOverviewNew: React.FC<Props> = ({ userContext, compactMode = false, rejectModal }) => {
	const { t } = useTranslation();

	const reopenDayEntry = useFlag(FeatureFlagEnums.REOPEN_DAY_ENTRY);
	const showOnCallHours = useFlag(FeatureFlagEnums.SHOW_ONCALL_HOURS);

	const [userId, setUserId] = React.useState(userContext?.id ?? '');
	const handleUpdateUserIdState = (value: string) => {
		setUserId(value);
	};

	const [selectedUser, setSelectedUser] = React.useState<User | undefined>(undefined);
	const handleUpdateSelectedUserState = (user: User | undefined) => {
		setSelectedUser(user);
	};

	const [substitutionReg, setSubstitutionReg] = React.useState(false);
	const [selectedDate, setSelectedDate] = React.useState('');

	const [showEditTimereg, setShowEditTimereg] = React.useState(false);
	const handleShowEditState = (value: boolean) => {
		setShowEditTimereg(value);
		if (!value) {
			setActiveTimeEntry(undefined);
		}
	};

	const [activeTimeEntry, setActiveTimeEntry] = React.useState<BaseTimeTrackingEntryFragment | undefined>(undefined);
	const handleUpdateTimeregEditState = (value: BaseTimeTrackingEntryFragment) => {
		setActiveTimeEntry(value);
	};

	const { timeTrackingsDayDataRaw, timeTrackingsDayLoading } = useTimeTrackingsDayForUserData(userId, selectedDate);
	const timeTrackingEntries = React.useMemo(() => {
		return timeTrackingsDayDataRaw?.timeTrackingsDayForUser?.timeTrackingEntries.slice() ?? [];
	}, [timeTrackingsDayDataRaw]);
	const accOvetimeHours = timeTrackingsDayDataRaw?.timeTrackingsDayForUser?.overtimeHours ?? 0;

	const { deleteTimeTrackingDay, deleteTimeTrackingLoading } = useDeleteTimeTrackingDay();
	const { refetchTimeTrackingsDay } = useTimeTrackingsDayForUserData(userId, selectedDate);
	const { openDay, reopenLoading } = useReopenDay();

	const reopenDayHandler = async () => {
		if(typeof currentDay === 'undefined' || currentDay === null) return;
		try {
			await openDay({
				date: currentDay.date,
				id: currentDay.id,
				user: currentDay.user.id,
				isPartOfRange: currentDay.isPartOfRange,
			});
		} catch (error) {
			console.error(error);
		}
	};

	const currentDay = timeTrackingsDayDataRaw?.timeTrackingsDayForUser;
	const totalDrivingHours = currentDay?.timeTrackingEntries.flatMap(e => e.addonLines).filter(a => a.paymentSupplementCode === '8010' || a.paymentSupplementCode === '8011').reduce((acc, curr) => acc + curr.hours, 0) ?? 0;

	const destructuredEntries = currentDay?.timeTrackingEntries.map(({ jobNo, jobTaskNo, hours, onCall }) => ({
		jobNo,
		jobTaskNo,
		hours,
		onCall,
	})) ?? [];

	const destructuredAddonLines = currentDay?.timeTrackingEntries.flatMap(e => e.addonLines).map(({ jobNo, jobTaskNo, hours, onCall }) => ({
		jobNo,
		jobTaskNo,
		hours,
		onCall,
	})) ?? [];

	const onCallHours = countOnCallHours(destructuredEntries, destructuredAddonLines, '0601', 2.5);
	const onCallbackHours = countOnCallHours(destructuredEntries, destructuredAddonLines, '0600', 3);

	return (
		<div>
			<BoxContainer noPadding={compactMode}>
				<div className={classNames('w-full', { 'md:block lg:flex': !compactMode })}>
					<TimeRegistrationForm
						key="timeregisrationform"
						userContext={{ id: userContext.id, permissions: userContext.permissions, salaryType: userContext.salaryType, hireDate: userContext.hireDate }}
						editMode={false}
						setShowEditTimereg={handleShowEditState}
						timeTrackingsDayDataRaw={timeTrackingsDayDataRaw?.timeTrackingsDayForUser as TimeTrackingDay}
						timeTrackingsDayLoading={timeTrackingsDayLoading}
						userId={{
							set: handleUpdateUserIdState,
							value: userId,
						}}
						substitutionReg={{
							set: setSubstitutionReg,
							value: substitutionReg,
							user: selectedUser,
							setUser: handleUpdateSelectedUserState,
						}}
						selectedDate={{
							set: setSelectedDate,
							value: selectedDate,
						}}
					/>

					<div className="w-full px-4">
						<div className="p-4 bg-blue-xlight">
							<div className="flex justify-between items-center mb-4">
								<div className="flex items-center">
									{!timeTrackingsDayLoading && (
										<>
											<div className={classNames('w-3 h-3 rounded-full', {
												'bg-red': currentDay?.timeTrackingDayStatus === TimeTrackingDayStatus.Closed,
												'bg-green': currentDay?.timeTrackingDayStatus !== TimeTrackingDayStatus.Closed,
											})}></div>
											<span className="ml-2 text-sm font-semibold">{currentDay?.timeTrackingDayStatus === TimeTrackingDayStatus.Closed ? t('timeRegistration.dayClosed') : t('timeRegistration.dayOpen')}</span>
											{currentDay?.isPartOfRange && <span className='ml-2 text-sm'>{t('timeRegistration.dayIsRange')} {formatDateForInputEU(new Date(currentDay.rangeStartDate ?? ''))} - {formatDateForInputEU(new Date(currentDay.rangeEndDate ?? ''))}</span>}
										</>
									)}
									{(reopenDayEntry && currentDay?.timeTrackingDayStatus === TimeTrackingDayStatus.Closed && userContext.permissions.datePicker) &&
										<Button
											className="text-sm"
											icon={faLockOpen}
											text='timeRegistration.reopenDay'
											onClick={() => reopenDayHandler()}
											loading={reopenLoading}
										/>
									}
								</div>
								<div>
									<p
										className={classNames('text-sm', {
											'opacity-50': currentDay?.timeTrackingDayStatus === TimeTrackingDayStatus.Closed,
											'cursor-pointer': currentDay?.timeTrackingDayStatus !== TimeTrackingDayStatus.Closed,
										})}
										onClick={
											async () => {
												if (!deleteTimeTrackingLoading && currentDay?.timeTrackingDayStatus !== TimeTrackingDayStatus.Closed) {
													await deleteTimeTrackingDay(currentDay?.id ?? '', currentDay?.isPartOfRange);
													refetchTimeTrackingsDay();
												}
											}
										}
									>
										{deleteTimeTrackingLoading
											? <FontAwesomeIcon icon={faSpinner} className="animate-spin" size="lg" />
											: <FontAwesomeIcon icon={faTrashAlt} className="mr-2" size="lg" />
										}
										{t('timeRegistration.deleteDay')}
									</p>
								</div>
							</div>
							<div className="w-full">
								<DayScheduler
									key="registrations-overview"
									dayId={currentDay?.id ?? ''}
									entries={timeTrackingEntries ?? []}
									noDataFoundText="timeRegistration.noTimeRegFound"
									loading={timeTrackingsDayLoading}
									setShowEditTimereg={handleShowEditState}
									setActiveTimeEntry={handleUpdateTimeregEditState}
									compactMode={true}
								/>

								<div className="border-t-1 flex justify-end pt-2">
									<span className="mr-1 font-semibold">{`${t('timeRegistration.totalHours')}:`}</span>
									{currentDay?.totalHours}
									{accOvetimeHours > 0 && (
										<span className="ml-1">
											{accOvetimeHours === 1 ? (
												<Trans
													t={t}
													i18nKey="timeRegistration.overtimeHours_one"
													count={accOvetimeHours}
													values={{
														hours: accOvetimeHours,
													}}
													components={[<strong />]}
												/>
											) : (
												<Trans
													t={t}
													i18nKey="timeRegistration.overtimeHours_many"
													count={accOvetimeHours}
													values={{
														hours: accOvetimeHours,
													}}
													components={[<strong />]}
												/>
											)}
										</span>
									)}
									{(totalDrivingHours > 0) && <span className="ml-2 font-semibold">{`${t('timeRegistration.totalDrivingHours')}: ${totalDrivingHours}`}</span>}
								</div>
								{showOnCallHours &&
									<div className="flex justify-end">
										{onCallHours.hours > 0 && (
											<span className="ml-2 font-semibold">
												<Trans
													t={t}
													i18nKey="timeRegistration.onCallHourSummary"
													count={onCallHours.hours}
													values={{
														onCallHours: onCallHours.hours,
														onCallOvertimeHours: onCallHours.overtimeHours,
													}}
												/>
											</span>
										)}
										{onCallbackHours.hours > 0 && (
											<span className="ml-2 font-semibold">
												<Trans
													t={t}
													i18nKey="timeRegistration.onCallbackHourSummary"
													count={onCallbackHours.hours}
													values={{
														onCallbackHours: onCallbackHours.hours,
														onCallbackOvertimeHours: onCallbackHours.overtimeHours,
													}}
												/>
											</span>
										)}
									</div>
								}

								<DayScheduler
									key="standalone-overview"
									dayId={currentDay?.id ?? ''}
									entries={timeTrackingEntries ?? []}
									noDataFoundText="timeRegistration.noTimeRegFound"
									loading={timeTrackingsDayLoading}
									setShowEditTimereg={handleShowEditState}
									setActiveTimeEntry={handleUpdateTimeregEditState}
									compactMode={true}
									standAloneMode={true}
								/>
							</div>
						</div>
					</div>
				</div>

				<Modal
					title="timeRegistration.editTimeRegistration"
					visible={showEditTimereg}
					close={() => setShowEditTimereg(false)}
					body={
						<div>
							<TimeRegistrationForm
								key="timeregisrationformEdit"
								userContext={{ id: userContext.id, permissions: userContext.permissions, salaryType: userContext.salaryType, hireDate: userContext.hireDate }}
								fullWidth={true}
								editMode={true}
								setShowEditTimereg={handleShowEditState}
								timeTrackingsDayDataRaw={currentDay as TimeTrackingDay}
								timeTrackingsDayLoading={timeTrackingsDayLoading}
								entryForEditing={activeTimeEntry}
								userId={{
									set: handleUpdateUserIdState,
									value: userId,
								}}
								substitutionReg={{
									set: setSubstitutionReg,
									value: substitutionReg,
									user: selectedUser,
									setUser: handleUpdateSelectedUserState,
								}}
								selectedDate={{
									set: setSelectedDate,
									value: selectedDate,
								}}
							/>
						</div>
					}
				/>
			</BoxContainer>

			{(rejectModal && !substitutionReg) && rejectModal}
		</div>
	);
};

export default TimeRegistrationOverviewNew;
