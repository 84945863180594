import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus, faExclamationCircle, faInfoCircle, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { useFieldArray, useForm, Controller } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { TimeRegistrationSchemaNew } from './schemas/TimeRegistrationSchemaNew';
import { ITimeRegistrationNew } from './schemas/ITimeRegistration';
import { DateTime, Interval } from 'luxon';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import {
	activityCode,
	addMinutesToTime,
	automaticSupplements,
	automaticSupplementsFunk,
	calculateHours,
	funktionaer,
	funktionaerSupplements,
	industrimedarbejder,
	industrimedarbejderSupplements,
	isWithinLunchTimeRange,
	rangedJobTasks,
	roundToNearestQuarter,
	sanitoer,
	sanitoerSupplements,
	shiftSupplements,
	tomroerSnedker,
	tomroerSnedkerSupplements,
	useCreateTimeTracking,
	useDrivingSlipCategoriesQuery,
	useDrivingSlipsData,
	useJobTasksDataQuery,
	usePaymentSupplementsQuery,
	useSearchUsersQuery,
	useSubmitDay,
	useTimeTrackingsDayForUserData,
	useUpdateTimeTracking,
	useUserBasisTimeDataQuery,
	useWebCasesByErpNoQuery,
	useWebCasesForTimeTrackingQuery,
	useWebDrivingSlipNoCaseQuery,
	vagtcentral,
	vagtcentralSupplements,
} from './TimeRegHelpers';
import {
	BaseTimeTrackingEntryFragment,
	GetTimeTrackingDayLatestByUserQuery,
	SupplementType,
	TimeTrackingDay,
	TimeTrackingDayStatus,
	TimeTrackingEntry,
	TimeTrackingEntryInputType,
	TimeTrackingEntryUpdateInputType,
	TimeTrackingStatus,
	TimeTrackingSupplement,
	TimeTrackingSupplementInputType,
	TimeTrackingSupplementUpdateInputType,
	User,
	useGetTimeTrackingDayLatestByUserQuery,
} from '@ssg/common/GraphQL/indexV2';
import Modal from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';
import Checkbox from '@ssg/common/Components/Checkbox';
import Datepicker from '@ssg/common/Components/Datepicker';
import Dropdown from '@ssg/common/Components/Dropdown';
import Input from '@ssg/common/Components/Input';
import Loading from '@ssg/common/Components/Loading';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import Textarea from '@ssg/common/Components/Textarea';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import classNames from 'classnames';
import TextButton from '@ssg/common/Components/TextButton';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import SupplementLineNew from './SupplementLineNew';
import Popover from '../Components/Popover';

interface Props {
	userContext: {
		id: string;
		permissions: {
			approver: boolean | undefined;
			responsible: boolean | undefined;
			datePicker: boolean | undefined;
		};
		salaryType: string | null;
		hireDate: string | null;
	};
	editMode: boolean;
	setShowEditTimereg: (value: boolean) => void;
	rejectMode?: boolean;
	closeRejectModal?: () => void;
	refetchRejected?: () => void;
	fullWidth?: boolean;
	timeTrackingsDayDataRaw: TimeTrackingDay;
	timeTrackingsDayLoading: boolean;
	entryForEditing?: BaseTimeTrackingEntryFragment;
	userId: {
		set: (value: string) => void;
		value: string;
	};
	substitutionReg: {
		set: React.Dispatch<React.SetStateAction<boolean>>;
		value: boolean;
		user?: User | undefined;
		setUser?: (user: User | undefined) => void;
	};
	selectedDate: {
		set: React.Dispatch<React.SetStateAction<string>>;
		value: string;
	};
}

const getCaseLabel = (c: Pick<NonNullable<BaseTimeTrackingEntryFragment['case']>, 'damage' | 'erpNo'>): string => {
	if (c === null) {
		return 'INTERN';
	}
	return `${c.erpNo} - ${c.damage.contact.address.road} ${c.damage.contact.address.houseNumber}, ${c.damage.contact.address.postalCode} ${c.damage.contact.address.city} (${c.damage.category.name}/${c.damage.cause.name})`;
};

const TimeRegistrationForm: React.FC<Props> = ({
	userContext,
	editMode,
	setShowEditTimereg,
	rejectMode = false,
	closeRejectModal,
	refetchRejected,
	fullWidth = false,
	timeTrackingsDayDataRaw,
	timeTrackingsDayLoading,
	entryForEditing,
	userId,
	substitutionReg,
	selectedDate,
}) => {
	const enabledSubstituteNo = useFlag(FeatureFlagEnums.READABLE_SUBSTITUE_NO);
	const enabledHumanReadableDate = useFlag(FeatureFlagEnums.TIME_REG_HUMAN_READABLE_DATE);
	const pieceworkValidation = useFlag(FeatureFlagEnums.PIECEWORK_VALIDATION);
	const allowEditOfTimereg = useFlag(FeatureFlagEnums.ALLOW_EDIT_OF_TIMEREG);

	const { t } = useTranslation();

	const { id } = useParams<{ id?: string }>();

	const [searchUserText, setSearchUserText] = React.useState('');
	const [overruleDate, setOverruleDate] = React.useState(false);
	const [registerRange, setRegisterRange] = React.useState(false);
	const [selectedDateRange, setSelectedDateRange] = React.useState('');
	const [caseSearchText, setCaseSearchText] = React.useState('');
	const [jobSearchText, setJobSearchText] = React.useState('');
	const [timeRegistration, setTimeRegistration] = React.useState(false);
	const [indicateLunch, setIndicateLunch] = React.useState(false);
	const [indicateOnCall, setIndicateOnCall] = React.useState(false);
	const [showIndicateOnCall, setShowIndicateOnCall] = React.useState(false);
	const [indicateOnCallback, setIndicateOnCallback] = React.useState(false);
	const [showIndicateOnCallback, setShowIndicateOnCallback] = React.useState(false);
	const [overEstimate, setOverEstimate] = React.useState(false);
	const [showOvertimeWarning, setShowOvertimeWarning] = React.useState(false);
	const [showCloseDayModal, setShowCloseDayModal] = React.useState(false);

	const employeeSalaryType = substitutionReg.value ? substitutionReg.user?.employeeSalaryType ?? '' : userContext.salaryType ?? '';
	const employeeHireDate = substitutionReg.value ? substitutionReg.user?.employeeHireStartDate ?? '' : userContext.hireDate ?? '';

	const [allowOvertime, setAllowOvertime] = React.useState(funktionaer.includes(employeeSalaryType));
	React.useEffect(() => {
		setAllowOvertime(funktionaer.includes(employeeSalaryType));
	}, [employeeSalaryType]);

	const { drivingSlipDataRaw, drivingSlipLoading } = useDrivingSlipsData(editMode ? entryForEditing?.drivingSlip?.id : id ?? '');
	const [selectedDrivingSlip, setSelectedDrivingSlip] = React.useState<{ start: string | undefined; end: string | undefined }>();

	const [selectedCase, setSelectedCase] = React.useState<SelectOption | undefined>(
		editMode && entryForEditing?.case
			? {
				label: getCaseLabel(entryForEditing.case),
				value: entryForEditing.case.erpNo,
			}
			: undefined,
	);

	const erpReferenceNo = React.useMemo(() => {
		if (typeof selectedCase === 'undefined') {
			return 'INTERN';
		}

		return selectedCase.value;
	}, [selectedCase]);

	const { jobTaskDataRaw, jobTaskLoading } = useJobTasksDataQuery(erpReferenceNo);

	const startTimeBasedOnSalaryType = employeeSalaryType === 'VAGT' ? '06:00D' : '07:00D';
	const endTimeBasedOnSalaryType = employeeSalaryType === 'VAGT' ? '06:30D' : '07:30D';

	const latestTimeregCallback = (data: GetTimeTrackingDayLatestByUserQuery) => {
		if (data.timeTrackingDayLatestByUser !== null) {
			const latestTimereg = data.timeTrackingDayLatestByUser;

			if (latestTimereg?.timeTrackingDayStatus !== TimeTrackingDayStatus.Open) {
				const latestClosedDay = latestTimereg?.date
					? DateTime.fromFormat(latestTimereg?.date, 'yyyy-MM-dd')
						.plus({ days: 1 })
						.toISODate()
					: formatDateForInput(new Date());
				selectedDate.set(latestClosedDay);
				setValue('date', latestClosedDay);
				setValue('startDate', latestClosedDay);
				setValue('startTime', startTimeBasedOnSalaryType + latestClosedDay);
				setValue('endTime', endTimeBasedOnSalaryType + latestClosedDay);

			} else {
				selectedDate.set(latestTimereg.date);
				setValue('date', latestTimereg.date);
				setValue('startDate', latestTimereg.date);

				// const lastestEntry = timeTrackingsDayDataRaw?.timeTrackingEntries
				// 	// Filter out entries that are standalone
				// 	.filter(e => !e.addonLines.some(a => a.supplementType === SupplementType.StandAlone))
				// 	.sort((a, b) => b.createdAt.localeCompare(a.createdAt))[0];

				// if (typeof lastestEntry === 'undefined') {
				// 	setValue('startTime', startTimeBasedOnSalaryType + latestTimereg.date);
				// 	setValue('endTime', endTimeBasedOnSalaryType + latestTimereg.date);
				// 	console.log('latestTimeregCallback 2');
				// }
				// else if (lastestEntry.startTime === '00:00' && lastestEntry.endTime === '00:00') {
				// 	setValue('startTime', startTimeBasedOnSalaryType + latestTimereg.date);
				// 	setValue('endTime', endTimeBasedOnSalaryType + latestTimereg.date);
				// } else {
				// 	setValue('startTime', `${lastestEntry.startTime}D${latestTimereg.date}`);
				// 	setValue('endTime', `${lastestEntry.endTime}D${latestTimereg.date}`);
				// }
			}
		} else {
			selectedDate.set(formatDateForInput(new Date()));
			setValue('date', selectedDate.value);
			setValue('startDate', selectedDate.value);
			setValue('startTime', startTimeBasedOnSalaryType + selectedDate.value);
			setValue('endTime', endTimeBasedOnSalaryType + selectedDate.value);
		}
	};

	const { data: lastedTimeregRaw, refetch: refetchLatestDay } = useGetTimeTrackingDayLatestByUserQuery({
		fetchPolicy: 'no-cache',
		variables: {
			user: userId.value,
		},
		skip: userId.value === '' || rejectMode || editMode,
		onCompleted: data => latestTimeregCallback(data),
	});

	const lastedTimeregData = React.useMemo(() => lastedTimeregRaw?.timeTrackingDayLatestByUser ?? undefined, [lastedTimeregRaw?.timeTrackingDayLatestByUser]);

	const setCurrentTimeregDate = lastedTimeregData?.timeTrackingDayStatus !== TimeTrackingDayStatus.Open
		? DateTime.fromFormat(lastedTimeregData?.date ?? '', 'yyyy-MM-dd')
			.plus({ days: 1 })
			.toISODate()
		: lastedTimeregData?.date;

	const { usersDataRaw, usersLoading } = useSearchUsersQuery(searchUserText, true, true);
	const searchedUsers = React.useMemo(() => usersDataRaw?.searchUsers.map((u): SelectOption => ({ value: u.id, label: u.name })) ?? [], [usersDataRaw]);

	const timeTrackingEntries = React.useMemo(() => {
		return timeTrackingsDayDataRaw?.timeTrackingEntries.slice() ?? [];
	}, [timeTrackingsDayDataRaw]);
	const accOvetimeHours = timeTrackingsDayDataRaw?.overtimeHours ?? 0;

	const { basisTimeRaw } = useUserBasisTimeDataQuery(userId.value, selectedDate.value);

	const erpReferenceNos: string[] = timeTrackingEntries
		.flatMap(t => t ?? [])
		.flatMap(te => te?.case ?? [])
		.map(tc => tc.erpNo);

	const { caseDataRaw, casesLoading } = useWebCasesForTimeTrackingQuery(erpReferenceNos, false, drivingSlipLoading, timeTrackingsDayLoading);

	const { erpNoCasesRaw } = useWebCasesByErpNoQuery(caseSearchText, false, false);
	const searchedCases = React.useMemo(() => {
		return ([
			...erpNoCasesRaw?.cases
				.filter(c => c.timeRegistration)
				.map(
					(c): SelectOption => ({
						label: `${c.erpNo} - ${c.damage.contact.address.road} ${c.damage.contact.address.houseNumber}, ${c.damage.contact.address.postalCode} ${c.damage.contact.address.city} (${c.damage.category.name}/${c.damage.cause.name})`,
						value: c.id,
					}),
				) ?? [],
		]);
	}, [erpNoCasesRaw]);

	const defaultStartTime = React.useMemo(() => `${startTimeBasedOnSalaryType}${selectedDate.value}`, [selectedDate.value, startTimeBasedOnSalaryType]);
	const defaultEndtTime = React.useMemo(() => `${endTimeBasedOnSalaryType}${selectedDate.value}`, [endTimeBasedOnSalaryType, selectedDate.value]);
	const defaultTask = React.useMemo(() => typeof id !== 'undefined' ? 'DRIVINGSLIP' : '', [id]);
	const defaultDrivingSlip = React.useMemo(() => typeof id !== 'undefined' ? id : null, [id]);

	const { handleSubmit, register, setValue, getValues, errors, control, watch, reset, setError, formState, clearErrors } = useForm<ITimeRegistrationNew>({
		resolver: yupResolver(TimeRegistrationSchemaNew),
		defaultValues: {
			substitute: false,
			user: entryForEditing ? entryForEditing.userId : userId.value,
			date: editMode ? entryForEditing?.startDate ?? (selectedDate.value ?? '') : (selectedDate.value ?? ''),
			registerRange: false,
			startTime: defaultStartTime,
			endTime: defaultEndtTime,
			startDate: editMode ? entryForEditing?.startDate : (selectedDate.value ?? ''),
			endDate: editMode ? entryForEditing?.endDate : (selectedDate.value ?? ''),
			task: editMode ? (entryForEditing?.task ?? '') : defaultTask,
			jobNo: editMode ? (entryForEditing?.jobNo ?? '') : '',
			jobTaskName: editMode ? (entryForEditing?.jobTaskName ?? undefined) : undefined,
			jobTaskNo: editMode ? (entryForEditing?.jobTaskNo ?? undefined) : undefined,
			workTypeCode: editMode ? entryForEditing?.workTypeCode : undefined,
			debitor: editMode ? entryForEditing?.case?.debitor.company : undefined,
			case: editMode ? entryForEditing?.case?.id : null,
			drivingSlip: editMode ? entryForEditing?.drivingSlip?.id : defaultDrivingSlip,
			remark: editMode ? (entryForEditing?.remark ?? undefined) : undefined,
			addonLines: editMode && entryForEditing
				? entryForEditing.addonLines
					.filter(a => funktionaer.includes(employeeSalaryType)
						? !automaticSupplementsFunk.includes(a?.paymentSupplementCode ?? '')
						: !automaticSupplements.includes(a?.paymentSupplementCode ?? ''))
				: undefined,
		},
		mode: 'all',
		reValidateMode: 'onChange',
		shouldUnregister: false,
	});

	// For setting date and time when no registrations exist
	// React.useEffect(() => {
	// 	if (timeTrackingEntries.length !== 0 || typeof entryForEditing !== 'undefined') return;
	// 	setValue('date', selectedDate.value);
	// 	setValue('startDate', selectedDate.value);
	// 	setValue('startTime', defaultStartTime);
	// 	setValue('endDate', selectedDate.value);
	// 	setValue('endTime', defaultEndtTime);
	// 	console.log('useEffect', defaultStartTime, defaultEndtTime);
	// }, [defaultEndtTime, defaultStartTime, entryForEditing, selectedDate.value, setValue, timeTrackingEntries.length]);

	const rangeEndDateWatch = watch('rangeEndDate');
	const startTimeWatch = watch('startTime');
	const endTimeWatch = watch('endTime');
	const hoursWatch = watch('hours');
	const taskWatch = watch('task');
	const jobTaskNoWatch = watch('jobTaskNo');
	const drivingSlipWatch = watch('drivingSlip', editMode ? entryForEditing?.drivingSlip?.id : drivingSlipDataRaw?.drivingSlip.id ?? null);

	const { drivingSlipsNoCaseDataRaw } = useWebDrivingSlipNoCaseQuery(erpNoCasesRaw, selectedCase, drivingSlipLoading, taskWatch);
	const drivingSlips = React.useMemo<SelectOption[]>(
		() =>
			[
				...(typeof drivingSlipDataRaw !== 'undefined'
					? [
						{
							label: dateToDateTimeString(new Date(drivingSlipDataRaw.drivingSlip.start)).replace(':', '.'),
							value: drivingSlipDataRaw.drivingSlip.id,
						},
					]
					: []),
				...(drivingSlipsNoCaseDataRaw?.drivingSlips.map<SelectOption>(d => ({
					label: dateToDateTimeString(new Date(d.start)).replace(':', '.'),
					value: d.id,
				})) ?? []),
			].filter((ds, i, a) => a.findIndex(ads => ads.value === ds.value) === i),
		[drivingSlipDataRaw, drivingSlipsNoCaseDataRaw],
	);

	const { drivingSlipCategoriesDataRaw } = useDrivingSlipCategoriesQuery();
	const { paymentSupplementsDataRaw } = usePaymentSupplementsQuery();

	const { refetchTimeTrackingsDay } = useTimeTrackingsDayForUserData(userId.value, selectedDate.value, rejectMode);
	const { closeDay, submitLoading } = useSubmitDay();
	const { onSubmit, createTimeTrackingLoading } = useCreateTimeTracking();
	const { onSubmitUpdate, updateTimeTrackingLoading } = useUpdateTimeTracking();

	const formFieldArrayObj = useFieldArray<TimeTrackingSupplementInputType>({
		control,
		name: 'addonLines',
	});
	const { fields, append, remove } = formFieldArrayObj;

	// For appending supplement object on "add supplement"
	const timeTrackingSuppplementLine: TimeTrackingSupplementInputType = {
		user: userId.value,
		date: selectedDate.value,
		hours: 0,
		paymentSupplementCode: undefined,
		paymentSupplementName: undefined,
		supplementUom: undefined,
		supplementType: undefined,
		workTypeCode: undefined,
	};

	const isEntryNextDay = (startTime: string, endTime: string, startDate: string, endDate: string): boolean => {
		const [startHours, startMinutes] = startTime.split(':').map(Number);
		const [endHours, endMinutes] = endTime.split(':').map(Number);
		// If the end date is greater than the start date, or the end time is after the start time and the hours are 24 or more, it's the next day
		return endDate > startDate || (endHours > startHours && endHours >= 24) || (endHours === startHours && endMinutes > startMinutes && endHours === 23 && endMinutes > 59);
	};

	// Sets start and end time based on last entry
	React.useEffect(() => {
		if (timeTrackingEntries.filter(e => !e.addonLines.some(a => a.supplementType === SupplementType.StandAlone)).length === 0 && !rejectMode) {
			setValue('startTime', defaultStartTime);
			setValue('startDate', selectedDate.value);
			setValue('endTime', defaultEndtTime);
			setValue('endDate', selectedDate.value);
			return;
		}

		const latestEntry = timeTrackingEntries
			.filter(e => !e.addonLines.some(a => a.supplementType === SupplementType.StandAlone))
			.sort((a, b) => b.endTime.localeCompare(a.endTime))
			.sort((a, b) => b.endDate.localeCompare(a.endDate))[0];

		const startTime = editMode && typeof entryForEditing !== 'undefined' ? entryForEditing.startTime : latestEntry.endTime;
		const endTime = editMode && typeof entryForEditing !== 'undefined' ? entryForEditing.endTime : addMinutesToTime(latestEntry.endTime, 30) ?? endTimeBasedOnSalaryType.split('D')[0];

		const startDate = editMode && typeof entryForEditing !== 'undefined' ? entryForEditing.startDate : latestEntry.startDate;
		const endDate = editMode && typeof entryForEditing !== 'undefined' ? entryForEditing.endDate : latestEntry.endDate;

		if (isEntryNextDay(startTime, endTime, startDate, endDate)) {
			setValue('startTime', `${startTime}D${endDate}`);
			setValue('startDate', endDate);
		} else {
			setValue('startTime', `${startTime}D${startDate}`);
			setValue('startDate', startDate);
		}

		if (isEntryNextDay(startTime, endTime, startDate, endDate)) {
			setValue('endTime', `${endTime}D${endDate}`);
			setValue('endDate', endDate);
		} else {
			setValue('endTime', `${endTime}D${startDate}`);
			setValue('endDate', startDate);
		}

	}, [defaultEndtTime, defaultStartTime, editMode, entryForEditing, entryForEditing?.endDate, entryForEditing?.endTime, entryForEditing?.startDate, entryForEditing?.startTime, rejectMode, selectedDate.value, setValue, timeTrackingEntries, endTimeBasedOnSalaryType]);

	const entryHasEndtimeNextDayLimit = timeTrackingEntries.some(entry => {
		// If entry includes a supplement that is standalone, it should not be included in the check
		if(getValues('addonLines')?.some(a => a.supplementType === SupplementType.StandAlone)) return false;

		// Convert endTime to a comparable format (e.g., HH:mm)
		const endTime = entry.endTime; // Assuming endTime is in 'HH:mm' format
		//const startDate = entry.startDate;
		const endDate = entry.endDate;

		// Compare endTime to '07:00'
		const startTimeSplit = startTimeBasedOnSalaryType.split('D')[0];
		return endDate > selectedDate.value && endTime >= startTimeSplit;
	});

	React.useEffect(() => {
		if (typeof drivingSlipDataRaw !== 'undefined') {
			setSelectedCase({
				label: getCaseLabel(drivingSlipDataRaw.drivingSlip.case),
				value: drivingSlipDataRaw.drivingSlip.case.erpNo,
			});
			setValue('case', drivingSlipDataRaw.drivingSlip.case.id, { shouldValidate: true });
			setValue('jobNo', drivingSlipDataRaw.drivingSlip.case.erpNo, { shouldValidate: true });
			setValue('drivingSlip', editMode ? entryForEditing?.drivingSlip?.id ?? null : id ?? null, { shouldValidate: true });
		}
	}, [drivingSlipDataRaw, editMode, entryForEditing, id, setValue]);

	const yesterday = new Date();
	yesterday.setDate(yesterday.getDate() - 1);

	const formRef = React.useRef<HTMLFormElement>(null);

	const remoteSubmit = () => {
		if (formRef.current) {
			formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
		}
	};

	// Calc hours based on startTime/endTime and fill out hours input
	React.useEffect(() => {
		if (!formState.errors.endTime) {
			let hours = calculateHours(startTimeWatch, endTimeWatch);
			if (indicateLunch) {
				hours = hours - 0.5;
			}
			const roundHoursToNearestQuarter = roundToNearestQuarter(hours);
			setValue('hours', roundHoursToNearestQuarter, { shouldValidate: true });
		}
	}, [endTimeWatch, formState.errors.endTime, indicateLunch, setValue, startTimeWatch]);

	const paymentSupplements = React.useMemo(
		() =>
			paymentSupplementsDataRaw?.paymentSupplements.filter(ps => {
				switch (taskWatch) {
					case 'INTERN':
						return ps?.templateJobNo === '+INTERN';
					case 'DRIVINGSLIP':
					case 'CASE':
						return ps?.templateJobNo === '+STANDARD';
					default:
						return ps?.templateJobNo;
				}
			}) ?? [],
		[taskWatch, paymentSupplementsDataRaw?.paymentSupplements],
	);

	// Check if 'vagtleder/vagtmand tillæg' is already added to day
	const dayIncludesSupervisorSupp = timeTrackingEntries.some(te => te.addonLines.some(addonLine => addonLine.paymentSupplementCode === '2150' || addonLine.paymentSupplementCode === '2094'));
	// Only one afvarsling or varsko supplement allowed per day
	const afvarslingOrVarskoSupp = React.useMemo(() => ['8600', '8630'], []);
	const dayIncludesAfvarslingOrVarskoSupp = timeTrackingEntries.some(te => te.addonLines.some(addonLine => afvarslingOrVarskoSupp.includes(addonLine.paymentSupplementCode ?? '')));

	// Filtered payment supplements
	const paymentSupplementsSelect = React.useMemo(() =>
		paymentSupplementsDataRaw?.paymentSupplements
			.filter(s => s?.blocked === false)
			.filter(s => {
				if (s && s.code) {
					if (funktionaerSupplements.includes(s.code)) {
						return true;
					}
					return !automaticSupplements.includes(s.code);
				}
				return true;
			})
			// Special conditions
			.filter(s => (s && s.code && (dayIncludesSupervisorSupp && !editMode) ? !shiftSupplements.includes(s.code) : true))
			.filter(s => (s && s.code && (dayIncludesAfvarslingOrVarskoSupp && !editMode) ? !afvarslingOrVarskoSupp.includes(s.code) : true))
			// Employee salary type conditions
			.filter(s => (s && s.code && sanitoer.includes(employeeSalaryType ?? '') ? sanitoerSupplements.includes(s.code) : true))
			.filter(s => (s && s.code && industrimedarbejder.includes(employeeSalaryType ?? '') ? industrimedarbejderSupplements.includes(s.code) : true))
			.filter(s => (s && s.code && funktionaer.includes(employeeSalaryType ?? '') ? funktionaerSupplements.includes(s.code) : true))
			.filter(s => (s && s.code && tomroerSnedker.includes(employeeSalaryType ?? '') ? tomroerSnedkerSupplements.includes(s.code) : true))
			.filter(s => (s && s.code && vagtcentral.includes(employeeSalaryType ?? '') ? vagtcentralSupplements.includes(s.code) : true))
			.filter(s => {
				switch (taskWatch) {
					case 'INTERN':
						return s?.templateJobNo === '+INTERN';
					case 'DRIVINGSLIP':
					case 'CASE':
						return s?.templateJobNo === '+STANDARD';
					default:
						return s?.templateJobNo;
				}
			})
			.map<SelectOption>(s => ({
				label: `${s?.description} (${s?.code})`,
				value: s?.code ?? '',
			})) ?? [], [dayIncludesSupervisorSupp, employeeSalaryType, editMode, taskWatch, paymentSupplementsDataRaw?.paymentSupplements, dayIncludesAfvarslingOrVarskoSupp, afvarslingOrVarskoSupp],
	);

	const drivingSlipJobTasks = React.useMemo(() => {
		const category =
			typeof drivingSlipDataRaw !== 'undefined' && drivingSlipDataRaw.drivingSlip.id === drivingSlipWatch
				? drivingSlipDataRaw.drivingSlip.category
				: drivingSlipsNoCaseDataRaw?.drivingSlips.find(ds => ds.id === drivingSlipWatch)?.category;

		if (typeof category === 'undefined' || category === null || category?.length === 0) {
			return;
		}

		return drivingSlipCategoriesDataRaw?.drivingSlipCategories.find(dsc => dsc.code === category)?.erpReferenceTasks;
	}, [drivingSlipCategoriesDataRaw?.drivingSlipCategories, drivingSlipDataRaw, drivingSlipWatch, drivingSlipsNoCaseDataRaw?.drivingSlips]);

	const jobTasks = React.useMemo(
		() =>
			jobTaskDataRaw?.jobTasks
				?.filter(jt => typeof drivingSlipJobTasks === 'undefined' || drivingSlipJobTasks.includes(jt?.jobTaskNo ?? '?'))
				.filter(jt => !registerRange || (registerRange && rangedJobTasks.includes(jt?.jobTaskNo ?? '?')))
				.filter(jt => {
					// 0600 should only be available for sanitør and industrimedarbejder
					if (jt?.jobTaskNo === '0600') {
						return sanitoer.includes(employeeSalaryType) || industrimedarbejder.includes(employeeSalaryType);
					}
					return true;
				})
				.filter(jt => {
					// 1902 akkord - should only be available for sanitør, industrimedarbejder and tømrer
					if (jt?.jobTaskNo === '1902') {
						return sanitoer.includes(employeeSalaryType) || industrimedarbejder.includes(employeeSalaryType) || tomroerSnedker.includes(employeeSalaryType);
					}
					return true;
				})
				.filter(jw => jw?.workTypeCode !== '')
				.map<SelectOption>(j => ({
					label: j?.description ?? '',
					value: j?.jobTaskNo ?? '',
				})) ?? [],
		[drivingSlipJobTasks, employeeSalaryType, jobTaskDataRaw?.jobTasks, registerRange],
	);

	const userRegisteredHours = timeTrackingsDayDataRaw?.totalHours ?? 0;

	const registedHoursMatchBasisTime = userRegisteredHours < (basisTimeRaw?.userBasisTime ?? 0);
	const closeDayCannotBeOverruled = (funktionaer.includes(employeeSalaryType) ? false : registedHoursMatchBasisTime);

	const dayEntry = {
		id: timeTrackingsDayDataRaw?.id ?? '',
		user: userId.value,
		date: selectedDate.value,
		isPartOfRange: registerRange,
		rangeEndDate: typeof rangeEndDateWatch === 'string' ? rangeEndDateWatch : undefined,
	};

	// Clear fields when task is changed
	const clearTaskFields = () => {
		setSelectedCase(undefined);
		if (taskWatch === 'INTERN') {
			setValue('jobNo', 'INTERN');
			setValue('case', null);
			setValue('drivingSlip', null);
		}

		if (taskWatch === 'CASE') {
			setIndicateOnCall(false);
			setIndicateOnCallback(false);
			setValue('jobNo', 'CASE');
			setValue('drivingSlip', null);
		}

		if (taskWatch === 'DRIVINGSLIP') {
			setValue('jobNo', 'CASE');
		}

		setValue('jobTaskNo', null);
		setValue('jobTaskName', null);
		setValue('workTypeCode', null);
		setJobSearchText('');
	};

	const resetForm = () => {
		// If registering from mobile drivingslip, don´t reset form
		if(typeof id !== 'undefined') {
			reset({
				substitute: getValues('substitute'),
				user: getValues('user'),
				date: getValues('date'),
				startTime: getValues('startTime'),
				endTime: getValues('endTime'),
				startDate: getValues('startDate'),
				endDate: getValues('endDate'),
				task: getValues('task'),
				jobNo: getValues('jobNo'),
				jobTaskName: undefined,
				jobTaskNo: undefined,
				workTypeCode: undefined,
				debitor: getValues('debitor'),
				case: getValues('case'),
				drivingSlip: getValues('drivingSlip'),
			});
			setJobSearchText('');
			return;
		}

		setValue('date', selectedDate.value);
		if (substitutionReg.value) {
			setValue('substitute', true, { shouldValidate: true });
		} else {
			setValue('substitute', false, { shouldValidate: true });
			substitutionReg.set(false);
			substitutionReg?.setUser && substitutionReg?.setUser(undefined);
			setSearchUserText('');
		}
		setRegisterRange(false);
		setIndicateLunch(false);
		setShowIndicateOnCall(false);
		setIndicateOnCall(false);
		setShowIndicateOnCallback(false);
		setIndicateOnCallback(false);
		clearTaskFields();
		reset();
	};

	// Update entry
	const beforeUpdateEntry = (dataEntry: TimeTrackingEntryUpdateInputType) => {
		// If registrations on selected date exceeds userBasisTime, show modal with warning
		const entryBeforeEdit = timeTrackingEntries.find(te => te.id === entryForEditing?.id);
		const entryBeforeEditHours = entryBeforeEdit?.hours ?? 0;

		const accHoursOnSelectedDate = (userRegisteredHours - entryBeforeEditHours) + dataEntry.hours;
		const drivingHours = timeTrackingEntries.flatMap(t => t.addonLines).filter(a => a.paymentSupplementCode === '8010' || a.paymentSupplementCode === '8011')?.reduce((acc, curr) => acc + curr.hours, 0) ?? 0;
		const accHoursMinusDriving = accHoursOnSelectedDate - drivingHours;
		const basisTime = typeof basisTimeRaw?.userBasisTime !== 'undefined' ? basisTimeRaw?.userBasisTime : 0;

		// Filter out automatic supplements
		const updatedDataEntry = {
			...dataEntry,
			id: entryForEditing?.id ?? '',
			addonLines: dataEntry.addonLines?.filter(a => funktionaer.includes(employeeSalaryType) ? !automaticSupplementsFunk.includes(a?.paymentSupplementCode ?? '') : !automaticSupplements.includes(a?.paymentSupplementCode ?? '')),
		};

		const isFunk = funktionaer.includes(employeeSalaryType);
		const isVagt = vagtcentral.includes(employeeSalaryType);
		const isIndustri = industrimedarbejder.includes(employeeSalaryType);

		if (accHoursMinusDriving > basisTime && !allowOvertime && !isFunk && !isVagt && !isIndustri) {
			setShowOvertimeWarning(true);
		} else {
			setShowOvertimeWarning(false);
			try {
				onSubmitUpdate(dayEntry.id, updatedDataEntry);
				resetForm();

			} catch (error) {
				console.error(error);
			}

			if (!rejectMode) {
				setShowEditTimereg(false);
			}
		}
	};

	// Create entry
	const beforeCreateEntry = (dataEntry: TimeTrackingEntryInputType) => {
		// If registrations on selected date exceeds userBasisTime, show modal with warning
		const accHoursOnSelectedDate = userRegisteredHours + dataEntry.hours;
		const drivingHours = timeTrackingEntries.flatMap(t => t.addonLines).filter(a => a.paymentSupplementCode === '8010' || a.paymentSupplementCode === '8011')?.reduce((acc, curr) => acc + curr.hours, 0) ?? 0;
		const accHoursMinusDriving = accHoursOnSelectedDate - drivingHours;
		const basisTime = typeof basisTimeRaw?.userBasisTime !== 'undefined' ? basisTimeRaw?.userBasisTime : 0;

		const isFunk = funktionaer.includes(employeeSalaryType);
		const isVagt = vagtcentral.includes(employeeSalaryType);
		const isIndustri = industrimedarbejder.includes(employeeSalaryType);

		if (accHoursMinusDriving > basisTime && !allowOvertime && !isFunk && !isVagt && !isIndustri) {
			setShowOvertimeWarning(true);
		} else {
			setShowOvertimeWarning(false);
			try {
				onSubmit(dayEntry, dataEntry);
				resetForm();

			} catch (error) {
				console.error(error);
			}
		}
	};

	React.useEffect(() => {
		setTimeRegistration(caseDataRaw?.cases.find(c => c.id === selectedCase?.value)?.timeRegistration ?? true);
	}, [selectedCase, caseDataRaw]);

	interface TimeSlotOption {
		key: string;
		value: string;
		isBlocked: boolean;
	}

	interface StartEnd {
		startTime: string;
		endTime: string;
		startDate: string;
		endDate: string;
		registerDate: string;
	}

	const generatetimeSlotOptions = (startEnd: StartEnd) => {
		// Find the maximum end time based on the first entry after the selected startTime
		const { startTime, endTime, startDate, endDate, registerDate } = startEnd;
		const ogStart = DateTime.fromFormat(`${registerDate}-${'00:00'}`, 'yyyy-mm-dd-HH:mm');
		const start = DateTime.fromFormat(`${startDate}-${startTime}`, 'yyyy-mm-dd-HH:mm');
		const end = DateTime.fromFormat(`${endDate}-${endTime}`, 'yyyy-mm-dd-HH:mm');

		// Check if end date is before start date and adjust accordingly
		// This is to handle the case where the end time is after midnight and might be next month
		let adjustedEnd = end;
		if (end < start) {
			adjustedEnd = end.plus({ months: 1 });
		}

		const pushDiff = start.diff(ogStart, 'minutes').minutes;
		const diff = adjustedEnd.diff(start, 'minutes').minutes;
		const slots = diff / 15;
		const pushSlots = pushDiff / 15;
		const totalSlots = slots + pushSlots;

		// Generate time slot options from 00:00 am to 00:00 am the next day
		const todayOptions: TimeSlotOption[] = [];
		const nextDayOptions: TimeSlotOption[] = [];

		for (let i = pushSlots; i < totalSlots; i++) {
			const totalMinutes = i * 15;
			const hours = Math.floor(totalMinutes / 60) % 24;
			const minutes = totalMinutes % 60;
			const isNextDay = totalMinutes >= 24 * 60;
			const key = `${hours < 10 ? '0' : ''}${hours}${minutes === 0 ? '00' : minutes}`;
			const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${minutes === 0 ? '00' : minutes}`;

			const option = {
				key: `${isNextDay ? 'next-' : ''}${key}`,
				value: formattedTime,
				isBlocked: false,
			};

			if (isNextDay) {
				nextDayOptions.push(option);
			} else {
				todayOptions.push(option);
			}
		}

		return {
			nextDayOptions,
			todayOptions,
		};
	};

	const generatetimeSlotHTMLOptions = (startDate: string, timeTrackingEntries: TimeTrackingEntry[], editingId: string | undefined) => {

		const endDate = DateTime.fromFormat(startDate, 'yyyy-MM-dd').plus({ days: 1 }).toISODate();

		// Find the maximum end time based on the first entry after the selected startTime
		const { nextDayOptions: next, todayOptions: today } = generatetimeSlotOptions({
			endDate,
			startDate,
			endTime: '23:45',
			startTime: '00:00',
			registerDate: startDate,
		});

		const timeTrackingTimeSlots = timeTrackingEntries
			.filter(tt => typeof editingId !== 'undefined' ? tt.id !== editingId : true)
			// Removed all entries with stand-alone supplements
			.filter(e => !e.addonLines.some(a => a.supplementType === SupplementType.StandAlone))
			.flatMap(entry => {
				const slots = generatetimeSlotOptions({
					endDate: entry.endDate,
					startDate: entry.startDate,
					endTime: entry.endTime,
					startTime: entry.startTime,
					registerDate: startDate,
				});

				return [...slots.nextDayOptions.slice(editMode ? 1 : 0), ...slots.todayOptions.slice(editMode ? 1 : 0)];
			})
			.sort((a, b) => a.key.localeCompare(b.key));

		const lastSlot = timeTrackingTimeSlots.slice(-1)[0];

		let allOptions: TimeSlotOption[] = [...today, ...next].sort((a, b) => a.key.localeCompare(b.key));

		const lastSlotIndex = allOptions.findIndex(option => lastSlot?.key === option.key);

		allOptions = allOptions.map((option, i) => {
			let isBlocked = timeTrackingTimeSlots.some(timeSlot => timeSlot.key === option.key);
			if (!editMode) {
				isBlocked = lastSlotIndex >= i;
			}

			return {
				...option,
				isBlocked,
			};
		});

		if (editMode && editingId) {
			const timeTrackingTimeSlots = timeTrackingEntries
				.filter(tt => tt.id === editingId)
				// Removed all entries with stand-alone supplements
				.filter(e => !e.addonLines.some(a => a.supplementType === SupplementType.StandAlone))
				.flatMap(entry => {
					const slots = generatetimeSlotOptions({
						endDate: entry.endDate,
						startDate: entry.startDate,
						endTime: entry.endTime,
						startTime: entry.startTime,
						registerDate: startDate,
					});

					return [...slots.nextDayOptions.slice(editMode ? 1 : 0), ...slots.todayOptions.slice(editMode ? 1 : 0)];
				})
				.sort((a, b) => a.key.localeCompare(b.key));

			if (timeTrackingTimeSlots.length > 0) {
				const lastSlot = timeTrackingTimeSlots.slice(-1)[0];
				const firstSlot = timeTrackingTimeSlots[0];
				const firstHalf = allOptions.slice(0, allOptions.findIndex(option => option.key === firstSlot.key));
				const secondHalf = allOptions.slice(allOptions.findIndex(option => option.key === lastSlot.key) + 1);
				const fistHalfLastBlockedKey = firstHalf.filter(option => option.isBlocked).slice(-1)[0]?.key;
				const secondHalfFirstBlockedKey = secondHalf.find(option => option.isBlocked)?.key;

				const firstHalfBlockedIndex = fistHalfLastBlockedKey ? allOptions.findIndex(option => option.key === fistHalfLastBlockedKey) : -1;
				const secondHalfBlockedIndex = secondHalfFirstBlockedKey ? allOptions.findIndex(option => option.key === secondHalfFirstBlockedKey) : 9999;
				allOptions = allOptions.map((option, i) => {
					const isBlocked = i <= firstHalfBlockedIndex || i >= secondHalfBlockedIndex;

					return {
						...option,
						isBlocked,
					};
				});
			}
		}

		const todayWithBlocked = allOptions.filter(option => !option.key.startsWith('next-'));
		const nextDayWithBlocked = allOptions.filter(option => option.key.startsWith('next-'));

		const currentDayLabel = DateTime.fromFormat(startDate ?? '', 'yyyy-MM-dd').toLocaleString({
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
		});
		const currentDayValue = DateTime.fromFormat(startDate ?? '', 'yyyy-MM-dd').toISODate();
		const nextDayLabel = DateTime.fromFormat(startDate ?? '', 'yyyy-MM-dd').plus({ days: 1 }).toLocaleString({
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
		});
		const nextDayValue = DateTime.fromFormat(startDate ?? '', 'yyyy-MM-dd').plus({ days: 1 }).toISODate();

		return (
			<>
				<optgroup label={currentDayLabel}>
					{todayWithBlocked.map(option => (
						<option key={option.key} value={option.value + 'D' + currentDayValue} disabled={option.isBlocked} data-next-day="false" data-test={option.key}>
							{option.value}
						</option>
					))}
				</optgroup>
				<optgroup label={nextDayLabel}>
					{nextDayWithBlocked
						.map(option => (
							<option key={option.key} value={option.value + 'D' + nextDayValue} disabled={option.isBlocked} data-next-day="true" data-test={option.key}>
								{option.value}
							</option>
						))}
				</optgroup>
			</>
		);
	};
	// Show warning when hours exceed drivingslip estimate
	React.useEffect(() => {
		if (typeof selectedDrivingSlip !== 'undefined') {
			setOverEstimate(hoursWatch > Interval.fromISO(`${selectedDrivingSlip?.start ?? ''}/${selectedDrivingSlip?.end ?? ''}`).length('hours'));
		} else {
			setOverEstimate(false);
		}
	}, [hoursWatch, selectedDrivingSlip, selectedDrivingSlip?.end, selectedDrivingSlip?.start]);

	// Logic for showing/hiding and setting default value for indicate lunch checkbox
	const dayIncludesLunch = timeTrackingEntries.some(te => te.includesLunch === true);
	const showDayInlcudesLunchCheckbox = entryForEditing?.includesLunch || !dayIncludesLunch;
	React.useEffect(() => {
		if (entryForEditing?.includesLunch) {
			setIndicateLunch(true);
			setValue('includesLunch', true, { shouldValidate: true });
			return;
		}

		if (typeof startTimeWatch === 'undefined' || typeof endTimeWatch === 'undefined') return;

		if (!editMode && !dayIncludesLunch && isWithinLunchTimeRange(startTimeWatch.split('D')[0], endTimeWatch.split('D')[0])) {
			setIndicateLunch(true);
			setValue('includesLunch', true, { shouldValidate: true });
		} else {
			setIndicateLunch(false);
			setValue('includesLunch', false, { shouldValidate: true });
		}

	}, [dayIncludesLunch, editMode, endTimeWatch, entryForEditing?.includesLunch, setValue, startTimeWatch]);

	// Logic for showing/hiding and indicate on call checkbox
	React.useEffect(() => {
		// 0601: Vagt og udkald / 0600: Tilkaldeordning
		if (jobTaskNoWatch !== '0601' && jobTaskNoWatch !== '0600') return;
		const currentDay = new Date(selectedDate.value).getDay();

		if (typeof startTimeWatch === 'undefined') return;

		const startHour = parseInt(startTimeWatch.split(':')[0]);
		const endHour = parseInt(endTimeWatch.split(':')[0]);

		const startDate = startTimeWatch.split('D')[1];
		const endDate = endTimeWatch.split('D')[1];

		// Adjust showIndicateOnCall to consider startHour and endHour
		const showIndicateOnCall = (
			currentDay === 0 || // Sunday
			currentDay === 6 || // Saturday
			startHour < 7 || 
			(startDate !== endDate && endHour < 7) || 
			(startDate === endDate && endHour >= 15)
		);
		if(jobTaskNoWatch === '0601' ) {
			setShowIndicateOnCall(showIndicateOnCall);
		}

		const showIndicateOnCallback = (
			currentDay === 0 || // Sunday
			currentDay === 6 || // Saturday
			startHour < 6 ||
			(startDate !== endDate && endHour < 6) || 
			(startDate === endDate && endHour >= 16)
		);
		if(jobTaskNoWatch === '0600' ) {
			setShowIndicateOnCallback(showIndicateOnCallback);
		}

	}, [endTimeWatch, selectedDate.value, startTimeWatch, timeTrackingEntries, jobTaskNoWatch]);

	// Prefill onCall checkbox if entryForEditing has onCall set to true
	React.useEffect(() => {
		if (entryForEditing?.onCall && !entryForEditing?.onCallback) {
			setIndicateOnCall(true);
			setValue('onCall', true, { shouldValidate: true });
		}
		if (entryForEditing?.onCallback) {
			setIndicateOnCallback(true);
			setValue('onCallback', true, { shouldValidate: true });
		}
	}, [entryForEditing?.onCall, entryForEditing?.onCallback, setValue]);

	const includeLunchLabel = editMode ? 'includesLunchEdit' : 'includesLunch';
	const indicateOnCallLabel = editMode ? 'onCallEdit' : 'onCall';
	const indicateOnCallbackLabel = editMode ? 'onCallbackEdit' : 'onCallback';

	// Select user logic for substitution
	const onSelectUser = (value: string): void => {
		if (value !== '') {
			userId.set(value);
			setValue('user', value, { shouldValidate: true });
			const specificUser = usersDataRaw?.searchUsers.find(u => u.id === value);
			substitutionReg?.setUser && substitutionReg?.setUser(specificUser as User);
		} else {
			userId.set(userContext?.id ?? '');
			substitutionReg?.setUser && substitutionReg?.setUser(undefined);
		}
	};

	const onlyOneStandAloneSuppAllowed: boolean = getValues().addonLines?.some(a => a.supplementType === SupplementType.StandAlone);

	const onlyOneDrivingSuppAllowed: boolean = getValues().addonLines?.some(a => a.paymentSupplementCode === '8010' || a.paymentSupplementCode === '8011');

	const standAloneRegistration: boolean = getValues().addonLines?.some(a => a.supplementUom === 'STK');

	const disabledAddSupplements = onlyOneStandAloneSuppAllowed || onlyOneDrivingSuppAllowed;

	// const form = watch();

	// React.useEffect(() => {
	// 	if(typeof entryForEditing !== 'undefined') {
	// 		console.log('Form values edit', form);
	// 	} else {
	// 		console.log('Form values', form);
	// 	}
	// }, [entryForEditing, form, formState]);

	const getOverrulePermission = (allowEditOfTimereg, userContext, timeTrackingsDayDataRaw): boolean => {
		if (allowEditOfTimereg) {
		  return userContext?.permissions?.datePicker ? false : (timeTrackingsDayDataRaw?.timeTrackingDayStatus === TimeTrackingDayStatus.Closed);
		}
		return timeTrackingsDayDataRaw?.timeTrackingDayStatus === TimeTrackingDayStatus.Closed;
	};

	return (
		<>
			<div className={classNames('w-full px-4 text-blue mb-4', { 'lg:w-1/3': !fullWidth })}>
				<div className="p-4 bg-blue-xlight">
					{drivingSlipLoading && casesLoading && typeof drivingSlipDataRaw === 'undefined' && typeof caseDataRaw === 'undefined' ? (
						<div className="relative h-40">
							<Loading />
						</div>
					) : (
						<form ref={formRef} onSubmit={typeof entryForEditing !== 'undefined' ? handleSubmit(beforeUpdateEntry) : handleSubmit(beforeCreateEntry)}>
							<div className="flex flex-col">
								{!editMode && (
									<>
										<div className="flex justify-between items-center">
											<Checkbox
												id="substitute"
												name="substitute"
												title="timeRegistration.substitute"
												innerRef={register}
												checked={substitutionReg.value}
												onChange={() => substitutionReg.set(!substitutionReg.value)}
											/>
											{userContext.permissions.datePicker && (
												<Button text={overruleDate ? 'timeRegistration.lockDate' : 'timeRegistration.unlockDate'} secondary onClick={() => setOverruleDate(!overruleDate)} />
											)}
										</div>

										<div
											className={classNames({
												hidden: !substitutionReg.value,
											})}
										>
											<SearchableSelect
												control={control}
												name="user"
												title="timeRegistration.substituteName"
												options={searchedUsers.filter(u => u.label.toLowerCase().includes(searchUserText.toLowerCase()))}
												searchFn={searchText => setSearchUserText(searchText)}
												onSelect={value => onSelectUser(value)}
												required
												onBlur={() => undefined}
												initialSelection={
													substitutionReg.value
														? {
															label: userId.value.split('@')[0],
															value: userId.value,
														}
														: undefined
												}
												minInputLength={-1}
												isLoading={usersLoading}
												errorMessage={errors.user?.message}
												disabled={!substitutionReg.value}
											/>
										</div>
									</>
								)}

								{enabledSubstituteNo && substitutionReg.value && (
									<p className="text-xs font-semibold">
										{t('common.selectedSubstituteNo')}: {substitutionReg.user?.employeeId ?? ''}
									</p>
								)}

								<p className='font-semibold'>
									{sanitoer.includes(employeeSalaryType ?? '') && 'Sanitør'}
									{industrimedarbejder.includes(employeeSalaryType ?? '') && 'Industrimedarbejder'}
									{funktionaer.includes(employeeSalaryType ?? '') && 'Funktionær'}
									{tomroerSnedker.includes(employeeSalaryType ?? '') && 'Tømrer/snedker'}
									{vagtcentral.includes(employeeSalaryType ?? '') && 'Vagtcentral'}
								</p>

								<div className="flex items-end">
									<div
										className={classNames({
											'w-5/6': !editMode,
											'w-full': editMode,
										})}
									>
										<Datepicker
											name="date"
											title={registerRange ? 'common.dateFrom' : 'common.date'}
											min={overruleDate
												? employeeHireDate
												: setCurrentTimeregDate
											}
											max={overruleDate
												? undefined
												: setCurrentTimeregDate
											}
											onChange={e => {
												selectedDate.set(e.target.value);
												setValue('date', e.target.value);
												setSelectedDateRange(
													DateTime.fromFormat(e.target.value ?? '', 'yyyy-MM-dd')
														.plus({ days: 1 })
														.toISODate(),
												);
											}}
											innerRef={register}
											readOnly={!timeRegistration || editMode}
											errorMessage={errors.date?.message}
										/>
									</div>
									{!editMode && (
										<div className="w-1/6 flex justify-center">
											<Button
												className="text-lg p-1"
												icon={faCalendarPlus}
												onClick={() => {
													setRegisterRange(!registerRange);
													setValue('isPartOfRange', !registerRange);
													setValue('jobNo', 'INTERN', { shouldValidate: true });
													setSelectedDateRange(
														DateTime.fromFormat(selectedDate.value ?? '', 'yyyy-MM-dd')
															.plus({ days: 1 })
															.toISODate(),
													);
												}}
												disabled={timeTrackingEntries.length > 0}
											/>
										</div>
									)}
								</div>

								{enabledHumanReadableDate && (
									<p className="text-xs font-semibold">
										{t('common.selectedDate')}:{' '}
										{DateTime.fromFormat(selectedDate.value, 'yyyy-MM-dd').toLocaleString({
											weekday: 'long',
											month: 'long',
											day: '2-digit',
											year: 'numeric',
										})}
									</p>
								)}

								{registerRange && (
									<div className="flex items-end">
										<div className="w-5/6">
											<Datepicker
												name="rangeEndDate"
												title="common.dateTo"
												defaultValue={selectedDateRange}
												min={DateTime.fromFormat(selectedDate.value ?? '', 'yyyy-MM-dd')
													.plus({ days: 1 })
													.toISODate()}
												onChange={e => {
													setSelectedDateRange(e.target.value);
													setValue('rangeEndDate', e.target.value);
												}}
												innerRef={register}
												disabled={!timeRegistration || !registerRange || editMode}
											/>
										</div>
										<div className="w-1/6 flex justify-center items-end p-1">
											<Popover
												placement="top"
												content={
													<div className="rounded-b-default border-1 shadow-default border-black bg-white p-2 text-xs">
														<p className="font-bold">Registrering over flere dage:</p>
														<p className="font-bold">Weekender/helligdage bliver automatisk ignoreret ved oprettelse</p>
														<p className="font-bold">Automatiske tillæg bliver ikke tilføjet</p>
													</div>
												}
											>
												<div>
													<FontAwesomeIcon icon={faInfoCircle} />
												</div>
											</Popover>
										</div>
									</div>
								)}

								{registerRange && enabledHumanReadableDate && (
									<p className="text-xs font-semibold">
										{t('common.selectedDate')}:{' '}
										{DateTime.fromFormat(selectedDateRange ?? '', 'yyyy-MM-dd').toLocaleString({
											weekday: 'long',
											month: 'long',
											day: '2-digit',
											year: 'numeric',
										})}
									</p>
								)}

								<FormFieldHeader title="Tidspunkt" />
								<div className="flex flex-row">
									<div className="w-1/2">
										<Controller
											control={control}
											name="startTime"
											rules={{ required: !standAloneRegistration }}
											render={(field) => (
												<select
													{...field}
													className="border-1 rounded-default block w-full border-gray-600 p-1 focus:outline-none"
													onChange={e => {
														field.onChange(e.target.value);

														const selectElement = e.target;
														const selectedIndex = selectElement.selectedIndex;
														const nextIndex = selectedIndex + 1;

														if (nextIndex < selectElement.options.length && typeof entryForEditing === 'undefined') {
															const nextOption = selectElement.options[nextIndex];
															const nextValue = nextOption.value;
															setValue('startDate', nextValue.split('D')[1]);
															setValue('endTime', nextValue);
														}
													}}
													disabled={standAloneRegistration}
												>
													{generatetimeSlotHTMLOptions(selectedDate.value, timeTrackingEntries as TimeTrackingEntry[], entryForEditing?.id)}
												</select>
											)}
										/>
									</div>
									<div className="w-8"></div>
									<div className="w-1/2">
										<Controller
											control={control}
											name="endTime"
											rules={{ required: !standAloneRegistration }}
											render={(field) => (
												<select
													{...field}
													className="border-1 rounded-default block w-full border-gray-600 p-1 focus:outline-none"
													onChange={e => {
														field.onChange(e.target.value);
														setValue('endDate', e.target.value.split('D')[1]);
													}}
													disabled={standAloneRegistration}
												>
													{generatetimeSlotHTMLOptions(selectedDate.value, timeTrackingEntries as TimeTrackingEntry[], entryForEditing?.id)}
												</select>
											)}
										/>
										{errors.endTime?.message && <p className="text-red text-sm font-semibold">{errors.endTime?.message}</p>}
									</div>
								</div>

								{showDayInlcudesLunchCheckbox &&
									<div className="mt-2">
										<Checkbox
											id={includeLunchLabel}
											name="includesLunch"
											title="timeRegistration.indicateLunch"
											innerRef={register}
											checked={indicateLunch}
											onChange={(e) => setIndicateLunch(!indicateLunch)}
											errorMessage={errors.includesLunch?.message}
										/>
									</div>
								}

								<Input
									name="hours"
									title="common.hours"
									type="number"
									step={0.01}
									lang="da"
									inputMode="decimal"
									innerRef={register({
										valueAsNumber: true,
									})}
									readOnly
									disabled={standAloneRegistration}
									errorMessage={errors.hours?.message}
								/>
								{overEstimate && <p className="text-orange text-sm font-semibold">{t('timeRegistration.exceedesEstimate')}</p>}

								{control.formState.dirtyFields.hours && (
									<p className="mt-2 font-bold">
										<FontAwesomeIcon icon={faExclamationCircle} /> {t('timeRegistration.rememberSupplement')}
									</p>
								)}

								<Dropdown
									name="task"
									title="timeRegistration.task"
									data={registerRange ? [{ label: 'Intern', value: 'INTERN' }] : [{ label: '', value: '' }, ...activityCode]}
									innerRef={register}
									required
									onChange={e => clearTaskFields()}
									errorMessage={errors.task?.message}
								/>

								{taskWatch !== 'INTERN' && taskWatch !== '' ? ( // Check if it's case otherwise select intern time code
									<div>
										<SearchableSelect
											control={control}
											name="case"
											title="common.case"
											options={searchedCases.filter(c => c.label.toLowerCase().includes(caseSearchText.toLowerCase()))}
											searchFn={searchText => setCaseSearchText(searchText)}
											onSelect={value => {
												setValue('case', value, {
													shouldValidate: true,
												});
												setSelectedCase({
													label: '',
													value: erpNoCasesRaw?.cases.find(c => c.id === value)?.erpNo ?? '',
												});
												setValue('jobNo', [...(caseDataRaw?.cases ?? []), ...(erpNoCasesRaw?.cases ?? [])].find(c => c.id === value)?.erpNo ?? null);
												setValue('debitor', erpNoCasesRaw?.cases.find(c => c.id === value)?.debitor.company ?? '');
											}}
											required
											onBlur={() => undefined}
											minInputLength={2}
											isLoading={casesLoading}
											initialSelection={selectedCase}
											className={classNames('w-full lg:w-full', {
												'border-red border-2': !timeRegistration,
											})}
											disabled={taskWatch === ''}
											errorMessage={errors.case?.message}
										/>
										<Input name="jobNo" innerRef={register} className="hidden" />
									</div>
								) : (
									<div className="hidden">
										<Input name="jobNo" title="timeRegistration.internTask" innerRef={register} defaultValue="INTERN" />
									</div>
								)}

								{selectedCase && !timeRegistration && (
									<div>
										<p className="text-red font-medium">{t('timeRegistration.stoppedOnCase')}</p>
									</div>
								)}

								<div
									className={classNames('w-full', {
										hidden: taskWatch !== 'DRIVINGSLIP',
									})}
								>
									<Dropdown
										name="drivingSlip"
										title="common.drivingSlip"
										data={[{ label: '', value: '' }, ...drivingSlips]}
										innerRef={register}
										onChange={e => {
											const value = e.target.value;
											setValue('drivingSlip', value);
											const drivingslip = drivingSlipsNoCaseDataRaw?.drivingSlips?.find(ds => ds.id === value);
											setSelectedDrivingSlip({ start: drivingslip?.start, end: drivingslip?.end });
										}}
										errorMessage={errors.drivingSlip?.message}
									/>
								</div>

								<SearchableSelect
									control={control}
									name="jobTaskNo"
									title="offer.jobTask"
									options={jobTasks
										.filter(c => c.label.toLowerCase().includes(jobSearchText.toLowerCase()))
										.sort((a, b) => {
											if (a.value === '0034') return 1;
											if (b.value === '0034') return -1;
											return a.label.localeCompare(b.label);
										})
									}
									searchFn={searchText => setJobSearchText(searchText)}
									onSelect={value => {
										setValue('jobTaskNo', value, { shouldValidate: true });
										setValue('jobTaskName', jobTaskDataRaw?.jobTasks?.find(t => t?.jobTaskNo === value)?.description ?? '', { shouldValidate: true });
										setValue('workTypeCode', jobTaskDataRaw?.jobTasks?.find(t => t?.jobTaskNo === value)?.workTypeCode ?? '', { shouldValidate: true });
									}}
									initialSelection={editMode ? { label: entryForEditing?.jobTaskName ?? '', value: entryForEditing?.jobTaskNo ?? '' } : undefined}
									required
									onBlur={() => undefined}
									allowEmpty
									minInputLength={-1}
									isLoading={jobTaskLoading}
									errorMessage={errors.jobTaskNo?.message}
								/>

								{(showIndicateOnCall && jobTaskNoWatch === '0601') && ( // "Vagt og udkald timer"-task
									<div>
										<Checkbox
											id={indicateOnCallLabel}
											name="onCall"
											title="timeRegistration.indicateOnCall"
											onChange={() => setIndicateOnCall(!indicateOnCall)}
											checked={indicateOnCall}
											className="mt-2"
											innerRef={register}
											disabled={funktionaer.includes(employeeSalaryType ?? '') || (Number(hoursWatch ?? 0) >= 2.5) || (indicateOnCallback && indicateOnCallback)}
										/>

										<p>{t('timeRegistration.onCallDescription')}</p>
									</div>
								)}

								{(showIndicateOnCallback && jobTaskNoWatch === '0600') && ( // "Tilkaldordning"-task
									<div>
										<Checkbox
											id={indicateOnCallbackLabel}
											name="onCallback"
											title="timeRegistration.indicateOnCallback"
											onChange={() => setIndicateOnCallback(!indicateOnCallback)}
											checked={indicateOnCallback}
											className="mt-2"
											innerRef={register}
											disabled={funktionaer.includes(employeeSalaryType ?? '') || (Number(hoursWatch ?? 0) >= 3) || (indicateOnCall && indicateOnCall)}
										/>

										<p>
											<Trans
												t={t}
												i18nKey="timeRegistration.onCallbackDescription"
												components={[<strong />]}
											/>
										</p>
									</div>
								)}

								{jobTaskNoWatch === '1900' && ( // "Akkord"-task
									<p className="mt-2">{t('timeRegistration.pieceWorkDescription')}</p>
								)}

								{(pieceworkValidation && jobTaskNoWatch === '1902') && ( // "Akkord"-task
									<p className="mt-2">{t('timeRegistration.rememberPieceworkSupps')}</p>
								)}

								<Input name="workTypeCode" innerRef={register} className="hidden" />

								<Textarea
									name="remark"
									title="common.description"
									rows={2}
									maxLength={100}
									innerRef={register}
									disabled={!timeRegistration}
									errorMessage={errors.remark?.message}
								/>
							</div>

							{!registerRange &&
								<div>
									<div className="border-b-1 mt-3 font-semibold">{t('timeRegistration.supplement')}</div>
									{editMode
										? fields
											.filter(a => funktionaer.includes(employeeSalaryType) ? !automaticSupplementsFunk.includes(a?.paymentSupplementCode ?? '') : !automaticSupplements.includes(a?.paymentSupplementCode ?? ''))
											.map((field, index) => {
												return (
													<SupplementLineNew
														key={field.id}
														lineIndex={index}
														paymentSupplements={paymentSupplements}
														paymentSupplementOptions={paymentSupplementsSelect}
														removeLine={() => {
															const entrySupplement = field as unknown as TimeTrackingSupplement;
															entrySupplement.bcStatus !== TimeTrackingStatus.Approved && remove(index);
														}}
														fieldRegister={register}
														fieldRegisterNumber={register({ valueAsNumber: true })}
														timeRegistration
														setValue={setValue}
														getValues={getValues}
														setError={setError}
														errors={errors}
														supplementEntry={field as TimeTrackingSupplementUpdateInputType}
														clearErrors={clearErrors}
													/>
												);
											},
											)
										: fields.map((field, index) => {
											return (
												<SupplementLineNew
													key={field.id}
													lineIndex={index}
													paymentSupplements={paymentSupplements}
													paymentSupplementOptions={paymentSupplementsSelect}
													removeLine={() => remove(index)}
													fieldRegister={register}
													fieldRegisterNumber={register({ valueAsNumber: true })}
													timeRegistration
													setValue={setValue}
													getValues={getValues}
													setError={setError}
													errors={errors}
													clearErrors={clearErrors}
												/>
											);
										})
									}

									<TextButton
										icon={faPlus}
										text="timeRegistration.addSupplement"
										onClick={() => append(timeTrackingSuppplementLine)}
										className="mt-3"
										disabled={disabledAddSupplements}
									/>
								</div>
							}

							<div className="flex justify-between">
								{editMode
									? (
										<div className="flex mt-3">
											<Button
												submit
												primary
												text={typeof entryForEditing === 'undefined' ? 'common.register' : 'common.update'}
												disabled={
													!timeRegistration ||
													!formState.isValid ||
													getOverrulePermission(allowEditOfTimereg, userContext, timeTrackingsDayDataRaw)
												}
												loading={createTimeTrackingLoading || updateTimeTrackingLoading}
											/>

											<Button
												secondary
												text={'common.reset'}
												className="ml-2"
												onClick={() => resetForm()}
											/>
										</div>
									)
									: <Button
										submit
										primary
										text={'common.register'}
										className="mt-3"
										disabled={
											!timeRegistration ||
											!formState.isValid ||
											getOverrulePermission(allowEditOfTimereg, userContext, timeTrackingsDayDataRaw) ||
											entryHasEndtimeNextDayLimit
										}
										loading={createTimeTrackingLoading || updateTimeTrackingLoading}
									/>
								}
								{(!editMode && !rejectMode) && (
									<Button
										primary
										text="timeRegistration.closeDay"
										className="mt-3"
										onClick={async () => {
											setShowCloseDayModal(true);
											closeRejectModal && closeRejectModal();
										}}
										disabled={submitLoading || timeTrackingsDayDataRaw?.timeTrackingDayStatus === TimeTrackingDayStatus.Closed}
										loading={submitLoading}
									/>
								)}
								{rejectMode && (
									<Button
										primary
										text="timeRegistration.closeDay"
										className="mt-3"
										onClick={async () => setShowCloseDayModal(true)}
										disabled={submitLoading}
										loading={submitLoading}
									/>
								)}
							</div>
							{(entryHasEndtimeNextDayLimit && !editMode) && <p className="text-red text-sm font-semibold mt-1">{t('timeRegistration.endTimeNextDayLimit')}</p>}
							{employeeSalaryType === '' && <p className="text-red text-sm font-semibold mt-1">{t('timeRegistration.noSalaryType')} <a href="mailto:servicedesk@belfor.dk">servicedesk@belfor.dk</a></p>}
							{(timeTrackingsDayDataRaw?.timeTrackingDayStatus === TimeTrackingDayStatus.Closed &&
								timeTrackingEntries.flatMap(te => te.bcStatus).some(e => e === TimeTrackingStatus.Open)) && (
								<p className="text-red text-sm font-semibold mt-1">{t('timeRegistration.dayIncludesChanges')}</p>
							)}
						</form>
					)}
				</div>
			</div>

			<Modal
				title="timeRegistration.registrationExceedsBasisTime"
				visible={showOvertimeWarning}
				nestedModal
				close={() => setShowOvertimeWarning(false)}
				body={
					<div>
						<p>
							{t('timeRegistration.registratingWillExceedBasisTime')} <strong className="mr-1">{basisTimeRaw?.userBasisTime ?? 0}</strong>
							<span className="mr-1 lowercase">{t('timeRegistration.totalHours')}.</span>
							<Trans t={t} i18nKey="timeRegistration.overtimeSuppWillBeAdded" components={[<strong />]} />
						</p>

						<p className="italic">{t('timeRegistration.checkTimeDraft')}</p>

						<div className="mt-2 flex justify-between">
							<Button
								secondary
								text="common.cancel"
								onClick={() => {
									setShowOvertimeWarning(false);
									setAllowOvertime(false);
								}}
							/>
							<Button
								primary
								text={editMode ? 'common.update' : 'common.register'}
								onClick={() => {
									setAllowOvertime(true);
									setTimeout(() => {
										remoteSubmit();
									}, 0);
								}}
								loading={createTimeTrackingLoading}
							/>
						</div>
					</div>
				}
			/>

			<Modal
				title="timeRegistration.closeDay"
				visible={showCloseDayModal}
				nestedModal
				close={() => setShowCloseDayModal(false)}
				body={
					<div>
						{(userRegisteredHours ?? 0) < (basisTimeRaw?.userBasisTime ?? 0) ? (
							<div>
								<p>
									<Trans
										t={t}
										i18nKey="timeRegistration.hoursRegisteredOnDay"
										values={{
											hours: userRegisteredHours,
										}}
										components={[<strong />]}
									/>
								</p>

								<div className={classNames({ 'invisible': allowOvertime })}>
									<p>
										<Trans
											t={t}
											i18nKey="timeRegistration.notEnoughHoursRegistered"
											values={{
												hours: basisTimeRaw?.userBasisTime ?? 0,
											}}
											components={[<strong />]}
										/>
									</p>
									<p className="mb-4 italic">{t('timeRegistration.useFlexHours')}</p>
								</div>
							</div>
						) : (
							<div>
								<Trans
									t={t}
									i18nKey="timeRegistration.wantToCloseDay"
									values={{
										date: timeTrackingsDayDataRaw?.isPartOfRange
											? DateTime.fromISO(timeTrackingsDayDataRaw?.rangeStartDate ?? '').toFormat('dd/MM/yyyy')
											: DateTime.fromISO(selectedDate.value).toFormat('dd/MM/yyyy'),
									}}
								/>
								{timeTrackingsDayDataRaw?.isPartOfRange && (
									<span>{` - ${DateTime.fromISO(timeTrackingsDayDataRaw?.rangeEndDate ?? '').toFormat('dd/MM/yyyy')}`}</span>
								)}
								?
							</div>
						)}

						{accOvetimeHours > 0 && (
							<>
								{accOvetimeHours === 1 ? (
									<Trans
										t={t}
										i18nKey="timeRegistration.totalOvertime_one"
										values={{
											hours: accOvetimeHours,
										}}
										components={[<strong />]}
									/>
								) : (
									<Trans
										t={t}
										i18nKey="timeRegistration.totalOvertime_many"
										values={{
											hours: accOvetimeHours,
										}}
										components={[<strong />]}
									/>
								)}
							</>
						)}

						<div className="mt-2 flex justify-between">
							<Button secondary text="common.cancel" onClick={() => setShowCloseDayModal(false)} />
							<Button
								primary
								text="timeRegistration.closeDay"
								onClick={async () => {
									await closeDay({
										date: dayEntry.date,
										id: dayEntry.id,
										user: dayEntry.user,
										isPartOfRange: dayEntry.isPartOfRange,
									});
									setShowCloseDayModal(false);
									closeRejectModal && closeRejectModal();
									resetForm();
									const date = DateTime.fromFormat(dayEntry.isPartOfRange ? dayEntry.rangeEndDate ?? '' : selectedDate.value ?? '', 'yyyy-MM-dd')
										.plus({ days: 1 })
										.toISODate();
									selectedDate.set(date);
									setValue('date', date);
									const latestDayData = await refetchLatestDay();
									if (typeof latestDayData !== 'undefined') {
										latestTimeregCallback(latestDayData.data);
									}
									(rejectMode && refetchRejected) && refetchRejected();
									await refetchTimeTrackingsDay();
								}}
								disabled={submitLoading || closeDayCannotBeOverruled}
								loading={submitLoading}
							/>
						</div>
					</div>
				}
			/>
		</>
	);
};

export default TimeRegistrationForm;
