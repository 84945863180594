import React from 'react';
import classNames from 'classnames';
import Loading from '../Components/Loading';
import SchedulerEvent from './SchedulerEvent';
import { useTranslation } from 'react-i18next';
import { BaseTimeTrackingEntryFragment, SupplementType } from '../GraphQL/indexV2';

interface DaySchedulerProps {
  dayId: string;
  entries: BaseTimeTrackingEntryFragment[];
  noDataFoundText: string;
  loading: boolean;
  setShowEditTimereg: (value: boolean) => void;
  setActiveTimeEntry: (value: BaseTimeTrackingEntryFragment) => void;
  compactMode?: boolean;
  standAloneMode?: boolean;
}

const DayScheduler: React.FC<DaySchedulerProps> = ({ dayId, entries, noDataFoundText, loading, setShowEditTimereg, setActiveTimeEntry, compactMode = false, standAloneMode = false }) => {
  const { t } = useTranslation();

  const scrollToLastElement = () => {
    if(entries.length === 0 || loading) {
      return;
    }
    const lastElement = document.getElementsByClassName('entry');
    if (lastElement.length > 0) {
      lastElement[lastElement.length - 1].scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  React.useEffect(() => {
    scrollToLastElement();
  });

  const timeSlots = Array.from({ length: 96 + 1 }, (_, i) => {
    const totalMinutes = i * 15;
    const hours = (Math.floor(totalMinutes / 60) + 7) % 24;
    const minutes = totalMinutes % 60;
    const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${minutes === 0 ? '00' : minutes}`;
    const isNextDay = i >= 17 * 4; // 68 slots represent 17 hours from 7am to midnight
    const day = isNextDay ? 'nextDay' : 'currentDay';
    return { time: formattedTime, showHour: minutes === 0, day };
  });

  const renderEventsForTimeSlot = ({ time, day }) => {
    const entriesForTimeSlot = entries.filter(entry => {
      const entryTime = entry.startTime; // Extract the time part (HH:mm) from entry startTime
      const entryDay = entry.startDate === entry.endDate ? 'currentDay' : 'nextDay';
      return entryTime === time && entryDay === day;
    });
  
    return entriesForTimeSlot.map(entry => (
      <SchedulerEvent 
        key={entry?.id ?? '' + entry?.startTime ?? ''}
        dayId={dayId}
        entry={entry}
        readOnlyMode={false}
        setShowEditTimereg={setShowEditTimereg}
        setActiveTimeEntry={setActiveTimeEntry}
        compactMode={compactMode}
      />
    ));
  };

  return (
    <div>
      {!compactMode &&
        <div className="p-2 flex flex-row bg-white border-b-1 border-gray-400">
          <div className="w-14">{t('timeRegistration.time')}</div>
          <div className="w-5/12 pl-3">{t('common.case')}</div>
          <div className="w-2/12">{t('offer.jobTask')}</div>
          <div className="w-3/12">{t('common.comment')}</div>
          <div className="w-1/12">{t('common.hours')}</div>
          <div className="w-1/12"></div>
        </div>
      }

      <div
        className={classNames('overflow-y-auto bg-white', {'mt-4': standAloneMode})}
        style={{ height: compactMode ? '100%' : '74vh' }}
      >
        <div className="flex flex-row">
          {!compactMode &&
            <div className="timeslot pl-2">
              {timeSlots.map(({ time, showHour }, index) => (
                <div
                  key={`timeslot-${index}`}
                  className={classNames('timeslot h-12 w-14 pr-4 border-r-1 border-gray-400', {
                    'border-t-1': showHour,
                  })}>
                    {showHour && time}
                </div>
              ))}
            </div>
          }
          <div className="w-full registration">
            {!standAloneMode && loading && (
              <div className="relative h-40">
                <Loading />
              </div>
            )}
            {!standAloneMode && !loading && noDataFoundText && entries.length === 0 && <p className="text-blue text-center text-2xl">{t(noDataFoundText)}</p>}
            
            {!loading && !compactMode && entries.length > 0 && timeSlots.map(({ time, showHour, day }, index) => (
              <div key={`timreg-${index}`} className={classNames('h-12 w-full relative border-t-1', {
                'border-gray-400': showHour,
                'border-gray-300': !showHour,
                'border-t-3 border-dotted border-gray-800': time === '00:00',
              })}>
                {renderEventsForTimeSlot({ time, day })}
              </div>
            ))}

            {compactMode &&
              <div className="w-full relative flex flex-col">
                {entries
                  .slice()
                  .sort((a, b) => b.endTime.localeCompare(a.endTime))
			            .sort((a, b) => b.endDate.localeCompare(a.endDate))
                  .reverse()
                  // If standAloneMode filter out entries that contain a stand-alone supplement
                  .filter(entry => standAloneMode ? entry.addonLines.some(a => a.supplementType === SupplementType.StandAlone) : !entry.addonLines.some(a => a.supplementType === SupplementType.StandAlone))
                  .map(entry => (
                    <SchedulerEvent
                      key={'compact' + entry?.id ?? '' + entry?.startTime ?? ''}
                      dayId={dayId}
                      entry={entry}
                      readOnlyMode={false}
                      standAlone={standAloneMode}
                      setShowEditTimereg={setShowEditTimereg}
                      setActiveTimeEntry={setActiveTimeEntry}
                      compactMode={compactMode}
                    />
                  ),
                )}
              </div>
            }
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default DayScheduler;
