import { IonCol, IonGrid, IonRow } from '@ionic/react';
import FormErrorText from '@ssg/common/Components/FormErrorText';
import { fileToInput } from '@ssg/common/Helpers/fileTypeHelper';
import { toggleArrayValue } from '@ssg/common/Helpers/toggleArrayValue';
import { removeTypenameGeneric } from '@ssg/common/Helpers/typenameHelper';
import { useStorageState } from '@ssg/common/Hooks/useLocalStorage';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from 'Components/Button';
import Checkbox from 'Components/Checkbox';
import BasePage from 'Components/Layout/BasePage';
import { ESDHFileFragment, GetDrivingSlipFiles_drivingSlipFiles, GetMobileV2DrivingSlip_drivingSlip_case, IndoorClimateMeassurementInput, IndoorClimateReportFormDataInput, ReportFormatType } from 'GQL';
import DrivingSlipMultiImageSelect from './Inputs/DrivingSlipMultiImageSelect';
import DrivingSlipSingleImageSelect from './Inputs/DrivingSlipSingleImageSelect';
import IndoorClimateMeassurementForm from './Inputs/IndoorClimateMeassurementForm';
import TextAreaInput from './Inputs/TextAreaInput';
import TextInput from './Inputs/TextInput';
import ReportStaticInfo from './ReportStaticInfo';
import useDrivingSlipImages from './useDrivingSlipImages';
import { useFlag } from '@unleash/proxy-client-react';
import { checkFilesExist, tryGetFile } from './checkFilesExist';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import Datepicker from '@ssg/common/Components/Datepicker';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { ApiMutationKey, useCreateIndoorClimateReport } from 'Store';
import dateToReportNameString from 'Helpers/dateHelper';
import { updateReportsCacheNew } from './updateReportsCache';
import { isConnected } from 'NetworkContext';

interface Props {
	drivingSlipId: string;
	drivingSlipCase: GetMobileV2DrivingSlip_drivingSlip_case;
}

interface ReportError {
	key: keyof IndoorClimateReportFormDataInput;
}
const throwErrorMessage = (key: keyof IndoorClimateReportFormDataInput, reportErrors: ReportError[]): string | undefined => {
	const isError = reportErrors.filter(re => re.key === key).length > 0;
	if (isError) return 'error.required';
	return;
};

const IndoorClimateReportForm: React.FC<Props> = ({ drivingSlipId, drivingSlipCase }): React.ReactElement => {
	const { t } = useTranslation();
	const mobileFacadeImageSelectFlag = useFlag(FeatureFlagEnums.MOBILE_FACADE_IMAGE_SELECT);
	const saveButtonReportsFlag = useFlag(FeatureFlagEnums.SAVE_BUTTON_REPORTS);
	const history = useHistory();
	const enableIndoorClimateReportWordButton = useFlag(FeatureFlagEnums.INDOOR_CLIMATE_REPORT_WORD_BUTTON);
	const reportFormat = React.useRef<ReportFormatType>();

	const { images } = useDrivingSlipImages(drivingSlipCase.erpNo, drivingSlipId);

	const useLocalStorage = <T,>(name: string, initialValue: T) => useStorageState<T>(window.localStorage, `${drivingSlipCase.id}-indoor-climate-${name}`, initialValue);

	// Form values
	const [facadeImage, setFacadeImage] = useLocalStorage<ESDHFileFragment | null>('facadeImage-indoorClimate', null);
	const [inspectionDate, setInspectionDate] = useLocalStorage('inspectionDate-indoorClimate', formatDateForInput(new Date()));
	const [purpose, setPurpose] = useLocalStorage('purpose-indoorClimate', '');
	const [buildYear, setBuildYear] = useLocalStorage('buildYear-indoorClimate', '');
	const [squareMeters, setSquareMeters] = useLocalStorage('squareMeters-indoorClimate', '');
	const [dailyUsers, setDailyUsers] = useLocalStorage('dailyUsers-indoorClimate', '');
	const [participants, setParticipants] = useLocalStorage('participants-indoorClimate', '');
	const [backgroundStory, setBackgroundStory] = useLocalStorage('backgroundStory-indoorClimate', '');
	const [showLabAnswers, setShowLabAnswers] = useLocalStorage('showLabAnswers-indoorClimate', false);
	const [showDataLog, setShowDataLog] = useLocalStorage('showDataLog-indoorClimate', false);
	const [indoorClimateMeassurements, setIndoorClimateMeassurements] = useLocalStorage<IndoorClimateMeassurementInput[]>('indoorClimateMeassurements-indoorClimate', []);
	const [observations, setObservations] = useLocalStorage('observations-indoorClimate', '');
	const [assessmentAndConclusion, setAssessmentAndConclusion] = useLocalStorage('assessmentAndConclusion-indoorClimate', '');
	const [suggestedActionPlan, setSuggestedActionPlan] = useLocalStorage('suggestedActionPlan-indoorClimate', '');
	const [floorplanImage, setFloorplanImage] = useLocalStorage<ESDHFileFragment | null>('floorplanImage-indoorClimate', null);
	const [otherImages, setOtherImages] = useLocalStorage<ESDHFileFragment[]>('otherImages-indoorClimate', []);

	const [submitted, setSubmitted] = React.useState(false);
	const [saved, setSaved] = React.useState(false);
	const [loadingType, setLoadingType] = React.useState<'CREATE' | 'SAVE'>('CREATE');
	const [facadeImageError, setFacadeImageError] = React.useState(false);
	const [reportErrors, setReportErrors] = React.useState<ReportError[]>([]);
	const isSetRef = React.useRef(false);
	React.useEffect(() => {
		const formData = removeTypenameGeneric(drivingSlipCase.caseReports?.indoorClimateReportFormData);
		if (formData && !isSetRef.current) {
			if (formData.inspectionDate) setInspectionDate(formatDateForInput(new Date(formData.inspectionDate)));
			if (formData.purpose) setPurpose(formData.purpose);
			if (formData.buildYear) setBuildYear(formData.buildYear);
			if (formData.squareMeters) setSquareMeters(formData.squareMeters);
			if (formData.dailyUsers) setDailyUsers(formData.dailyUsers);
			if (formData.participants) setParticipants(formData.participants);
			if (formData.backgroundStory) setBackgroundStory(formData.backgroundStory);
			if (formData.showLabAnswers) setShowLabAnswers(formData.showLabAnswers);
			if (formData.showDataLog) setShowDataLog(formData.showDataLog);
			if (formData.indoorClimateMeassurements) setIndoorClimateMeassurements(formData.indoorClimateMeassurements);
			if (formData.observations) setObservations(formData.observations);
			if (formData.assessmentAndConclusion) setAssessmentAndConclusion(formData.assessmentAndConclusion);
			if (formData.suggestedActionPlan) setSuggestedActionPlan(formData.suggestedActionPlan);

			if (formData.facadeImage) {
				const sanitizedFacadeImage = checkFilesExist([formData.facadeImage], images)[0];
				if (sanitizedFacadeImage) {
					setFacadeImage(tryGetFile(sanitizedFacadeImage.fileName, images));
				}
			}
			if (formData.floorplanImage) {
				const sanitizedFloorPlanImage = checkFilesExist([formData.floorplanImage], images)[0];
				if (sanitizedFloorPlanImage) {
					setFloorplanImage(tryGetFile(sanitizedFloorPlanImage.fileName, images));
				}
			}
			const sanitizedOtherImages = checkFilesExist(formData.otherImages ?? [], images);
			if (sanitizedOtherImages.length > 0) {
				setOtherImages(
					sanitizedOtherImages.reduce((images: GetDrivingSlipFiles_drivingSlipFiles[], otherImage) => {
						const file = tryGetFile(otherImage.fileName, images);
						if (file) {
							images.push(file);
						}
						return images;
					}, []),
				);
			}
		}
		isSetRef.current = true;
	}, [
		drivingSlipCase.caseReports?.indoorClimateReportFormData,
		images,
		setAssessmentAndConclusion,
		setBackgroundStory,
		setBuildYear,
		setDailyUsers,
		setFacadeImage,
		setFloorplanImage,
		setIndoorClimateMeassurements,
		setInspectionDate,
		setObservations,
		setOtherImages,
		setParticipants,
		setPurpose,
		setShowDataLog,
		setShowLabAnswers,
		setSquareMeters,
		setSuggestedActionPlan,
	]);

	React.useEffect(() => {
		if (submitted) {
			setFacadeImageError(facadeImage == null);

			const errors = errorCheck({ buildYear });
			setReportErrors(errors);
		}
	}, [buildYear, facadeImage, submitted]);

	// Setup mutation to create report
	const [createIndoorClimateReport, { loading: submitting }] = useCreateIndoorClimateReport();

	const errorCheck = (formData: Pick<IndoorClimateReportFormDataInput, 'buildYear'>) => {
		const errors: ReportError[] = [];

		if (!formData.buildYear || formData.buildYear.length === 0) {
			errors.push({ key: 'buildYear' });
		}

		return errors;
	};

	async function submitReport(reportFormatType: ReportFormatType) {
		reportFormat.current = reportFormatType;
		setSubmitted(true);

		if (facadeImage == null) {
			setFacadeImageError(true);
		}

		if (facadeImage == null) {
			return;
		}

		const thisFacadeImage = images.find(image => image.name === facadeImage.name) ?? facadeImage;
		const thisFloorplanImage = images.find(image => image.name === floorplanImage?.name) ?? floorplanImage;
		const thisOtherImages = otherImages?.map(oi => images.find(image => image.name === oi.name) ?? oi);

		const formData = {
			inspectionDate: formatDateForInput(new Date(inspectionDate)),
			purpose,
			buildYear,
			squareMeters,
			dailyUsers,
			participants,
			backgroundStory,
			showLabAnswers,
			showDataLog,
			indoorClimateMeassurements,
			observations,
			assessmentAndConclusion,
			suggestedActionPlan,
			facadeImage: fileToInput(thisFacadeImage),
			floorplanImage: thisFloorplanImage ? fileToInput(thisFloorplanImage) : null,
			otherImages: thisOtherImages.map(fileToInput),
		};

		const errors = errorCheck(formData);
		setReportErrors(errors);

		if (errors.length > 0 || facadeImage == null) {
			return;
		}

		try {
			await createIndoorClimateReport({
				variables: {
					caseId: drivingSlipCase.id,
					formData: formData,
					reportFormat: reportFormat.current,
					onlySave: false,
				},
				onOptimisticResponseOverride(cache) {
					const created = new Date().toISOString();
					const createdSplit = dateToReportNameString(created);
					const extension = reportFormat.current === ReportFormatType.PDF ? 'pdf' : 'docx';
					const report = {
						name: `Indeklimarapport_${drivingSlipCase.erpNo}_${createdSplit}.${extension} (Oprettet Offline, kan ikke tilgås endnu)`,
						comments: null,
						created: created,
						extension: extension,
						fileId: created,
						groupingKey: '',
						metadata: [{ key: 'DokumentType', value: 'Rapport' }],
						thumbnail: '',
						url: 'report.' + extension,
						__typename: 'ESDHFile',
					};

					if (!isConnected()) {
						updateReportsCacheNew(drivingSlipCase.erpNo, report, cache);
					}
					// const cachedRequest = cache.readQuery<GetCaseFiles, GetCaseFilesVariables>({
					// 	query: GET_CASE_FILES,
					// 	variables: {
					// 		caseErpNo: drivingSlipCase.erpNo,
					// 		folder: drivingSlipCase.erpNo,
					// 	},
					// });
					// console.log(cachedRequest);
					// console.log([report, ...(cachedRequest?.caseFiles ?? [])]);
					// cache.writeQuery<GetCaseFiles, GetCaseFilesVariables>({
					// 	query: GET_CASE_FILES,
					// 	variables: {
					// 		caseErpNo: drivingSlipCase.erpNo,
					// 		folder: drivingSlipCase.erpNo,
					// 	},
					// 	data: {
					// 		caseFiles: [report, ...(cachedRequest?.caseFiles ?? [])],
					// 	},
					// });
				},
				queueOfflineMutationOptions: {
					key: ApiMutationKey.DrivingSlip_CreateIndoorClimateReport,
					removePreviousEntry: true,
					findPreviousEntryPredicate(variables) {
						return variables.caseId === drivingSlipCase.id;
					},
				},
			});

			// // Remove state from local storage
			// clearFacadeImage();
			// clearPurpose();
			// clearBuildYear();
			// clearSquareMeters();
			// clearDailyUsers();
			// clearParticipants();
			// clearBackgroundStory();
			// clearShowLabAnswers();
			// clearShowDataLog();
			// clearIndoorClimateMeassurements();
			// clearObservations();
			// clearAssessmentAndConclusion();
			// clearSuggestedActionPlan();
			// clearFloorplanImage();
			// clearOtherImages();

			history.goBack();
		} catch (e) {
			console.error(e);
		}
	}
	React.useEffect(() => {
		setSaved(false);
	}, [inspectionDate,
		purpose,
		buildYear,
		squareMeters,
		dailyUsers,
		participants,
		backgroundStory,
		showLabAnswers,
		showDataLog,
		indoorClimateMeassurements,
		observations,
		assessmentAndConclusion,
		suggestedActionPlan,
		facadeImage,
		floorplanImage,
		otherImages]);

	async function saveReport() {
		const thisFacadeImage = facadeImage ? images.find(image => image.name === facadeImage.name) ?? facadeImage : null;
		const thisFloorplanImage = floorplanImage ? images.find(image => image.name === floorplanImage.name) ?? floorplanImage : null;
		const thisOtherImages = otherImages?.map(oi => images.find(image => image.name === oi.name) ?? oi);

		const formData: IndoorClimateReportFormDataInput = {
			inspectionDate: formatDateForInput(new Date(inspectionDate)),
			purpose,
			buildYear,
			squareMeters,
			dailyUsers,
			participants,
			backgroundStory,
			showLabAnswers,
			showDataLog,
			indoorClimateMeassurements,
			observations,
			assessmentAndConclusion,
			suggestedActionPlan,
			facadeImage: thisFacadeImage ? fileToInput(thisFacadeImage) : null,
			floorplanImage: thisFloorplanImage ? fileToInput(thisFloorplanImage) : null,
			otherImages: thisOtherImages.map(fileToInput),
		};
		try {
			setLoadingType('SAVE');
			await createIndoorClimateReport({
				variables: {
					caseId: drivingSlipCase.id,
					formData: formData,
					reportFormat: ReportFormatType.PDF,
					onlySave: true,
				},
				queueOfflineMutationOptions: {
					key: ApiMutationKey.DrivingSlip_CreateIndoorClimateReport,
					removePreviousEntry: true,
					findPreviousEntryPredicate(variables) {
						return variables.caseId === drivingSlipCase.id;
					},
				},
			});
			setSaved(true);

		} catch (e) {
			console.error(e);
		}
	}
	return (
		<BasePage title={t('reports.reportTypes.indoorClimate')}>
			<IonGrid className="mb-4 text-sm">
				<IonRow>
					<ReportStaticInfo drivingSlipCase={drivingSlipCase} />

					{mobileFacadeImageSelectFlag ? (
						<DrivingSlipMultiImageSelect
							label={t('reports.uploadFacadeImage')}
							caseNo={drivingSlipCase.erpNo}
							drivingSlipId={drivingSlipId}
							onImageSelect={setFacadeImage}
							selectedImages={facadeImage ? [facadeImage] : []}
						/>
					) : (
						<DrivingSlipSingleImageSelect
							caseNo={drivingSlipCase.erpNo}
							drivingSlipId={drivingSlipId}
							noImageText={t('reports.noFacadeImage')}
							uploadButtonText={t('reports.uploadFacadeImage')}
							onImageSelect={setFacadeImage}
							selectedImage={facadeImage}
						/>
					)}
					{facadeImageError && <FormErrorText text="reports.error.facadeImage" />}

					<Datepicker
						title="reports.inspectionDate"
						value={inspectionDate}
						onChange={e => setInspectionDate(e.target.value)}
						name="reports.inspectionDate"
						className="mx-1"
						titleClass="mx-1"
					/>

					<TextAreaInput label={t('reports.purpose')} placeholder={t('reports.purposePlaceholder')} value={purpose} onChange={setPurpose} />

					<TextAreaInput label={t('reports.backgroundStory')} placeholder={t('reports.backgroundStoryIndoorClimatePlaceholder')} value={backgroundStory} onChange={setBackgroundStory} />
					<IonCol size="12">
						<TextInput
							label={t('reports.buildYear')}
							placeholder={t('reports.buildYearPlaceholder')}
							value={buildYear}
							onChange={setBuildYear}
							errorMessage={throwErrorMessage('buildYear', reportErrors)}
						/>
					</IonCol>
					<IonCol size="12">
						<TextInput label={t('reports.squareMeters')} placeholder={t('reports.squareMetersPlaceholder')} value={squareMeters} onChange={setSquareMeters} />
					</IonCol>
					<IonCol size="12">
						<TextInput label={t('reports.dailyUsers')} placeholder={t('reports.dailyUsersPlaceholder')} value={dailyUsers} onChange={setDailyUsers} />
					</IonCol>

					<TextAreaInput label={t('reports.participants')} value={participants} placeholder={t('reports.participantsPlaceholder')} onChange={setParticipants} />

					<TextAreaInput label={t('reports.observations')} placeholder={t('reports.observationsIndoorClimatePlaceholder')} value={observations} onChange={setObservations} />

					<h2 className="text-blue text-xl font-bold">{t('reports.indoorClimateMeassurements')}</h2>
					<IonCol size="12">
						<Checkbox name="showLabData" title="reports.showLabData" onChange={e => setShowLabAnswers(e.currentTarget.checked)} checkedControlled={showLabAnswers} />
					</IonCol>

					<IonCol size="12">
						<Checkbox name="showDataLog" title="reports.showDataLog" onChange={e => setShowDataLog(e.currentTarget.checked)} checkedControlled={showDataLog} />
					</IonCol>

					<IndoorClimateMeassurementForm indoorClimateMeassurements={indoorClimateMeassurements} onChange={setIndoorClimateMeassurements} />

					<TextAreaInput
						label={t('reports.assessmentAndConclusion')}
						placeholder={t('reports.assessmentAndConclusionIndoorClimatePlaceholder')}
						value={assessmentAndConclusion}
						onChange={setAssessmentAndConclusion}
					/>

					<TextAreaInput
						label={t('reports.suggestedActionPlan')}
						placeholder={t('reports.suggestedActionPlanIndoorClimatePlaceholder')}
						value={suggestedActionPlan}
						onChange={setSuggestedActionPlan}
					/>

					<DrivingSlipSingleImageSelect
						caseNo={drivingSlipCase.erpNo}
						drivingSlipId={drivingSlipId}
						noImageText={t('reports.noFloorplanImage')}
						uploadButtonText={t('reports.uploadFloorplanImage')}
						onImageSelect={setFloorplanImage}
						selectedImage={floorplanImage}
					/>

					{/* TODO: Skimmelsvampe? */}

					<DrivingSlipMultiImageSelect
						caseNo={drivingSlipCase.erpNo}
						label={t('reports.images')}
						drivingSlipId={drivingSlipId}
						onImageSelect={image => setOtherImages(current => toggleArrayValue(current, image, img => img.url))}
						selectedImages={otherImages}
					/>
					<IonCol size="12">
						<Button onClick={() => submitReport(ReportFormatType.PDF)} expand="block" disabled={submitting} loading={submitting && reportFormat.current === ReportFormatType.PDF && loadingType === 'CREATE'}>
							{t('reports.createPdfReport')}
						</Button>
						{enableIndoorClimateReportWordButton && (
							<Button onClick={() => submitReport(ReportFormatType.WORD)} expand="block" disabled={submitting} loading={submitting && reportFormat.current === ReportFormatType.WORD && loadingType === 'CREATE'}>
								{t('reports.createWordReport')}
							</Button>
						)}
						{saveButtonReportsFlag &&
							<Button expand="block" disabled={submitting} loading={submitting && loadingType === 'SAVE'} onClick={() => saveReport()} >
								{t(saved ? 'reports.saved' : 'reports.saveReport')}
							</Button>
						}
					</IonCol>
				</IonRow>
			</IonGrid>
		</BasePage>
	);
};

export default IndoorClimateReportForm;
